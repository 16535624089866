export const InvitationStatus = {
  INVITED: 'Invited',
  EXPIRED: 'Expired',
  COMPLETED: 'Completed',
  CANCELLED: 'Cancelled',
  UNDEFINED: 'Undefined',
};

export const Types = {
  CUSTOMER: '1',
  VENDOR: '2',
  GSA_EMPLOYEE: '3',
  PUBLIC_OR_GENERAL: '4',
};
