import React from 'react';
import PropTypes from 'prop-types';
import { Modal, connectModal, Button } from '@gsa/afp-component-library';
import { useRole } from '../providers';
import './styles/modal.css';

const Title = (props) => {
  const {
    role: { name = 'the role' },
  } = props;
  return <h2>{`Are you sure you want to deactivate ${name}?`}</h2>;
};

Title.defaultProps = {
  role: { name: '' },
};
Title.propTypes = {
  role: PropTypes.objectOf(PropTypes.shape),
};

const Content = () => {
  return (
    <p>
      Once the role is deactivated, you can no longer assign users to this role.
      You can always reactivate the role.
    </p>
  );
};

const Actions = ({ onCancel, onConfirm }) => {
  return (
    <>
      <Button
        label="Cancel"
        variant="unstyled"
        className="padding-right-1"
        onClick={onCancel}
        data-testid="role-deactivate-cancel-btn"
      />
      <Button
        label="Deactivate role"
        variant="secondary"
        type="submit"
        form="role-edit-form"
        data-testid="role-deactivate-confirm-btn"
        onClick={onConfirm}
      />
    </>
  );
};

Actions.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

const ConfirmDeactivationModal = () => {
  const { confirmDeactivation, deactivatingRole, deactivate } = useRole();

  const ConfirmModal = connectModal(() => (
    <Modal
      title={<Title role={deactivatingRole} />}
      actions={
        <Actions
          onCancel={() => confirmDeactivation(false)}
          onConfirm={() => deactivate(deactivatingRole.id)}
        />
      }
      onClose={() => confirmDeactivation(false)}
    >
      <Content />
    </Modal>
  ));

  return <ConfirmModal isOpen={deactivatingRole} />;
};

export default ConfirmDeactivationModal;
