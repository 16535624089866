import { useLazyQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useReducer } from 'react';
import { GET_USER_INVITATIONS, CANCEL_USER_INVITATION } from '../../../services/data-layer';

const UserInvitationContext = createContext({});
const useUserInvitation = () => useContext(UserInvitationContext);

const initialState = {
  error: '',
  feedback: null,
  userInvitations: {
    rows: [],
    count: 0,
    hasMore: false,
  },
  invitationToCancel: null
};

const extractErrorMessage = (err) => err.message || 'Unknown Error';

const actions = {
  setUserInvitations: 'SET_USER_INVITATION_LIST',
  setFeedback: 'SET_FEEDBACK',
  setError: 'SET_ERROR',
  invitationToCancel: 'INVITATION_TO_CANCEL',
};

const userInvitationReducer = (state, { action, payload }) => {
  const {
    setUserInvitations, 
    setFeedback,
    setError,
    invitationToCancel
  } = actions;

  switch (action) {
    case setUserInvitations: {
      return {
        ...state,
        error: null,
        userInvitations: { ...payload } || initialState.userInvitations,
      };
    }
    case setFeedback: {
      return {
        ...state,
        error: null,
        feedback: payload,
      };
    }
    case setError: {
      return { ...state, error: extractErrorMessage(payload) };
    }

    case invitationToCancel: {
      return { ...state, invitationToCancel: payload };
    }
    
    default:
      throw new Error('Invalid role action');
  }
};

const UserInvitationProvider = ({ children, ...props }) => {
  const [state, setDispatch] = useReducer(
    userInvitationReducer,
    initialState,
    () => initialState,
  );

  const dispatch = (action, payload) => setDispatch({ action, payload });

  const setFeedbackMessage = (value) => {
    dispatch(actions.setFeedback, value);
  };

  const [
    getUserInvitations,
    { loading: fetchingUserInvitations, refetch: refetchUserInvitations },
  ] = useLazyQuery(GET_USER_INVITATIONS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (err) => {
      setFeedbackMessage({ type: 'error' });
      dispatch(actions.setError, err);
    },
    onCompleted: (data) =>
      dispatch(actions.setUserInvitations, data?.getUserInvitations),
  });

  const [cancelUserInvitation] = useMutation(CANCEL_USER_INVITATION, {
    onError: () => {
      dispatch(actions.invitationToCancel, null);
      setFeedbackMessage({ type: 'error' });
    },
    onCompleted: (data) => {
      if (data.cancelUserInvitation) {
        const fullName = `${state.invitationToCancel.firstName} ${state.invitationToCancel.lastName}`;
        setFeedbackMessage({
          type: 'success',
          message: (
            <span>
              Invitation for&nbsp;
              <b>{fullName}</b> is successfully canceled.
            </span>
          ),
        });
      }
      dispatch(actions.invitationToCancel, null);
      refetchUserInvitations();
    },
  });

  const cancelInvitation = (invitationId) => {
    cancelUserInvitation({ variables: { invitationId }});
  }

  const setInvitationToCancel = (invitation) => {
    dispatch(actions.invitationToCancel, invitation);
  }

  return (
    <UserInvitationContext.Provider
      value={{
        ...state,
        ...props,
        fetchingUserInvitations,
        getUserInvitations,
        setFeedbackMessage,
        refetchUserInvitations,
        setInvitationToCancel,
        cancelInvitation,
      }}
    >
      {children}
    </UserInvitationContext.Provider>
  );
};

UserInvitationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { UserInvitationProvider as default, useUserInvitation };
