import { Types } from '../users/constants/user-constants';
import {
  canViewVehicle,
  canViewAOVehicles,
  canViewGFVehicles,
  canViewAOTags,
  canViewGFTags,
  canViewAOTagOrders,
  canViewGFTagOrders,
  canUpdateAOVehicle,
  canCreateVehicle,
  canViewMotorPool,
} from '../../vms-authz';
import { canViewMNRDashboard, canViewAIECard } from '../../mnr-authz';
import {
  canViewStandardItem,
  canViewCatalogCode,
  canViewEquipmentCode,
} from '../../catalog-authz';
import {
  canViewLeaseVehicle,
  canCreateRequisition,
  canViewRequisitionAndOrders,
} from '../../store-authz';
import { isFeatureEnabled } from '../../utilities/feature-toggle';

const isSCOUser = (currentUser) => {
  return currentUser?.roles?.some((role) => role?.id === '1001');
};

const isVehicleSupplier = (currentUser) => {
  return currentUser?.roles?.some((role) => role?.id === '503');
};

export const homeLinks = [
  {
    header: 'Profile',
    userTypes: [Types.VENDOR],
    cards: [
      {
        title: 'View Vendor Profile',
        description: 'View company and user profiles for vendors',
        link: '#{appURLs.home}/profile',
        rule: (ability) => ability.can('view', 'Vendor'),
      },
    ],
  },
  {
    header: 'Acquisition Management',
    userTypes: [Types.VENDOR],
    cards: [
      {
        title: 'Federal Vehicle Standards',
        description:
          'View the published federal vehicle standards by contract year. Review, comment, express interest on standard items and propose makes and models during open commenting periods.',
        link: '#{appURLs.catalog}/catalog/vehicle-standards',
        rule: (ability) => ability.can('view', 'Vendor'),
      },
      {
        title: 'Bid Management',
        description:
          "View available solicitations and submit bids in response to GSA Fleet's requirements.",
        link: '#{appURLs.catalog}/catalog/bids',
        rule: (_, currentUser) => isVehicleSupplier(currentUser),
      },
    ],
  },
  {
    header: 'Vehicle Sales',
    userTypes: [Types.GSA_EMPLOYEE],
    cards: [
      {
        title: 'Vehicle Sales Advertising',
        description:
          'View vehicles that are currently being advertised for sale to the general public.',
        link: '#{appURLs.marketplace}/sales/landing',
        rule: (_, currentUser) => isSCOUser(currentUser),
      },
      {
        title: 'Vehicle Sales Inventory',
        description: 'Manage sale events and vehicles eligible for sale.',
        link: '#{appURLs.marketplace}/sales/vehicle-and-sale-listing',
        rule: (_, currentUser) => isSCOUser(currentUser),
      },
    ],
  },
  {
    header: 'Accident and maintenance',
    userTypes: [Types.GSA_EMPLOYEE],
    cards: [
      {
        title: 'Accident and Maintenance Program',
        description:
          'Central repository for all accident, third party and repair procurement documentation.',
        link: '#{appURLs.mnr}',
        rule: (ability) =>
          canViewMNRDashboard(ability) &&
          isFeatureEnabled('accident-and-maintenance'),
      },
      {
        title: 'Maintenance and Repair History',
        description:
          'View maintenance and repair history for all GSA leased vehicles.',
        link: '#{appURLs.mnr}/repair-history',
        rule: () => isFeatureEnabled('repair'),
      },
    ],
  },
  {
    header: 'Manage Offerings',
    userTypes: [Types.GSA_EMPLOYEE],
    cards: [
      {
        title: 'Standard Items',
        description:
          'View and manage Standard Items, which are the way GSA Fleet categorizes its vehicles within the Standard Order Program (SOP).',
        link: '#{appURLs.catalog}/catalog/standard-items',
        rule: (ability) => canViewStandardItem(ability),
      },
      {
        title: 'Equipment Codes',
        description:
          'View and manage Equipment Codes, which make up the requirements of Standard Items.',
        link: '#{appURLs.catalog}/catalog/equip-codes',
        rule: (ability) => canViewEquipmentCode(ability),
      },
      {
        title: 'Catalog Codes',
        description: 'View and manage Catalog Codes used across GSA Fleet.',
        link: '#{appURLs.catalog}/catalog/codes',
        rule: (ability) => canViewCatalogCode(ability),
      },
      {
        title: 'Purchasing rates',
        description:
          'View, create, and manage the purchase type and rate application fee.',
        link: '#{appURLs.bm}/bm/purchase-rates',
      },
    ],
  },
  {
    header: 'Fleet Offerings',
    userTypes: [
      Types.GSA_EMPLOYEE,
      Types.CUSTOMER,
      Types.VENDOR,
      Types.PUBLIC_OR_GENERAL,
    ],
    cards: [
      {
        title: 'Manage requisitions and orders',
        description:
          'View, track, and manage your existing vehicle requisitions and orders. Provide and check order status, view reports, and acknowledge receipt of delivered vehicles.',
        link: '#{appURLs.store}/orders',
        rule: (ability) => canViewRequisitionAndOrders(ability),
      },
      {
        title: 'Purchase vehicles',
        description:
          'Create requisitions and place orders to buy vehicles for your agency. GSA Fleet offers a wide variety of vehicles, configurations, and options for purchase to meet a wide range of mission requirements.',
        link: '#{appURLs.store}/purchase',
        rule: (ability) => canCreateRequisition(ability),
      },
      {
        title: 'Lease vehicles',
        description:
          'Create and manage requests for GSA Fleet leased vehicles. GSA Fleet leasing offers a wide variety of vehicles with economical and efficient fleet management services.',
        link: '#{appURLs.store}/',
        rule: (ability) => canViewLeaseVehicle(ability),
      },
      {
        title: 'Short-term rentals',
        description:
          'Create and manage requests for your vehicle and equipment short-term rental needs. GSA Fleet’s Short-Term Rental Program offers a wide variety of vehicles and equipment to support seasonal work, special events, surge requirements, and replacements during repairs and maintenance.',
        link: 'https://str.gsa.gov/',
      },
      {
        title: 'Just Browsing',
        description:
          'View vehicles and options to see what is currently available without placing an order.',
        link: '#{appURLs.store}/purchase?mode=browsing',
      },
    ],
  },
  {
    header: 'Manage My Fleet',
    userTypes: [Types.GSA_EMPLOYEE, Types.CUSTOMER],
    cards: [
      {
        title: 'Vehicle Inventory',
        description:
          'View and update vehicle specifications and download up-to-date registration cards for each vehicle. Add new vehicle assets to the inventory and update existing ones with points of contact and new license plates.',
        link: '#{appURLs.vms}/vehicles',
        // TODO: Remove canViewVehicle once we have the correct permissions
        rule: (ability) =>
          canViewVehicle(ability) &&
          (canViewAOVehicles(ability) || canViewGFVehicles(ability)),
      },
      {
        title: 'License plate orders',
        description:
          'Retrieve the status of all incoming license plate orders. See all license plates on an order, mark license plates as received, and begin using them on vehicles, motorcycles, and trailers.',
        link: '#{appURLs.vms}/orders',
        rule: (ability) =>
          canViewAOTagOrders(ability) || canViewGFTagOrders(ability),
      },
      {
        title: 'Vehicle registration',
        description:
          'Register federally owned and operated vehicles with GSA Fleet. Step through a simple process to attach license plates and points of contact to a vehicle. Retrieve up-to-date registration cards for each vehicle.',
        link: '#{appURLs.vms}/vehicle-registration',
        rule: (ability) =>
          canUpdateAOVehicle(ability) || canCreateVehicle(ability),
      },
      {
        title: 'License plates',
        description:
          'Track and manage the details and history of each license plate. Manage license plates that need to be reconciled, reported damaged, have gone missing, or need to be returned for destruction.',
        link: '#{appURLs.vms}/license-plates',
        rule: (ability) => canViewAOTags(ability) || canViewGFTags(ability),
      },
      {
        title: 'Vehicle recalls',
        description:
          'View and track open actionable safety recalls that exist on your vehicles.',
        link: '#{appURLs.vms}/recalls',
        rule: (ability) =>
          canViewAOVehicles(ability) || canViewGFVehicles(ability),
      },
      {
        title: 'Vehicle reports manager',
        description:
          'Generate reports for FAST, vehicle inventory, expenses, fuel usage and repairs. You can also export a template that you can use to bulk update Customer Driven Data, expense data and mileage.',
        link: '#{appURLs.vms}/reports',
        rule: (ability) =>
          canViewAOVehicles(ability) || canViewGFVehicles(ability),
      },
      {
        title: 'Agency owned vehicle mileage history',
        description: 'View and manage the mileage records on your vehicles.',
        link: '#{appURLs.vms}/vehicle/mileages',
        rule: (ability) =>
          canViewAOVehicles(ability) || canViewGFVehicles(ability),
      },
      {
        title: 'Agency owned vehicle expenses',
        description:
          'View and track transactions from your fuel card provider as well as manually loaded expenses.',
        link: '#{appURLs.vms}/vehicle/expenses',
        rule: (ability) =>
          canViewAOVehicles(ability) || canViewGFVehicles(ability),
      },
      {
        title: 'Dispatch and reservation',
        description:
          'View and manage motor pools, request access to public motor pools, create and manage vehicle reservations.',
        link: '#{appURLs.vms}/motor-pools',
        rule: (ability) =>
          canViewMotorPool(ability) && isFeatureEnabled('motor-pools'),
      },
      {
        title: 'Vehicle Modification Overview',
        description: 'View all submitted vehicle modification requests here.',
        link: '#{appURLs.mnr}/modification-history',
      },
      {
        title: 'Manage Vendors',
        description: `View and edit details for all of GSA vendors.`,
        link: '#{appURLs.home}/vendor-listing',
        rule: (_, currentUser) => currentUser?.userType?.id === '3',
      },
      {
        title: 'Agency-Incurred Expenses',
        description: `Create, review and make decisions on Agency-Incurred Expenses. Here you can also review all automated agency incurred expenses or process a credit back to a customer.`,
        link: '#{appURLs.mnr}/agency-incurred-expenses',
        rule: (ability) => canViewAIECard(ability) && isFeatureEnabled('aie'),
      },
    ],
  },
];

export default homeLinks;
