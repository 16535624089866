import React from 'react';
import PropTypes from 'prop-types';
import { VENDOR_TYPE_MAP } from '../../constants';

const VendorListingTableSubRow = ({ row }) => {
  const { vendorContacts, vendorTypes } = row;

  const vendorContact = vendorContacts?.find(
    (contact) => contact?.contactType === 'VendorContact',
  );

  return (
    <div className="grid-container sales-disposal-sub-component-wrapper">
      <div className="grid-row grid-gap-6">
        <div className="tablet:grid-col desktop:margin-bottom-4">
          <div className="grid-row padding-y-2 border-bottom border-base-lighter">
            <div className="text-bold">Fleet Vendor Number</div>
            <div className="tablet:grid-col text-right">
              {row.fleetVendorNumber || <>&ndash;</>}
            </div>
          </div>
          <div className="grid-row padding-y-2 border-bottom border-base-lighter">
            <div className="text-bold">UEI</div>
            <div className="tablet:grid-col text-right">
              {row.uniqueEntityIdentifier || <>&ndash;</>}
            </div>
          </div>
          <div className="grid-row padding-y-2 border-bottom border-base-lighter">
            <div className="text-bold">Vendor type(s)</div>
            <div className="tablet:grid-col text-right">
              {vendorTypes
                .map((type) => VENDOR_TYPE_MAP[type?.vendorTypeCode])
                .sort()
                .join(', ') || <>&ndash;</>}
            </div>
          </div>
        </div>
        <div className="tablet:grid-col desktop:margin-bottom-4">
          <div className="grid-row padding-y-2 border-bottom border-base-lighter">
            <div className="text-bold">Contact name</div>
            <div className="tablet:grid-col text-right">
              {`${vendorContact?.firstName || '-'} ${
                vendorContact?.lastName || '-'
              }`}
            </div>
          </div>
          <div className="grid-row padding-y-2 border-bottom border-base-lighter">
            <div className="text-bold">Contact email</div>
            <div className="tablet:grid-col text-right">
              {vendorContact?.emailAddress || <>&ndash;</>}
            </div>
          </div>
        </div>
      </div>
      {/* Not needed for MVP, will be reactivated in the feature. */}
      {/* <div className="grid-row grid-gap-6">
        <div className="tablet:grid-col desktop:margin-bottom-2">
          <div className="grid-row padding-y-1">
            <div className="text-bold">Reactivate justifications</div>
            <div className="text-left">
              <p>
                This is placeholder clarification text for this item. In sem justo, commodo ut, suscipit at,
                pharetra vitae, orci. Duis sapien nunc, commodo et, interdum suscipit, sollicitudin et, dolor.
                Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.
              </p>
              <Button
                label="Edit justifications"
                variant="outline"
                onClick={() => {}}
              />
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

VendorListingTableSubRow.propTypes = {
  row: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default VendorListingTableSubRow;
