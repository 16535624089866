import React from 'react';
import PropTypes from 'prop-types';
import { Modal, connectModal, Button } from '@gsa/afp-component-library';
import { useRole } from '../providers';
import './styles/modal.css';

const Title = (props) => {
  const {
    role: { name = 'the role' },
  } = props;
  return <h2>{`Are you sure you want to reactivate ${name}?`}</h2>;
};

Title.defaultProps = {
  role: { name: '' },
};
Title.propTypes = {
  role: PropTypes.objectOf(PropTypes.shape),
};

const Content = () => {
  return (
    <p>
      Once the role is reactivated , you can go back to the role details page to
      assign users and/or set permissions or scopes.
    </p>
  );
};

const Actions = ({ onCancel, onConfirm }) => {
  return (
    <>
      <Button
        label="Cancel"
        variant="unstyled"
        className="padding-right-1"
        onClick={onCancel}
        data-testid="role-reactivate-cancel-btn"
      />
      <Button
        label="Reactivate role"
        variant="primary"
        type="submit"
        form="role-edit-form"
        data-testid="role-reactivate-confirm-btn"
        onClick={onConfirm}
      />
    </>
  );
};

Actions.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

const ConfirmReactivationModal = () => {
  const { confirmReactivation, reactivatingRole, reactivate } = useRole();

  const ConfirmModal = connectModal(() => (
    <Modal
      className="usa-prose"
      title={<Title role={reactivatingRole} />}
      actions={
        <Actions
          onCancel={() => confirmReactivation(false)}
          onConfirm={() => reactivate(reactivatingRole.id)}
        />
      }
      onClose={() => confirmReactivation(false)}
    >
      <Content />
    </Modal>
  ));

  return <ConfirmModal isOpen={reactivatingRole} />;
};

export default ConfirmReactivationModal;
