import React, { useState, useEffect } from 'react';
import { FilterPanel } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import { useOfficeManagementListing } from '../../office-management-listing-provider';


const OfficeManagementListingFilter = ({
  currentFilters,
  setFilters,
  presetFilterStructures,
}) => {

  const {
    FilterPanel:OfficeManagementLisitngFilterPanel
  } = FilterPanel;

  const {
    setAgencyCode,
    setBureauCode,
    getAgencies,
    getBureaus,
    resetAllFilters,
  } = useOfficeManagementListing();

  const defaultFilterStructure = [
    {
      title: 'Organization',
      key: 'organization',
      expanded: true,
      filters: presetFilterStructures,
    },
  ];
  const [filterStructure] = useState(defaultFilterStructure);

  const handleFiltersChange = (filters) => {
    const agencyCodeFilter = filters.find(
      (filter) => filter.key === 'agencyCode',
    );

    const bureauCodeFilter = filters.find(
      (filter) => filter.key === 'bureauCode',
    );

    const updatedFilters = {
      ...currentFilters,
      conditions: [],
    };

    if (agencyCodeFilter) {
      setAgencyCode(agencyCodeFilter.value);

      getBureaus({
        variables: {
          operation: 'view',
          subject: 'OrganizationProfile',
          agencyCode: agencyCodeFilter.value,
        },
      });

      updatedFilters.conditions.push(agencyCodeFilter);
    }

    if (bureauCodeFilter && agencyCodeFilter) {
      setBureauCode(bureauCodeFilter.value);

      updatedFilters.conditions.push(bureauCodeFilter);
    }

    if (bureauCodeFilter && !agencyCodeFilter) {
      resetAllFilters();
    }

    setFilters(updatedFilters);
  }

  const handleClearAll = () => {
    resetAllFilters();
  };

  useEffect(() => {
    getAgencies({
      variables: {
        operation: 'view',
        subject: 'OrganizationProfile',
      },
    });
    setFilters(currentFilters);
  }, []);

  return (
    <>
      <OfficeManagementLisitngFilterPanel
        clearButtonLabel="Reset All"
        filterStructure={filterStructure}
        setQueryFiltersState={handleFiltersChange}
        handleClearAll={handleClearAll}
        showClearIcon
      />
    </>
  );
};

OfficeManagementListingFilter.propTypes = {
  currentFilters: PropTypes.shape({
      operator: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      conditions: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
      ]),
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }).isRequired,
  setFilters: PropTypes.func.isRequired,
  presetFilterStructures: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default OfficeManagementListingFilter;
