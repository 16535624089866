import { ErrorMessage, FormGroup } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import { propTypeFormContext } from './createFormContext';
import { StandardFormLabel } from './standard-form-label';

export const StandardFormGroup = ({
  formContext,
  name,
  label,
  required,
  children,
}) => {
  const { errors } = formContext;
  const error =
    (errors[name] && (errors[name].message || errors[name].type)) || '';
  return (
    <FormGroup className="margin-left-0" error={error}>
      {label && (
        <StandardFormLabel htmlFor={name} required={required}>
          {label}
        </StandardFormLabel>
      )}
      {error && <ErrorMessage>{error}</ErrorMessage>}
      {children}
    </FormGroup>
  );
};

StandardFormGroup.propTypes = {
  formContext: propTypeFormContext.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

StandardFormGroup.defaultProps = {
  required: undefined,
  label: undefined,
};

export default StandardFormGroup;
