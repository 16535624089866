import React from 'react';
import PropTypes from 'prop-types';
import {
  connectModal,
  Modal,
  useModal,
  Button,
} from '@gsa/afp-component-library';
import { useUserDetails } from '../providers/user-details-provider';
import { capitalize } from '../../../../../utilities/StringUtils';

const Title = ({ user }) => {
  const { fullName = '' } = user;
  return (
    <h2 data-testid="deny-user-access-confirmation-title">
      Deny {capitalize(fullName)}&apos;s access?
    </h2>
  );
};

const Content = () => {
  return <p>User will be notified that the access has been denied.</p>;
};

Title.propTypes = {
  user: PropTypes.objectOf(PropTypes.shape).isRequired,
};

const Actions = ({ onCancel, onConfirm }) => {
  return (
    <>
      <Button
        label="Cancel"
        variant="unstyled"
        className="padding-right-1"
        type="button"
        onClick={onCancel}
        data-testid="deny-user-access-cancel-button"
      />
      <Button
        label="Yes, deny the user access"
        variant="secondary"
        type="button"
        data-testid="deny-user-access-confirm-button"
        onClick={onConfirm}
      />
    </>
  );
};

Actions.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

const DenyUserAccessModal = () => {
  const { isOpen, openModal, closeModal } = useModal();
  const { userId, user, denyUserAccess } = useUserDetails();

  const onCancel = () => {
    closeModal();
  };

  const onConfirm = () => {
    denyUserAccess({ variables: { id: userId } });
    closeModal();
  };

  const DenyAccessModal = connectModal(() => (
    <Modal
      title={<Title user={user} />}
      actions={<Actions onCancel={onCancel} onConfirm={onConfirm} />}
      onClose={onCancel}
    >
      <Content />
    </Modal>
  ));

  return (
    <>
      <Button
        label="Deny access"
        leftIcon={{
          name: "do_not_disturb"
        }}
        type="button"
        variant="secondary"
        data-testid="deny-user-access-button"
        className="margin-y-1 desktop:margin-y-0"
        onClick={() => {
          openModal();
        }}
      />
      <DenyAccessModal isOpen={isOpen} />
    </>
  );
};

export default DenyUserAccessModal;
