import React from 'react';
import PropTypes from 'prop-types';
import { StatusBadge } from '@gsa/afp-component-library';

import {
  Status,
  StatusLabel,
} from '../../admin-console/users/constants/user-details-constants';

export const cellStatus = (value) => {
  let userStatus;
  // eslint-disable-next-line default-case
  switch (value) {
    case Status.ACTIVE:
    case StatusLabel.ACTIVE:
      userStatus = {
        label: value,
        status: 'Ready-Gray',
      };
      break;
    case Status.INACTIVE:
    case StatusLabel.INACTIVE:
      userStatus = {
        label: value,
        status: 'Inactive-Gray',
      };
      break;
    case Status.PENDING_PROFILE:
    case Status.PENDING_ROLE:
    case Status.PENDING_REACTIVATION:
    case StatusLabel.PENDING_PROFILE:
    case StatusLabel.PENDING_APPROVAL:
    case StatusLabel.PENDING_REACTIVATION:
      userStatus = {
        label: value,
        status: 'Warning-Gray',
      };
      break;
    case Status.DEACTIVATED:
    case Status.DELETED:
    case StatusLabel.DEACTIVATED:
    case StatusLabel.DELETED:
    case Status.DENIED:
    case StatusLabel.DENIED:
      userStatus = {
        label: value,
        status: 'Urgent-Gray',
      };
      break;
  }

  return userStatus;
};

const UserStatusTag = ({ value }) => {
  const { status, label } = cellStatus(value);
  return <StatusBadge variant={status}>{label}</StatusBadge>;
};
UserStatusTag.propTypes = {
  value: PropTypes.string.isRequired,
};

export default UserStatusTag;
