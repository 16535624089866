/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import {
  AFPTable,
  AFPTableRowAction,
  Button,
  Pagination,
  FilterTableFrame,
  EmptyState,
  Spinner,
} from '@gsa/afp-component-library';

import React, { useMemo, useState, useEffect } from 'react';
import { useCurrentUser, useAppAbility } from '@gsa/afp-shared-ui-utils';
import { VMSOperations, VMSSubjects } from '../../vms-authz';

import useDidMountEffect from '../../hooks/use-did-mount';
import {
  useOfficeManagementListing,
} from './office-management-listing-provider';
import OfficeManagementListingFilter from './component/Filter';
import {
  FLEET_STATUS_ACTION_MAP,
  FLEET_STATUS_UPDATED_BY,
  FLEET_STATUS,
} from '../constants';
import EditOfficeModal from './edit-office-modal';
import CreateOfficeModal from './create-office-modal';

const getActions = (original) => {
  const actions = [];
  const editAction = {
    icon: 'edit',
    label: 'Edit',
  };
  const deactivate = {
    icon: 'arrow_downward',
    label: 'Deactivate',
  };
  const reactivate = {
    icon: 'check',
    label: 'Reactivate',
  };

  switch (original.fleetStatus) {
    case FLEET_STATUS.DRAFT:
    case FLEET_STATUS.ACTIVE:
      actions.push(editAction);
      actions.push(deactivate);
      break;
    case FLEET_STATUS.INACTIVE:
      actions.push(editAction);
      actions.push(reactivate);
      break;
    default:
      actions.push(editAction);
  }

  return actions;
};

const OfficeManagementTable = () => {
  
  const initFilters = {
    conditions: [
      {
        key: '',
        operator: '',
        value: '',
      },
    ],
    operator: '$and',
  };
  const [filters, setFilters] = useState(initFilters);
  const [order, setOrder] = useState('bureau.bureaushortname ASC,Office.officecode ASC');
  const [showFilters] = useState(true);
  const [isInternalUser, setIsInternalUser] = useState(false);
  const [paginationState, setPaginationState] = useState({
    limit: 10,
    offset: 0,
    currentPage: 1,
  });

  const {
    officeManagements,
    getOfficeManagements,
    changeOfficeManagementFleetStatus,
    isOfficeDataLoading,
    resetOfficeManagementList,
    selectedAgency,
    selectedBureau,
    setOfficeData,
    presetFilterStructures,
    agencies,
    bureaus,
    setBureauCode,
    setEditOfficeModalState,
    setCreateOfficeModalState,
  } = useOfficeManagementListing();

  const ability = useAppAbility();
  const canManageOffice = ability.can(
    VMSOperations.Manage,
    VMSSubjects.ORGANIZATION_PROFILE,
  );

  const tableRef = React.createRef();

  const getData = () => {
    const { limit, offset } = paginationState;
    
    let targetAgencyCode;
    let targetBureauCode;

    if (filters.conditions?.length) {
      filters.conditions?.forEach((f) => {
        if (f.key === 'agencyCode') {
          targetAgencyCode = f.value;
        }
        if (f.key === 'bureauCode') {
          targetBureauCode = f.value;
        }
      });
    }

    if ( // only internal user can see all the agencies
      !targetAgencyCode &&
      !isInternalUser
    ) {
      return;
    }

    if(!targetBureauCode) {
      setBureauCode('');
    }
    if (
      !isInternalUser &&
      targetAgencyCode &&
      (
        !targetBureauCode &&
        !bureaus.length
      )
    ) {
      return;
    }

    // AFP-75999, scenario 6
    // if single agency is selected and
    // there are more than 1 bureau, and
    // there is no bureau selected, then
    // display the scoped bureau(s)
    if (
      !targetBureauCode &&
      agencies.length === 1 &&
      bureaus.length &&
      filters?.conditions.length
    ) {
      filters.conditions.push({
        key: 'bureauCode',
        operator: '$in',
        value: bureaus.map((b) => b.value),
      });
    }

    getOfficeManagements({
      variables: {
        filters,
        offset,
        limit,
        order,
        agencyCode: selectedAgency,
        bureauCode: selectedBureau,
      },
    });
  };
  
  useEffect(() => {
    if (
      filters?.conditions?.length &&
      filters?.conditions[0]?.value
    ) {
      getData();
    } else {
      resetOfficeManagementList();
    }
  }, []);

  

  useDidMountEffect(() => {
    if (
      filters?.conditions?.length &&
      filters?.conditions[0]?.value
    ) {
      getData();
    } else {
      resetOfficeManagementList();
    }
  }, [filters, order, paginationState]);

  const handleSelectedAction = (action, row) => {

    if (row) {
      setOfficeData(row.original)
    }
    if (action === 'Deactivate' || action === 'Reactivate')
    changeOfficeManagementFleetStatus({
      variables: {
        input: {
          id: row.original.id,
          fleetStatus: FLEET_STATUS_ACTION_MAP[action],
          fleetStatusUpdatedBy: FLEET_STATUS_UPDATED_BY.Admin,
        },
      },
    });
    if (action === 'Edit'){
      setEditOfficeModalState(true);      
    }      
  };

  const columns = useMemo(() => {
    const columnList = [
      {
        Header: 'Bureau',
        accessor: 'bureau.shortName',
        sortable: true,
      },
      {
        Header: 'Office code',
        accessor: 'officeCode',
        sortable: true,
        Cell: ({ row: { original } }) => {
          const organization = {
            agencyCode: original?.agencyCode,
            bureauCode: original?.bureauCode,
            officeCode: original?.officeCode,
            agencyName: original?.agency?.name,
            bureauName: original?.bureau?.name,
            officeName: original?.officeName,
          }
          return (
            <a
              href={`${window.AFP_CONFIG.appURLs.vms}/office-management/${encodeURIComponent(JSON.stringify(organization))}`}
            >
              {original?.officeCode}
            </a>
          );
        },
      },
      {
        Header: 'Office name',
        accessor: 'officeName',
        sortable: true,
      },
    ];

    if (canManageOffice) {
      columnList.push({
        Header: 'Actions',
        sortable: false,
        headerClassName: 'cell-center',
        cellClassName: 'cell-center',
        Cell: (props) => (
          <AFPTableRowAction
            actions={getActions(props.row.original)}
            // eslint-disable-next-line react/prop-types
            onSelectAction={(evt) => handleSelectedAction(evt, props.row)}
            {...props}
          />
        ),
      });
    }
    return columnList;
  }, []);

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    // Calculate new offset.
    const offset = (currentPage - 1) * itemsPerPage;
    setPaginationState({
      limit: itemsPerPage,
      offset,
      currentPage,
    });
  };

  const TableWrapper = ({ tableProps, paginationProps }) => {
    const userObject = useCurrentUser();

    if (`${userObject?.currentUser?.userType?.id}` === '3') {
      setIsInternalUser(true);
    }

    const result = 
      <>
        <AFPTable {...tableProps} />        
        {tableProps.isOfficeDataLoading && <Spinner data-testid="office-management-listing-table-spinner" size="small" />}
        {tableProps.data?.length && !tableProps.isOfficeDataLoading ? <Pagination {...paginationProps} /> : null}
        {tableProps.data === null ||
          ((tableProps.data?.length === 0 && !tableProps.isOfficeDataLoading) && (
            <div data-testid="table-data" className="text-center margin-top-neg-2 height-full">
              <EmptyState
                hasBackground
                containerStyles="padding-top-8 height-full"
                topText={
                  <p
                    aria-label="There are no office(s)."
                    // eslint-disable-next-line
                    tabIndex={0}
                  >
                    <strong>There are no office(s).</strong>
                  </p>
                }
              />
            </div>
        ))}        
      </>;

    return result;
  };

  const FTF = useMemo(
    () => FilterTableFrame(
      null,
      canManageOffice?Button:null,
      OfficeManagementListingFilter,
      TableWrapper,
    ),
    [
      agencies.length,
      bureaus.length
    ],
  );

  return (
    <>
      {/* <Can I="create" a="OfficeManagement"> -- enable this when permissions are set up */}
      <FTF
        upRightProps={{
          label: 'Create Office',
          className: 'float-right',
          leftIcon: { name: 'add' },
          onClick: () => { 
            setCreateOfficeModalState(true);
          },
          
        }}
        lowLeftProps={{
          currentFilters: filters,
          setFilters,
          presetFilterStructures,
        }}
        lowRightProps={{
          tableProps: {
            testId: 'officeManagement-listing-table',
            expandable: true,
            fullWidth: true,
            ref: tableRef,
            columns,
            data: officeManagements.rows || [],
            defaultSort: order,
            isOfficeDataLoading,
            onSort: setOrder,
          },
          paginationProps: {
            itemsPerPageOptions: [10, 25, 50],
            onPageChange: handlePaginationChange,
            variant: 'advanced',
            itemsPerPage: paginationState.limit,
            currentPage: paginationState.currentPage,
            itemsCount: officeManagements?.count,
          },
        }}
        filterToggle={showFilters}
      />
      <CreateOfficeModal />
      <EditOfficeModal />
    </>
  );
};

export default OfficeManagementTable;
