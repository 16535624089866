import React from 'react';
import { Breadcrumbs as DSBreadcrumbs } from '@gsa/afp-component-library';

import { Link } from 'react-router-dom';

const Breadcrumbs = () => (
  <DSBreadcrumbs
    trail={[<Link to="/tasks">Task Manager</Link>]}
    current="Task Details"
  />
);
export default Breadcrumbs;
