import React from 'react';
import PropTypes from 'prop-types';
import { gql, useLazyQuery } from '@apollo/client';
import { AddressConfirmationModal } from '@gsa/afp-component-library';

// USPS address validation API from afp-shared-svc
export const VALIDATE_ADDRESS = gql`
  query ValidateAddress(
    $address: String!
    $address2: String
    $city: String!
    $state: String!
    $zip: String!
  ) {
    validateAddress(
      address: $address
      address2: $address2
      city: $city
      state: $state
      zip: $zip
    ) {
      address
      address2
      city
      state
      zip
      updates
    }
  }
`;

export const USPSAddressValidation = ({
  addressToValidate,
  saveButtonText,
  cancelButtonText,
  onSave,
  onCancel,
}) => {
  const [validatedAddress, setValidatedAddress] = React.useState(null);

  const [uspsValidation, { error, loading }] = useLazyQuery(VALIDATE_ADDRESS, {
    onCompleted: ({ validateAddress: data }) => {
      if (data) {
        const { address, address2, city, state, zip } = data;
        setValidatedAddress({
          address,
          address2,
          city,
          state,
          zip,
          countryCode: 'US',
        });
      }
    },
  });

  React.useEffect(() => {
    setValidatedAddress(null);
    if (addressToValidate && Object.keys(addressToValidate).length) {
      uspsValidation({
        variables: { ...addressToValidate },
      });
    }
  }, [addressToValidate]);

  return (
    <AddressConfirmationModal
      validating={loading}
      hasValidationError={!!error}
      addressToValidate={addressToValidate}
      validatedAddress={validatedAddress}
      saveButtonText={saveButtonText}
      cancelButtonText={cancelButtonText}
      onSave={onSave}
      onCancel={onCancel}
    />
  );
};

const addressShape = {
  address: PropTypes.string,
  address2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string,
  countryCode: PropTypes.string,
};

USPSAddressValidation.defaultProps = {
  saveButtonText: 'Save',
  cancelButtonText: 'Cancel',
  onCancel: () => undefined,
};

USPSAddressValidation.propTypes = {
  addressToValidate: PropTypes.shape(addressShape).isRequired,
  saveButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
};

export default USPSAddressValidation;
