import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { Spinner } from '@gsa/afp-component-library';
import {
  propTypeFormContext,
  StandardLabeledSelectDropdown,
} from '../../../components/Forms';
import { GET_ZONES_CENTERS } from '../../../services/data-layer';

const getCenters = (zones, zoneId) => {  
  if(!zones){
    return [];
  }
  const zone = zones.find(z => z.id === zoneId);
  if(!zone || !zone.centers){
    return [];
  }
  return [...zone.centers]
}

const getCenterOptions = (centers, defaultDropdownOptions) => {  
  const opts = centers
    .sort((c1,c2)=> c1.name.localeCompare(c2.name))
    .map(c => ({value: c.id, label: c.name, fieldOffices: c.fieldOffices}));    
  return defaultDropdownOptions.concat(...opts);
}

const getFieldOfficeOptions = (centers, centerId, defaultDropdownOptions) => {
  if(!centers){
    return [];
  }
  const center = centers.find(c => c.id === centerId);
  if(!center || !center.fieldOffices){
    return [];
  }
  const opts = [...center.fieldOffices]
    .sort((f1,f2)=> f1.name.localeCompare(f2.name))
    .map(f => ({value: f.id, label: f.name}));  
  return defaultDropdownOptions.concat(...opts);
}

export default function UserProfileZoneMgmtCenter({ formContext, setInvalidState }) {
  const [ zones, setZones ] = useState([]);
  const [ centers, setCenters ] = useState([]);
  const [ centerOptions, setCenterOptions ] = useState([]);
  const [ fieldOffices, setFieldOffices ] = useState([]);
  const [ allZonesAndRegions, setAllZonesAndRegions] = useState([]);
  const defaultDropdownOptions = [
    { value: '-', label: 'Please select an option' },
  ];

  const [ getZonesAndCenters, { loading: loadingZonesCenters } ] = useLazyQuery(GET_ZONES_CENTERS, {
    onError: () => {
      setInvalidState(true, 'getZonesAndCenters');
    },
    onCompleted: (data) => {
      const options = data.getZones.map(z =>
        ({value: z.id, label: z.name}));
      setZones(defaultDropdownOptions.concat(...options));
      if (formContext.defaultValues.zoneId) {
        const managementCenters = getCenters(data.getZones, formContext.defaultValues.zoneId);
        setCenters(managementCenters);
        const opts = getCenterOptions(managementCenters, defaultDropdownOptions);
        setCenterOptions(opts);
        if (formContext.defaultValues.managementCenterId) {
          const offices = getFieldOfficeOptions(managementCenters, 
            formContext.defaultValues.managementCenterId, defaultDropdownOptions);
          setFieldOffices(offices);
        }
      }
      setAllZonesAndRegions(data.getZones);
    },
    fetchPolicy: 'cache-and-network',
  })

  useEffect(getZonesAndCenters, []);

  return loadingZonesCenters ? <Spinner /> : (
    <>
      <StandardLabeledSelectDropdown
        label="Which Zone are you assigned to?"
        formContext={formContext}
        name="zoneId"
        options={zones}
        required
        onChangeCallback={ev => {          
          const ctrs = getCenters(allZonesAndRegions, ev.target.value);
          setCenters(ctrs);
          const options = getCenterOptions(ctrs, defaultDropdownOptions);
          setCenterOptions(options);
        }}
      />
      {centerOptions.length > 1 && <StandardLabeledSelectDropdown
        formContext={formContext}
        name="managementCenterId"
        label="Which Fleet Management Center (FMC) are you assigned to?"
        options={centerOptions}
        required
        onChangeCallback={ev => {
          const options = getFieldOfficeOptions(centers, ev.target.value, defaultDropdownOptions);
          setFieldOffices(options)
        }}
      />}
      {fieldOffices.length > 1 && <StandardLabeledSelectDropdown
        formContext={formContext}
        name="fieldOfficeId"
        label="Which Field Office are you assigned to?"
        options={fieldOffices}
      />}
    </>
  );
}

UserProfileZoneMgmtCenter.propTypes = {
  formContext: propTypeFormContext.isRequired,
  setInvalidState: PropTypes.func.isRequired,
};
