import React from 'react';

export default () => {
  return (
    <div className="usa-prose margin-bottom-4">
      <p>
        Tasks assigned to you will be listed below. You can see the details of
        any tasks by clicking its Task ID button. You can also apply filters to
        show more specific tasks.
      </p>
    </div>
  );
};
