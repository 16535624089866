import React from 'react';
import { Spinner } from '@gsa/afp-component-library';
import { useUserDetails } from '../providers/user-details-provider';

const LoadingUserSpinner = () => {
  const { loadingUser } = useUserDetails();
  if (!loadingUser) {
    return null;
  }
  return (
    <span data-testid="loading-user-details-spinner">
      <Spinner aria-busy="true" className="loading_backdrop" size="large" />
    </span>
  );
};

export default LoadingUserSpinner;
