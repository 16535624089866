import React, { useEffect, useState } from "react";
import { Redirect, Link } from "react-router-dom";
import { useMutation } from '@apollo/client';
import {
    Button,
    Spinner,
    Breadcrumbs,
    PageTitle,
  } from '@gsa/afp-component-library';
  import {
    InternalError,
    useCurrentUser,
    FleetTechnicalSupportContact,
    useTitle
  } from '@gsa/afp-shared-ui-utils';
  import { useQueryParams } from '../../utilities/use-query-params';
  import UserCreationError from '../error/user-creation-error';
  import ExistingAccountError from '../signup/existing-account-error';
  import DeactivatedAccountError from '../signup/deactivated-account-error';
  import { REGISTER_USER_BY_INVITATION } from "../../services/data-layer";
  import AccountSetup from "../signup/acctSetup";
import ActivationTokenError from "../error/activation-token-error";
import InvitationExpiredError from './invitation-expired-error'

  const AcceptInvitationPage = () => {
    const params = useQueryParams();
    const token = params.get('token');
    if (!token) {
        return <Redirect to="/" />;
    }

    useTitle("Create Account");
    const auth = useCurrentUser();
    const [registrationResponse, setRegistrationResponse] = useState({});
    const [registrationError, setRegistrationError] = useState(undefined);

    const [registerByInvitation, { loading: creatingProspect }] = useMutation(
        REGISTER_USER_BY_INVITATION,
        {
          context: {
            clientName: 'public',
          },
          onError: ({ graphQLErrors = [] }) => {
            const err = graphQLErrors[0];
            const { exception } = err?.extensions || {};
            setRegistrationError(exception?.name || 'unknown');
          },
          onCompleted: (data) => {
            setRegistrationResponse(data?.registerByInvitation);
          },
        },
    );

    useEffect(() => {
        registerByInvitation({ variables: {
            code: token
        }});
    }, []);

    if (creatingProspect) {
      return <Spinner aria-busy="true" className="loading_backdrop" size="large" />;
    }

    const knownErrors = [
      UserCreationError.ExistingUser,
      UserCreationError.DeactivatedUser,
      ActivationTokenError.TokenExpired
    ];
  
    const isInvalidError = (err) => {
      return err && !knownErrors.some((knownError) => knownError === err);
    };
    const isUserExists = (err) => {
      return err === UserCreationError.ExistingUser;
    };
    const isUserDeactivated = (err) => {
      return err === UserCreationError.DeactivatedUser;
    };

    const isInvitationExpired = (err) => {
      return err === ActivationTokenError.TokenExpired;
    }

    const isAccountActivate = (state) => {
      return state === 'ACTIVATE';
    };
    const isAccountCreated = (state) => {
      return state === 'CREATED';
    };

    const handleLoginExternal = async (e) => {
      e.preventDefault();
      await auth.loginUserExternal();
    };

    if (isAccountCreated(registrationResponse.state)) {
      return (
        <div className="desktop:grid-col-7">
          <Breadcrumbs
            trail={[
              <Link style={{ textDecoration: 'underline' }} to="/">
                Home
              </Link>,
            ]}
            current="Create Account"
          />
          <PageTitle title="Account successfully created" />
          <p>
            We noticed you have a GSA account already using your FAS ID and we
            have added your existing account to GSAFleet.gov to log in. For
            technical support, <FleetTechnicalSupportContact />.
          </p>
          <Button
            label="Log in using FAS ID"
            variant="primary"
            className="margin-top-2 margin-bottom-5"
            id="external-okta-login"
            onClick={handleLoginExternal}
            data-testid="handle-external-login-created-user"
          />
        </div>
      );
    }
  
    if (isAccountActivate(registrationResponse.state)) {
      return (
        <AccountSetup
          token={registrationResponse.token}
        />
      );
    }

    if (isInvitationExpired(registrationError)) {
      return (
        <div className="desktop:grid-col-7 margin-top-2">
          <InvitationExpiredError />
        </div>
      );
    }

    return (
      <div className="desktop:grid-col-7">
        <Breadcrumbs
          trail={[
            <Link style={{ textDecoration: 'underline' }} to="/">
              Home
            </Link>,
          ]}
          current="Create Account"
        />
        {isInvalidError(registrationError) && <InternalError />}
        {isUserExists(registrationError) && <ExistingAccountError />}
        {isUserDeactivated(registrationError) && <DeactivatedAccountError />}
      </div>
    );
}

export default AcceptInvitationPage;