import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FilterPanel } from '@gsa/afp-component-library';
import { useUserFilter } from '../providers';
import { getSelectedFilterValue } from '../../../utilities/filterUtils';

const { FilterPanelField, FilterPanelFilterShape } = FilterPanel;

const placeholderOption = {
  value: '',
  label: 'Select agency',
  defaultValue: true,
};

const AgencyFilterItem = ({ filter }) => {
  const { getAgencies, agencies, filters } = useUserFilter();
  const selectedValue = getSelectedFilterValue(filters, filter) || '';

  const [options, setOptions] = useState([placeholderOption]);

  useEffect(() => {
    getAgencies({
      variables: {
        order: [['id', 'asc']]
      }
    });
  }, []);

  useEffect(() => {
    setOptions([
      placeholderOption,
      ...agencies.map(({ id, name }) => ({
        value: id,
        label: name,
      })),
    ]);
  }, [agencies]);

  return (
    <>
      <p className="text-bold">Agency</p>
      <FilterPanelField
        key={[filter.key]}
        filter={{
          ...filter,
          type: 'select',
          options,
          value: selectedValue,
        }}
      />
    </>
  );
};

AgencyFilterItem.propTypes = {
  filter: PropTypes.shape(FilterPanelFilterShape).isRequired,
};

export default AgencyFilterItem;
