import React from 'react';
import PropTypes from 'prop-types';
import {
  propTypeFormContext,
  StandardFieldset,
  StandardLabeledSelectDropdown,
  StandardLabelledReadonly,
} from '../../components/Forms';

export const UserProfileVendorManager = ({
  formContext,
  vendorManagerOptions,
  onManagerChange,
}) => {  
  const { defaultValues = {}, validationOptions } = formContext || {};
  const { supervisorId } = defaultValues;
  const option = vendorManagerOptions.find((o) => o.value === supervisorId);
  validationOptions.supervisorId = null;
  return (    
    <StandardFieldset label="Manager Information" name="vendorManagerInformation">
      { 
        supervisorId? (
          <StandardLabelledReadonly
            formContext={formContext}
            name="supervisorId"
            defaultValue={option?.label}
            label="Manager"
          />
        ) : 
        (
          <StandardLabeledSelectDropdown 
            formContext={formContext}
            name="supervisorId"
            label="Manager"
            options={vendorManagerOptions}
            onChangeCallback={onManagerChange}
          />
        ) 
      }
    </StandardFieldset>
  );
};

UserProfileVendorManager.propTypes = {
  formContext: propTypeFormContext.isRequired,
  vendorManagerOptions: PropTypes.arrayOf(PropTypes.shape).isRequired,
  onManagerChange: PropTypes.func,
};

UserProfileVendorManager.defaultProps = {
  onManagerChange: () => {
    // default empty function
  }
};

export default UserProfileVendorManager;
