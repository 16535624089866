import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumbs, Button, PageTitle } from '@gsa/afp-component-library';
import './index.scss';
import { isFeatureEnabled } from '../../utilities/feature-toggle';

const OrganizationProfile = () => {
  const isDocumentManagementEnabled = isFeatureEnabled('document-management');
  return (
    <>
      <Breadcrumbs
        trail={[
          <Link style={{ textDecoration: 'underline' }} to="/home">
            Home
          </Link>,
        ]}
        current="Organization Profile"
      />
      <div>
        <PageTitle title="Organization Profile" />
        <p>
          Create and manage content for your agency’s fleet and its users from
          this page. Items found here will be referenced throughout the system.
        </p>
        <div className="grid-row width-full margin-top-5">
          <div className="grid-col flex-6">
            <div className="buttons-container">
              <div className="grid-row">
                <Button
                  className="purchase-click-button"
                  label={
                    <div>
                      <h2
                        tabIndex="-1"
                        className="grid-row title-m text-info-darker button-titles"
                      >
                        Office Management
                      </h2>
                      <p className="grid-row margin-bottom-5 margin-top-0 button_text">
                        Use offices to organize your customer accounts and
                        agency vehicles.
                      </p>
                    </div>
                  }
                  labelClass="font-sans-md"
                  onClick={() => {
                    window.location.assign(
                      `${window.AFP_CONFIG.appURLs.home}/office-management`,
                    );
                  }}
                />
              </div>
            </div>
          </div>
          {/* TODO: placeholder for Documentation Management */}
          {isDocumentManagementEnabled && (
            <div className="grid-col flex-10">
              <div className="buttons-container">
                <div className="grid-row">
                  <Button
                    label={
                      <>
                        <h2
                          tabIndex="-1"
                          className="grid-row title-m text-info-darker button-titles"
                        >
                          Documentation Management
                        </h2>
                        <p className="grid-row margin-bottom-5 margin-top-0 button_text">
                          Manage Agency Guidance and policy, agreements, and
                          other documentation.
                        </p>
                      </>
                    }
                    labelClass="font-sans-md"
                    className="purchase-click-button"
                    onClick={() => {
                      window.location.assign(
                        `${window.AFP_CONFIG.appURLs.home}/document-management`,
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default OrganizationProfile;
