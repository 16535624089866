/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useEffect, useState, useMemo } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { DateTime } from 'luxon';
import {
  AFPTable,
  Pagination,
  AFPTableRowAction,
  PageTitle,
  FilterTableFrame,
  EmptyState,
  Spinner,
  StatusBadge,
  ErrorMessage,
} from '@gsa/afp-component-library';
import useDidMountEffect from '../../hooks/use-did-mount';
import { USER_TASKS, TOGGLE_TASK_STATUS } from '../../services/data-layer';
import WelcomeMessage from './WelcomeMessage';
import './TaskManager.scss';
import Breadcrumbs from './widgets/Breadcrumbs';
import { AllowedActions, statusMap } from './constants';
import TaskManagerFilters from './TaskManagerFilterPanel';

export default function TaskManager() {

  const [toggleTaskStatus,] = useMutation(
    TOGGLE_TASK_STATUS,
    {
      onCompleted: () => {
        // eslint-disable-next-line
        refetchTasks();
      },  
      onError: () => {
        <ErrorMessage>Something went wrong.</ErrorMessage>;
      }
    }
  );

  // eslint-disable-next-line 
  const handleSelectedAction = (evt, row) => {
    const taskId = row?.original?.id;
    if (evt === 'View') {
      const url = `${window.AFP_CONFIG.appURLs.catalog}/catalog/tasks/${taskId}`;
      window.location.href = url;
      return url;
    }
    const taskStatus = {
      Reopen: 'To-Do',
      'Mark as done': 'Closed',
      'Mark as In progress': 'In progress',
    };

    if (taskStatus[evt]) {
      toggleTaskStatus({
        variables: { id: parseInt(taskId, 10), status: taskStatus[evt] },
      });
    }
  };

  const getActionsByStatus = ({ row }) => {
    const status = row?.original?.status;
    if (status === 'ToDo' || status === 'To-Do') {
      return AllowedActions.filter((item) => item.label !== 'Reopen');
    }
    if (status === 'Closed') {
      return AllowedActions.filter(
        (item) =>
          item.label !== 'Mark as In progress' && item.label !== 'Mark as done',
      );
    }
    if (status === 'In progress' || status === 'InProgress') {
      return AllowedActions.filter(
        (item) =>
          item.label !== 'Reopen' && item.label !== 'Mark as In progress',
      );
    }
    return AllowedActions;
  };

  const actions = {
    Header: 'Actions',
    sortable: false,
    headerClassName: 'cell-center',
    cellClassName: 'cell-center',
    Cell: (props) => (
      <AFPTableRowAction
        {...props}
        actions={getActionsByStatus(props)}
        // eslint-disable-next-line react/prop-types
        onSelectAction={(evt) => handleSelectedAction(evt, props.row)}
      />
    ),
  };

  const defaultFilters = {
    operator: "$and",
    conditions: [
      {
        operator: "$exact",
        key: "taskTypeId",
        value: "2"
      },
      {
        operator: "$in",
        key: "status",
        value: ["To-Do", "In progress"]
      }
    ]
  }

  const [order,] = useState('dueDate', 'DESC'); // [['updatedAt', 'DESC']]
  const [filters, setFilters] = useState(defaultFilters);
  const [paginationState, setPaginationState] = useState({
    limit: 10,
    offset: 0,
    currentPage: 1,
  });

  const columns = useMemo(() => {

    return [
      {
        Header: 'Task ID',
        accessor: 'taskID',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value, data }) => {
          // eslint-disable-next-line react/prop-types
          const filterData = data?.filter((val) => val.taskID === value);
          return (
            <a
              // eslint-disable-next-line react/prop-types
              href={`${window.AFP_CONFIG.appURLs.catalog}/catalog/tasks/${filterData[0]?.id}`}
              className="usa-button white-anchor-text display-block"
            >
              {value}
            </a>
          );
        },
      },

      {
        Header: 'Task Title',
        accessor: 'title',
      },
      {
        Header: 'Task Type',
        accessor: 'taskType.taskTypeName',
      },
      {
        Header: 'Status',
        accessor: 'status',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => (
          <StatusBadge variant={statusMap(value).color}>
            {statusMap(value).text}
          </StatusBadge>
        ),
      },
      {
        Header: 'Due Date',
        accessor: 'dueDate',
        Cell: ({ value }) =>
          value ? DateTime.fromISO(value).toFormat('LL/dd/yyyy') : '—',
      },
      actions,
    ]
  }, []);



  const handlePaginationChange = (currentPage, itemsPerPage) => {
    const offset = (currentPage - 1) * itemsPerPage;
    setPaginationState({
      limit: itemsPerPage,
      offset,
      currentPage,
    });
  };


  const [getTasks, { refetch: refetchTasks, data, loading }] = useLazyQuery(USER_TASKS, {
    fetchPolicy: 'network-only',
  });


  const getData = () => {
    const { limit, offset } = paginationState;
    getTasks({
      variables: { filters, order, offset, limit },
    });
  };

  useEffect(() => {
    getData();
  }, []);


  useDidMountEffect(() => {
    getData();
  }, [filters, order, paginationState]);


  const TableWrapper = ({ tableProps, paginationProps }) => (
    <>
      <AFPTable {...tableProps} />
      {tableProps.isTaskDataLoading && <Spinner aria-busy="true" size="small" />}
      {!tableProps.isTaskDataLoading && tableProps.data?.length ? <Pagination {...paginationProps} /> : null}
      {tableProps.data === null ||
        (!tableProps.isTaskDataLoading && !tableProps.data?.length && (
          <div className="text-center margin-top-neg-2 height-full">
            <EmptyState
              hasBackground
              containerStyles="padding-top-9 height-full"
              topText={
                <>
                  <strong>No Tasks Available.</strong>
                </>
              }
            />
          </div>
        ))}
    </>
  );

  const FTF = useMemo(
    () => FilterTableFrame(null, null, TaskManagerFilters, TableWrapper),
    [],
  );

  return (
    <div className="task-manager grid-container overflow-x-scroll">
      <Breadcrumbs />
      <PageTitle title='Task Manager' />
      <WelcomeMessage />
      <div className="task-manager__table desktop:grid-col-12 tablet-lg:grid-col-9 standard-table-wrapper tbl">
        <FTF
          lowLeftProps={{
            defaultFilters,
            setFilters,
          }}
          lowRightProps={{
            tableProps: {
              columns,
              data: data?.getTasks?.rows || [],
              fullWidth: true,
              testId: 'task-management-table',
              isTaskDataLoading: loading,
            },
            paginationProps: {
              itemsPerPageOptions: [10, 25, 50],
              boundaryCount: 1,
              siblingCount: 1,
              onPageChange: handlePaginationChange,
              variant: 'advanced',
              itemsPerPage: paginationState.limit,
              currentPage: paginationState.currentPage,
              itemsCount: data?.getTasks?.count,
              // isReset: isReset,
              disabled: !!loading,
            },
          }}
          filterToggle
        />
      </div>
    </div>
  );
}
