// FIXME move this hook to afp-shared-ui-utils
import { useState } from 'react';

const getQuery = () => {
  if (typeof window !== 'undefined') {
    return new URLSearchParams(window.location.search);
  }
  return new URLSearchParams();
};

const getQueryStringVal = (key) => {
  return getQuery().get(key);
};

const useQueryParam = (key, defaultVal) => {
  const [query, setQuery] = useState(getQueryStringVal(key) || defaultVal);

  const updateUrl = (newVal) => {
    setQuery(newVal);

    const queryPrms = getQuery();

    if (newVal.trim() !== '') {
      queryPrms.set(key, newVal);
    } else {
      queryPrms.delete(key);
    }
  };

  return [query, updateUrl];
};

export default useQueryParam;
