import PropTypes from 'prop-types';
import React from 'react';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import Attachment from '../../../components/Attachment';
import AttachmentProvider, {
  useAttachments,
} from '../../../components/Attachment/AttachmentProvider';
import { useTask } from '../TaskContextProvider';

const AttachmentWrapper = ({ uploadPath, allowChanges }) => {
  const {
    fetching,
    rows,
    count,
    hasMore,
    loadMore,
    saving,
    saved,
    updating,
    updated,
    deleting,
    currentFetchVariables: { limit },
    downloadAttachment,
    deleteAttachment,
    sortAttachment,
    saveAttachment,
    updateDescription,
    attachmentErrors,
    setAttachmentErrors,
    currentUser,
    linkedEntities,
  } = useAttachments();

  return (
    <div className="attachment" data-testid="attachment-container">
      <Attachment
        title={<h2>Supporting Documents</h2>}
        loading={fetching}
        data={rows}
        count={count}
        hasMore={hasMore}
        loadMoreLimit={5}
        paginationLimit={limit}
        uploadPath={uploadPath}
        onLoadMore={loadMore}
        adding={saving}
        added={saved}
        updating={updating}
        updated={updated}
        deleting={deleting}
        onDownload={downloadAttachment}
        onSort={sortAttachment}
        onDelete={deleteAttachment}
        onUpdateDescription={updateDescription}
        onAdd={saveAttachment}
        currentUser={currentUser}
        errors={attachmentErrors}
        setErrors={setAttachmentErrors}
        linkedEntities={linkedEntities}
        allowChanges={allowChanges}
      />
    </div>
  );
};

AttachmentWrapper.defaultProps = {
  uploadPath: '',
  allowChanges: true,
};
AttachmentWrapper.propTypes = {
  uploadPath: PropTypes.string,
  allowChanges: PropTypes.bool,
};

const Documents = () => {
  const { task, setTaskAttachments } = useTask();
  const allowChanges = task.status === 'Open';
  const { currentUser } = useCurrentUser();
  const linkedEntities = {
    ...task?.linkedEntities,
    tasks: [{ taskId: task.id }],
  };

  return (
    <AttachmentProvider
      fetchVariables={{
        filter: { tasks: [{ taskId: task.id }] },
        limit: 5,
        offset: 0,
        order: 'updatedAt DESC',
      }}
      setParentContext={setTaskAttachments}
      linkedEntities={linkedEntities}
      currentUser={currentUser}
    >
      <AttachmentWrapper
        uploadPath={`vehicles${`/${task?.metaData?.vin}`}`}
        allowChanges={allowChanges}
      />
    </AttachmentProvider>
  );
};

export default Documents;
