export const formatZipCodeDomestic = (zipCode) => {
  if (!zipCode) {
    return '';
  }
  let formattedZipCode = zipCode;
  formattedZipCode = formattedZipCode.trim();
  // https://regex101.com/r/w9khTI/1/
  formattedZipCode = formattedZipCode.replace(/[^0-9]+/g, '');
  // https://regex101.com/r/lhi3nT/1
  formattedZipCode = formattedZipCode.replace(
    /^([0-9]{5})([0-9]{4})$/,
    '$1-$2',
  );
  return formattedZipCode;
};

export const formatPostalCodeInternational = (postalCode) => {
  if (!postalCode) {
    return '';
  }
  let formattedPostalCode = postalCode;
  formattedPostalCode = formattedPostalCode.trim();
  return formattedPostalCode;
};

export const formatZipOrPostalCode = (zipOrPostalCode, isDomestic) => {
  if (isDomestic) {
    return formatZipCodeDomestic(zipOrPostalCode);
  }
  return formatPostalCodeInternational(zipOrPostalCode);
};
