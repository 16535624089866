import React from 'react';
import { Alert } from '@gsa/afp-component-library';
import { FleetTechnicalSupportContact } from '@gsa/afp-shared-ui-utils';

import './signup.scss';

const DeactivatedAccountError = () => {
  return (
    <Alert
      heading="Account cannot be created at this time"
      type="error"
      data-testid="deactivated-account-error"
      focused
    >
      We noticed that you already have one of GSA accounts using FAS ID but your
      FAS ID is deactivated, <FleetTechnicalSupportContact />. Once your FAS ID
      has been reactivated, please retry creating your account.
    </Alert>
  );
};

export default DeactivatedAccountError;
