import React from 'react';
import PropTypes from 'prop-types';
import { StatusBadge } from '@gsa/afp-component-library';
import { useUserDetails } from '../providers/user-details-provider';
import { Status } from '../../constants/user-details-constants';

const UserStatus = ({ className }) => {
  const { user } = useUserDetails();
  const statusName = user?.status ? user?.status?.name : '';
  const statusId = user?.status ? user?.status?.id : '';

  const getStatus = (status) => {
    switch (status) {
      case Status.ACTIVE:
        return 'Ready-Gray';
      case Status.PENDING_PROFILE:
      case Status.PENDING_ROLE:
      case Status.PENDING_REACTIVATION:
        return 'Warning-Gray';
      case Status.DEACTIVATED:
      case Status.DENIED:
      case Status.DELETED:
        return 'Urgent-Gray';
      default:
        return 'Inactive-Gray';
    }
  };

  return (
    <>
      {statusId && (
        <div data-testid="status-tag-test-id" className={className}>
          <StatusBadge variant={getStatus(statusId)}>{statusName}</StatusBadge>
        </div>
      )}
    </>
  );
};

UserStatus.defaultProps = {
  className: '',
};

UserStatus.propTypes = {
  className: PropTypes.string,
};

export default UserStatus;
