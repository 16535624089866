import PropTypes from 'prop-types';
import React from 'react';
import './standard-forms.scss';

export const StandardFieldset = ({ label, children, name }) => {
  return (
    <fieldset className="usa-fieldset usa-fieldset  margin-bottom-6" name={name}>
      {label && (
        <legend className="usa-legend font-body-lg text-bold text-primary">
          <h4 className="standard-section-header">{label}</h4>
        </legend>
      )}
      {children}
    </fieldset>
  );
};
StandardFieldset.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  children: PropTypes.node.isRequired,
};
StandardFieldset.defaultProps = {
  label: undefined,
};

export default StandardFieldset;
