import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
  FormGroup,
  ErrorMessage,
  Textbox,
  Alert,
  Label,
} from '@gsa/afp-component-library';
import { FleetTechnicalSupportContact } from '@gsa/afp-shared-ui-utils';
import { StandardFieldset } from '../../../components/Forms';
import Regex from '../../../utilities/RegexUtils';
import { useCreateInvitation } from '../providers/create-invitation-provider';

const InvitationPersonalInfoForm = () => {
  const { register, errors, watch } = useFormContext();
  const { message } = useCreateInvitation();
  const showWarning =
    message && message.message === 'User or Invitation already exists';

  const email = watch('email');
  const validationOptions = {
    email: {
      required: 'Please provide your email',
      pattern: {
        value: Regex.email,
        message: 'Email is not valid',
      },
    },
    confirmEmail: {
      required: 'Please re-type your email',
      pattern: {
        value: Regex.email,
        message: 'Email is not valid',
      },
      validate: {
        emailMatch: (confirmEmail) =>
          email === confirmEmail ||
          'Email address does not match. Please try again.',
      },
    },
    firstName: {
      required: 'Please provide your first name',
      maxLength: {
        value: 51,
        message: 'First name should be 50 characters or fewer',
      },
      pattern: {
        value: Regex.name,
        message: 'First name is not valid',
      },
    },
    lastName: {
      required: 'Please provide your last name',
      maxLength: {
        value: 51,
        message: 'Last name should be 50 characters or fewer',
      },
      pattern: {
        value: Regex.name,
        message: 'Last name is not valid',
      },
    },
  };

  return (
    <StandardFieldset label="Personal Information" name="personalInformation">
      <div className="grid-row grid-gap">
        <div className="mobile-lg:grid-col-6">
          <FormGroup
            className="margin-left-0"
            error={errors && errors.firstName}
          >
            <Label required htmlFor="firstName" className="text-bold">
              First name
            </Label>
            {errors && errors.firstName && (
              <ErrorMessage id="first-name-errors">
                {errors.firstName.message}
              </ErrorMessage>
            )}
            <Textbox
              name="firstName"
              id="firstName"
              type="text"
              aria-invalid={errors && errors.firstName ? 'true' : 'false'}
              aria-describedby={
                errors && errors.firstName ? 'first-name-errors' : undefined
              }
              inputRef={register(validationOptions.firstName)}
              required
              data-testid="firstName-input"
            />
          </FormGroup>
        </div>
        <div className="mobile-lg:grid-col-6">
          <FormGroup
            className="margin-left-0"
            error={errors && errors.lastName}
          >
            <Label required htmlFor="lastName" className="text-bold">
              Last name
            </Label>

            {errors && errors.lastName && (
              <ErrorMessage id="last-name-errors">
                {errors.lastName.message}
              </ErrorMessage>
            )}
            <Textbox
              name="lastName"
              id="lastName"
              type="text"
              aria-invalid={errors && errors.lastName ? 'true' : 'false'}
              aria-describedby={
                errors && errors.lastName ? 'last-name-errors' : undefined
              }
              inputRef={register(validationOptions.lastName)}
              required
              data-testid="lastName-input"
            />
          </FormGroup>
        </div>
      </div>
      <FormGroup
        className="margin-left-0"
        error={(errors && errors.email) || showWarning}
      >
        <Label required htmlFor="email" className="text-bold">
          Email
        </Label>
        {errors && errors.email && (
          <ErrorMessage id="email-errors">{errors.email.message}</ErrorMessage>
        )}
        {showWarning && (
          <ErrorMessage>
            A user with this email address already exists.
          </ErrorMessage>
        )}
        <Textbox
          name="email"
          id="email"
          type="email"
          className="maxw-full"
          aria-invalid={errors && errors.email ? 'true' : 'false'}
          aria-describedby={errors && errors.email ? 'email-errors' : undefined}
          inputRef={register(validationOptions.email)}
          required
          data-testid="email-input"
        />
      </FormGroup>
      {showWarning && (
        <Alert type="warning" data-testid="deactivated-account-error" focused>
          Invitation cannot be sent, as this user has a GSAFleet.gov account or
          has already received an invitation with this email address.{' '}
          <FleetTechnicalSupportContact />.
        </Alert>
      )}
      <FormGroup
        className="margin-left-0"
        error={errors && errors.confirmEmail}
      >
        <Label required htmlFor="confirmEmail" className="text-bold">
          Confirm email
        </Label>
        {errors && errors.confirmEmail && (
          <ErrorMessage id="confirm-email-errors">
            {errors.confirmEmail.message}
          </ErrorMessage>
        )}
        <Textbox
          name="confirmEmail"
          id="confirmEmail"
          type="email"
          className="maxw-full"
          aria-invalid={errors && errors.confirmEmail ? 'true' : 'false'}
          aria-describedby={
            errors && errors.confirmEmail ? 'confirm-email-errors' : undefined
          }
          inputRef={register(validationOptions.confirmEmail)}
          required
          data-testid="confirm-email-input"
        />
      </FormGroup>
    </StandardFieldset>
  );
};

export default InvitationPersonalInfoForm;
