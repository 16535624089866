import React from 'react';

import { connectModal, Modal, Button } from '@gsa/afp-component-library';
import CreateInvitationModal from './create-invitation-modal';
import {
  CreateInvitationProvider,
  createInvitationContext,
  useCreateInvitation,
} from '../providers/create-invitation-provider';

const CreateInvitationModalTrigger = () => {
  const invitationModal = () => {
    const { reset } = useCreateInvitation();
    return (
      <Modal
        title={<div className="title-l">Invite User</div>}
        onClose={reset}
        variant="large"
        actions={
          <>
            <Button
              className="padding-right-2"
              variant="unstyled"
              label="Cancel"
              onClick={reset}
            />
            <Button
              variant="primary"
              type="submit"
              form="create-user-invitation-form"
              label="Send"
            />
          </>
        }
      >
        <CreateInvitationModal />
      </Modal>
    );
  };

  const ConnectedInvitationModal = connectModal(invitationModal);

  return (
    <CreateInvitationProvider>
      <createInvitationContext.Consumer>
        {(ctx) => (
          <>
            <Button
              leftIcon={{
                className: '',
                name: 'mail',
                type: '',
              }}
              type="button"
              onClick={ctx.openModal}
              label="Invite user"
              className="usa-button float-right"
            />

            <ConnectedInvitationModal
              isOpen={ctx.isOpen}
              onClose={ctx.closeModal}
            />
          </>
        )}
      </createInvitationContext.Consumer>
    </CreateInvitationProvider>
  );
};

export default CreateInvitationModalTrigger;
