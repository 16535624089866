import React from 'react';
import { Icon } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';

const Links = ({ links }) =>
  !links.length ? (
    ''
  ) : (
    <div className="afp-task-detail__task_detail_links">
      {links.map((link) => (
        <a rel="noreferrer" target="_blank" href={link.href} key={link.title}>
          {link.title}
          <Icon
            iconName="launch"
            style={{ fill: 'rgb(114,118,122)', verticalAlign: 'text-top' }}
            className="usa-icon--size-2"
          />
        </a>
      ))}
    </div>
  );

Links.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default Links;
