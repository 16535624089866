import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { DetailsTable, Alert, PageTitle } from '@gsa/afp-component-library';
import { FleetTechnicalSupportContact, useTitle } from '@gsa/afp-shared-ui-utils';
import LockedSupervisorInformation from './locked-supervisor-information';
import { formatPhoneNumber, capitalize } from '../../../utilities/StringUtils';
import VendorUserProfileCard from './vendor-user-profile-card';
import {
  Types,
  Status,
} from '../../admin-console/users/constants/user-details-constants';
import {
  GET_AGENCY,
  GET_BUREAU,
  GET_OFFICE,
  GET_VENDORS_BY_PERMISSION
} from '../../../services/data-layer';
import '../../admin-console/users/user-details/components/user-information.scss';
import { OPERATIONS, SUBJECTS } from '../../../utilities/constants';


const formatIsContractor = (user) => {
  if (user?.customerAttrs?.isContractor == null) return '-';
  return user.customerAttrs.isContractor ? 'Yes' : 'No';
};
//---------
// This file is a knock off user-information.js
// TODO eventually both files will need to be consolidated
const UserInformation = ({ currentUser: user, setInvalidState }) => {
  useTitle(capitalize(user?.fullName));
  const [agencyName, setAgencyName] = useState('');
  const [bureauName, setBureauName] = useState('');
  const [officeName, setOfficeName] = useState('');
  const [vendors, setVendors] = useState([]);
  const [,setVendorsError] = useState(null);
  // TODO: the current sequelize version lib the project has does not support composite foreign keys integration.
  // Update sequelize and sequelize-typescript versions and refactor breaking changes in user models
  // to allow this capability in afp-shared-svc
  const [getAgency] = useLazyQuery(GET_AGENCY, {
    onError: () => {
      setInvalidState(true);
    },
    onCompleted: (data) => {
      setAgencyName(data.getAgency?.name || '');
    },
  });

  const [getBureau] = useLazyQuery(GET_BUREAU, {
    onError: () => setInvalidState(true),
    onCompleted: (data) => {
      setBureauName(data.getBureau?.name || '');
    },
  });

  const [getOffice] = useLazyQuery(GET_OFFICE, {
    onError: () => setInvalidState(true),
    onCompleted: (data) => {
      setOfficeName(data.getOfficeNext?.officeName || '');
    },
  });

  const {loading} = useQuery(GET_VENDORS_BY_PERMISSION, {
    variables: {
      operation: OPERATIONS.View,
      subject: SUBJECTS.Vendor,
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    skip: user?.userType?.id !== '2',
    onCompleted: (data) => {
      setVendors(data.getVendorsByPermission)
    },
    onError: () => {
      setVendorsError('Not able to load vendors')
    }
  })

  useEffect(() => {
    if (user && user.customerAttrs) {
      const {
        registeredAgencyCode: agencyCode,
        registeredBureauCode: bureauCode,
        registeredOfficeCode: officeCode,
      } = user.customerAttrs;

      if (agencyCode) {
        getAgency({ variables: { id: agencyCode } });

        if (bureauCode) {
          getBureau({
            variables: {
              id: bureauCode,
              agencyCode,
            },
          });

          if (officeCode) {
            getOffice({
              variables: {
                officeCode,
                bureauCode,
                agencyCode,
              },
            });
          }
        }
      }
    }
  }, []);


  const formatData = (...params) => {
    if (!params) {
      return null;
    }

    const filterParams = params?.filter((item) => item && item.trim().length);
    return filterParams?.reduce((ans, item, idx) => {
      ans.push(item);
      // eslint-disable-next-line react/no-array-index-key
      ans.push(<br key={idx} />);
      return ans;
    }, []);
  };

  const formatCityStateZipcode = (city, state, zipcode) =>
    city?.concat(`, ${state}`).concat(` ${zipcode}`);

  const mailto = (mail) => `mailto:${mail}`;

  if (!user.userType) {
    return <> </>;
  }
  let officeInfo;
  if (user?.userType.id === Types.GSA_EMPLOYEE) {
    if (user?.internalAttrs?.zone) {
      officeInfo = [
        ['Zone', user?.internalAttrs?.zone?.name],
        ['Management Center', user?.internalAttrs?.managementCenter?.name],
        ['Field Office', user?.internalAttrs?.fieldOffice?.name],
      ];
    } else if (user?.internalAttrs?.division) {
      officeInfo = [
        ['Division', user?.internalAttrs?.division?.name],
        ['Branch', user?.internalAttrs?.branch?.name],
      ];
    } else {
      officeInfo = [
        ['Zone', '-'],
        ['Management Center', '-'],
        ['Field Office', '-'],
        ['Division', '-'],
        ['Branch', '-'],
      ];
    }
    officeInfo.push([
      'Work Address',
      formatData(
        user?.addrline1,
        user?.addrline2,
        user?.addrline3,
        formatCityStateZipcode(user?.city, user?.state, user?.postalCode),
      ),
    ]);
    officeInfo.push([
      'Work Phone',
      formatPhoneNumber(user?.phoneNumber, user?.phoneNumberExt),
    ]);
  }
  const personInfoData = [
    ['Name', capitalize(user?.fullName)],
    [
      'Email',
      user?.email ? <a href={mailto(user?.email)}>{user?.email}</a> : null,
    ],
    ['User type', user?.userType?.name],
  ];
  if (user?.userType.id === Types.CUSTOMER) {
    personInfoData.push([
      <div className="text-wrap width-card-lg">
        Non federal employee working under contract with a federal agency
      </div>,
      formatIsContractor(user),
    ]);
  }

  return (
    <>
      {user.status.id === Status.PENDING_ROLE && (
        <Alert
          className="alert"
          type="warning"
          heading="Your profile is under review"
        >
          Thank you for your patience while an authorized approver is currently
          reviewing your profile. For technical support,{' '}
          <FleetTechnicalSupportContact />.
        </Alert>
      )}
      <div>
        <PageTitle title="Profile" />
        <h2 style={{ fontWeight: 'normal', marginTop: '0.25rem' }}>
          Welcome back, {user.fullName}.
        </h2>
      </div>
      <div className="user-information">
        <div className="margin-top-5 margin-bottom-8">
          <div className="grid-row grid-gap-lg">
            <div className="mobile-lg:grid-col-12 tablet:grid-col-8 desktop:grid-col-6">
              <h2 className="text-heavy text-primary font-sans-xs text-uppercase">
                Personal Information
              </h2>
              <div className="grid-container-widescreen details-locked bg-gray-3 padding-3 radius-md">
                <DetailsTable data={personInfoData} bordered />
              </div>
            </div>
            <LockedSupervisorInformation user={user} />
            {user?.userType.id === Types.CUSTOMER && (
              <div className="customer-details mobile-lg:grid-col-12 tablet:grid-col-8 desktop:grid-col-6">
                <h2 className="text-heavy text-primary font-sans-xs text-uppercase">
                  Work Information
                </h2>
                <div className="grid-container-widescreen details-locked bg-gray-3 padding-3 radius-md">
                  <DetailsTable
                    data={[
                      ['Agency', agencyName],
                      ['Bureau', bureauName],
                      ['Office', officeName],
                      [
                        'Work Address',
                        formatData(
                          user?.addrline1,
                          user?.addrline2,
                          user?.addrline3,
                          formatCityStateZipcode(
                            user?.city,
                            user?.state,
                            user?.postalCode,
                          ),
                        ),
                      ],
                      [
                        'Work Phone',
                        formatPhoneNumber(
                          user?.phoneNumber,
                          user?.phoneNumberExt,
                        ),
                      ],
                    ]}
                    bordered
                  />
                </div>
              </div>
            )}
            {user?.userType.id === Types.VENDOR && (
              <VendorUserProfileCard vendors={vendors} isLoading={loading} />
            )}
            {user?.userType.id === Types.GSA_EMPLOYEE && (
              <div className="customer-details mobile-lg:grid-col-12 tablet:grid-col-8 desktop:grid-col-6">
                <h2 className="text-heavy text-primary font-sans-xs text-uppercase">
                  Work Information
                </h2>
                <div className="grid-container-widescreen details-locked bg-gray-3 padding-3 radius-md">
                  <DetailsTable data={officeInfo} bordered />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

UserInformation.propTypes = {
  currentUser: PropTypes.shape({
    addrline1: PropTypes.string,
    addrline2: PropTypes.string,
    addrline3: PropTypes.string,
    city: PropTypes.string,
    isContractor: PropTypes.bool,
    state: PropTypes.string,
    countryCode: PropTypes.string,
    zipcode: PropTypes.string,
    phoneNumber: PropTypes.string,
    phoneNumberExt: PropTypes.string,
    email: PropTypes.string,
    fullName: PropTypes.string,
    customerAttrs: PropTypes.shape({
      registeredAgencyCode: PropTypes.string,
      registeredBureauCode: PropTypes.string,
      registeredOfficeCode: PropTypes.string,
    }),
    userType: PropTypes.shape({
      name: PropTypes.string,
    }).isRequired,
  }).isRequired,
  setInvalidState: PropTypes.func.isRequired,
};

export default UserInformation;
