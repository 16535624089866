import { useMutation } from '@apollo/client';
import {
  Button,
  connectModal,
  Modal,
  RequiredFieldIndicator,
  SelectDropdown,
} from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { UPDATE_USER_STATUS } from '../../../../services/data-layer';
import {
  Status,
  StatusLabel,
} from '../../../admin-console/users/constants/user-details-constants';
import { canActivateOrDeactivateUser, canUpdateUser } from '../../authorization';
import UserStatusTag from '../user-status-tag';
import './styles/modal.css';
import { RequiredFieldsHint } from '../../../../components/Required/RequiredFieldsHint';

export const isEditableStatus = (userStatusId) => {  
  switch (userStatusId) {
    case Status.ACTIVE:
    case Status.INACTIVE:
    case Status.PENDING_REACTIVATION:
    case Status.DEACTIVATED:
    case Status.DENIED:
      return true;
    default:
      return false;
  }
};

export const canEditProfile = (ability, userStatusId) => {  
  return canUpdateUser(ability) && isEditableStatus(userStatusId);
};

export const canEditUserStatus = (
  ability, 
  userStatusId, 
  currentUserId, 
  targetUserId) => {  
  return currentUserId !== targetUserId
    && canActivateOrDeactivateUser(ability) 
    && isEditableStatus(userStatusId);  
};

const Title = () => {
  return <h1 data-testid="edit-user-state-modal-title">Edit account status</h1>;
};

const Actions = ({ onCancel, onConfirm, hideButton }) => {
  return (
    <>
      <Button
        label="Cancel"
        variant={`${hideButton ? 'primary' : 'unstyled'}`}
        className="padding-right-1"
        onClick={onCancel}
        data-testid="edit-user-state-cancel-btn"
      />
      {!hideButton ? (
        <Button
          label="Update user status"
          variant="primary"
          type="submit"
          data-testid="edit-user-state-confirm-btn"
          onClick={onConfirm}
        />
      ) : null}
    </>
  );
};

Actions.defaultProps = {
  hideButton: false,
};

Actions.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  hideButton: PropTypes.bool,
};

export const EditUserStatusModal = ({
  isOpen,
  userId,
  userFirstName,
  userLastName,
  userEmail,
  userStatusId,
  userStatusName,
  onDataChanged,
  onClose,
  onFeedback,
}) => {

  const ConfirmModal = connectModal(() => {
    const [newStatusId, setNewStatusId] = React.useState();
    const [errorRequired, setErrorRequired] = useState(false);
    const statusInputRef = useRef();
    const hideButton = false;

    const newStatusOptions = [{ value: '', label: '-Select-' }];
    if (userStatusId !== Status.ACTIVE) {
      newStatusOptions.push({
        value: Status.ACTIVE,
        label: StatusLabel.ACTIVE,
      });
    }
    if (userStatusId !== Status.DEACTIVATED) {
      newStatusOptions.push({
        value: Status.DEACTIVATED,
        label: StatusLabel.DEACTIVATED,
      });
    }

    const [updateUserStatus] = useMutation(UPDATE_USER_STATUS, {
      onError: () => {
        onFeedback({ type: 'error' });
        onClose();
      },
      onCompleted: (data) => {
        if (data.updateUserStatus) {
          const getStatus = (statusId) => {
            switch (`${statusId}`) {
              case Status.ACTIVE:
                return 'active';
              case Status.DEACTIVATED:
                return 'deactivated';
              default:
                return 'unknown';
            }
          };

          onFeedback({
            type: 'success',
            message: (
              <span>
                The user status is successfully updated to{' '}
                <b>{getStatus(newStatusId)}</b>.
              </span>
            ),
          });
          onDataChanged();
          onClose();
        }
      },
    });

    const validateForm = (status) => {
      setErrorRequired(!status);
      if (!status) {
        statusInputRef?.current?.focus();
      }
    }

    return (
      <Modal
        variant="large"
        title={<Title />}
        actions={
          <Actions
            onCancel={() => {
              onClose();
            }}
            onConfirm={() => {
              validateForm(newStatusId);
              const statusId = parseInt(newStatusId, 10) || 0;
              if (statusId && `${statusId}` !== userStatusId) {
                updateUserStatus({
                  variables: { id: `${userId}`, status: statusId },
                });
              }
            }}
            hideButton={hideButton}
          />
        }
        onClose={() => {
          onClose();
        }}
      >
        <RequiredFieldsHint />

        <h2 className="text-heavy text-primary font-sans-xs text-uppercase">
          User Information
        </h2>
        <span className="section-minor-header">Name</span>
        <div className="padding-bottom-4">
          {userFirstName} {userLastName}
        </div>

        <span className="section-minor-header">Email address</span>
        <div className="padding-bottom-4">
          <a
            href={`mailto:${userEmail}`}
            data-testid="edit-user-status-modal-email"
          >
            {userEmail}
          </a>
        </div>

        <h2 className="text-heavy text-primary font-sans-xs text-uppercase">
          Account Status
        </h2>
        <span>Current account status</span>
        <div className="padding-bottom-4">
          <UserStatusTag value={userStatusName} />
        </div>

        <span>
          New account status <RequiredFieldIndicator />
        </span>
        <br />
        <span>
          Please choose below the new account status for the following user.
        </span>
        <SelectDropdown
          id="newState"
          name="newState"
          onChange={(ev) => {
            validateForm(ev.target.value);
            setNewStatusId(ev.target.value);
          }}
          options={newStatusOptions}
          value={newStatusId}
          data-testid="new-state-select-dropdown"
          inputRef={statusInputRef}
          errorMessage={(errorRequired ? 'Please select account status' : '')}
          aria-invalid={errorRequired} 
        />
      </Modal>
    );
  });

  return <ConfirmModal isOpen={isOpen} />;
};

EditUserStatusModal.propTypes = {
  onDataChanged: PropTypes.func.isRequired,
  onFeedback: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,

  isOpen: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  userFirstName: PropTypes.string.isRequired,
  userLastName: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
  userStatusId: PropTypes.string.isRequired,
  userStatusName: PropTypes.string.isRequired,
};

export default EditUserStatusModal;
