import React from 'react';
import { useForm } from 'react-hook-form';
import { Alert } from '@gsa/afp-component-library';
import { useAttachmentComponent } from './ComponentContext';
import DescriptionField from './DescriptionField';

const AttachmentEditForm = () => {
  const {
    onUpdateDescription,
    attachmentErrors,
    editState: { data = {} },
  } = useAttachmentComponent();

  const { register, handleSubmit, errors } = useForm({
    defaultValues: { description: data?.description },
  });

  const onSubmit = (formData) => {
    if (!data.id) return;
    onUpdateDescription({
      variables: {
        id: parseInt(data.id, 10),
        description: formData.description,
      },
    });
  };

  return (
    <form
      data-testid="attachment-edit-form"
      id="attachment-edit-form"
      onSubmit={handleSubmit(onSubmit)}
    >
      {attachmentErrors?.update?.message && (
        <Alert type="error">{attachmentErrors?.update?.message}</Alert>
      )}
      <DescriptionField
        fieldRef={register}
        showErrorMessage={errors?.description?.type === 'required'}
      />
    </form>
  );
};

export default AttachmentEditForm;
