export const vehicleRedirect = () => {
  const url = [
    window.AFP_CONFIG.appURLs.vms,
    window.location.pathname,
    window.location.search,
  ].join('');
  window.location.assign(url);
  return null;
};

export const catalogRedirect = () => {
  window.location.assign(`${window.AFP_CONFIG.appURLs.catalog}/catalog`);
  return null;
};

export const storeRedirect = () => {
  window.location.assign(`${window.AFP_CONFIG.appURLs.store}/store`);
  return null;
};
