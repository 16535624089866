import React from 'react';
import UserProfile from './UserProfile';

const UserProfilePage = () => {
  return (
    <>
      <UserProfile />
    </>
  );
};

export default UserProfilePage;
