import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import fileDownload from 'js-file-download';
import PropTypes from 'prop-types';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { getToken } from '@gsa/afp-shared-ui-utils';
import * as GQL from './helpers';

export const AttachmentContext = createContext();

export default function AttachmentProvider({
  fetchVariables,
  children,
  setParentContext,
  linkedEntities,
  currentUser,
}) {
  const [attachmentData, setAttachmentData] = useState({
    rows: [],
    count: 0,
    hasMore: false,
  });

  // Temporary state for file to be downloaded.
  const [downloadObject, setDownloadObject] = useState(null);

  // Error state.
  const [attachmentErrors, setAttachmentErrors] = useState({});

  // Helpful to give success feedbacks.
  const [saved, setSaved] = useState(false);
  const [saving, setSaving] = useState(false);

  const [updated, setUpdated] = useState(false);
  const [deleted, setDeleted] = useState(false);

  /**
   * Attachment fetch handlers.
   * Fetch PaginatedResponse of shape {rows, count, hasMore}
   */
  const {
    loading: fetching,
    data,
    errors: fetchErrors,
    variables: currentFetchVariables,
    refetch,
  } = useQuery(GQL.GET_ATTACHMENTS, {
    variables: {
      ...fetchVariables,
    },
  });

  useEffect(() => {
    // If data is loaded, set to context state.
    if (data?.getAttachments) {
      const attachmentResponse = data?.getAttachments;
      setAttachmentData({
        ...attachmentResponse,
      });

      // Pass response to parent context.
      setParentContext({
        ...attachmentResponse,
      });
    }
  }, [data]);

  useEffect(() => {
    // If fetch error occurs set to attachment error
    setAttachmentData((prev) => ({ ...prev, fetch: fetchErrors }));
  }, [fetchErrors]);

  /**
   * Download handlers.
   */
  const [getSignedReadURL, { loading: gettingReadURL }] = useLazyQuery(
    GQL.GET_AWS_SIGNED_READ_URL,
    {
      onError: (readURLError) => {
        setAttachmentErrors((prev) => ({
          ...prev,
          downloadSignedURL: readURLError,
        }));
        setDownloadObject(null);
      },
      onCompleted: async (readURLData) => {
        try {
          if (readURLData?.generateReadSignedURL) {
            const fileURL = readURLData.generateReadSignedURL;
            if (downloadObject?.name && downloadObject?.type) {
              // Download file using signed url.
              const response = await fetch(fileURL, {
                method: 'GET',
                headers: { 'Content-Type': downloadObject.type },
              });

              // Grab blob from response
              const blob = await response.blob();

              // Browser starts download
              fileDownload(blob, downloadObject.name);
            }
            // Clear temporary download state
            setDownloadObject(null);
          }
        } catch (error) {
          // Clear temporary download state
          setDownloadObject(null);
          setAttachmentErrors((prev) => ({ ...prev, download: error }));
        }
      },
    },
  );

  const downloadAttachment = useCallback((attachment) => {
    if (attachment?.contentURL) {
      setDownloadObject(attachment);
      getSignedReadURL({
        variables: { fileKey: attachment.contentURL, t: new Date().getTime() },
      });
    }
  }, []);

  /**
   * Sort Attachment
   */
  const sortAttachment = useCallback((order = '') => {
    refetch({
      order,
    });
  }, []);

  /**
   * Edit Attachment.
   */
  const [updateDescription, { loading: updating }] = useMutation(
    GQL.UPDATE_ATTACHMENT_DESCRIPTION,
    {
      onError: (error) => {
        setAttachmentErrors((prev) => ({ ...prev, update: error }));
        setUpdated(false);
      },
      onCompleted: async () => {
        setUpdated(true);
        setUpdated(false);
        refetch();
      },
    },
  );

  /**
   *  Save Attachment.
   */
  const saveAttachment = async (formData) => {
    try {
      const token = await getToken();
      if (!token) throw new Error('Invalid user session');

      setSaving(true);

      const response = await fetch(
        `${window.AFP_CONFIG.upload_api_url}/attachment/upload`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
          redirect: 'follow',
        },
      );

      const responseData = await response.json();

      if (response.status === 201) {
        setSaved(true);
        refetch();
        setSaving(false);
        setSaved(false);
      } else if (response.status === 403) {
        // TODO: Token may have expired refresh token
      } else if (response.status === 401) {
        // TODO: Unauthorized access
      } else {
        setSaving(false);
        setSaved(false);

        throw new Error(
          responseData?.message || 'Problem uploading attachment',
        );
      }
    } catch (error) {
      setSaving(false);
      setSaved(false);
      setAttachmentErrors((prev) => ({ ...prev, save: error }));
    }
  };

  /**
   *  Delete Attachment.
   */
  const [processAttachmentDelete, { loading: deleting }] = useMutation(
    GQL.DELETE_ATTACHMENT,
    {
      onError: (error) => {
        setAttachmentErrors((prev) => ({ ...prev, delete: error }));
        setDeleted(false);
      },
      onCompleted: async (deleteData) => {
        if (deleteData?.deleteAttachment) {
          setDeleted(true);
          setDeleted(false);
          refetch();
        }
      },
    },
  );

  const deleteAttachment = useCallback((attachment) => {
    if (attachment?.id) {
      processAttachmentDelete({
        variables: {
          id: parseInt(attachment.id, 10),
          t: new Date().getTime(), // FIXME: Used to trick cache
        },
      });
    }
  }, []);

  /**
   * Fetch x number of records.
   * FIXME: explore graphql fetchMore approach.
   */
  const loadMore = useCallback((records) => {
    refetch({ limit: records });
  }, []);

  return (
    <AttachmentContext.Provider
      value={{
        attachmentErrors,
        ...attachmentData,
        currentFetchVariables,
        fetching,
        gettingReadURL,
        saving,
        saved,
        updating,
        updated,
        deleting,
        deleted,
        linkedEntities,
        currentUser,
        setAttachmentErrors,
        saveAttachment,
        updateDescription,
        deleteAttachment,
        downloadAttachment,
        sortAttachment,
        refetch,
        loadMore,
      }}
    >
      {children}
    </AttachmentContext.Provider>
  );
}

AttachmentProvider.defaultProps = {
  linkedEntities: {},
  currentUser: { email: '', token: '' },
  setParentContext: () => {
    // default empty function
  },
};

AttachmentProvider.propTypes = {
  fetchVariables: PropTypes.objectOf(Object).isRequired,
  children: PropTypes.node.isRequired,
  setParentContext: PropTypes.func,
  linkedEntities: PropTypes.shape(Object),
  currentUser: PropTypes.shape({
    email: PropTypes.string,
    token: PropTypes.string,
  }),
};

// Hook to exposes context value.
export const useAttachments = () => useContext(AttachmentContext);
