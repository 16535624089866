import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { RadioButton, Fieldset } from '@gsa/afp-component-library';
import {
  propTypeFormContext,
  StandardFieldset,
} from '../../../components/Forms';
import UserProfileZoneMgmtCenter from './user-profile-zone-management-center';
import UserProfileDivisionBranches from './user-profile-division-branch';
import { UserProfileWorkPhoneAlt } from '../user-profile-work-phone-alt';

const HqZonalChooser = ({
  formContext,
  setInvalidState,
  phoneCountryCodesOptions,
}) => {
  const [isHq, setIsHq] = useState(true);
  useEffect(() => {
    if (formContext.defaultValues.zoneId !== '') {
      setIsHq(false);
    }
  }, [formContext.defaultValues.divisionId]);

  return (
    <>
      <StandardFieldset label="Work Information" name="workInformation">
        <Fieldset
          legend="Are you working at Headquarters or in a Zone?"
          required
        >
          <RadioButton
            name="hqOrZone"
            label="Headquarter"
            onChange={() => setIsHq(true)}
            checked={isHq}
            value="hq"
            data-testid="internal-working-headquarter"
          />
          <RadioButton
            name="hqOrZone"
            label="Zonal"
            onChange={() => setIsHq(false)}
            checked={!isHq}
            value="zonal"
            data-testid="internal-working-zonal"
          />
          {isHq ? (
            <UserProfileDivisionBranches
              setInvalidState={setInvalidState}
              formContext={formContext}
            />
          ) : (
            <UserProfileZoneMgmtCenter
              setInvalidState={setInvalidState}
              formContext={formContext}
            />
          )}
        </Fieldset>
      </StandardFieldset>
      {!isHq && (
        <StandardFieldset
          className="mt-0"
          label="Work Phone Number"
          name="phoneNumber"
        >
          {/* At a future time we will upgrade to use Typeaheads */}
          {/* <UserProfileWorkPhone
                  formContext={formContext}
                  phoneCountryCodes={phoneCountryCodesOptions}
                /> */}
          <UserProfileWorkPhoneAlt
            formContext={formContext}
            phoneCountryCodes={phoneCountryCodesOptions}
          />
        </StandardFieldset>
      )}
    </>
  );
};

HqZonalChooser.propTypes = {
  formContext: propTypeFormContext.isRequired,
  setInvalidState: PropTypes.func.isRequired,
  phoneCountryCodesOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
};

export default HqZonalChooser;
