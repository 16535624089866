import React from 'react';
import { Breadcrumbs as DSBreadcrumbs } from '@gsa/afp-component-library';
import { Link } from 'react-router-dom';

/* eslint-disable react/prop-types */
const Breadcrumbs = ({ current, path }) => {
  return (
    <div>
      <DSBreadcrumbs
        trail={
          path
            ? [
                ...path.map((item) => {
                  if (item?.location?.indexOf('http' >= 0)) {
                    return <a href={item.location}>{item.label}</a>;
                  }
                  return item.location ? (
                    <Link to={item.location}>{item.label}</Link>
                  ) : (
                    <span className="text-ink">{item.label}</span>
                  );
                }),
              ]
            : [<Link to="/">Home</Link>]
        }
        current={current}
      />
    </div>
  );
};

export default Breadcrumbs;
