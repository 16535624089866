import React from 'react';
import {Button, Icon} from '@gsa/afp-component-library';
import {
  useCurrentUser,
  FleetTechnicalSupportContact,
} from '@gsa/afp-shared-ui-utils';

const InvitationExpiredError = () => {
  const auth = useCurrentUser();

  const handleLoginExternal = async (e) => {
    e.preventDefault();
    await auth.loginUserExternal();
  };

  return (
    <div>
      <span className='title-xl'>Your Invitation Link Has Expired</span>
      <p>
        Invitation link expires after 72 hours and can only be used once. To get a new link, please reach out to your manager. For technical&nbsp; support,{' '}
        <FleetTechnicalSupportContact /> for approval.
      </p>
      <p className='margin-top-2'>
        Already used this link to sign up?{' '}
        <Button
          className="text-primary"
          variant="unstyled"
          onClick={handleLoginExternal}
          label='Log in'
        />
      </p>
      <Icon iconName="error_outline" className="usa-icon--size-9" />
    </div>
  );
};

export default InvitationExpiredError;
