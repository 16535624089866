import React from 'react';
import { Breadcrumbs, Accordion, PageTitle } from '@gsa/afp-component-library';
import { FleetTechnicalSupportContactInfo } from '@gsa/afp-shared-ui-utils';
import { Link } from 'react-router-dom';
import './styles/privacy.css';

const PrivacyRegulationsPage = () => {

  const accordionItems = [
    {
      id: '1',
      title: 'What information do we collect and store?',
      content: (
        <>
          <p>We collect only the following information about you:</p>
          <ul>
            <li>
              The name of the domain from which you access the Internet (i.e.,
              verizon.net if you are connecting from a Verizon account, or
              harvard.edu if you are connecting from Harvard University&apos;s
              domain)
            </li>
            <li>The date and time of your visit</li>
            <li>
              The pages you visit on{' '}
              <a href="https://GSAFleet.gov" rel="noreferrer" target="_blank">
                GSAFleet.gov
              </a>
            </li>
            <li>
              The Internet address of the website you came from if it linked you
              directly to gsa.gov
            </li>
          </ul>
          <p>
            If your browser accepts cookies, we may use a session cookie to
            decide if you are a repeat or a new visitor. We also use session
            cookies so we can learn how many different visitors come to{' '}
            <a href="https://gsafleet.gov">https://GSAFleet.gov</a>. We use this
            information for statistical purposes and to help us make our site
            more useful to you. When we look at this data, it is always
            presented in an aggregate form. We do not track or look at an
            individual&apos;s use of our site.
          </p>
        </>
      ),
    },
    {
      id: '2',
      title:
        'How do we handle the information we gather when you visit our website?',
      content: (
        <p>
          Our privacy policy is clear: unless you choose to provide additional
          information to us, we will collect no personal information about you
          other than the information we listed below, which is automatically
          collected and stored when you visit our site.
        </p>
      ),
    },
    {
      id: '3',
      title: 'What are cookies and how do we use them on GSAFleet.gov?',
      content: (
        <>
          <p>
            <b>What is a cookie?</b>
          </p>
          <p>
            Whenever you visit a website, the website sends a small piece of
            information called a cookie along with the webpage. The main purpose
            of a cookie is to identify and customize web pages for you.
          </p>
          <p>
            There are two kinds of cookies. A session cookie is a line of text
            that is stored temporarily in your computer&apos;s random access
            memory (RAM). A session cookie is never written to a drive, and it
            is destroyed as soon as you close your browser. A persistent cookie
            from a website is saved to a file on your hard drive and is called
            up the next time you visit that website. This lets the website
            remember what you were interested in the last time you visited.
          </p>
          <p>
            <b>Use of cookies on GSAFleet.gov:</b>
          </p>
          <p>
            The GSAFleet.gov site only creates a session cookie when you visit
            and destroys it as soon as you close your browser. There are no
            persistent cookies created on gsa.gov. For more information on
            privacy and security, see GSA&apos;s{' '}
            <a href="https://www.gsa.gov/website-information/website-policies#privacy">
              Privacy and Security Policy
            </a>
            . If you have any questions about these policies, please{' '}
            <a href={`mailto: ${FleetTechnicalSupportContactInfo.Email}`}>contact us</a>.
          </p>
        </>
      ),
    },
    {
      id: '4',
      title: 'What happens if you send us personal information?',
      content: (
        <>
          <p>
            When you send us personal information (e.g., in an email containing
            a question or comment, or by filling out a form that emails us this
            information), we only use this information to respond to your
            request. We may forward your email to the government employee who
            can best answer your questions. We do not disclose, give, sell, or
            transfer any personal information about our visitors, unless
            required by law enforcement or statute.
          </p>
          <p>
            We want to make it clear that we will not obtain personally
            identifying information about you when you visit our site, unless
            you choose to provide that information to us. Except for authorized
            law enforcement investigations, or as otherwise required by law, we
            do not share any information we receive with anyone else.
          </p>
        </>
      ),
    },
    {
      id: '5',
      title: 'Links to other sites',
      content: (
        <>
          <p>
            The pages on <a href="https://GSAFleet.gov">https://GSAFleet.gov</a>{' '}
            may include hypertext links or pointers to information created and
            maintained by other public and private organizations. All links and
            pointers that are not funded or supported in whole or in part by GSA
            are clearly identified as external links. These links and pointers
            are provided for our visitors&apos; convenience. GSA does not
            control or guarantee the accuracy, timeliness, or completeness of
            any information from non-GSA sites.
          </p>
          <p>
            The inclusion of links and pointers to websites is not intended to
            assign importance to those sites or to the information contained on
            those sites. It is also not intended to endorse or recommend any
            views expressed or products or services offered on these sites.
          </p>
        </>
      ),
    },
    {
      id: '6',
      title: 'Security',
      content: (
        <>
          <p>
            For site security purposes and to ensure that this service remains
            available to all users, GSAFleet.gov servers use industry-standard
            methods to monitor network traffic to identify unauthorized attempts
            to upload or change information, or otherwise cause damage.
            Unauthorized attempts to upload information or change information on
            GSA servers are strictly prohibited and may be punishable by law,
            including the Computer Fraud and Abuse Act of 1986 and the National
            Information Infrastructure Protection Act.
          </p>
        </>
      ),
    },
    {
      id: '7',
      title: 'Privacy notice',
      content: (
        <>
          <p>
            This is a U.S. General Services Administration Federal government
            computer system that is &quot;FOR OFFICIAL USE ONLY.&quot; This
            system is subject to monitoring. Therefore, no expectation of
            privacy is to be assumed. Individuals found performing unauthorized
            activities are subject to disciplinary action including criminal
            prosecution.
          </p>
        </>
      ),
    },
  ];



  return (
    <div className="grid-container overflow-x-auto">
      <Breadcrumbs
        trail={[<Link to="/">Home</Link>]}
        current="Privacy and Regulations"
      />
      <PageTitle title='Privacy and Regulations' />
      <div className="usa-prose margin-bottom-4">
        <p>
          The privacy of our General Services Administration / GSAFleet.gov
          customers and affiliates has always been of utmost importance to
          GSAFleet.gov. Per our own policy for personal privacy, GSAFleet.gov
          will collect no personal information about you during your visit to
          our site unless you choose to provide that information via web-based
          forms or built in e&#8209;mail interfaces.
        </p>
        <p>
          Although the following discussion may seem exhaustive, GSAFleet.gov
          wants to ensure that you, our customer, fully understand our policy
          with respect to your privacy. Our goal is to answer all questions
          concerning your privacy below.
        </p>
        <Accordion items={accordionItems} className="privacy-accordian" />
      </div>
    </div>
  );
};

export default PrivacyRegulationsPage;
