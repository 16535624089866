import { Button } from '@gsa/afp-component-library';
import React from 'react';
import { useUserDetails } from '../providers/user-details-provider';
import { EditUserStatusModal } from '../../../../users/components/modal/edit-user-status-modal';

export const EditUserStatusButton = () => {
  const {
    user,
    isEditUserStatusOpen,
    setIsEditUserStatusOpen,
    refetchUserDetails,
    setFeedbackMessage,
  } = useUserDetails();

  if (!user) {
    return null;
  }

  return (
    <>
      <Button
        label="Edit user status"
        leftIcon={{
          name: "edit"
        }}
        type="button"
        variant="primary"
        data-testid="edit-user-status-button"
        onClick={() => {
          setIsEditUserStatusOpen(true);
        }}
      />
      <EditUserStatusModal
        userId={user.id}
        userFirstName={user.firstName}
        userLastName={user.lastName}
        userEmail={user.email}
        userStatusId={user.status.id}
        userStatusName={user.status.name}
        isOpen={isEditUserStatusOpen || false}
        onClose={() => {
          setIsEditUserStatusOpen(false);
        }}
        onDataChanged={() => {
          refetchUserDetails();
        }}
        onFeedback={(feedback) => {
          setFeedbackMessage(feedback);
        }}
      />
    </>
  );
};

export default EditUserStatusButton;
