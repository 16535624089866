import React from 'react';
import { useLocation } from 'react-router';
import { Alert } from '@gsa/afp-component-library';
import { InternalError } from '@gsa/afp-shared-ui-utils';
import { useUserDetails } from '../providers/user-details-provider';

const Types = {
  success: 'success',
  error: 'error',
  customeError: 'customError',
  warning: 'warning',
};

const alertType = (feedback, onClose) => {
  const { type, message } = feedback || {};
  if (!type) return null;

  if (type === Types.success || type === Types.customeError) {
    return (
      <Alert
        type={type === Types.customeError? Types.error:Types.success}
        data-testid="feedback-user-details-success-message"
        focused
        slim
        onClose={onClose}
      >
        {message}
      </Alert>
    );
  }

  if (type === Types.error) {
    return (
      <InternalError
        id="feedback-user-details-failure-message"
      />
    );
  }
  return null;
};

const FeedbackUserDetails = () => {
  const { user, feedback, setFeedbackMessage, loadingUser } = useUserDetails();
  
  if (loadingUser) {
    return null;
  }

  const handleClose = () => {
    setFeedbackMessage(false);
  };

  const location = useLocation();
  const userDetailsUpdated = location && location.state && location.state.userDetailsUpdated;
  const fullName = user ? user.fullName : '';
  if(userDetailsUpdated){
    return alertType(
      { 
        type: Types.success,
        message:  <><strong>{fullName}</strong> profile is updated successfully.</>        
      }, 
      handleClose
    );
  }
  
  return alertType(feedback, handleClose);
};

export default FeedbackUserDetails;
