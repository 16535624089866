import Regex from './RegexUtils';

export const passwordHasEmailParts = (pwd, email) => {
  if (pwd?.length < 4) {
    // when pwd is less than 4 chars no need to check
    return false;
  }
  const containsMutualChars = (s1, s2) => s1.includes(s2) || s2?.includes(s1);
  // test for case insensitive words in password
  const sPwd = pwd.toLowerCase();
  const words = email?.toLowerCase().split(/[^A-Za-z0-9]/);
  // only consider the words with 4 chars or more
  const bigWords = words?.filter((w) => w.length > 3);
  // check any of the words included in current password
  return bigWords?.some((w) => containsMutualChars(w, sPwd));
};

export const containsStr = (input, target) => {
  // Case insensitive search
  return input?.toLowerCase().includes(target?.toLowerCase());
};

// returns true if target contains any word from input
export const containsWord = (input, target) => {
  const inputWords = input?.toLowerCase().split(' ');
  const targetLower = target?.toLowerCase();
  return inputWords.some((e) => targetLower?.includes(e));
};

export const hasMinLen = (input) => {
  return input?.length > 11;
};

export const hasUpperCase = (input) => {
  return /[A-Z]+/.test(input);
};

export const hasLowerCase = (input) => {
  return /[a-z]+/.test(input);
};

export const hasNumber = (input) => {
  return /\d/.test(input);
};

export const hasSpace = (input) => {
  return /^\S*$/.test(input);
};

export const hasSpecial = (input) => {
  return /[!@#$%^&*]/.test(input);
};

export const formatDate = (date) => {
  //  Returns a date of format MM/DD/YYYY
  if (!date) {
    return '';
  }
  const format = new Date(date);

  // Get the day, month, and year components
  let day = format.getDate().toString();
  let month = (format.getMonth() + 1).toString(); // getMonth() returns 0-11
  const year = format.getFullYear().toString();

  // Add leading zeros if day or month is less than 10
  day = day.length < 2 ? `0${day}` : day;
  month = month.length < 2 ? `0${month}` : month;

  // Construct the formatted date string
  return `${month}/${day}/${year}`;
};

export const formatPhoneNumber = (phone, ext) => {
  if (!phone) {
    return null;
  }

  let phoneNumber = `${phone?.slice(0, 3)}-${phone?.slice(3, 6)}-${phone?.slice(
    6,
  )}`;
  if (ext) {
    phoneNumber = phoneNumber.concat(` ext. ${ext}`);
  }

  return phoneNumber;
};

export const isGSAEmail = (input) => {
  return /@gsa.gov$/i.test(input);
};

export const isValidEmail = (input) => {
  return Regex.email.test(input);
};

export const capitalize = (word) =>
  word
    ?.toLowerCase()
    .split(' ')
    .map((item) => item.charAt(0).toUpperCase() + item.substring(1))
    .join(' ');

export const formatTimeToAMPM = (timeString) => {
  const date = new Date(`2021-05-05T${timeString}`);
  return date.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  });
};

export const formatCurrency = (price = '') => {
  const priceAsNumber = Number(price);
  if (Number.isNaN(priceAsNumber)) {
    return '';
  }
  return priceAsNumber.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
};
