import React from 'react';
import PropTypes from 'prop-types';

const WelcomeMessage = ({ message }) => {
  return (
    <div data-testid="message" className="usa-prose margin-bottom-2">
      <div className="desktop:grid-col-8">
        <p>{message}</p>
        {}
      </div>
    </div>
  );
};

WelcomeMessage.propTypes = {
  message: PropTypes.string.isRequired,
};

export default WelcomeMessage;
