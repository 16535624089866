/* eslint-disable import/prefer-default-export */
export const VMSSubjects = {
  VEHICLE: 'Vehicle',
  TAG: 'Tag',
  ORDER: 'TagOrder',
  MOTOR_POOL: 'Motorpool',
  ORGANIZATION_PROFILE: 'OrganizationProfile',
};

const viewAO = 'viewAO';
const updateAO = 'updateAO';
const viewGF = 'viewGF';
const updateGF = 'updateGF';
const create = 'create';

export const VMSOperations = {
  VIEW_TAG_AO: viewAO,
  VIEW_TAG_GF: viewGF,
  UPDATE_TAG_AO: updateAO,
  UPDATE_TAG_GF: updateGF,
  VIEW_VEHICLE_AO: viewAO,
  VIEW_VEHICLE_GF: viewGF,
  UPDATE_VEHICLE_AO: updateAO,
  UPDATE_VEHICLE_GF: updateGF,
  VIEW_ORDER_AO: viewAO,
  VIEW_ORDER_GF: viewGF,
  UPDATE_ORDER_AO: updateAO,
  UPDATE_ORDER_GF: updateGF,
  CREATE_VEHICLE: create,
  VIEW: 'view',
  MANAGE: 'manage',
};

export const canViewAOTagOrders = (ability) =>
  ability.can(VMSOperations.VIEW_ORDER_AO, VMSSubjects.ORDER);

export const canViewGFTagOrders = (ability) =>
  ability.can(VMSOperations.VIEW_ORDER_GF, VMSSubjects.ORDER);

export const canUpdateTagOrders = (ability) =>
  ability.can(VMSOperations.UPDATE_ORDER_AO, VMSSubjects.ORDER);

export const canUpdateTagAO = (ability) =>
  ability.can(VMSOperations.UPDATE_TAG_AO, VMSSubjects.TAG);

export const canUpdateTagGF = (ability) =>
  ability.can(VMSOperations.UPDATE_TAG_GF, VMSSubjects.TAG);

export const canRegisterVehicle = (ability) =>
  ability.can(VMSOperations.CREATE_VEHICLE, VMSSubjects.VEHICLE);

export const canViewGFVehicles = (ability) =>
  ability.can(VMSOperations.VIEW_VEHICLE_GF, VMSSubjects.VEHICLE);

export const canViewAOVehicles = (ability) =>
  ability.can(VMSOperations.VIEW_VEHICLE_AO, VMSSubjects.VEHICLE);

// TODO: Remove this once we have the correct permissions
export const canViewVehicle = (ability) =>
  ability.can(VMSOperations.VIEW, VMSSubjects.VEHICLE);

export const canViewAOTags = (ability) =>
  ability.can(VMSOperations.VIEW_TAG_AO, VMSSubjects.TAG);

export const canViewGFTags = (ability) =>
  ability.can(VMSOperations.VIEW_TAG_GF, VMSSubjects.TAG);

export const canUpdateAOVehicle = (ability) =>
  ability.can(VMSOperations.UPDATE_VEHICLE_AO, VMSSubjects.VEHICLE);

export const canCreateVehicle = (ability) =>
  ability.can(VMSOperations.CREATE_VEHICLE, VMSSubjects.VEHICLE);

export const canManageMotorPool = (ability) =>
  ability.can(VMSOperations.MANAGE, VMSSubjects.MOTOR_POOL);

export const canViewMotorPool = (ability) =>
  ability.can(VMSOperations.VIEW, VMSSubjects.MOTOR_POOL);
