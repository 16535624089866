import React, { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { TaskPropType } from '../../utilities/types';

export const taskContext = createContext(null);

const TaskContextProvider = ({ children, task, setTask, refetchTask }) => {
  const [taskComments, setTaskComments] = useState({
    rows: [],
    count: 0,
    hasMore: false,
  });

  const [taskAttachments, setTaskAttachments] = useState({
    rows: [],
    count: 0,
    hasMore: false,
  });

  return (
    <taskContext.Provider
      value={{
        task,
        setTask,
        refetchTask,
        taskComments,
        setTaskComments,
        taskAttachments,
        setTaskAttachments,
      }}
    >
      {children}
    </taskContext.Provider>
  );
};

TaskContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  task: PropTypes.shape(TaskPropType).isRequired,
  setTask: PropTypes.func.isRequired,
  refetchTask: PropTypes.func.isRequired,
};

export default TaskContextProvider;

// Hook to exposes context value.
export const useTask = () => useContext(taskContext);
