import React from 'react';
import { Spinner, Breadcrumbs, PageTitle } from '@gsa/afp-component-library';
import { Link } from 'react-router-dom';
import {
  FleetTechnicalSupportContact,
  useCurrentUser,
} from '@gsa/afp-shared-ui-utils';
import './styles/inactive.css';

const ReactivationSubmittedPage = () => {

  const { isLoading } = useCurrentUser();

  if (isLoading) return <Spinner className="margin-y-9" />;

  return (
    <div>
      <Breadcrumbs
        trail={[
          <Link style={{ textDecoration: 'underline' }} to="/">
            Home
          </Link>,
        ]}
        current="Account Submitted for Approval"
      />
      <div className="padding-bottom-5">
        <PageTitle
          data-testid="account-submitted-for-approval"
          title="Account Submitted for Approval"
        />
        <article className="padding-bottom-5">
          We received your request to reactivate your account. You will receive
          an email with updates from an authorized approver. For technical
          support, <FleetTechnicalSupportContact />.
        </article>
      </div>
    </div>
  );
};

export default ReactivationSubmittedPage;
