import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { AFPTable, StatusBadge } from '@gsa/afp-component-library';
import { formatDate } from '../../../../../utilities/StringUtils';

const RecertifiableUsersTable = (props) => {
  const { recertifiableUsers } = props;
  const cellRecertification = (value) => {
    let recertStatus;
    // eslint-disable-next-line default-case
    switch (value) {
      case 'Pending':
        recertStatus = {
          label: value,
          status: 'Warning-Gray',
        };
        break;
      case 'Past due':
        recertStatus = {
          label: value,
          status: 'Urgent-Gray',
        };
        break;
      case 'Recertified':
        recertStatus = {
          label: value,
          status: 'Ready-Gray',
        };
        break;
    }

    return recertStatus;
  };

  const formatCell = (cell) => {
    const { value } = cell;
    if (value) {
      return formatDate(value);
    }
    return '-';
  };

  const columns = useMemo(
    () => [
      {
        Header: 'User name',
        accessor: 'fullName',
        sortable: false,
      },
      {
        Header: 'Last certified date',
        accessor: 'lastCertified',
        sortable: false,
        Cell: formatCell,
      },
      {
        Header: 'Recertification due date',
        accessor: 'recertificationDueDate',
        sortable: false,
        Cell: formatCell,
      },
      {
        Header: 'Recertification status',
        accessor: 'recertificationStatus',
        sortable: false,
        Cell: (cell) => {
          const { value } = cell;
          if (value) {
            const { status, label } = cellRecertification(value);
            return <StatusBadge variant={status}>{label}</StatusBadge>;
          }
          return '-';
        },
      },
    ],
    [],
  );
  return (
    <>
      <span className="padding-bottom-10 padding-top-10">
        You’re about to recertify the following user(s) for the next one year.
        Are you sure you want to recertify?
      </span>
      <div
        data-testid="selected-users-table-test-id"
        className="selected-users-table"
      >
        <AFPTable
          fullWidth
          stacked
          testId="selected-users-table"
          columns={columns}
          data={recertifiableUsers}
        />
      </div>
    </>
  );
};

RecertifiableUsersTable.defaultProps = {
  recertifiableUsers: [],
};
RecertifiableUsersTable.propTypes = {
  recertifiableUsers: PropTypes.arrayOf(PropTypes.shape),
};

export default RecertifiableUsersTable;
