import React from 'react';
import PropTypes from 'prop-types';

const PDFViewer = ({ source, className }) => {
    return (
      <div className={className}>
        <embed src={source} type="application/pdf" width="100%" height="500px" />
      </div>);
}

PDFViewer.defaultProps = {
    className: '' 
};

PDFViewer.propTypes = {
    source: PropTypes.func.isRequired,
    className: PropTypes.string
};

export default PDFViewer;