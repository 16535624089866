import React from 'react';
import PropTypes from 'prop-types';
import { StatusBadge } from '@gsa/afp-component-library';

import { InvitationStatus } from '../user-invitation-constants';

export const cellStatus = (value) => {
  let userInvitationStatus;
  // eslint-disable-next-line default-case
  switch (value) {
    case InvitationStatus.INVITED:
      userInvitationStatus = {
        status: 'Warning-Gray',
      };
      break;
    case InvitationStatus.COMPLETED:
      userInvitationStatus = {
        status: 'Ready-Gray',
      };
      break;
    case InvitationStatus.CANCELLED:
      userInvitationStatus = {
        status: 'Inactive-Gray',
      };
      break;
    case InvitationStatus.EXPIRED:
      userInvitationStatus = {
        status: 'Urgent-Gray',
      };
      break;
  }

  return userInvitationStatus;
};

const UserInvitationStatusTag = ({ value }) => {
  const { status } = cellStatus(value);
  return <StatusBadge variant={status}>{value}</StatusBadge>;
};
UserInvitationStatusTag.propTypes = {
  value: PropTypes.string.isRequired,
};

export default UserInvitationStatusTag;
