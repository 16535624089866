import React from 'react';
import BannerMessage from '../../components/BannerMessage';
import { useOfficeManagementListing } from './office-management-listing-provider';

const OfficeManagementListingAlert = () => {
  const {
    officeManagementListingMessage: { type, message },
    setMessage,
  } = useOfficeManagementListing();

  if (!type || !message) return null;

  return (
    <BannerMessage
      type={type}
      message={message}
      onClose={() => {
        setMessage('SET_MESSAGE', { type: 'success', message: '' });
      }}
    />
  );
};

export default OfficeManagementListingAlert;
