import { Textbox } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import { StandardFormGroup } from './standard-form-group';
import { propTypeFormContext } from './createFormContext';

export const StandardTextbox = ({
  formContext,
  name,
  required,
  validationKey,
  defaultValue,
  ...props
}) => {
  const { register, validationOptions, defaultValues } = formContext;
  const { errors } = formContext;
  const error = (errors[name] && errors[name].message) || '';

  const vk = validationKey || name;
  const validation = validationOptions[vk];

  return (
    <Textbox
      name={name}
      id={name}
      type="text"
      className="usa-input"
      aria-invalid={error ? 'true' : undefined}
      inputRef={validation && register(validation)}
      defaultValue={defaultValue ?? defaultValues[name] ?? ''}
      required={required}
      data-testid={`${name}-input`}
      {...props}
    />
  );
};

StandardTextbox.propTypes = {
  formContext: propTypeFormContext.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  validationKey: PropTypes.string,
  required: PropTypes.bool,
};

StandardTextbox.defaultProps = {
  defaultValue: undefined,
  required: undefined,
  validationKey: undefined,
};

export default StandardTextbox;

export const StandardLabeledTextbox = ({
  formContext,
  name,
  label,
  defaultValue,
  validationKey,
  required,
  ...props
}) => {
  return (
    <StandardFormGroup
      formContext={formContext}
      name={name}
      label={label}
      required={required}
    >
      <StandardTextbox
        formContext={formContext}
        label={label}
        name={name}
        required={required}
        defaultValue={defaultValue}
        validationKey={validationKey}
        {...props}
      />
    </StandardFormGroup>
  );
};

StandardLabeledTextbox.propTypes = {
  formContext: propTypeFormContext.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  validationKey: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string.isRequired,
};

StandardLabeledTextbox.defaultProps = {
  defaultValue: undefined,
  validationKey: undefined,
  required: undefined,
};
