import React from 'react';
import PropTypes from 'prop-types';
import RecertifiableUsersTable from './recertifiable-users-table';
import { Types } from '../../../constants/user-constants';
import { getUserStr } from '../../../utils/user-utils';
import { isObjectWithProperties } from '../../../../../utilities/objectUtils';

const Content = (props) => {
  const { recertifiableUsers, notRecertifiableUsers, selfCertifyUser } = props;
  const externalUsers = notRecertifiableUsers.filter(
    (user) => user.userType.id !== Types.GSA_EMPLOYEE,
  );
  const userStr = getUserStr(externalUsers);

  return (
    <>
      {externalUsers.length ? (
        <>
          <span className="padding-bottom-10">
            {`The following ${externalUsers.length} ${userStr}`} <b>cannot</b>{' '}
            be recertified because their current user type is not{' '}
            <b>GSA employee</b>.
          </span>
          <ul className="padding-left-2">
            {externalUsers.map((user) => (
              <li key={user.id}>{user.fullName}</li>
            ))}
          </ul>
        </>
      ) : null}
      {isObjectWithProperties(selfCertifyUser) ? (
        <>
          <span
            className="padding-bottom-10"
            data-testid="self-certification-message-testid"
          >
            The following user <b>cannot</b> be recertified because{' '}
            <b>you cannot recertify yourself</b> . Please select GSA employees
            who need to be recertified other than yourself or reach out to your
            supervisor if you need to be recertified.
          </span>
          <ul className="padding-left-2 padding-bottom-4">
            <li key={selfCertifyUser.id}>{selfCertifyUser.fullName}</li>
          </ul>
        </>
      ) : null}
      {recertifiableUsers.length ? (
        <RecertifiableUsersTable recertifiableUsers={recertifiableUsers} />
      ) : null}
    </>
  );
};

Content.defaultProps = {
  recertifiableUsers: [],
  notRecertifiableUsers: [],
  selfCertifyUser: {},
};
Content.propTypes = {
  recertifiableUsers: PropTypes.arrayOf(PropTypes.shape),
  notRecertifiableUsers: PropTypes.arrayOf(PropTypes.shape),
  selfCertifyUser: PropTypes.shape({
    id: PropTypes.string,
    fullName: PropTypes.string,
  }),
};

export default Content;
