import React from 'react';
import PropTypes from 'prop-types';
import {
  connectModal,
  Modal,
  Button,
} from '@gsa/afp-component-library';
import { useUserInvitation } from '../providers';

const Title = () => {
  return (
    <h2 data-testid="cancel-invitation-title">
      Cancel invitation
    </h2>
  );
};

const Content = ({ invitation }) => {
  const fullName = `${invitation.firstName} ${invitation.lastName}`;
  return <p>You&apos;re about to cancel invitation for <b>{ fullName }</b>. Are you sure you want to cancel?</p>;
};

Content.propTypes = {
  invitation: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string
  }).isRequired,
};

const Actions = ({ onCancel, onConfirm }) => {
  return (
    <>
      <Button
        label="Cancel"
        variant="unstyled"
        className="padding-right-1"
        type="button"
        onClick={onCancel}
        data-testid="cancel-invitation-cancel-button"
      />
      <Button
        label="Confirm"
        type="button"
        data-testid="cancel-invitation-confirm-button"
        onClick={onConfirm}
      />
    </>
  );
};

Actions.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

const CancelInvitationModal = () => {
  const {
    invitationToCancel,
    cancelInvitation,
    setInvitationToCancel
  } = useUserInvitation();

  const handleCancel = () => {
    setInvitationToCancel(null);
  };

  const handleConfirm = () => {
    const invitationId = parseInt(invitationToCancel?.id, 10) || 0;
    cancelInvitation(invitationId);
  };

  const CancelInvitationConnectModal = connectModal(() => (
    <Modal
      title={<Title />}
      actions={<Actions onCancel={handleCancel} onConfirm={handleConfirm} />}
      onClose={handleCancel}
    >
      <Content invitation={invitationToCancel} />
    </Modal>
  ));

  return (
    <CancelInvitationConnectModal isOpen={invitationToCancel} />
  );
};

export default CancelInvitationModal;
