import {
  AFPTableRowAction,
  FilterTableFrame,
} from '@gsa/afp-component-library';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import UserInvitationFilters from './user-invitation-filters';
import UserInvitationStatusTag from './user-invitation-status-tag';
import { useUserInvitation, useUserInvitationFilter } from '../providers';
import CreateInvitationModalTrigger from './create-invitation-modal-trigger';
import UserInvitationExtra from './user-invitation-extra';
import CancelInvitationModal from './cancel-invitation-modal';
import { canCancelInvitation } from '../authorization';
import TableWrapper from '../../users/components/afp-table-wrapper';

const emDashUnicode = '\u2014';

const UserInvitationsTable = () => {
  const ability = useAppAbility();
  const {
    getUserInvitations,
    fetchingUserInvitations,
    userInvitations,
    setInvitationToCancel,
    setFeedbackMessage,
  } = useUserInvitation();

  const itemsPerPageOptions = [10, 25, 50];
  const { filters } = useUserInvitationFilter();
  const [sort, setSort] = useState('lastName ASC');

  const [paginationState, setPaginationState] = useState({
    limit: itemsPerPageOptions[0],
    offset: 0,
    currentPage: 1,
    isReset: false,
  });

  const tableRef = React.createRef();

  const getData = () => {
    const { limit, offset } = paginationState;
    getUserInvitations({
      variables: {
        limit,
        offset,
        order: sort,
        filters: {
          ...filters,
          conditions: [
            ...(filters.conditions || []),
            {
              operator: '',
              key: 'vendorInvitationAttrs',
              value: '',
            },
          ],
        },
      },
    });
  };

  useEffect(() => {
    getData();
  }, [sort, paginationState, filters]);

  useEffect(() => {
    setPaginationState({
      ...paginationState,
      offset: 0,
      currentPage: 1,
      isReset: true,
    });
  }, [filters]);

  const getActionColumn = () => {
    const actionsLabel = {
      cancel: 'Cancel invitation',
    };
    const actionsColumn = {
      getActions(invitation) {
        const { cancel } = actionsLabel;
        const actions = [];

        if (canCancelInvitation(ability, invitation.status)) {
          actions.push({
            icon: 'cancel',
            label: cancel,
          });
        }
        return actions;
      },
      execute(operation, invitation) {
        const { cancel } = actionsLabel;
        if (operation === cancel) {
          setInvitationToCancel(invitation);
        }
      },
    };

    return (props) => {
      /* eslint-disable react/prop-types */
      const { cell } = props;
      const { row } = cell;
      const { original: invitation } = row;
      const rowActions = actionsColumn.getActions(invitation);
      if (!rowActions.length) {
        return emDashUnicode;
      }
      return (
        <AFPTableRowAction
          actions={rowActions}
          actionTitle="Row actions"
          onSelectAction={(operation) => {
            actionsColumn.execute(operation, invitation);
          }}
          {...props}
        />
      );
    };
  };

  const columns = useMemo(() => [
    {
      Header: 'First name',
      accessor: 'firstName',
    },
    {
      Header: 'Last name',
      accessor: 'lastName',
    },
    {
      Header: 'User type',
      accessor: 'userType.name',
      sortable: false,
    },
    {
      Header: 'Status',
      accessor: 'status',
      sortable: false,
      Cell: (cell) => {
        const { value } = cell;
        return <UserInvitationStatusTag value={value} />;
      },
    },
    {
      Header: 'Actions',
      sortable: false,
      headerClassName: 'cell-center',
      cellClassName: 'cell-center',
      Cell: getActionColumn(),
    },
  ]);

  const renderRowSubComponent = useCallback(({ row: { original: data } }) => {
    return (
      <UserInvitationExtra
        userInvitation={data}
        setFeedbackMsg={setFeedbackMessage}
      />
    );
  }, []);

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    const offset = (currentPage - 1) * itemsPerPage;
    setPaginationState({
      limit: itemsPerPage,
      offset,
      currentPage,
    });
  };

  const FTF = useMemo(
    () =>
      FilterTableFrame(
        null,
        CreateInvitationModalTrigger,
        UserInvitationFilters,
        TableWrapper,
      ),
    [],
  );

  const userInvitationTableWrapperProps = {
    tableProps: {
      isPending: fetchingUserInvitations,
      expandable: true,
      fullWidth: true,
      testId: 'user-invitations-table',
      ref: tableRef,
      columns,
      data: userInvitations?.rows,
      renderRowSubComponent,
      onSort: setSort,
      defaultSort: sort,
    },
    paginationProps: {
      variant: 'advanced',
      itemsPerPageOptions,
      itemsCount: userInvitations?.count,
      itemsPerPage: paginationState.limit,
      currentPage: paginationState.currentPage,
      isReset: paginationState.isReset,
      onPageChange: handlePaginationChange,
    },
  };

  return (
    <div className="user-invitations-table">
      <FTF lowRightProps={userInvitationTableWrapperProps} filterToggle />
      <CancelInvitationModal />
    </div>
  );
};

export default UserInvitationsTable;
