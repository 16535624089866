import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typeahead } from '@gsa/afp-component-library';
import { useLazyQuery } from '@apollo/client';
import { GET_VENDORS_BY_PERMISSION } from '../../../services/data-layer';
import { VENDOR_CONTACT_TYPE } from '../../constants';
import { OPERATIONS, SUBJECTS } from '../../../utilities/constants';

export const vendorTypeAheadValue = (
  vendorName,
  vendorContacts,
  fleetVendorNumber,
) => {
  const address = vendorContacts.find(
    (contact) =>
      contact.contactType === VENDOR_CONTACT_TYPE.VendorPhysicalContact,
  );
  const city = address?.city;
  const state = address?.state?.stateCode;
  const addressStr = [city, state].filter((a) => a).join(', ');
  if (addressStr)
    return [vendorName, addressStr, fleetVendorNumber].join(' - ');
  return [vendorName, fleetVendorNumber].join(' - ');
};

const VendorNameTypeahead = ({ onVendorSelection, errorMessage }) => {
  const [suggestions, setSuggestions] = useState([]);
  const [, setError] = useState(null);
  const [getVendorsByPermission] = useLazyQuery(GET_VENDORS_BY_PERMISSION, {
    fetchPolicy: 'network-only',
    onCompleted: ({ getVendorsByPermission: data }) => {
      setSuggestions(data);
    },
    onError: () => {
      setError('Not able to fetch vendor suggestions');
    },
  });

  const fetchValues = (_, value) => {
    getVendorsByPermission({
      variables: {
        operation: OPERATIONS.View,
        subject: SUBJECTS.Vendor,
        filters: {
          operator: '$or',
          conditions: [
            { operator: '$like', key: 'vendorName', value },
            { operator: '$like', key: 'fleetVendorNumber', value },
          ],
        },
      },
    });
  };

  return (
    <Typeahead
      filterValue=""
      label={<span className="text-bold">Name or Fleet vendor number</span>}
      required
      placeholder="Search for a vendor by name or Fleet vendor number"
      help="Search will show results based on matching characters in any part of the word."
      onOptionEnter={(value) => {
        const segs = value.split(' - ');
        const fleetVendorNo = segs[segs.length - 1];
        if (onVendorSelection)
          onVendorSelection(
            suggestions.find((v) => v.fleetVendorNumber === fleetVendorNo),
          );
      }}
      onClear={() => {
        if (onVendorSelection) onVendorSelection(null);
      }}
      errorMessage={errorMessage}
      typeaheadValues={suggestions.map((d) =>
        vendorTypeAheadValue(
          d.vendorName,
          d.vendorContacts,
          d.fleetVendorNumber,
        ),
      )}
      fetchTypeaheadValues={fetchValues}
      inputCharNum={2}
      debounceDelay={500}
      promptText="Search requires 3 characters"
      aria-invalid={errorMessage ? 'true' : 'false'}
      customFieldProps={{ inputCharNum: 2 }}
    />
  );
};

VendorNameTypeahead.propTypes = {
  onVendorSelection: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
};

VendorNameTypeahead.defaultProps = {
  errorMessage: null,
};

export default VendorNameTypeahead;
