import React, { useEffect, useState } from 'react';
import { FilterPanel } from '@gsa/afp-component-library';
import { useRoleFilter } from '../providers';

const initialFilterStructure = [
  {
    title: 'Role name',
    key: 'name',
    id: 'filter-role-name',
    type: 'text',
    permanent: false,
    placeholder: 'All roles',
    operator: '$startsWith',
  },
  {
    title: 'Status',
    key: 'inactive',
    id: 'filter-role-status',
    type: 'select',
    permanent: false,
    operator: '$exact',
    options: [{ value: '', label: 'All status', defaultValue: true }],
    value: '',
  },
  {
    title: 'User type',
    key: 'user_type_id',
    id: 'filter-role-user-types',
    type: 'select',
    permanent: false,
    operator: '$exact',
    options: [{ value: '', label: 'All user type', defaultValue: true }],
    value: '',
  },
];

const RoleFilters = () => {
  const {
    getRolesStatus,
    rolesStatus,
    getUserTypes,
    userTypes,
    setFilters,
  } = useRoleFilter();

  const [filterStructure, setfilterStructure] = useState(
    initialFilterStructure,
  );

  const addOptions = (id, options) => {
    return filterStructure.map((f) => {
      const filter = f;
      if (filter.id === id) {
        filter.options = [filter.options[0], ...options];
      }
      return filter;
    });
  };

  useEffect(() => {
    getRolesStatus();
    getUserTypes();
  }, []);

  useEffect(() => {
    setfilterStructure(addOptions('filter-role-status', rolesStatus));
  }, [rolesStatus]);

  useEffect(() => {
    setfilterStructure(
      addOptions(
        'filter-role-user-types',
        userTypes.map((ut) => ({ value: ut.id, label: ut.name })),
      ),
    );
  }, [userTypes]);

  return (
    <div data-testid="role-filters">
      <FilterPanel.FilterPanel
        filterStructure={filterStructure}
        setQueryFiltersState={(filters) => {
          setFilters(filters);
        }}
        clearButtonLabel="Reset All"
        showClearIcon
      />
    </div>
  );
};

export default RoleFilters;
