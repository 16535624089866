import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumbs } from '@gsa/afp-component-library';
import { RoleProvider, RoleFilterProvider } from './providers';
import { RoleHeader, RoleTable, FeedbackMessage } from './components';

const Roles = () => {
  return (
    <RoleProvider>
      <RoleFilterProvider>
        <div className="grid-col">
          <Breadcrumbs
            trail={[
              <Link style={{ textDecoration: 'underline' }} to="/home">
                Home
              </Link>,
              <Link style={{ textDecoration: 'underline' }} to="/admin">
                Admin Console
              </Link>,
            ]}
            current="Roles"
          />
          <FeedbackMessage />
          <RoleHeader />
          <RoleTable />
        </div>
      </RoleFilterProvider>
    </RoleProvider>
  );
};

export default Roles;
