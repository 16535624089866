import React from 'react';
import { PageHeader } from '../../../components';

const UserInvitationHeader = () => {
  return (
    <>
      <PageHeader title="Invitations" id="user-invitations-page-header">
        <p>
          Invite new users to register on GSAFleet.gov. Once a user accepts the
          invitation, and completes registration you will be able to approve
          their profile.
        </p>
      </PageHeader>
    </>
  );
};

export default UserInvitationHeader;
