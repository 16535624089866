import { camelCase } from 'lodash';
import { useCallback, useMemo } from 'react';

const createReducerActions = (actionTypes) => (dispatch) => {
  const setDispatch = useCallback(
    (type) => (payload) => dispatch({ type, payload }),
    [],
  );
  return useMemo(
    () =>
      Object.entries(actionTypes).reduce(
        (reducedActions, [actionType, value]) => ({
          ...reducedActions,
          [camelCase(actionType)]: setDispatch(value),
        }),
        {},
      ),
    [],
  );
};

export default createReducerActions;
