import { useLazyQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useReducer, useState } from 'react';
import {
  GET_OFFICE_MANAGEMENT_LIST,
  DELETE_OFFICE_MANAGEMENT,
  GET_OFFICE_MANAGEMENTS,
  CHANGE_OFFICE_MANAGEMENT_FLEET_STATUS,
} from './office-management-listing.gql';
import {
  GET_AGENCIES_BY_PERMISSION,
  GET_BUREAUS_BY_PERMISSION,
} from '../../services/data-layer';

import { SYSYTEM_ERROR_MESSAGE } from '../constants';

export const OfficeManagementListingContext = createContext();

const initialState = {
  officeManagementFilters: {
    officeManagementTypeFilter: [],
    stateFilter: [],
    officeManagementStatusFilter: [],
    officeManagementTierFilter: [],
  },
  officeManagementList: { rows: [], hasMore: false, count: 0 },
  officeManagements: { rows: [], hasMore: false, count: 0 },
  officeManagementListingMessage: { type: '', message: '' },
  selectedAgency: '',
  selectedBureau: '',
  selectedOffice: '',
  showEditOfficeModal: false,
  showCreateOfficeModal: false,
  modalState: {
    currentModalName: null,
    currentMode: null,
    currentModalAlert: { type: null, message: null, header: null },
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_MESSAGE':
      return { ...state, officeManagementListingMessage: action.payload };
    case 'SET_OFFICE_MANAGEMENT_LIST':
      return { ...state, officeManagementList: action.payload };
    case 'SET_OFFICE_MANAGEMENTS':
      return { ...state, officeManagements: action.payload };
    case 'SET_OFFICE_MANAGEMENT_LIST_FILTERS':
      return { ...state, officeManagementFilters: action.payload };
    case 'SET_AGENCY':
      return { ...state, selectedAgency: action.payload };
    case 'SET_BUREAU':
      return { ...state, selectedBureau: action.payload };
    case 'SET_SELECTED_OFFICE':
      return { ...state, selectedOffice: action.payload };
    case 'SET_SHOW_CREATE_OFFICE_MODAL': {
      return { ...state, showCreateOfficeModal: action.payload };
    }
    case 'SET_SHOW_EDIT_OFFICE_MODAL': {
      return { ...state, showEditOfficeModal: action.payload };
    }

    default:
      return { ...state };
  }
};

function OfficeManagementListingProvider({ children, ...props }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [agencies, setAgencies] = useState([]);
  const [bureaus, setBureaus] = useState([]);
  const [agency, setAgencyCode] = useState('');
  const [bureau, setBureauCode] = useState('');
  const [presetFilterStructures, setPresetFilterStructures] = useState([]);

  const setMessage = (type, message) => {
    dispatch({ type: 'SET_MESSAGE', payload: { type, message } });
  };

  const setAgency = (agencyCode) => {
    dispatch({ type: 'SET_AGENCY', payload: agencyCode });
  };

  const setBureau = (bureauCode) => {
    dispatch({ type: 'SET_BUREAU', payload: bureauCode });
  };

  const setOfficeData = (officeData) => {
    dispatch({ type: 'SET_SELECTED_OFFICE', payload: officeData });
  };

  const setEditOfficeModalState = (showModal) => {
    dispatch({ type: 'SET_SHOW_EDIT_OFFICE_MODAL', payload: showModal });
  };

  const setCreateOfficeModalState = (showModal) => {
    dispatch({ type: 'SET_SHOW_CREATE_OFFICE_MODAL', payload: showModal });
  };

  const [getOfficeManagementList, { refetch }] = useLazyQuery(
    GET_OFFICE_MANAGEMENT_LIST,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onError: (err) => {
        setMessage('error', err.message);
      },
      onCompleted: (data) => {
        dispatch({
          type: 'SET_OFFICE_MANAGEMENT_LIST',
          payload: data.getOfficesList,
        });
      },
    },
  );

  const resetOfficeManagementList = () => {
    dispatch({
      type: 'SET_OFFICE_MANAGEMENTS',
      payload: [],
    });
  };

  const [
    getOfficeManagements,
    { refetch: refetchOfficeManagements, loading: isOfficeDataLoading },
  ] = useLazyQuery(GET_OFFICE_MANAGEMENTS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (err) => {
      setMessage('error', err.message);
    },
    onCompleted: (data) => {
      dispatch({
        type: 'SET_OFFICE_MANAGEMENTS',
        payload: data.getOfficesList,
      });
    },
  });

  const [changeOfficeManagementFleetStatus] = useMutation(
    CHANGE_OFFICE_MANAGEMENT_FLEET_STATUS,
    {
      onError: () => {
        setMessage('error', SYSYTEM_ERROR_MESSAGE);
      },
      onCompleted: () => {
        const initFilters = {
          conditions: [
            {
              key: '',
              operator: '',
              value: '',
            },
          ],
          operator: '$and',
        };
        // setMessage('success', `Fleet status for ${data.toggleOfficeManagementFleetStatus.officeManagementName} updated successfully.`);
        refetchOfficeManagements({
          variables: {
            filters: initFilters,
            offset: 0,
            limit: 10,
            order: 'bureau.bureaushortname ASC,Office.officecode ASC',
          },
        });
      },
    },
  );

  const setOfficeManagementFilters = (filters) => {
    dispatch({ type: 'SET_OFFICE_MANAGEMENT_LIST_FILTERS', payload: filters });
  };

  const [deleteOfficeManagement] = useMutation(DELETE_OFFICE_MANAGEMENT, {
    onError: (err) => setMessage('error', err.message),
    onCompleted: (data) => {
      setMessage(
        'success',
        `OfficeManagement ${data.officeManagementDelete.fleetOfficeManagementNumber} was successfully deleted`,
      );
      refetch({
        variables: {
          limit: 10,
          offset: 0,
          order: 'bureau ASC',
        },
      });
    },
  });

  const defaultAgencyOption = {
    value: '',
    label: '-Select agency-',
    defaultValue: true,
  };
  const defaultBureauOption = {
    value: '',
    label: '-Select bureau-',
    defaultValue: true,
  };
  const [getBureaus] = useLazyQuery(GET_BUREAUS_BY_PERMISSION, {
    onError: () => {
      setMessage({
        type: 'ISE',
        message: 'Internal Server Error - Unmanaged',
      });
    },
    onCompleted: (data) => {
      if (data?.getBureausByPermission) {
        const options = [
          ...data.getBureausByPermission
            .map((c) => ({
              value: c.id,
              label: c.name,
            }))
            .sort((a, b) => (a.value < b.value ? -1 : 1)),
        ];

        let bureauCode = '';
        if (options.length === 1) {
          setBureauCode(bureauCode);
          bureauCode = options[0].value;
        }

        setPresetFilterStructures([
          {
            key: 'agencyCode',
            title: 'Agency',
            type: 'select',
            permanent: false,
            operator: '$exact',
            options: [defaultAgencyOption, ...agencies],
            value: agency,
          },
          {
            key: 'bureauCode',
            title: 'Bureau',
            type: 'select',
            permanent: false,
            operator: '$in',
            options: [defaultBureauOption, ...options],
            value: bureauCode,
          },
        ]);
        setBureaus(options);
      }
    },
    fetchPolicy: 'cache-and-network',
  });

  const [getAgencies] = useLazyQuery(GET_AGENCIES_BY_PERMISSION, {
    onError: () => {
      setMessage({
        type: 'ISE',
        message: 'Internal Server Error - Unmanaged',
      });
    },
    onCompleted: (data) => {
      if (data?.getAgenciesByPermission) {
        const options = [
          ...data.getAgenciesByPermission
            .map((c) => ({
              value: c.id,
              label: c.name,
            }))
            .sort((a, b) => (a.value < b.value ? -1 : 1)),
        ];

        let agencyCode = state.selectedAgency || '';
        if (options.length === 1) {
          agencyCode = options[0].value;
          getBureaus({
            variables: {
              operation: 'view',
              subject: 'OrganizationProfile',
              agencyCode,
            },
          });
          setAgencyCode(agencyCode);
        }
        setAgencies(options);
        setPresetFilterStructures([
          {
            key: 'agencyCode',
            title: 'Agency',
            type: 'select',
            permanent: false,
            operator: '$exact',
            options: [defaultAgencyOption, ...options],
            value: agencyCode,
          },
          {
            key: 'bureauCode',
            title: 'Bureau',
            type: 'select',
            permanent: false,
            operator: '$exact',
            options: [defaultBureauOption],
            value: '',
          },
        ]);
      }
    }, // eo onCompleted
    fetchPolicy: 'cache-and-network',
  });

  const resetAllFilters = () => {
    setAgencyCode('');
    setBureauCode('');
    setAgencies([]);
    setBureaus([]);
    setPresetFilterStructures([
      {
        key: 'agencyCode',
        title: 'Agency',
        type: 'select',
        permanent: false,
        operator: '$exact',
        options: [defaultAgencyOption],
        value: '',
      },
      {
        key: 'bureauCode',
        title: 'Bureau',
        type: 'select',
        permanent: false,
        operator: '$exact',
        options: [defaultBureauOption],
        value: '',
      },
    ]);
  };

  return (
    <OfficeManagementListingContext.Provider
      value={{
        ...state,
        deleteOfficeManagement,
        dispatch,
        setMessage,
        getOfficeManagementList,
        getOfficeManagements,
        refetchOfficeManagements,
        setOfficeManagementFilters,
        changeOfficeManagementFleetStatus,
        isOfficeDataLoading,
        resetOfficeManagementList,
        setAgencyCode,
        setAgencies,
        setBureauCode,
        setOfficeData,
        setBureaus,
        getAgencies,
        getBureaus,
        agencies,
        agency,
        bureaus,
        bureau,
        setAgency,
        setBureau,
        presetFilterStructures,
        resetAllFilters,
        setEditOfficeModalState,
        setCreateOfficeModalState,
        ...props,
      }}
    >
      {children}
    </OfficeManagementListingContext.Provider>
  );
}

export default OfficeManagementListingProvider;

OfficeManagementListingProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useOfficeManagementListing = () =>
  useContext(OfficeManagementListingContext);
