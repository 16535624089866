import React from 'react';
import { UserDetailsProvider } from '../user-details/providers';
import { useUserDetails } from '../user-details/providers/user-details-provider';
import {
  UserBreadcrumbs,
  LoadingUserSpinner,
  FeedbackUserDetails,
} from '../user-details/components';
import { UserHeader } from './components/user-header';
import { UserProfileForm } from '../../../user-profile/user-profile-form';

const ProfileEditablePage = () => {
  const EditProfileForm = () => {
    const { user, loadingUser, setFeedbackMessage } = useUserDetails();

    if (loadingUser || !user) {
      return null;
    }
    return (
      <UserProfileForm
        currentUser={user}
        setInvalidState={setFeedbackMessage}
        isAdminEditingUser
      />
    );
  }
  return (
    <UserDetailsProvider>
      <LoadingUserSpinner />
      <UserBreadcrumbs />
      <FeedbackUserDetails />
      <UserHeader /> 
      <EditProfileForm />
    </UserDetailsProvider>
  );
};

export default ProfileEditablePage;
