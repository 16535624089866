import React from 'react';
// TODO: extract to a common component
// TODO: standardize text
export const RequiredFieldsHint = () => {
  return (
    <div className="padding-bottom-2">
      <abbr title="required" className="usa-hint usa-hint--required">
        <strong>*</strong>
      </abbr>{' '}
      indicates a required field.
    </div>
  );
};

export const RequiredFieldsHintAlt = () => {
  return (
    <div className="padding-bottom-2">
      Required fields are marked with an asterisk (
      <abbr title="required" className="usa-hint usa-hint--required">
        <strong>*</strong>
      </abbr>
      ).
    </div>
  );
};
