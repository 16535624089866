import React from 'react';
import PropTypes from 'prop-types';
import { Typeahead } from '@gsa/afp-component-library';
import { useLazyQuery } from '@apollo/client';
import { GET_ASSIGNABLE_SCOPES } from '../../../../../services/data-layer';

const AssigneableVendorNameTypeahead = ({
  roleId,
  onVendorSelection,
  errorMessage,
}) => {
  const [tpState, setTpState] = React.useState([]);
  const [, setError] = React.useState(null);
  const [getAssignableRoles] = useLazyQuery(GET_ASSIGNABLE_SCOPES, {
    fetchPolicy: 'network-only',
    onCompleted: ({ getAssignableScopes: data }) => {
      setTpState(
        data.scopes.map((s) => ({
          id: s.id,
          vendorName: s.name,
          fleetVendorNumber: s.scopeReference,
        })),
      );
    },
    onError: () => {
      setError('Not able to fetch vendor suggestions');
    },
  });

  const vendorTypeAheadValue = (vendorName, fleetVendorNumber) => {
    return vendorName.concat(' ', `- ${fleetVendorNumber}`);
  };

  const fetchValues = (_, value) => {
    getAssignableRoles({
      variables: {
        userTypeId: 2,
        level: 1,
        roleId,
        parentId: null,
        filters: {
          operator: '$or',
          conditions: [
            {
              operator: '$like',
              key: 'name',
              value: [value.includes('-') ? value.split('-')[0].trim() : value],
            },
            {
              operator: '$like',
              key: 'scopeReference',
              value: [value.includes('-') ? value.split('-')[0].trim() : value],
            },
          ],
        },
      },
    });
  };

  return (
    <Typeahead
      filterValue=""
      label={<span className="text-bold">Name or Fleet vendor number</span>}
      help="Search will show results based on matching characters in any part of the word."
      required
      placeholder="Search for a vendor by name or Fleet vendor number"
      onOptionEnter={(value) => {
        const compStr = value.includes('-')
          ? value.split('-').slice(-1)[0].trim()
          : value;
        if (onVendorSelection) {
          onVendorSelection(
            tpState.find((v) => v.fleetVendorNumber === compStr),
          );
        }
      }}
      onClear={() => {
        if (onVendorSelection) {
          onVendorSelection(null);
        }
      }}
      errorMessage={errorMessage}
      typeaheadValues={tpState.map((d) =>
        vendorTypeAheadValue(d.vendorName, d.fleetVendorNumber),
      )}
      fetchTypeaheadValues={fetchValues}
      inputCharNum={2}
      debounceDelay={500}
      promptText="Search requires 3 characters"
      aria-invalid={errorMessage ? 'true' : 'false'}
    />
  );
};

AssigneableVendorNameTypeahead.propTypes = {
  onVendorSelection: PropTypes.func.isRequired,
  roleId: PropTypes.number.isRequired,
  errorMessage: PropTypes.string,
};

AssigneableVendorNameTypeahead.defaultProps = {
  errorMessage: null,
};

export default AssigneableVendorNameTypeahead;
