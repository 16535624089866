import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Can, useAppAbility, useCurrentUser, useTitle } from '@gsa/afp-shared-ui-utils';
import {
  Button,
  DetailsTable,
  EmptyState
} from '@gsa/afp-component-library';
import { useUserDetails } from '../providers/user-details-provider';
import {
  formatPhoneNumber,
  capitalize,
} from '../../../../../utilities/StringUtils';
import { isFeatureEnabled } from '../../../../../utilities/feature-toggle';
import UserRoles from './user-roles';
import { Types, Status } from '../../constants/user-details-constants';
import AssignRolesModal from './assign-roles-modal';
import './user-information.scss';
import { GET_VENDORS_BY_FLEET_VENDOR_NUMBER } from '../../../../../services/data-layer';
import { USER_TYPE_ID } from '../../../../../utilities/constants';

// TODO use user status utility
const isUserActive = (user) => user?.status?.id === Status.ACTIVE;

const userRoles = (targetUser) => {
  if (!isUserActive(targetUser)) {
    return (
      <EmptyState
        hasBackground
        containerStyles="padding-y-5"
        topText={<strong>User must be active in order to assign roles</strong>}
      />
    );
  }

  return <UserRoles />;
};

const UserInformation = () => {
  const [vendor, setVendor] = useState(null);
  const [, setVendorError] = useState(null);
  const { user, agencyName, bureauName, officeName, setFeedbackMessage, refetchUserDetails, refetchUserRoleScopes } = useUserDetails();
  const { currentUser } = useCurrentUser();
  const [assigningRole, setAssigningRole] = useState(false);
  const ability = useAppAbility();


  const [getVendor] = useLazyQuery(GET_VENDORS_BY_FLEET_VENDOR_NUMBER, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    skip: user?.userType?.id !== '2',
    onCompleted: (data) => {
      setVendor(data.getVendorDetailByFleetVendorNumber)
    },
    onError: () => {
      setVendorError('Not able to load vendors')
    }
  })

  useEffect(() => {
    if(user?.vendorAttrs?.fleetVendorNumber) {
      getVendor({ variables: {
        fleetVendorNumber: user?.vendorAttrs?.fleetVendorNumber
      }})
    }
  }, [user])
  
  useTitle(capitalize(user?.fullName));
  const formatData = (...params) => {
    if (!params) {
      return null;
    }

    const filterParams = params?.filter((item) => item && item.trim().length);
    const formattedData = [];
    filterParams?.forEach((item) => {
      formattedData.push(item);
      formattedData.push(<br />);
    });
    return formattedData.length ? formattedData : null;
  };

  const formatCityStateZipcode = (city, state, zipcode) =>
    city?.concat(`, ${state}`).concat(` ${zipcode}`);
  
  const getAddressAndPhone = (userInfo) => {
    return [[
      'Address',
      formatData(
        userInfo?.addrline1,
        userInfo?.addrline2,
        userInfo?.addrline3,
        formatCityStateZipcode(
          userInfo?.city,
          userInfo?.state,
          userInfo?.postalCode,
        ),
      ),
    ],
    [
      'Phone',
      formatPhoneNumber(
        userInfo?.phoneNumber,
        userInfo?.phoneNumberExt,
      ),
    ],];
  };

  const getWarrantInfo = (userInfo) => {
    if (!ability?.can('view', 'warrantLevel') || userInfo.userType.id === USER_TYPE_ID.Customer) return [];
    return [[
      'Warrant Level',
      userInfo?.internalAttrs?.warrantLevel
        ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(userInfo?.internalAttrs?.warrantLevel)
        : 'N/A',
    ]]
  }

  const getWorkInfo = (userInfo) => { 
    if(!userInfo || !userInfo.userType){
      return [];
    }
    const { userType, internalAttrs } = userInfo;

    let workInfo = [];
    
    if(userType.id === Types.CUSTOMER)
    {
      workInfo = [
        ...getAddressAndPhone(userInfo),
        ['Agency', agencyName],
        ['Bureau', bureauName],
        ['Office', officeName],
      ];
    }
    if(userType.id === Types.GSA_EMPLOYEE){
      if(internalAttrs && internalAttrs?.zone && internalAttrs?.managementCenter){
        workInfo = [
          ...getAddressAndPhone(userInfo),
          ['Zone', internalAttrs?.zone?.name],
          ['Fleet Management Center', internalAttrs?.managementCenter?.name],
        ];
        if(internalAttrs?.fieldOffice){
          workInfo.push(['Field Office', internalAttrs?.fieldOffice?.name])
        }
      } else if(internalAttrs && internalAttrs?.division && internalAttrs?.branch){
        workInfo = [
          ...getAddressAndPhone(userInfo),
          ['Headquarter division', internalAttrs?.division?.name],
          ['Branch', internalAttrs?.branch?.name],
        ];
      } else {
        workInfo = [
          ...getAddressAndPhone(userInfo),
          ['Headquarter division', ''],
          ['Branch', '']
        ]; 
      }
    } else if(userType.id === Types.VENDOR) {
      const contact = vendor?.vendorContacts?.find(c => c.contactType === 'VendorPhysicalContact');
      workInfo = [
        ['Vendor name', vendor?.vendorName],
        ['Vendor types', vendor?.vendorTypes?.map(v => v.commonCode?.name).join(", ")],
        ['Address', formatData(
          contact?.address1,
          contact?.address2,
          formatCityStateZipcode(
            contact?.city,
            contact?.state?.stateCode,
            contact?.postalCode,
          ),
        )],
        ['Phone', formatPhoneNumber(
          userInfo?.phoneNumber,
          userInfo?.phoneNumberExt,
        )]
      ];
    }

    if (workInfo.length > 0 && isFeatureEnabled('accident-and-maintenance')) {
      const warrantInfo = getWarrantInfo(userInfo);
      workInfo.push(...warrantInfo);
    }

    return workInfo.filter(Boolean)
  }

  const formatFullName = (first, last) =>
    first?.concat(` ${last}`).trim().length
      ? capitalize(first?.concat(` ${last}`))
      : null;

  const mailto = (mail) => `mailto:${mail}`;
  
  const getSupervisorInfo = (userInfo) => { 
    const { userType, supervisorUser } = userInfo;    
    if(!userInfo || userType.id === Types.GSA_EMPLOYEE){
      return [];
    }

    return [
      [
        'Name',
        formatFullName(
          supervisorUser?.firstName || '',
          supervisorUser?.lastName || '',
        ),
      ],
      [
        'Email',
        supervisorUser?.email ? (
          <a
            href={mailto(
              supervisorUser?.email,
            )}
          >
            {supervisorUser?.email}
          </a>
        ) : null,
      ],
    ];
  }

  const getPersonalInfo = (userInfo) => {
    if(!userInfo){
      return [];
    }
    const personalInfo = [
      ['Name', capitalize(userInfo?.fullName)],
      [
        'Email',
        user?.email ? (
          <a href={mailto(userInfo?.email)}>{userInfo?.email}</a>
        ) : null,
      ],
      ['User type', userInfo?.userType?.name],
    ];
    const { userType, customerAttrs={} } = userInfo;
    if(userType.id === Types.CUSTOMER)
    {
      
      personalInfo.push(["Non-federal employee working under contract", 
      customerAttrs && customerAttrs.isContractor? "Yes": "No" ]);
    }

    return personalInfo;
  }

  const assignRoles = () => {
    if (!isUserActive(user) || user?.id === currentUser?.id) {
      return null;
    }
  
    const assignRoleFeedback = (feedback) => {
      setAssigningRole(false);
      if (feedback) {
        if (feedback.type === 'success') {
          refetchUserDetails();
          refetchUserRoleScopes();
        }
        setFeedbackMessage(feedback);
      }
    }
  
    return (
      <Can I="assign" a="UserRole">
        <Button
          label="Assign role"
          leftIcon={{
            name: "add"
          }}
          variant="outline"
          onClick={() => {
            setAssigningRole(true);
          }}
          data-testid="assign-role-btn-testid"
        />
        {assigningRole && user &&
          <AssignRolesModal
            user={user}
            feedback={assignRoleFeedback}
          />}
      </Can>
    );
  };

  return (
    <>
      <div className="user-information">
        <div className="margin-top-5">
          <div className="grid-row grid-gap-lg">
            <div className="tablet:grid-col-4">
              <h2 className="text-heavy text-primary font-sans-xs text-uppercase">
                Personal Information
              </h2>
              <div className="details-table margin-bottom-6 bg-gray-3 padding-3 radius-md">
                <DetailsTable
                  data={getPersonalInfo(user)}
                  wrapText                  
                  bordered
                />
              </div>
              
              <div className="customer-details">
                <h2 className="text-heavy text-primary font-sans-xs text-uppercase">
                  Work Information
                </h2>
                
                <div className="details-table margin-bottom-6 bg-gray-3 padding-3 radius-md">
                  <DetailsTable
                    data={[
                      ...getWorkInfo(user),                      
                    ]}
                    bordered
                  />
                </div>
                
                { (user?.userType?.id === Types.CUSTOMER
                  || user?.userType?.id === Types.VENDOR)
                  && 
                  <>
                    <h2 className="text-heavy text-primary font-sans-xs text-uppercase">
                      Manager Information
                    </h2>
                    <div className="details-table margin-bottom-6 bg-gray-3 padding-3 radius-md">
                      <DetailsTable
                        data={[
                          ...getSupervisorInfo(user),
                        ]}
                        bordered
                      />
                    </div>
                  </>}
              </div>
              
            </div>
            <div className="tablet:grid-col-8">
              <div className="display-flex flex-row flex-justify">
                <div>
                  <h2 className="text-heavy text-primary font-sans-xs text-uppercase">
                    Assigned Roles
                  </h2>
                </div>
                {assignRoles()}
              </div>
              {userRoles(user)}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserInformation;
