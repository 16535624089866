import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  connectModal,
  Modal,
  Button,
  Spinner,
  SelectDropdown,
} from '@gsa/afp-component-library';
import { useLazyQuery, useMutation } from '@apollo/client';
import { capitalize } from '../../../../../utilities/StringUtils';
import {
  GET_ASSIGNABLE_ROLES,
  ASSIGN_ROLE,
  GET_ASSIGNABLE_SCOPES,
} from '../../../../../services/data-layer';
import { RequiredFieldsHintAlt } from '../../../../../components/Required/RequiredFieldsHint';
import { Types, Scopes } from '../../constants/user-details-constants';
import { getScopesStr } from '../utils/scope-utils';
import AssigneableVendorNameTypeahead from './assignable-vendor-name-typeahead';

const AssignRolesModal = ({ user, feedback }) => {
  const UNAUTHORIZED_OPERATION_FAILURE = 'UNAUTHORIZED_OPERATION_FAILURE';

  const assignModal = () => {
    const TARGETS = {
      ROLE: 'assign-role',
      SCOPE1: 'assign-scope-level1',
      SCOPE2: 'assign-scope-level2',
      SCOPE3: 'assign-scope-level3',
      SCOPE4: 'assign-scope-level4',
    }
    const userTypeId = parseInt(user.userType.id, 10);

    // Form default values
    const defaultDropdownOption = [{ value: '', label: '-Select-' }];
    const initialLevel1 = {
      levelOptions: defaultDropdownOption,
      selectedLevel: defaultDropdownOption[0],
      required: true,
    };
    const initialLevel2 = {
      levelOptions: defaultDropdownOption,
      selectedLevel: defaultDropdownOption[0],
      required: false,
      visible: false,
    };
    const initialLevel3 = {
      levelOptions: defaultDropdownOption,
      selectedLevel: defaultDropdownOption[0],
      required: false,
      visible: false,
    };
    const initialLevel4 = {
      levelOptions: defaultDropdownOption,
      selectedLevel: defaultDropdownOption[0],
      required: false,
    };

    // Form states
    const [formError, setFormError] = useState();
    const [roleState, setRoleState] = useState({
      roleOptions: defaultDropdownOption,
      selectedRole: defaultDropdownOption[0],
    });
    const [level1State, setLevel1State] = useState(initialLevel1);
    const [level2State, setLevel2State] = useState(initialLevel2);
    const [level3State, setLevel3State] = useState(initialLevel3);
    const [level4State, setLevel4State] = useState(initialLevel4);

    // Form utilities
    const isInternal = () => userTypeId === parseInt(Types.GSA_EMPLOYEE, 10);
    const isVendor = (typeId) => typeId === parseInt(Types.VENDOR, 10);

    const formMap = {
      'assign-role': {
        ref: useRef(),
        label: () => 'Role',
        buildErrorMessage: () => 'Please select a role',
        isAriaInvalid: formError?.target === TARGETS.ROLE ? 'true' : 'false',
        errorMessage: formError?.target === TARGETS.ROLE ? formError?.message : null,
      },
      'assign-scope-level1': {
        ref: useRef(),
        label: () => !isInternal() ? 'Agency' : 'Zone',
        buildErrorMessage: () => !isInternal() ? 'Please select an agency' : 'PLease select zone',
        isAriaInvalid: formError?.target === TARGETS.SCOPE1 ? 'true' : 'false',
        errorMessage:  formError?.target === TARGETS.SCOPE1 ? formError?.message : null,
      },
      'assign-scope-level2': {
        ref: useRef(),
        label: () => !isInternal() ? 'Bureau' : 'Management Zone',
        buildErrorMessage: () => !isInternal() ? 'Please select a bureau' : 'Please select management zone',
        isAriaInvalid: formError?.target === TARGETS.SCOPE2 ? 'true' : 'false',
        errorMessage:  formError?.target === TARGETS.SCOPE2 ? formError?.message : null,
      },
      'assign-scope-level3': {
        ref: useRef(),
        label: () => !isInternal() ? 'Office' : 'Division',
        buildErrorMessage: () => !isInternal() ? 'Please select an office' : 'Please select division',
        isAriaInvalid: formError?.target === TARGETS.SCOPE3 ? 'true' : 'false',
        errorMessage: formError?.target === TARGETS.SCOPE3 ? formError?.message : null,
      },
      'assign-scope-level4': {
        ref: useRef(),
        label: () => 'Branch',
        buildErrorMessage: () => 'Please select branch',
        isAriaInvalid: formError?.target === TARGETS.SCOPE4 ? 'true' : 'false',
        errorMessage: formError?.target === TARGETS.SCOPE4 ? formError?.message : null,
      },
    };

    const [loadingRoles, setLoadingRoles] = useState(true);
    const [getAssignableRoles] = useLazyQuery(
      GET_ASSIGNABLE_ROLES,
      {
        onError: () => {
          setLoadingRoles(false);
          const target = TARGETS.ROLE;
          setFormError({
            target,
            message: 'Fail fetching the roles'
          });
          setTimeout(() => {
            formMap[target].ref?.current?.focus();
          }, 300);
        },
        onCompleted: ({ getAssignableRoles: assignableRoles }) => {
          const roleOptionVals = assignableRoles.map(({ id, name }) => ({
            value: id,
            label: name,
          })) || [];
          setRoleState({
            ...roleState,
            ...{ roleOptions: defaultDropdownOption.concat(roleOptionVals) }
          });
          setLoadingRoles(false);
        },
      },
    );

    // Get roles on mounted event
    useEffect(() => {
      getAssignableRoles({ variables: { id: user?.id } });
    }, []);


    // Form actions
    const [assignRole, { loading: assigningRole}] = useMutation(ASSIGN_ROLE, {
      onError: ({ graphQLErrors = [] }) => {
        const err = graphQLErrors[0];
        const { exception } = err?.extensions || {};
        if(exception 
            && exception?.jse_cause 
            && exception?.jse_cause?.name === UNAUTHORIZED_OPERATION_FAILURE
          ){
          feedback({
            type: 'customError',
            message: <>You don&apos;t have permission to assign this role.</>
          });
        }
        else{
          feedback({
            type: 'error'
          });
        }
      },
      onCompleted: ({ assignRole: data }) => {
        const { role, scope } = data;
        feedback({
          type: 'success',
          message: <><strong>{user?.fullName}</strong> is successfully assigned as a <strong>{role?.name}</strong> at <strong>{getScopesStr(scope, ' / ')}</strong>.</>
        });
      },
    });

    const getSelectedScopeId = () => {
      if (isInternal()) {        
        return Scopes.ROOT;
      }

      const levels = [
        level1State.selectedLevel,
        level2State.selectedLevel,
        level3State.selectedLevel,
        level4State.selectedLevel
      ];
      for (let i = levels.length - 1; i >= 0; i -= 1) {
        if (levels[i].value) return levels[i].value;
      }
      return undefined;
    }
    const validateRole = (role) => {
      if (!role.value) {
        const target = TARGETS.ROLE;
        setFormError({
          target,
          message: formMap[target].buildErrorMessage()
        });
        formMap[target].ref.current.focus();
        return false;
      }
      
      setFormError(null);
      return true;
    }
    const validateLevel = (required, target, levelData) => {
      if(!required){
        return true;
      }
      if (!levelData.value) {        
        setFormError({
          target,
          message: formMap[target].buildErrorMessage()
        });
        formMap[target].ref.current?.focus();
        return false;
      }
      
      setFormError(null);
      return true;
    }
    const isValidForm = () => {
      const { selectedRole: role } = roleState;
      const { selectedLevel: level1 } = level1State;
      const { selectedLevel: level2, required: level2Required } = level2State;
      const { selectedLevel: level3, required: level3Required } = level3State;
      return (
       validateRole(role) &&
       (isInternal() 
        || (
          validateLevel(true, TARGETS.SCOPE1, level1)
          && validateLevel(level2Required, TARGETS.SCOPE2, level2)
          && validateLevel(level3Required, TARGETS.SCOPE3, level3)
        )
       )
      );
    }
    const onAssignRole = () => {
      const { selectedRole: role } = roleState;
      const scopeId = getSelectedScopeId();
      if (isValidForm()) {
        assignRole({ variables: {
          userId: user.id,
          roleId: parseInt(role.value, 10),
          scopeId: parseInt(scopeId, 10),
          userTypeId,
        }});
      }
    };
    const onCancelAssignRole = () => {
      feedback();
    };

    const Actions = ({ onCancel, onSave }) => {
      return (
        <>
          <Button
            label="Cancel"
            type="button"
            variant="unstyled"
            className="padding-right-1"
            onClick={onCancel}
            data-testid="assign-role-cancel-btn"
          />
          <Button
            label="Save change"
            type="button"
            onClick={onSave}
            data-testid="assign-role-save-btn"
          />
        </>
      );
    };
    Actions.propTypes = {
      onCancel: PropTypes.func.isRequired,
      onSave: PropTypes.func.isRequired,
    };

    // Form queries
    const [getLevel1Scopes] = useLazyQuery(GET_ASSIGNABLE_SCOPES, {
      fetchPolicy: 'no-cache',
      onCompleted: ({ getAssignableScopes: data }) => {
        const levels = data.scopes.map(({ id, name }) => {
          return { label: name, value: id };
        });
        setLevel1State({
          ...initialLevel1,
          ...{
            levelOptions: defaultDropdownOption.concat(levels),
          }
        });
      },
      onError: () => {
        const target = TARGETS.SCOPE1;
        setFormError({
          target,
          message: formMap[target].buildErrorMessage()
        });
      }
    });
    const [getLevel2Scopes] = useLazyQuery(GET_ASSIGNABLE_SCOPES, {
      fetchPolicy: 'no-cache',
      onCompleted: ({ getAssignableScopes: data }) => {
        const levels = data.scopes.map(({ id, name }) => {
          return { label: name, value: id };
        });
        setLevel2State({
          ...initialLevel2,
          ...{
            levelOptions: defaultDropdownOption.concat(levels),
            required: data.required,
            visible: data.visible,
          }
        });
      },
      onError: () => {
        const target = TARGETS.SCOPE2;
        setFormError({
          target,
          message: formMap[target].buildErrorMessage()
        });
      }
    });
    const [getLevel3Scopes] = useLazyQuery(GET_ASSIGNABLE_SCOPES, {
      fetchPolicy: 'no-cache',
      onCompleted: ({ getAssignableScopes: data }) => {
        const levels = data.scopes.map(({ id, name }) => {
          return { label: name, value: id };
        });
        setLevel3State({
          ...initialLevel3,
          ...{
            levelOptions: defaultDropdownOption.concat(levels),
            required: data.required,
            visible: data.visible,
          }
        });
      },
      onError: () => {
        const target = TARGETS.SCOPE3;
        setFormError({
          target,
          message: formMap[target].buildErrorMessage()
        });
      }
    });
    const [getLevel4Scopes] = useLazyQuery(GET_ASSIGNABLE_SCOPES, {
      fetchPolicy: 'no-cache',
      onCompleted: ({ getAssignableScopes: data }) => {
        const levels = data.scopes.map(({ id, name }) => {
          return { label: name, value: id };
        });
        setLevel4State({
          ...initialLevel4,
          ...{
            levelOptions: defaultDropdownOption.concat(levels),
            required: data.required,
          }
        });
      },
      onError: () => {
        const target = TARGETS.SCOPE4;
        setFormError({
          target,
          message: formMap[target].buildErrorMessage()
        });
      }
    });

    // Form change handlers
    const onChangeRole = (event) => {
      const { value } = event?.target;
      const { roleOptions: options } = roleState;

      const option = options.find((item) => item.value === value);
      setRoleState({
        ...roleState,
        selectedRole: option
      });

      if (isVendor(userTypeId)) {
        return
      }

      setLevel1State(initialLevel1);
      setLevel2State(initialLevel2);
      setLevel3State(initialLevel3);

      if (validateRole(option)) {
        getLevel1Scopes({
          variables: { 
            userTypeId, 
            level: 1,
            roleId: parseInt(value, 10),
            parentId: null,
          }
        });
      }
    };
    const onChangeScopeLevel1 = (value) => {
      const { levelOptions: options } = level1State;
      const { selectedRole: role } = roleState;
      const option = options.find((item) => item.value === value);

      if (isVendor(userTypeId)) {
        setLevel1State({
          ...level1State,
          selectedLevel: { value }
        });
        return;
      }

      setLevel1State({
        ...level1State,
        selectedLevel: option
      });
      setLevel2State(initialLevel2);
      setLevel3State(initialLevel3);

      if (option.value) {
        getLevel2Scopes({
          variables: { 
            userTypeId, 
            level: 2,
            roleId: parseInt(role.value, 10),
            parentId: parseInt(option.value, 10),
          }
        });
      }
    }
    const onChangeScopeLevel2 = (evt) => {
      const { value } = evt?.target;
      const { levelOptions: options } = level2State;
      const { selectedRole: role } = roleState;
      const option = options.find((item) => item.value === value);
      setLevel2State({
        ...level2State,
        selectedLevel: option
      });
      setLevel3State(initialLevel3);

      if (option.value) {
        getLevel3Scopes({
          variables: { 
            userTypeId, 
            level: 3, 
            roleId: parseInt(role.value, 10),
            parentId: parseInt(option.value, 10), 
          }
        });
      }
    }
    const onChangeScopeLevel3 = (evt) => {
      const { value } = evt?.target;
      const { levelOptions: options } = level3State;
      const { selectedRole: role } = roleState;
      const option = options.find((item) => item.value === value);
      setLevel3State({
        ...level3State,
        selectedLevel: option
      });

      if (!isInternal()) {
        if (option.value) {
          getLevel4Scopes({
            variables: { 
              userTypeId, 
              level: 4,
              roleId: parseInt(role.value, 10),
              parentId: parseInt(option.value, 10),              
            }
          });
        }
      }
    }
    const onChangeScopeLevel4 = (evt) => {
      const { value } = evt?.target;
      const { levelOptions: options } = level4State;

      const option = options.find((item) => item.value === value);
      setLevel4State({
        ...level4State,
        selectedLevel: option
      });
    }

    if (loadingRoles || assigningRole) {
      return <Spinner aria-busy="true" className="loading_backdrop" size="large" />;
    }

    const { fullName } = user;
    const { selectedRole, roleOptions } = roleState;
    const { selectedLevel: selectedLevel1, levelOptions: level1Options } = level1State;
    const { 
      selectedLevel: selectedLevel2,
      levelOptions: level2Options,
      required: leve2Required,
      visible: level2Visible,
    } = level2State;
    const { 
      selectedLevel: selectedLevel3,
      levelOptions: level3Options,
      required: leve3Required,
      visible: level3Visible,
    } = level3State;
    const { 
      selectedLevel: selectedLevel4,
      levelOptions: level4Options,
      required: leve4Required
    } = level4State;
    // For MVP we are adding the root scope to the internal users
    const displayLevel4 = false;

    return (
      <Modal
        title={<h2>Assign new role for {capitalize(fullName)}</h2>}
        onClose={onCancelAssignRole}
        actions={<Actions onCancel={onCancelAssignRole} onSave={onAssignRole} />}
      >
        <RequiredFieldsHintAlt />
        
        <SelectDropdown
          id={TARGETS.ROLE}
          name={TARGETS.ROLE}
          label={<strong>{formMap[TARGETS.ROLE].label()}</strong>}
          onChange={onChangeRole}
          value={selectedRole?.value || defaultDropdownOption[0]}
          options={roleOptions}
          data-testid="assign-role-select-dropdown"
          required
          inputRef={formMap[TARGETS.ROLE].ref}
          errorMessage={formMap[TARGETS.ROLE].errorMessage}
          aria-invalid={formMap[TARGETS.ROLE].isAriaInvalid}
        />
        {
          (!isInternal() && selectedRole.value) && 
          !isVendor(userTypeId)          
          &&
          <SelectDropdown
            id={TARGETS.SCOPE1}
            name={TARGETS.SCOPE1}
            label={<strong>{formMap[TARGETS.SCOPE1].label()}</strong>}
            onChange={(v) => {
              onChangeScopeLevel1(v.target.value)
            }}
            value={selectedLevel1?.value || defaultDropdownOption[0]}
            options={level1Options}
            data-testid="assign-scope-level1-dropdown"
            required
            inputRef={formMap[TARGETS.SCOPE1].ref}
            errorMessage={formMap[TARGETS.SCOPE1].errorMessage}
            aria-invalid={formMap[TARGETS.SCOPE1].isAriaInvalid}
          />
        }
        {
          (!isInternal() && selectedRole.value )&& isVendor(userTypeId) &&
          <AssigneableVendorNameTypeahead
            roleId={parseInt(selectedRole.value, 10)}
            onVendorSelection={(scope) => {
              if (scope) {
                onChangeScopeLevel1(scope.id);
              } else {
                onChangeScopeLevel1('');
              }
            }}
            errorMessage={formMap[TARGETS.SCOPE1].errorMessage && 'Please select a Vendor name'}
          />
        }
        {
          (!isInternal() && selectedLevel1.value) && !isVendor(userTypeId) && level2Visible &&
          <SelectDropdown
            id={TARGETS.SCOPE2}
            name={TARGETS.SCOPE2}
            label={<strong>{formMap[TARGETS.SCOPE2].label()}</strong>}
            onChange={onChangeScopeLevel2}
            value={selectedLevel2?.value || defaultDropdownOption[0]}
            options={level2Options}
            required={leve2Required}
            data-testid="assign-scope-level2-dropdown"
            inputRef={formMap[TARGETS.SCOPE2].ref}
            errorMessage={formMap[TARGETS.SCOPE2].errorMessage}
            aria-invalid={formMap[TARGETS.SCOPE2].isAriaInvalid}
          />
        }
        {
          (!isInternal() && selectedLevel2.value && level3Visible) &&
          <SelectDropdown
            id={TARGETS.SCOPE3}
            name={TARGETS.SCOPE3}
            label={<strong>{formMap[TARGETS.SCOPE3].label()}</strong>}
            onChange={onChangeScopeLevel3}
            value={selectedLevel3?.value || defaultDropdownOption[0]}
            options={level3Options}
            required={leve3Required}
            data-testid="assign-scope-level3-dropdown"
            inputRef={formMap[TARGETS.SCOPE3].ref}
            errorMessage={formMap[TARGETS.SCOPE3].errorMessage}
            aria-invalid={formMap[TARGETS.SCOPE3].isAriaInvalid}  
          />
        }
        {
          (displayLevel4 && selectedLevel3.value) &&
          <SelectDropdown
            id={TARGETS.SCOPE4}
            name={TARGETS.SCOPE4}
            label={<strong>{formMap[TARGETS.SCOPE4].label()}</strong>}
            onChange={onChangeScopeLevel4}
            value={selectedLevel4?.value || defaultDropdownOption[0]}
            options={level4Options}
            required={leve4Required}
            data-testid="assign-scope-level4-dropdown"
            inputRef={formMap[TARGETS.SCOPE4].ref}
            errorMessage={formMap[TARGETS.SCOPE4].errorMessage}
            aria-invalid={formMap[TARGETS.SCOPE4].isAriaInvalid}   
          />
        }
      </Modal>
    );
  }

  const ConnectedAssignModal = connectModal(assignModal);
  return (
    <ConnectedAssignModal
      isOpen={user}
    />
  );
};

AssignRolesModal.propTypes = {
  user: PropTypes.shape(Object).isRequired,
  feedback: PropTypes.func.isRequired
};

export default AssignRolesModal;
