import { Alert, Spinner } from '@gsa/afp-component-library';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAttachmentComponent } from './ComponentContext';
import DescriptionField from './DescriptionField';

const SELECT_FILE_MESSAGE = 'Please select a file';

const AttachmentAddForm = ({ requireFile }) => {
  const [file, setFile] = useState();
  const [fileErrorMessage, setFileErrorMessage] = useState();

  const {
    addState,
    uploadPath,
    attachmentErrors,
    fileTypes,
    maxFileSize,
    linkedEntities,
    onAdd,
  } = useAttachmentComponent();

  const { register, handleSubmit, errors, setError, clearErrors } = useForm();

  const fileInputRef = useRef();

  useEffect(() => {
    if (fileInputRef.current) {
      register(fileInputRef.current, { required: requireFile });
      fileInputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (errors?.file?.message) {
      setFileErrorMessage(errors?.file?.message);
    } else if (!file?.type && errors?.file?.type === 'required') {
      setFileErrorMessage(SELECT_FILE_MESSAGE);
    }
  }, [errors && errors.file]);

  const onSubmit = (fileInfo) => {
    if (!file || !file?.type) {
      setFileErrorMessage(SELECT_FILE_MESSAGE);
      return;
    }
    const formData = new FormData();

    formData.append('attachment', file, file?.name);
    formData.append('description', fileInfo?.description);
    formData.append('path', uploadPath);
    formData.append('linkedEntities', JSON.stringify(linkedEntities));

    onAdd(formData);
  };

  const fileInputGroupStyles = classnames('usa-form-group', {
    [`usa-form-group--error`]: !!fileErrorMessage,
  });

  return (
    <form
      id="attachment-upload-form"
      data-testid="modal-attachment-form"
      onSubmit={handleSubmit(onSubmit)}
    >
      {addState?.adding && (
        <Spinner data-testid="adding-spinner" size="small" />
      )}

      {attachmentErrors?.save?.message && (
        <Alert type="error">{attachmentErrors?.save?.message}</Alert>
      )}

      <div className={fileInputGroupStyles}>
        <label className="usa-label" htmlFor="attachment-file-input-single">
          File
        </label>
        {fileErrorMessage && (
          <span className="usa-error-message">{fileErrorMessage}</span>
        )}
        <div className="usa-file-input">
          <div className="usa-file-input__target">
            <div className="usa-file-input__instructions" aria-hidden="true">
              <span className="usa-file-input__drag-text">
                Drag file here or&nbsp;
              </span>
              <span className="usa-file-input__choose">choose from folder</span>{' '}
              (maximum size: 5 MB)
            </div>
            <div className="usa-file-input__box" />
            <input
              disabled={addState?.adding}
              name="file"
              ref={fileInputRef}
              id="attachment-file-input-single"
              data-testid="attachment-file-input"
              className="usa-file-input__input"
              type="file"
              aria-describedby="file-input-specific-hint"
              onChange={(e) => {
                // Clear errors and file on every select.
                clearErrors('file');
                setFile(null);
                setFileErrorMessage(undefined);

                // Supports single file upload only,
                // extract file [0];
                if (e.target.files?.length) {
                  const [selected] = e.target.files;

                  // Checks file type during drag drop.
                  const extension = selected.name.substr(
                    selected.name.lastIndexOf('.'),
                  );

                  if (!fileTypes?.includes(extension)) {
                    setError('file', {
                      type: 'file-type',
                      message: 'File format not supported',
                    });
                    return;
                  }

                  // Checks file size
                  if (selected.size === 0 || selected.size > maxFileSize) {
                    let errorMessage = 'Size should be under 5 MB';

                    if (selected.size === 0) errorMessage = 'Invalid file size';

                    setError('file', {
                      type: 'file-size',
                      message: errorMessage,
                    });
                    return;
                  }

                  setFile(selected);
                }
              }}
              accept={fileTypes}
            />
          </div>
        </div>
      </div>

      {file?.name && (
        <span id="attachment-file-input-single-hint" className="usa-hint">
          <strong>Selected file: </strong>
          {file?.name}
        </span>
      )}
      <Alert type="warning">
        Help prevent a privacy incident by ensuring that any supporting document
        uploaded here does not contain{' '}
        <a
          href="https://www.gsa.gov/reference/gsa-privacy-program/rules-and-policies-protecting-pii-privacy-act"
          target="_blank"
          rel="noreferrer"
        >
          personally identifiable information
        </a>{' '}
        (PII).
      </Alert>
      <DescriptionField
        fieldRef={register({ maxLength: 100 })}
        showErrorMessage={errors?.description?.type === 'required'}
      />
    </form>
  );
};

export default AttachmentAddForm;

AttachmentAddForm.defaultProps = {
  requireFile: true,
};
AttachmentAddForm.propTypes = {
  requireFile: PropTypes.bool,
};
