import React from 'react';
import PropTypes from 'prop-types';
import { DetailsTable, StatusBadge } from '@gsa/afp-component-library';
import { DateTime } from 'luxon';
import { TaskPropType } from '../../../utilities/types';
import Links from './Links';

function detailsForTask(task) {
  const {
    recallCode = '-',
    recallNhtsaID = '-',
    recallTypeCode = '-',
    recallStatusCode = '-',
  } = task.metaData;

  const recallTypeEnum = {
    S: 'Safety',
    W: 'Warranty',
    NS: 'Non-Safety',
    NA: 'Not-Actionable',
  };

  const recallStatusEnum = {
    C: 'Closed',
    F: 'Closed by FSR',
    M: 'Closed by MFG',
    O: 'Open',
  };

  if (task.taskType === 'RCL') {    
    return [
      ['Recall Code', recallCode],
      ['NHTSA ID', recallNhtsaID],
      ['Recall Type', recallTypeEnum[recallTypeCode] || recallTypeCode],
      [
        'Recall Status Code',
        recallStatusEnum[recallStatusCode] || recallStatusCode,
      ],
      ['GSA Notified', DateTime.fromISO(task.createdAt).toFormat('LL/dd/yyyy')], // TODO this should be recallGsaNotifiedDate - backend will send right dates into this field
      ['Recall Updated', DateTime.fromISO(task.updatedAt).toFormat('LL/dd/yyyy')], // TODO this should be recallUpdatedDate
      [
        'Recall Closed',
        task.metaData.recallClosedDate &&
          DateTime.fromISO(task.metaData.recallClosedDate).toFormat('LL/dd/yyyy'),
      ],
    ];
  }
  
  return [['Status Code', task.status]];  
}

const TaskDetails = ({ task }) => {
  const links = [
    {
      href: task.metaData.recallUrl,
      title: 'Recall Document',
    },
    {
      href: `https://www.nhtsa.gov/recalls?vin=${task.metaData.vin}#vin`,
      title: 'NHTSA VIN-Specific Details',
    },
  ];

  const recallStatus = (task.taskRecall || {}).recallStatusCode;
  const getStatus = (recall) => {
    if(!recall)
      return null;
    if(recall === 'O'){
      return (
        <StatusBadge
          variant='Ready-Gray'
          className="afp-task-status-tag"
        >
          Recall is open
        </StatusBadge>
      )
    }
    return (
      <StatusBadge
        variant='Inactive-Gray'
        className="afp-task-status-tag"
      >
        Recall is closed
      </StatusBadge>
    )
  };

  return (
    <div className="bg-base-lighter padding-3 margin-top-4 margin-bottom-8 afp-task-detail">
      <div className="afp-task-detail__inline_container">
        <h2 className="font-sans-xs margin-top-0 margin-bottom-3 afp-task-detail__recall_campaign">
          This task relates to{' '}
          <span className="afp-underlined-text">
            NHTSA Campaign {(task.taskRecall || {}).recallNhtsaID}
          </span>
        </h2>
        {getStatus(recallStatus)}
      </div>
      <DetailsTable data={detailsForTask(task)} />
      <Links links={links} />
    </div>
  );
};

TaskDetails.propTypes = {
  task: PropTypes.shape(TaskPropType).isRequired,
};

export default TaskDetails;
