import PropTypes from 'prop-types';
import React from 'react';
import { DetailsTable } from '@gsa/afp-component-library';
import { capitalize } from '../../../utilities/StringUtils';
import { Types } from '../../admin-console/users/constants/user-details-constants';

const formatFullName = (first, last) =>
first?.concat(` ${last}`).trim().length
  ? capitalize(first?.concat(` ${last}`))
  : null;
const mailto = (mail) => `mailto:${mail}`;
const getUserAttributes = (user) => {
  const { userType = {} } = user || {};
  const attrs = {
    supervisorEmail: user?.supervisorUser?.email,
    supervisorFirstName: user?.supervisorUser?.firstName,
    supervisorLastName: user?.supervisorUser?.lastName,
  };
  if (userType.id === Types.CUSTOMER) {
    const customer = user?.customerAttrs || {}
    return {
      ...attrs,
      ...customer
    };
  }
  if (userType.id === Types.GSA_EMPLOYEE) {
    const internal = user?.internalAttrs || {};
    return {
      ...attrs,
      ...internal
    };
  }

  return attrs;
};
const emDashUnicode = '\u2014';

const LockedSupervisorInformation = ({ user }) => {
  const attributes = getUserAttributes(user);
  const { userType = {} } = user || {};

  const {
    supervisorFirstName = '',
    supervisorLastName = '',
    supervisorEmail = ''
  } = attributes;
  const fullName = formatFullName(supervisorFirstName, supervisorLastName);
  const email = supervisorEmail ? (
    <a href={mailto(supervisorEmail)}>
      {supervisorEmail}
    </a>
  ) : null

  // TODO remove condition after start displaying supervisor info for internal user
  if (userType.id === Types.GSA_EMPLOYEE) {
    return null;
  }

  return (
    <div className="customer-details mobile-lg:grid-col-12 tablet:grid-col-8 desktop:grid-col-6">
      <h2 className="text-heavy text-primary font-sans-xs text-uppercase">
        Manager Information
      </h2>
      <div
        className="grid-container-widescreen details-locked bg-gray-3 padding-3 radius-md"
      >
        <DetailsTable
          data={[
            [
              'Name',
              fullName || emDashUnicode,
            ],
            [
              'Email',
              email || emDashUnicode,
            ],
          ]}
          bordered
        />
      </div>
    </div>
  );
};

LockedSupervisorInformation.propTypes = {
  user: PropTypes.shape({
    userType: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default LockedSupervisorInformation;