import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Breadcrumbs, SideNav, Spinner } from '@gsa/afp-component-library';
// eslint-disable-next-line import/no-cycle
import { HELP_SIDENAV_MAPPING, getHelpNavItem } from './help-sidenav-mapping';
import HelpSection from './help-section';

const getCurrentSegment = (currentPath) =>
  currentPath.substring(currentPath.lastIndexOf('/') + 1);

const Help = () => {
  const location = useLocation();
  const [sideNavItems, setSideNavItems] = useState(getHelpNavItem());

  const updateActiveSideNavItems = (activeItem) => {
    const updatedItems = sideNavItems.map((item) => {
      if (item.body.props.to === activeItem) {
        return { ...item, active: true };
      }
      return { ...item, active: false };
    });
    setSideNavItems(updatedItems);
  };

  const [activeItem, _setActiveItem] = useState(
    getCurrentSegment(location.pathname),
  );
  const setActiveItem = (item) => {
    if (HELP_SIDENAV_MAPPING[item]) {
      _setActiveItem(item);
    }
  };

  const [trail, setTrail] = useState([<Link to="home">Home</Link>]);

  useEffect(() => {
    const currentTab = getCurrentSegment(location.pathname);
    if (currentTab !== 'help')
      setTrail([<Link to="home">Home</Link>, <Link to="help">Help</Link>]);

    updateActiveSideNavItems(currentTab);
    setActiveItem(currentTab);
  }, [location]);

  if (!sideNavItems) {
    return <Spinner className="padding-y-9" />;
  }

  return (
    <>
      <Breadcrumbs
        trail={trail}
        current={
          activeItem === 'help'
            ? 'Help'
            : HELP_SIDENAV_MAPPING[activeItem]?.title
        }
      />

      <div className="grid-row grid-gap-6 margin-top-3 padding-1 tablet:padding-0">
        <div className="tablet:grid-col-3">
          <SideNav items={sideNavItems} />
        </div>
        <div className="tablet:grid-col-9">
          {activeItem && <HelpSection activeItem={activeItem} />}
        </div>
      </div>
    </>
  );
};

export default Help;
