import PropTypes from 'prop-types';
import React from 'react';

export const StandardFormLabel = ({ htmlFor, children, required }) => {
  return (
    <label className="usa-label text-bold" htmlFor={htmlFor}>
      {children}
      {required && (
        <>
          {' '}
          <abbr title="required" className="usa-label--required">
            *
          </abbr>
        </>
      )}
    </label>
  );
};

StandardFormLabel.propTypes = {
  htmlFor: PropTypes.string.isRequired,
  required: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

StandardFormLabel.defaultProps = {
  required: undefined,
};

export default StandardFormLabel;
