import React from 'react';
import {
  propTypeFormContext,
  propTypeSelectOptions,
  StandardLabeledSelectDropdown,
  StandardLabeledTextbox,
} from '../../components/Forms';
import { formatPhoneNumber } from './phoneNumberFormatter';

export const USA_DOMESTIC_PHONE_CALLING_CODE = '+1 (United States)';
let isDomestic;
export const UserProfileWorkPhoneAlt = ({ formContext, phoneCountryCodes }) => {    
  const {defaultValues} = formContext;

  isDomestic =  (typeof isDomestic==='undefined')?defaultValues.phoneNumberCountryCode=== USA_DOMESTIC_PHONE_CALLING_CODE: isDomestic;
  defaultValues.phoneNumber = formatPhoneNumber(defaultValues.phoneNumber, isDomestic);
  
  return (
    <>
      <StandardLabeledSelectDropdown
        formContext={formContext}
        name="phoneNumberCountryCode"
        label="Country code"
        options={phoneCountryCodes}
        required
        onChangeCallback={(ev) => {
          isDomestic = ev.target.value === USA_DOMESTIC_PHONE_CALLING_CODE;
        }}
      />

      <StandardLabeledTextbox
        formContext={formContext}
        name="phoneNumber"
        label="Phone"
        required
        maxLength={isDomestic ? 12 : 20}
        validationKey={`phoneNumber${
          isDomestic ? 'Domestic' : 'International'
        }`}
        onBlur={(ev) => {
          formContext.control.setValue('phoneNumber',formatPhoneNumber(ev.target.value, isDomestic));
        }}
      />

      <StandardLabeledTextbox
        formContext={formContext}
        name="phoneNumberExt"
        label="Extension"
        maxLength={10}
        validationKey={`phoneNumberExt${
          isDomestic ? 'Domestic' : 'International'
        }`}
      />
    </>
  );
};

UserProfileWorkPhoneAlt.propTypes = {
  formContext: propTypeFormContext.isRequired,
  phoneCountryCodes: propTypeSelectOptions.isRequired,
};

export default UserProfileWorkPhoneAlt;
