export const UserRoleName = {
  0: 'generic',
  1: 'fsr',
  500: 'engineer',
};

export const statusMap = (value) => {
  let color;
  let text;

  switch (value) {
    case 'ToDo':
      color = 'Ready-Gray';
      text = 'To-do';
      return { text, color };

    case 'InProgress':
      color = 'Info-Gray';
      text = 'In progress';
      return { text, color };

    default:
      color = 'Inactive-Gray';
      text = 'Done';
      return { text, color };
  }
};

export const AllowedActions = [
  {
    icon: 'visibility',
    label: 'View',
  },
  // {
  //   icon: 'cancel',
  //   label: 'Delete',
  // },
  {
    icon: 'build',
    label: 'Mark as In progress',
  },
  {
    icon: 'check',
    label: 'Mark as done',
  },
  {
    icon: 'check',
    label: 'Reopen',
  },
];

export const TaskTypes = {
  2: 'Standard Peer Review',
  1: 'Recall',
};

export const getTaskTypeOptions = (defaultType) => {
  const types = [];
  Object.keys(TaskTypes).forEach((e) =>
    types.push({
      value: e,
      label: TaskTypes[e],
      defaultValue: e === defaultType,
    }),
  );
  return types;
};
