import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
    Button,
    Breadcrumbs
  } from '@gsa/afp-component-library';
import ResendActivationLink from './resend-activation-link';
import { isToken } from './props-validator';

const SignupActivationEmail = ({ token, user }) => {
    const { firstName, email } = user;
    const [resend, setResend] = useState(false);

    if (resend) {
        return <ResendActivationLink token={token} />;
    }

    return (
      <div className="desktop:grid-col-7 margin-bottom-8">
        <Breadcrumbs
          trail={[
            <Link style={{ textDecoration: 'underline' }} to="/">
              Home
            </Link>,
          ]}
          current="Create Account"
        />
        <h1 id="email-sent-heading" className="font-body-2xl" tabIndex="-1">Email Sent</h1>
        <p>Thank you {firstName}.</p>
        <p>
          To start your account setup we have sent you a verification email to{' '}
          <strong>{email}</strong>. It may take a minute to receive your link. Your activation link
          will expire in 7 days.
        </p>
        <p>
          Haven’t received it?
        </p>
        <Button
          label="Resend Activation Link"
          variant="outline"
          onClick={() => { setResend(true); }}
        />
      </div>
    );
}

SignupActivationEmail.defaultProps = {
    token: '',
    user: {}
};

SignupActivationEmail.propTypes = {
    token: isToken,
    user: PropTypes.shape({
        firstName: PropTypes.string,
        email: PropTypes.string
    })
};

export default SignupActivationEmail;