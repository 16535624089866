import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Spinner } from '@gsa/afp-component-library';
import TaskContextProvider, { taskContext } from './TaskContextProvider';
import { TASK_DETAIL } from '../../services/data-layer';
import * as TaskTypes from './taskTypes';
import './taskDetails.scss';

const getComponent = (taskType) => {
  const componentName = {
    RCL: 'Recall',
  }[taskType];

  return TaskTypes[componentName] || TaskTypes.Generic;
};

const TaskDetail = () => {
  const { id } = useParams();
  const [task, setTask] = useState(null);

  const { data, refetch } = useQuery(TASK_DETAIL, {
    // variables: { id: parseFloat(id.split('-').pop()) }, //TODO it is a good idea to bring back the URL based on TaskID
    variables: { id: parseFloat(id) },
  });

  useEffect(() => {
    if (data && data.getTaskById) {
      setTask({
        comments: task && task.comments, // TODO: gql nested query for comments
        ...data.getTaskById,
      });
    } else {
      setTask(null);
    }
  }, [data]);

  return task ? (
    <TaskContextProvider task={task} setTask={setTask} refetchTask={refetch}>
      {React.createElement(getComponent(task.taskType), {
        task,
      })}
    </TaskContextProvider>
  ) : (
    <Spinner className="margin-y-9" />
  );
};

TaskDetail.context = taskContext;

export default TaskDetail;
