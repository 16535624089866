/* eslint-disable import/prefer-default-export */
const MNRSubjects = {
  MNRDashboard: 'MNRDashboard',
  AIECardInHomePage: 'AIECardOnHomePage',
};

const MNROperations = {
  VIEW: 'view',
};

export const canViewMNRDashboard = (ability) =>
  ability.can(MNROperations.VIEW, MNRSubjects.MNRDashboard);

export const canViewAIECard = (ability) =>
  ability.can(MNROperations.VIEW, MNRSubjects.AIECardInHomePage);
