import React from 'react';
import PropTypes from 'prop-types';
import { DetailsTable } from '@gsa/afp-component-library';
import { TaskPropType } from '../../../utilities/types';

const CustomerDetails = ({ task }) => {
  const {
    customerNumber = '-',
    agencyPocName = '-',
    agencyPocEmail = '-',
    agencyPocPhone = '-',
  } = task;
  const data = [
    ['Customer Number', customerNumber],
    ['Name', agencyPocName],
    ['Email', agencyPocEmail],
    ['Phone', agencyPocPhone],
  ];

  return (
    <>
      <div className="afp-task-detail__inline_container">
        <h2 className="text-uppercase font-sans-xs margin-top-6 margin-bottom-2 afp-task-manager__subsection_title">
          Related Customer
        </h2>
        <a href="#/customer-details/not-implemented">More</a>
      </div>
      <DetailsTable data={data} />
    </>
  );
};

CustomerDetails.propTypes = {
  task: PropTypes.shape(TaskPropType).isRequired,
};

export default CustomerDetails;
