import { useLazyQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useReducer } from 'react';
import formatPhoneNumber from '../../utilities/formatPhoneNumber';
import { GET_VENDOR_LIST, DELETE_VENDOR, GET_VENDORS, CHANGE_VENDOR_FLEET_STATUS } from './vendor-listing.gql';
import { SYSYTEM_ERROR_MESSAGE } from '../constants';

export const VendorListingContext = createContext();

const initialState = {
  vendorFilters: {
    vendorTypeFilter: [],
    stateFilter: [],
    vendorStatusFilter: [],
    vendorTierFilter: [],
  },
  vendorList: { rows: [], hasMore: false, count: 0 },
  vendors: { rows: [], hasMore: false, count: 0 },
  vendorListingMessage: { type: '', message: '' },
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_MESSAGE':
      return { ...state, vendorListingMessage: action.payload };
    case 'SET_VENDOR_LIST':
      return { ...state, vendorList: action.payload };
    case 'SET_VENDORS':
      return { ...state, vendors: action.payload };  
    case 'SET_VENDOR_LIST_FILTERS':
      return { ...state, vendorFilters: action.payload };
    default:
      return { ...state };
  }
};

function VendorListingProvider({ children, ...props }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const formatVendorData = (data) => {
    const newData = { ...data };
    if (newData?.rows?.length)
      newData.rows = newData.rows.map((row) => {
        const newRow = { ...row };

        if (newRow?.city && newRow.stateCode)
          newRow.stateCode = `${toTitleCase(newRow.city)}, ${newRow.stateCode}`;

        if (newRow?.city && newRow.stateName)
          newRow.stateCode = `${toTitleCase(newRow.city)}, ${newRow.stateName}`;

        if (newRow?.phone)
          newRow.phone = formatPhoneNumber(
            newRow.phone,
            newRow.country || 'US',
          );

        return newRow;
      });

    return newData;
  };

  const setMessage = (type, message) => {
    dispatch({ type: 'SET_MESSAGE', payload: { type, message } });
  };

  const [getVendorList, { refetch }] = useLazyQuery(GET_VENDOR_LIST, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (err) => {
      setMessage('error', err.message);
    },
    onCompleted: (data) => {
      dispatch({
        type: 'SET_VENDOR_LIST',
        payload: formatVendorData(data.getVendorList),
      });
    },
  });


  const [getVendors, { refetch: refetchVendors, loading: isVendroDataLoading }] = useLazyQuery(GET_VENDORS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (err) => {
      setMessage('error', err.message);
    },
    onCompleted: (data) => {
      dispatch({
        type: 'SET_VENDORS',
        payload: formatVendorData(data.getFilteredVendors),
      });
    },
  });

  const [changeVendorFleetStatus] = useMutation(CHANGE_VENDOR_FLEET_STATUS, {
    onError: () => {
      setMessage('error', SYSYTEM_ERROR_MESSAGE);
    },
    onCompleted: () => {
      const initFilters = 
      {
        conditions: [
          {
            key: '',
            operator: '',
            value: ''
          }
        ],
        operator: '$and',
      };
      // setMessage('success', `Fleet status for ${data.toggleVendorFleetStatus.vendorName} updated successfully.`);
      refetchVendors({
        variables: { 
          filters: initFilters, 
          offset: 0, 
          limit: 10, 
          order: [["vendorName", "ASC"]] 
        }
      })
    },
  });

  const setVendorFilters = (filters) => {
    dispatch({ type: 'SET_VENDOR_LIST_FILTERS', payload: filters });
  };

  const [deleteVendor] = useMutation(DELETE_VENDOR, {
    onError: (err) => setMessage('error', err.message),
    onCompleted: (data) => {
      setMessage(
        'success',
        `Vendor ${data.vendorDelete.fleetVendorNumber} was successfully deleted`,
      );
      refetch({
        variables: {
          limit: 10,
          offset: 0,
          order: 'vendor_name ASC',
        },
      });
    },
  });

  return (
    <VendorListingContext.Provider
      value={{
        ...state,
        deleteVendor,
        dispatch,
        setMessage,
        getVendorList,
        getVendors,
        setVendorFilters,
        changeVendorFleetStatus,
        isVendroDataLoading,
        ...props,
      }}
    >
      {children}
    </VendorListingContext.Provider>
  );
}

export default VendorListingProvider;

VendorListingProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useVendorListing = () => useContext(VendorListingContext);
