import React from 'react';
import { UserDetailsProvider } from './providers';
import {
  UserBreadcrumbs,
  LoadingUserSpinner,
  FeedbackAssignRole,
  FeedbackUserDetails,
  UserHeader,
  UserInformation,
} from './components';

const UserDetailsPage = () => {
  return (
    <UserDetailsProvider>
      <LoadingUserSpinner />
      <UserBreadcrumbs />
      <FeedbackAssignRole />
      <FeedbackUserDetails />
      <UserHeader />
      <UserInformation />
    </UserDetailsProvider>
  );
};

export default UserDetailsPage;
