import React, { useState, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';

import {
  Button,
  Modal,
  SelectDropdown,
  TextInput,
  Alert,
} from '@gsa/afp-component-library';
import { GET_BUREAUS_BY_PERMISSION } from '../../services/data-layer';
import { useOfficeManagementListing } from './office-management-listing-provider';
import { CREATE_OFFICE } from './office-management-listing.gql';

const ModalContent = () => {
  const {       
    setCreateOfficeModalState
  } = useOfficeManagementListing();
  const { handleSubmit } = useForm();

  const defaultOption = {
    value: '',
    label: '- Select -',
  };

  const defaultBureauOption = {
    value: '',
    label: '- Select Agency first -',
  };
  const initalValues = {
    agencyCode: '',
    bureauCode: '',
    officeCode: '',
    officeName: '',
    isCustomerAccount: 0
  };

  const initialMessages = {
    message: '',
    officeCodeError: '',
    officeNameError: ''
  };

  const [agencyBureaus, setAgencyBureaus] = useState([]);
  const officeData = useOfficeManagementListing();
  const agencies = officeData?.agencies || [];
  const bureaus = officeData?.bureaus || [];
  const agency = officeData?.agency || '';
  const bureau = officeData?.bureau || '';
  
  const [office, setOffice] = useState(initalValues);
  const [messages, setMessages] = useState(initialMessages);

  const [isShowAlert, setIsShowAlert] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const refetch = officeData.agency!==''

  const [getBureaus] = useLazyQuery(
    GET_BUREAUS_BY_PERMISSION,
    {
      onCompleted: (data) => {
        if (data?.getBureausByPermission) {
          const options = [
            ...data.getBureausByPermission.map((c) => ({
              value: c.id,
              label: c.name,
            })),
          ];
          setAgencyBureaus(options);
        }
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  const getBureausByAgency = (agencyCode) => {
    setOffice({ ...office, agencyCode });
    getBureaus({
      variables: {
        operation: 'view',
        subject: 'OrganizationProfile',
        agencyCode,
      },
    })
  };

  const validateOfficeCode = (e) => {
    if (e.target.value.trim().length === 0) {
      setMessages({ ...messages, 'officeCodeError': 'Office code is required.' });
      return;
    }
    if (e.target.value.trim().length < 3) {
      setMessages({ ...messages, 'officeCodeError': 'Please enter 3 characters.' });
    } else if (e.target.value.trim().length > 3) {
      setMessages({ ...messages, 'officeCodeError': 'Only 3 characters allwed.' });
    }
    else {
      setMessages({ ...messages, 'officeCodeError': '' });
    }
  };

  const validateOfficeName = (e) => {
    if (e.target.value.trim().length === 0) {
      setMessages({ ...messages, 'officeNameError': 'Office name is required.' });
      return;
    }
    if (e.target.value.trim().length > 100) {
      setMessages({ ...messages, 'officeNameError': 'Only 100 characters allowed.' });
    } else {
      setMessages({ ...messages, 'officeNameError': '' });
    }
  };

  const [createOffice] = useMutation(CREATE_OFFICE, {
    onError: (errors) => {
      const errorMessageName =
        errors?.graphQLErrors[0]?.extensions?.exception?.message;
      const errorMessagePk =
        errors?.graphQLErrors[0]?.extensions?.exception?.jse_cause?.errors[0]
          ?.message;

      if (
        errorMessageName?.trim() ===
        'fieldsvalidationError: officeName - This office name already exists for this agency/bureau'
      ) {
        setMessages({
          ...messages,
          message: 'Office name already exists for this agency/bureau.',
        });
      } else if (errorMessagePk?.trim() === 'office.PRIMARY must be unique') {
        setMessages({
          ...messages,
          message: 'Office code already exists for this agency/bureau.',
        });
      } else {
        setMessages({ ...messages, message: 'Office creation failed.' });
      }
      setIsSuccess(false);
      setIsShowAlert(true);
    },
    onCompleted: () => {
      officeData?.setMessage(
        'success',
        `You have successfully created ${office.officeCode}-${office.officeName}.`,
      );
      setOffice(initalValues);
      if (refetch) officeData.refetchOfficeManagements();
      setCreateOfficeModalState(false);
    },
  });

  const onSubmit = () => {
    if (messages.officeCodeError.trim().length === 0 && messages.officeNameError.trim().length === 0) {
      createOffice({
        variables: { office }
      });
    }
  };

  useEffect(() => {
    office.agencyCode = agency;
    setAgencyBureaus(bureaus);
    if (bureau) {
      office.bureauCode = bureau;
    }
    else if (bureaus && bureaus.length === 1) {
      office.bureauCode = bureaus[0]?.value;
    }
  }, [agency]);

  return (
    <>
      {isShowAlert && (
        <Alert
          type={isSuccess ? "success" : "error"}
          showClose
          onClose={() => {
            setOffice(initalValues);
            setIsShowAlert(false);
          }}
        >
          {messages.message}
        </Alert>
      )}

      <div className="grid-row grid-gap">
        <div className="tablet:grid-col-8">
          <p className="afp-create-office__section_text">
            Use this tool to create and name offices within your
            agency/bureau to be displayed throughout GSAFleet.gov’s
            Organization filters.
          </p>
        </div>
      </div>
      <form data-testid="create-office-form" id="create-office-form" onSubmit={handleSubmit(onSubmit)}>
        <SelectDropdown
          id="agency"
          data-testid="agency"
          name="agency"
          label="Agency"
          required
          value={office.agencyCode}
          options={[
            defaultOption,
            ...agencies?.sort((a, b) => (a.value < b.value ? -1 : 1))
          ]}
          onChange={(e) => {
            office.bureauCode = '';
            getBureausByAgency(e.target.value);
          }}
        />
        <SelectDropdown
          id="bureau"
          name="bureau"
          data-testid="bureau"
          label="Bureau"
          required
          value={office.bureauCode}
          options={
            [defaultBureauOption,
              ...agencyBureaus?.sort((a, b) => (a.value < b.value ? -1 : 1))
            ]
          }
          onChange={(e) => {
            setOffice({ ...office, 'bureauCode': e.target.value });
          }}
        />
        <TextInput
          label="Office code"
          name="officeCode"
          value={office.officeCode}
          required
          onBlur={validateOfficeCode}
          onChange={(e) => {
            setOffice({ ...office, 'officeCode': e.target.value });
          }}
          data-testid="office-code"
          hint="3 characters required"
          errorMessage={messages.officeCodeError}
        />

        <TextInput
          label="Office name"
          name="officeName"
          value={office.officeName}
          required
          onBlur={validateOfficeName}
          onChange={(e) => {
            setOffice({ ...office, 'officeName': e.target.value });
          }}
          data-testid="office-name"
          hint="100 characters allowed"
          errorMessage={messages.officeNameError}
        />

        <div className="margin-top-4 margin-bottom-8">
          <Button
            id="cancel-office"
            type="button"
            variant="unstyled"
            className="margin-right-3"
            onClick={() => {              
              setCreateOfficeModalState(false);
            }}            
            label="Cancel"
          />
          <Button id="submit-office" type="submit" form="create-office-form" label="Create Office" />
        </div>
      </form>
    </>
  );
};

const CreateOfficeModal = () => {
  const {    
    showCreateOfficeModal,
    setCreateOfficeModalState
  } = useOfficeManagementListing();
  
  return showCreateOfficeModal && (
    <div className="afp-modal-wrapper">
      <div className="afp-modal-overlay">
        <Modal
          title={<h2 className="margin-bottom-1 text-bold">Create Office</h2>}
          variant="large"
          onClose={() => { setCreateOfficeModalState(false); }}
        >
          <>
            <ModalContent />
          </>
        </Modal>
      </div>
    </div>
  );
};

export default CreateOfficeModal;
