import { CounterTag, StatusBadge } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import displayName from '../helpers/displayName';
import CloseTaskButton from './CloseTaskButton';
import { TaskPropType } from '../../../utilities/types';
import { useTask } from '../TaskContextProvider';
import { statusMap, TaskStatus } from '../helpers/taskStatus';

const Status = ({ status }) => (
  <StatusBadge variant={statusMap(status).color} className="afp-task-status-tag">
    {TaskStatus(status)}
  </StatusBadge>
);

Status.propTypes = { status: PropTypes.string.isRequired };

const TypeTag = ({ type }) => <span>{`Task Type: ${displayName(type)}`}</span>;
TypeTag.propTypes = { type: PropTypes.string.isRequired };

const DocumentsTag = ({ count }) => (
  <CounterTag count={count}>Supporting Documents</CounterTag>
);
DocumentsTag.propTypes = { count: PropTypes.number.isRequired };

const CommentsTag = ({ count }) => (
  <CounterTag count={count}>Comments</CounterTag>
);
CommentsTag.propTypes = { count: PropTypes.number.isRequired };

const Title = ({ task }) => {
  const { taskAttachments, taskComments } = useTask();

  return (
    <div className="display-flex flex-align-center afp-task-detail flex-wrap">
      <h1>{task.taskID}</h1>
      <div className="afp-task-detail__title_tags display-flex flex-fill">
        <TypeTag type={task.taskType} />
        <DocumentsTag count={taskAttachments.count} />
        <CommentsTag count={taskComments.count} />
      </div>
      <Status status={task.status} />
      <CloseTaskButton task={task} />
    </div>
  );
};

Title.propTypes = {
  task: PropTypes.shape(TaskPropType).isRequired,
};

export default Title;
