import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Alert,
  Spinner,
  Button,
  Breadcrumbs,
} from '@gsa/afp-component-library';
import { FleetTechnicalSupportContact } from '@gsa/afp-shared-ui-utils';
import { useMutation } from '@apollo/client';
import { REACTIVATE_PROSPECT } from '../../services/data-layer';
import { isToken } from './props-validator';

const ResendActivationLink = ({ token }) => {
  const [counter, setCounter] = useState(0);
  const [maxAttemptReached, setMaxAttemptReached] = useState(false);
  const [linkDisabled, setLinkDisabled] = useState(false);
  const [reactivateProspect, { loading: reactivatingProspect }] = useMutation(
    REACTIVATE_PROSPECT,
    {
      context: {
        clientName: 'public',
      },
      onError: () => {
        // todo
      },
      onCompleted: () => {
        // todo
      },
    },
  );

  const handleResendActivationLink = () => {
    setLinkDisabled(true);

    reactivateProspect({ variables: { code: token } });

    if (counter >= 1) {
      setMaxAttemptReached(true);
    }
    setCounter(counter + 1);

    setTimeout(() => setLinkDisabled(false), 180000);
  };

  useEffect(() => {
    setLinkDisabled(true);
    if (token) {
      reactivateProspect({ variables: { code: token } });
    }

    setTimeout(() => setLinkDisabled(false), 180000);
  }, [token]);

  const successInfoAlert = () => {
    return (
      <Alert
        type={linkDisabled ? 'success' : 'info'}
        className="display-flex flex-justify"
        slim
      >
        {linkDisabled ? (
          <span>
            New account activation link was successfully sent. To request a new
            activation link, you will have to wait 3 minutes.
          </span>
        ) : (
          <span>You can now request a new activation link again.</span>
        )}
      </Alert>
    );
  };

  const resendButton = () => {
    if (linkDisabled || maxAttemptReached) {
      return null;
    }

    return (
      <>
        <p>Haven’t received it?</p>
        <Button label="Resend activation link" variant="outline" onClick={handleResendActivationLink} />
      </>
    );
  };

  if (maxAttemptReached) {
    return (
      <div className="desktop:grid-col-7 margin-bottom-8">
        <Alert type="error" className="display-flex flex-justify" slim>
          <span>
            New account activation link was successfully sent. You reached the
            maximum number of attempts to resend the link.{' '}
            <FleetTechnicalSupportContact />.
          </span>
        </Alert>
      </div>
    );
  }

  if (reactivatingProspect) {
    return (
      <Spinner aria-busy="true" className="loading_backdrop" size="large" />
    );
  }

  return (
    <div className="desktop:grid-col-7 margin-bottom-8">
      <Breadcrumbs
        trail={[
          <Link style={{ textDecoration: 'underline' }} to="/">
            Home
          </Link>,
        ]}
        current="Create Account"
      />
      {successInfoAlert()}
      <h1 className="font-body-2xl">New Activation Link Sent</h1>
      <p>
        Please check your inbox for the new activation link. It may take a
        minute to receive your link.
      </p>
      {resendButton()}
    </div>
  );
};

ResendActivationLink.defaultProps = {
  token: '',
};

ResendActivationLink.propTypes = {
  token: isToken,
};

export default ResendActivationLink;
