/* eslint-disable import/prefer-default-export */

/**
 * Checks for an object with at least one property
 * @param obj - target value, e.g: {}, {prop:value}
 * @returns {boolean} - True if is an object with at least one property, otherwise False
 * */
export const isObjectWithProperties = (obj) => {
  return !!(obj && obj.constructor === Object && Object.keys(obj).length > 0);
};
