import { Button } from '@gsa/afp-component-library';
import classnames from 'classnames';
import React, { useState } from 'react';
import { useAttachmentComponent } from './ComponentContext';

const AttachmentLoadMore = () => {
  const {
    rows,
    count,
    hasMore,
    onLoadMore,
    loadMoreLimit,
    paginationLimit,
  } = useAttachmentComponent();

  const [currentLimit, setCurrentLimit] = useState(paginationLimit);

  const classes = classnames('attachment-load-more', {
    [`attachment-load-more-hide`]: count <= loadMoreLimit || !hasMore,
  });

  let remainingCount = loadMoreLimit;

  // Show calculated remaining count.
  if (count - rows.length <= loadMoreLimit) {
    remainingCount = count - rows.length;
  }

  return (
    <div className={classes}>
      <Button
        label={`Show ${remainingCount} additional documents`}
        rightIcon={{
          className: 'attachment-load-more-icon',
          name: 'down-chevron',
        }}
        type="button"
        variant="unstyled"
        onClick={() => {
          onLoadMore(currentLimit + remainingCount);
          setCurrentLimit((prev) => prev + remainingCount);
        }}
      />
    </div>
  );
};

export default AttachmentLoadMore;
