import React from 'react';
import {
  GET_TASK_COMMENTS,
  CREATE_TASK_COMMENT,
} from '../../../services/data-layer';
import { useTask } from '../TaskContextProvider';
import Comments from '../../../components/Comments/Comments';

const TaskComments = () => {
  const { task, setTaskComments } = useTask();
  const allowChanges = task.status === 'Open';
  return (
    <Comments
      parentPrimaryKey={parseFloat(task.id)}
      fetchGQL={GET_TASK_COMMENTS}
      createGQL={CREATE_TASK_COMMENT}
      fetchResponseKey="getTaskComments"
      setParentContext={setTaskComments}
      allowChanges={allowChanges}
    />
  );
};

export default TaskComments;
