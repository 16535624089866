import React from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';
import { PageTitle } from "@gsa/afp-component-library";

const PageHeader = ({ children, ...props }) => {
  const { title, className, id } = props;
  return (
    <section
      className={`usa-header mobile-lg:grid-col-6 padding-bottom-6 ${className}`}
      data-testid={id || uniqueId('page-header-')}
    >
      <PageTitle title={title} />
      {children}
    </section>
  );
};

PageHeader.defaultProps = {
  children: null,
  className: '',
  id: '',
};

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  id: PropTypes.string,
  children: PropTypes.node,
};

export default PageHeader;
