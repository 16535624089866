import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import reducer, { defaultState, createActions } from './reducer';

const AlerNotificationsCtx = createContext({});

const AlertNotificationProvider = ({ children }) => {
  const [state, setDispatch] = useReducer(reducer, defaultState);
  const actions = createActions(setDispatch);

  const notifications = Object.values(state);
  const providerValue = { notifications, ...actions };

  return (
    <AlerNotificationsCtx.Provider value={providerValue}>
      {children}
    </AlerNotificationsCtx.Provider>
  );
};

AlertNotificationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

/**
 * An object with id and message required fields.
 * @typedef {Object} Notification
 * @property {string} id - unique id value
 * @property {string} message
 *
 */

/**
 * @typedef {Object} UseNotifications
 * @property {[Notification]} notifications
 * @property {(id: string)=>void} clearNotification
 * @property {(notification: Notification!)=>void} setNotification
 * @property {()=>void} resetNotifications
 */

/**
 * Returns the `AlertNotificationsCtx.Provider` values
 * @returns {UseNotifications} {notifications, clearNotification, setNotification, resetNotification}
 */

export const useNotifications = () => useContext(AlerNotificationsCtx);

export default AlertNotificationProvider;
