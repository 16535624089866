import React from 'react';
import BaseAlertNotifications from './AlertNotifications';
import AlertNotificationProvider, { useNotifications } from './provider';

const AlertNotifications = () => {
  const { notifications, clearNotification } = useNotifications();
  return (
    <BaseAlertNotifications
      messages={notifications}
      onClose={clearNotification}
    />
  );
};

export {
  BaseAlertNotifications,
  useNotifications,
  AlertNotificationProvider,
  AlertNotifications,
};
