import React, { useEffect, useState } from 'react';
import { FilterPanel } from '@gsa/afp-component-library';
import { useUserInvitationFilter } from '../providers';

const initialFilterStructure = [
  {
    title: 'Email',
    key: 'email',
    id: 'filter-user-email',
    type: 'text',
    permanent: false,
    placeholder: 'Enter email',
    operator: '$like',
  },
  {
    title: 'Status',
    key: 'status',
    id: 'filter-user-invitation-status',
    type: 'select',
    permanent: false,
    operator: '$exact',
    options: [{ value: '', label: 'All statuses', defaultValue: true }],
    value: '',
  },
  {
    title: 'User type',
    key: 'user_type_id',
    id: 'filter-user-types',
    type: 'select',
    permanent: false,
    operator: '$exact',
    options: [{ value: '', label: 'All user types', defaultValue: true }],
    value: '',
  },
  {
    key: '$vendorInvitationAttrs.fleet_vendor_no$',
    title: 'Vendor',
    id: 'filter-user-vendors',
    type: 'typeahead',
    value: '',
    operator: '$exact',
    expanded: false,
    hideClear: true,
  },
];

const UserInvitationFilter = () => {
  const {
    getUserTypes,
    userTypes,
    getStatuses,
    statuses,
    setFilters,
    vendorTypeaheadData,
    handleFetchVendorTypeaheads,
  } = useUserInvitationFilter();

  const [filterStructure, setfilterStructure] = useState(
    initialFilterStructure,
  );

  const addOptions = (id, options) => {
    return filterStructure.map((f) => {
      const filter = f;
      if (filter.id === id) {
        filter.options = [filter.options[0], ...options];
      }
      return filter;
    });
  };

  useEffect(() => {
    getUserTypes();
    getStatuses();
  }, []);

  useEffect(() => {
    setfilterStructure(addOptions('filter-user-invitation-status', statuses));
  }, [statuses]);

  useEffect(() => {
    setfilterStructure(
      addOptions(
        'filter-user-types',
        userTypes.map((ut) => ({ value: ut.id, label: ut.name })),
      ),
    );
  }, [userTypes]);

  return (
    <div data-testid="user-invitation-filters">
      {filterStructure.length && (
        <FilterPanel.FilterPanel
          filterStructure={filterStructure}
          setQueryFiltersState={(filters) => {
            setFilters(filters);
          }}
          fetchTypeaheads={handleFetchVendorTypeaheads}
          typeaheadData={vendorTypeaheadData}
          clearButtonLabel="Reset All"
          showClearIcon
        />
      )}
    </div>
  );
};

export default UserInvitationFilter;
