import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { DetailsTable } from '@gsa/afp-component-library';
import { TaskPropType } from '../../../utilities/types';

const VehicleDetails = ({ task }) => {
  const {
    inventoryStatusCode = '-',
    tag,
    vin,
    modelYear = '-',
    vehicleMake = '-',
    vehicleModel = '-',
  } = (task || {}).metaData || {};

  const data = [
    ['Inventory Status', inventoryStatusCode],
    ['Plate Number', tag ? <Link to={`/vehicle/${vin}`}>{tag}</Link> : '-'],
    ['VIN', vin ? <Link to={`/vehicle/${vin}`}>{vin}</Link> : '-'],
    ['Year', modelYear],
    ['Make', vehicleMake],
    ['Model', vehicleModel],
  ];

  return (
    <>
      <div className="afp-task-detail__inline_container">
        <h2 className="text-uppercase font-sans-xs margin-bottom-2 afp-task-manager__subsection_title">
          Related Vehicle
        </h2>
        <a href={vin ? `/vehicle/${vin}` : '#'}>More</a>
      </div>
      <DetailsTable data={data} />
    </>
  );
};

VehicleDetails.propTypes = {
  task: PropTypes.shape(TaskPropType).isRequired,
};

export default VehicleDetails;
