import PropTypes from 'prop-types';
import React from 'react';
import { propTypeFormContext } from './createFormContext';
import { StandardFormGroup } from './standard-form-group';

export const StandardReadonly = ({ formContext, name, defaultValue }) => {
  const { defaultValues } = formContext;
  return (
    <p data-testid={`${name}-standard-readonly`} className="usa-label standard-readonly margin-top-1">
      {defaultValue ?? defaultValues[name]}
    </p>
  );
};

StandardReadonly.propTypes = {
  formContext: propTypeFormContext.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
};

StandardReadonly.defaultProps = {
  defaultValue: undefined,
};

export default StandardReadonly;

export const StandardLabelledReadonly = ({
  formContext,
  name,
  defaultValue,
  label,
}) => {
  return (
    <StandardFormGroup formContext={formContext} name={name} label={label}>
      <StandardReadonly
        defaultValue={defaultValue}
        name={name}
        formContext={formContext}
      />
    </StandardFormGroup>
  );
};

StandardLabelledReadonly.propTypes = {
  formContext: propTypeFormContext.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  label: PropTypes.string.isRequired,
};

StandardLabelledReadonly.defaultProps = {
  defaultValue: undefined,
};
