// TODO: combine with UserStatus from shared-svc-utils
export const Status = {
  PENDING_PROFILE: '1',
  PENDING_ROLE: '2',
  PENDING_APPROVAL: '2',
  ACTIVE: '3',
  DEACTIVATED: '4',
  DENIED: '5',
  DELETED: '6',
  PENDING_REACTIVATION: '7',
  INACTIVE: '8',
};

export const StatusLabel = {
  PENDING_PROFILE: 'Pending Profile',
  PENDING_APPROVAL: 'Pending Approval',
  ACTIVE: 'Active',
  DEACTIVATED: 'Deactivated',
  DENIED: 'Denied',
  DELETED: 'Deleted',
  PENDING_REACTIVATION: 'Pending Reactivation',
  INACTIVE: 'Inactive',
};

export const Types = {
  CUSTOMER: '1',
  VENDOR: '2',
  GSA_EMPLOYEE: '3',
  PUBLIC_OR_GENERAL: '4',
};

export const Scopes = {
  ROOT: 1,
};
