import PropTypes from 'prop-types';

export const createFormContext = (
  control,
  register,
  watch,
  errors,
  defaultValues,
  validationOptions,
) => {
  return {
    control,
    register,
    watch,
    errors,
    defaultValues,
    validationOptions,
  };
};

export const propTypeFormContext = PropTypes.shape({
  control: PropTypes.shape({
    isFormDirty: PropTypes.func,
    register: PropTypes.func,
    watch: PropTypes.func,
    getValues: PropTypes.func,
    setValue: PropTypes.func,
    unregister: PropTypes.func,
    shouldUnregister: PropTypes.bool,
  }),
  register: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.any, // Any is required here because it's either a complex object or undefined
  // eslint-disable-next-line react/forbid-prop-types
  defaultValues: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  validationOptions: PropTypes.object.isRequired,
});

export const propTypeSelectOptions = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
);

export default createFormContext;
