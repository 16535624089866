import React, { useState, useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { SelectDropdown } from '@gsa/afp-component-library';
import { useLazyQuery } from '@apollo/client';
import { useCreateInvitation } from '../providers/create-invitation-provider';
import { StandardFieldset } from '../../../components/Forms';
import { GET_AGENCIES_BY_PERMISSION } from '../../../services/data-layer';

const InvitationCustomerForm = () => {
  const { setValue, control } = useFormContext();
  const [agencies, setAgencies] = useState([]);
  const { showLoader, hideLoader, setMessage } = useCreateInvitation();
  const fields = {
    agencyCode: 'agencyCode'
  };
  const defaultOption = {
    value: '',
    label: '- Select -',
  };
  
  const [getAgencies, { loading: getAgenciesLoading }] = useLazyQuery(
    GET_AGENCIES_BY_PERMISSION,
    {
      onError: () => {
        setMessage({
          type: 'ISE',
          message: 'Internal Server Error - Unmanaged',
        });
      },
      onCompleted: (data) => {
        if (data?.getAgenciesByPermission) {
          const options = [
            defaultOption,
            ...data.getAgenciesByPermission.map((c) => ({
              value: c.id,
              label: c.name,
            })),
          ];
          setAgencies(options);
        }
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  useEffect(() => {
    if (getAgenciesLoading) {
      showLoader();
    } else {
      if (agencies.length === 2) {
        setValue(fields.agencyCode, agencies[1].value);
      }
      hideLoader();
    }
  }, [getAgenciesLoading, agencies]);

  useEffect(() => {
    getAgencies({
      variables: {
        operation: 'create',
        subject: 'Invitation',
      },
    });
  }, []);

  return (
    <StandardFieldset label="Customer Information" name="customerInfo">
      <Controller
        name={fields.agencyCode}
        control={control}
        defaultValue=""
        render={({ ref, ...fieldProps }) => {
          return (
            <SelectDropdown
              {...fieldProps}
              label={<span className="text-bold">Agency</span>}
              name={fields.agencyCode}
              id={fields.agencyCode}
              options={agencies}
              className="maxw-full"
              data-testid="customer-invitation-agency"
            />
          );
        }}
      />
    </StandardFieldset>
  );
};

export default InvitationCustomerForm;
