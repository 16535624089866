export const statusMap = (value) => {
  let color;
  let text;

  switch (value) {
    case 'Open':
      color = 'Ready-Gray';
      text = 'To-do';
      return { text, color };

    case 'Inprogress':
      color = 'Info-Gray';
      text = 'In progress';
      return { text, color };

    default:
      color = 'Inactive-Gray';
      text = 'Done';
      return { text, color };
  }
};
export const taskStatusMapping = (status) => {
  if (status === 'Open') {
    return 'Inprogress';
  }
  if (status === 'Inprogress') {
    return 'Closed';
  }
  if (status === 'Closed') {
    return 'Open';
  }
  return 'Open';
};

export const TaskStatus = (status) => {
  if (status === 'Open') {
    return 'To-do';
  }
  if (status === 'Inprogress') {
    return 'In progress';
  }
  return 'Done';
};
