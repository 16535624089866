import React, { useState, useEffect } from 'react';
import { Spinner } from '@gsa/afp-component-library';
import { useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { GET_VENDOR } from '../../../services/data-layer';
import { formatDate } from '../../../utilities/StringUtils';

const emDashUnicode = '\u2014';

const UserInvitationExtra = ({ userInvitation, setFeedbackMsg }) => {
  const [vendorDetails, setVendorDetails] = useState(undefined);
  const handleError = () => {
    setFeedbackMsg({
      type: 'error',
      message: <span>An exception occured while fetching Vendor details</span>,
    });
  };

  const [getVendorDetails, { data: vendorData, loading }] = useLazyQuery(
    GET_VENDOR,
    {
      onError: () => handleError,
      onCompleted: () => setVendorDetails(vendorData.getVendor),
      fetchPolicy: 'cache-and-network',
    },
  );

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    userInvitation &&
      userInvitation.vendorInvitationAttrs &&
      userInvitation.vendorInvitationAttrs.fleetVendorNo &&
      getVendorDetails({
        variables: {
          fleetVendorNumber: userInvitation.vendorInvitationAttrs.fleetVendorNo,
        },
      });
  }, [userInvitation]);

  const showLoading = !vendorDetails && loading;

  const mailto = (mail) => `mailto:${mail}`;

  return (
    <>
      {showLoading && <Spinner size="small" />}
      {userInvitation && (
        <div
          className="grid-container padding-top-2 padding-bottom-2"
          data-testid={`user-invitation-extended-${userInvitation.id}`}
        >
          <div className="tablet:display-flex">
            <div className="grid-col padding-bottom-1">
              <div className="tablet:display-flex tablet:flex-justify tablet:border-base-lighter tablet:border-bottom padding-top-1 padding-bottom-1">
                <div className="text-bold">Email address</div>
                <span>
                  {userInvitation.email ? (
                    <a href={mailto(userInvitation?.email)}>
                      {userInvitation?.email}
                    </a>
                  ) : (
                    emDashUnicode
                  )}
                </span>
              </div>
              <div className="tablet:display-flex tablet:flex-justify tablet:border-base-lighter tablet:border-bottom padding-top-1 padding-bottom-1">
                <div className="text-bold">Date invitation sent</div>
                <span>
                  {userInvitation.createdAt
                    ? formatDate(new Date(userInvitation.createdAt))
                    : ''}
                </span>
              </div>
            </div>
            <div className="grid-col tablet:padding-left-4">
              <div className="tablet:display-flex tablet:flex-justify tablet:border-base-lighter tablet:border-bottom padding-top-1 padding-bottom-1">
                <div
                  className="text-bold margin-right-1"
                  style={{ flex: '0 0 auto' }}
                >
                  Manager
                </div>
                <span>
                  {userInvitation.manager ? (
                    <a href={mailto(userInvitation.manager.email)}>
                      {`${userInvitation.manager.firstName} ${userInvitation.manager.lastName}`}
                    </a>
                  ) : (
                    emDashUnicode
                  )}
                </span>
              </div>
              {userInvitation.userType.name === 'Vendor' && (
                <>
                  <div className="tablet:display-flex tablet:flex-justify tablet:border-base-lighter tablet:border-bottom padding-top-1 padding-bottom-1">
                    <div
                      className="text-bold margin-right-1"
                      style={{ flex: '0 0 auto' }}
                    >
                      Vendor name
                    </div>
                    <span>
                      {vendorDetails && vendorDetails.vendorName
                        ? vendorDetails.vendorName
                        : emDashUnicode}
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

UserInvitationExtra.propTypes = {
  userInvitation: PropTypes.node.isRequired,
  setFeedbackMsg: PropTypes.func.isRequired,
};

export default UserInvitationExtra;
