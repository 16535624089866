import { Status } from './constants/user-details-constants';
import { canManageAll } from '../../users/authorization';
import { canEditProfile } from '../../users/components/modal/edit-user-status-modal';

const canUnassignRole = (ability) => ability.can('unassign', 'UserRole');
export const canAssignRole = (ability) => ability.can('assign', 'UserRole');
export const canEditUserProfile = (ability, userStatusId) => {
  return (
    (userStatusId === Status.PENDING_APPROVAL && canManageAll(ability)) ||
    canEditProfile(ability, userStatusId)
  );
};

export default canUnassignRole;
