import { gql } from '@apollo/client';

export const AttachmentFields = gql`
  fragment AttachmentFields on Attachment {
    id
    name
    description
    contentURL
    type
    size
    lastModified
    createdAt
    updatedAt
    createdByUser
    user {
      fullName
    }
  }
`;

export const VehicleFields = gql`
  fragment VehicleFields on Vehicle {
    id
    tagNumber
    comments {
      count
    }
    attachments {
      count
    }
    fleetVendor {
      fleetVendorNumber
      vendorName
    }
    vehicleMake {
      makeCode
      makeName
    }
    vehicleModel {
      modelCode
      makeCode
      modelDescription
    }
    customer {
      customerID
      firstName
    }
  }
`;

export const VehicleFieldsFull = gql`
  fragment VehicleFieldsFull on Vehicle {
    id
    tagNumber
    customerID
    modelCode
    makeCode
    modelYear
    standardItemCode
    fleetVendorNumber
    customerEquipmentNumber
    makeColorName
    fuelCode
    odometerUnitCode
    bookValue
    capitalValue
    originalAcquisitionDate
    isNletsQueriable
    lastNletsQueryDateTime
    garagePostalCode
    primaryPocEmail
    secondaryPocEmail
    keyCodeIgnition
    keyCodeOther
    standardItemExtension
    vehicleLeaseRateCode
    itemInventoryStatusCode
    fsrUserEmail
    ownershipTypeCode
    consolidationNumber
    consolidationDate
    isReplacementEligible
    lawEnforcementClass
    subSectionCode
    agencyCode
    bureauCode
    subSectionTypeCode
    createdByUser
    createdAt
    updatedByUser
    updatedAt
    isDeleted
    dmlFlag
    vehicleMake {
      makeCode
      makeName
    }
    vehicleModel {
      modelCode
      makeCode
      modelTypeCode
      modelDescription
    }
    customer {
      agency {
        name
        shortName
      }
      customerID
      boac
      boacSerialNumber
      customerGsaRegionNumber
      fmcAgencyCode
      fmcBureauCode
      fmcSubSectionCode
      fmcSubSectionTypeCode
      customerAgencyCode
      customerBureauCode
      isRentalRequestAllowed
      address1
      address2
      address3
      city
      stateCode
      isoCountryCode2
      officeName
      customerType
      phone
      phoneExtension
      alternatePhone
      alternatePhoneExtension
      phoneCommercial
      fax
      firstName
      middleName
      lastName
      email
      mileageReportingReminder
      maintenanceReminder
      fsrUserEmail
      afvStartDate
      afvEndDate
      afvAmount
      postalCode
    }

    odometerReading {
      reading
      readingAt
    }
    assignment {
      assignmentDate
    }
    assetReport {
      monthlyRate
      mileageRate
      optionalEquipmentCode
      optionalEquipmentMonthlyRate
      optionalEquipmentMileageRate
    }
    standardItem {
      standardItemName
      standardItemTypeCode
      defaultVehicleLeaseRateCode
      standardItemCodeFastDescription
      standardItemTypeFuel {
        expectedLifeMonths
      }
    }
    makeColor {
      name
    }
    tasks {
      count
    }
    attachments {
      count
    }
    comments {
      count
    }
    openRecalls {
      count
    }
  }
`;
