import React from 'react';
import PropTypes from 'prop-types';
import { TaskPropType } from '../../../utilities/types';

const Description = ({ task, taskType, recallType }) => {
  const recallTypeEnum = {
    S: 'Safety',
    W: 'Warranty',
    NS: 'Non-Safety',
    NA: 'Not-Actionable',
  };
  return (
    <>
      {task.title && (
        <h2 className="font-sans-m margin-bottom-3 margin-top-0">
          {task.title}
        </h2>
      )}
      {task.description && (
        <p className="afp-task-detail__task_description_text">
          {task.description}
        </p>
      )}
      {taskType === 'recall' ? (
        <div className="afp-recall-service-text">
          <div className="text-bold">
            {recallTypeEnum[recallType]} recall remediation required.
          </div>
          <div>
            This is a system generated task for an open and actionable{' '}
            {recallTypeEnum[recallType]} recall. This task is meant to help you
            track and manage the remediation of a recall for this vehicle. You
            can add comments and upload documents to track your work. Once you
            have completed the required remediation, you can mark this task as
            &quot;Done&quot;.{' '}
            <div className="text-bold display-inline-block">
              Marking the task as done will not change the status of the recall.
            </div>{' '}
          </div>
        </div>
      ) : null}
    </>
  );
};

Description.propTypes = {
  task: PropTypes.shape(TaskPropType).isRequired,
  taskType: PropTypes.string,
  recallType: PropTypes.string,
};

Description.defaultProps = {
  taskType: '',
  recallType: 'NA',
};

export default Description;
