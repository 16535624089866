export const formatPhoneNumberDomestic = (phoneNumber) => {
  if (!phoneNumber) {
    return '';
  }
  let formattedPhoneNumber = phoneNumber;
  formattedPhoneNumber = formattedPhoneNumber.trim();
  // https://regex101.com/r/w9khTI/1/
  formattedPhoneNumber = formattedPhoneNumber.replace(/[^0-9]+/g, '');
  // https://regex101.com/r/7N2AUC/1
  formattedPhoneNumber = formattedPhoneNumber.replace(
    /^([0-9]{3})([0-9]{3})([0-9]{4})$/,
    '$1-$2-$3',
  );
  return formattedPhoneNumber;
};

export const formatPhoneNumberInternational = (phoneNumber) => {
  if (!phoneNumber) {
    return '';
  }
  let formattedPhoneNumber = phoneNumber;
  formattedPhoneNumber = formattedPhoneNumber.trim();
  // https://regex101.com/r/o1oe37/1
  formattedPhoneNumber = formattedPhoneNumber.replace(/([^0-9.-])+/g, '');
  return formattedPhoneNumber;
};

export const formatPhoneNumber = (phoneNumber, isDomestic) => {
  if (isDomestic) {
    return formatPhoneNumberDomestic(phoneNumber);
  }
  return formatPhoneNumberInternational(phoneNumber);
};
