import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Button } from '@gsa/afp-component-library';
import React, { useEffect, useRef, useState } from 'react';
import { useAttachmentComponent } from './ComponentContext';

const AttachmentRowAction = ({ row: { original }, allowChanges }) => {
  const {
    onDownload,
    currentUser,
    setEditState,
    setDeleteState,
  } = useAttachmentComponent();

  const [open, setOpen] = useState(false);
  const dropContentRef = useRef(null);

  const isOwner = currentUser?.email
    ? currentUser.email === original.createdByUser
    : false;

  const dropDownClasses = classnames('dropdown-content', { [`show`]: open });

  const handleClickOutside = () => {
    setOpen(false);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <div className="dropdown">
      <Button
        label="Document Action"
        labelClass="usa-sr-only"
        leftIcon={{
          name: "more_horiz"
        }}
        type="button"
        title="document actions"
        className="usa-button usa-button--outline usa-button dropdown-button"
        onClick={() => setOpen(!open)}
      />
      <div className={dropDownClasses} ref={dropContentRef}>
        <Button
          label="Download"
          leftIcon={{
            name: "download",
            className: "dropdown-content-icon"
          }}
          type="button"
          className="usa-button usa-button--outline"
          onClick={() => {
            setOpen(false);
            onDownload(original);
          }}
        />

        {isOwner && allowChanges && (
          <Button
            label="Edit Description"
            leftIcon={{
              name: "edit",
              className: "dropdown-content-icon"
            }}
            type="button"
            className="usa-button usa-button--outline"
            onClick={() => {
              setOpen(false);
              setEditState({ data: original, show: true });
            }}
          />
        )}

        {isOwner && allowChanges && (
          <Button
            label="Delete"
            leftIcon={{
              name: "delete",
              className: "dropdown-content-icon"
            }}
            data-testid="attachment-delete-button"
            type="button"
            className="usa-button usa-button--outline attachment-delete-link"
            onClick={() => {
              setOpen(false);
              setDeleteState({ data: original, show: true });
            }}
          />
        )}
      </div>
    </div>
  );
};

AttachmentRowAction.defaultProps = {
  allowChanges: true,
};

AttachmentRowAction.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.string,
      description: PropTypes.string,
      name: PropTypes.string,
      contentURL: PropTypes.string,
      type: PropTypes.string,
      lastModified: PropTypes.number,
      size: PropTypes.number,
      createdByUser: PropTypes.string,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
    }),
  }).isRequired,
  allowChanges: PropTypes.bool,
};

export default AttachmentRowAction;
