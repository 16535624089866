import { useLazyQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import {
  APPROVE_USER_ACCESS,
  DENY_USER_ACCESS,
  GET_AGENCY,
  GET_BUREAU,
  GET_OFFICE,
  GET_USER_BY_ID,
  GET_USER_ROLE_SCOPES_BY_USER_ID,
} from '../../../../../services/data-layer';

const UserDetailContext = createContext({});
const useUserDetails = () => useContext(UserDetailContext);

const initialState = {
  user: null,
  userRoleScopes: null,
  agencyName: '',
  bureauName: '',
  officeName: '',
  feedback: null,
  isEditUserStatusOpen: false,
  editUserNewStatusId: '',
};

const actions = {
  setUser: 'SET_USER',
  setUserRoleScopes: 'SET_USER_ROLE_SCOPES',
  setAgencyName: 'SET_AGENCY_NAME',
  setBureauName: 'SET_BUREAU_NAME',
  setOfficeName: 'SET_OFFICE_NAME',
  setFeedback: 'SET_FEEDBACK',
  setIsEditUserStatusOpen: 'SET_IS_EDIT_USER_STATUS_OPEN',
};

const userDetailReducer = (state, { action, payload }) => {
  const {
    setUser,
    setUserRoleScopes,
    setAgencyName,
    setBureauName,
    setOfficeName,
    setFeedback,
    setIsEditUserStatusOpen,
  } = actions;

  switch (action) {
    case setUserRoleScopes: {
      return {
        ...state,
        userRoleScopes: payload,
      };
    }
    case setUser: {
      return {
        ...state,
        user: payload,
      };
    }
    case setAgencyName: {
      return {
        ...state,
        agencyName: payload,
      };
    }
    case setBureauName: {
      return {
        ...state,
        bureauName: payload,
      };
    }
    case setOfficeName: {
      return {
        ...state,
        officeName: payload,
      };
    }
    case setFeedback: {
      return {
        ...state,
        feedback: payload,
      };
    }
    case setIsEditUserStatusOpen: {
      return {
        ...state,
        error: null,
        isEditUserStatusOpen: payload,
        editUserNewStatusId: '',
      };
    }
    default:
      return state;
  }
};

const UserDetailsProvider = ({ children, ...props }) => {
  const [state, setDispatch] = useReducer(
    userDetailReducer,
    initialState,
    () => initialState,
  );

  const dispatch = (action, payload) => setDispatch({ action, payload });

  const { id } = useParams();
  const [error, setError] = useState();
  const [assignSuccess, setAssignSuccess] = useState({});

  const setFeedbackMessage = (value) => {
    dispatch(actions.setFeedback, value);
  };

  const [
    getUserById,
    { loading: loadingUser, refetch: refetchUserDetails },
  ] = useLazyQuery(GET_USER_BY_ID, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: () => {
      setFeedbackMessage({ type: 'error' });
      setError(true);
    },
    onCompleted: (data) => {
      dispatch(actions.setUser, { ...data.getUserById, id });
    },
  });

  const setUser = (value) => {
    dispatch(actions.setUser, value);
  };

  const [
    getUserRoleScopes,
    { loading: loadingUserRoleScopes, refetch: refetchUserRoleScopes },
  ] = useLazyQuery(GET_USER_ROLE_SCOPES_BY_USER_ID, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: () => {
      setFeedbackMessage({ type: 'error' });
      setError(true);
    },
    onCompleted: (data) => {
      dispatch(actions.setUserRoleScopes, data.getUserRoleScopes);
    },
  });

  // TODO: the current sequelize version lib the project has does not support composite foreign keys integration.
  // Update sequelize and sequelize-typescript versions and refactor breaking changes in user models
  // to allow this capability in afp-shared-svc
  const [getAgency] = useLazyQuery(GET_AGENCY, {
    onError: () => {
      // TODO
    },
    onCompleted: (data) => {
      dispatch(actions.setAgencyName, data.getAgency?.name || '');
    },
  });

  const [getBureau] = useLazyQuery(GET_BUREAU, {
    onError: () => {
      // TODO
    },
    onCompleted: (data) => {
      dispatch(actions.setBureauName, data.getBureau?.name || '');
    },
  });

  const [getOffice] = useLazyQuery(GET_OFFICE, {
    onError: () => {
      // TODO
    },
    onCompleted: (data) => {
      dispatch(actions.setOfficeName, data.getOfficeNext?.officeName || '');
    },
  });

  const [denyUserAccess] = useMutation(DENY_USER_ACCESS, {
    onError: () => {
      setFeedbackMessage({ type: 'error' });
    },
    onCompleted: (data) => {
      if (data.denyUserAccess) {
        setFeedbackMessage({
          type: 'success',
          message: (
            <span>
              The user status is successfully updated to&nbsp;
              <b>denied</b>. The user account will be deleted after 90 days.
            </span>
          ),
        });
      }
      refetchUserDetails();
    },
  });

  const [approveUserAccess] = useMutation(APPROVE_USER_ACCESS, {
    onError: () => {
      setFeedbackMessage({ type: 'error' });
    },
    onCompleted: (data) => {
      if (data.approveUserAccess) {
        setFeedbackMessage({
          type: 'success',
          message: (
            <span>
              User access has been successfully <b>approved</b>. Please assign
              the user appropriate roles.
            </span>
          ),
        });
      }
      refetchUserDetails();
    },
  });

  const setIsEditUserStatusOpen = (value) => {
    dispatch(actions.setIsEditUserStatusOpen, value);
  };

  useEffect(() => {
    if (id) {
      getUserById({ variables: { id } });
    }
  }, [id]);

  useEffect(() => {
    const { user } = state;
    if (user?.customerAttrs) {
      const {
        registeredAgencyCode: agencyCode,
        registeredBureauCode: bureauCode,
        registeredOfficeCode: officeCode,
      } = user.customerAttrs;

      if (agencyCode) {
        getAgency({ variables: { id: agencyCode } });

        if (bureauCode) {
          getBureau({
            variables: {
              id: bureauCode,
              agencyCode,
            },
          });

          if (officeCode) {
            getOffice({
              variables: {
                officeCode,
                bureauCode,
                agencyCode,
              },
            });
          }
        }
      }
    }
  }, [state]);

  const assignRoleSuccess = (isSuccessful, label) => {
    setAssignSuccess({ value: isSuccessful, label });
  };

  const setInternalError = () => setError(true);

  return (
    <UserDetailContext.Provider
      value={{
        user: state.user,
        userId: id,
        agencyName: state.agencyName,
        bureauName: state.bureauName,
        officeName: state.officeName,
        vendor: state.vendor,
        loadingUser,
        error,
        setUser,
        getUserById,
        getUserRoleScopes,
        loadingUserRoleScopes,
        userRoleScopes: state.userRoleScopes,
        denyUserAccess,
        approveUserAccess,
        assignSuccess,
        assignRoleSuccess,
        refetchUserDetails,
        refetchUserRoleScopes,
        setInternalError,
        feedback: state.feedback,
        setFeedbackMessage,
        setIsEditUserStatusOpen,
        isEditUserStatusOpen: state.isEditUserStatusOpen,
        ...props,
      }}
    >
      {children}
    </UserDetailContext.Provider>
  );
};

UserDetailsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { UserDetailsProvider as default, useUserDetails };
