import { gql } from '@apollo/client';

export const AttachmentFields = gql`
  fragment AttachmentFields on Attachment {
    id
    name
    description
    contentURL
    type
    size
    lastModified
    createdAt
    updatedAt
    createdByUser
    user {
      fullName
    }
  }
`;

export const GET_ATTACHMENTS = gql`
  query GetAttachments(
    $filter: AttachmentFilter
    $limit: Float
    $offset: Float
    $order: OrderBy
  ) {
    getAttachments(
      filter: $filter
      limit: $limit
      offset: $offset
      order: $order
    ) {
      rows {
        ...AttachmentFields
      }
      count
      hasMore
    }
  }
  ${AttachmentFields}
`;

export const GET_AWS_SIGNED_UPLOAD_URL = gql`
  query GenerateUploadSignedURL($path: String!, $type: String!) {
    generateUploadSignedURL(path: $path, type: $type) {
      signedURL
      fileKey
    }
  }
`;

export const GET_AWS_SIGNED_READ_URL = gql`
  query GenerateReadSignedURL($fileKey: String!) {
    generateReadSignedURL(fileKey: $fileKey)
  }
`;

export const DELETE_ATTACHMENT = gql`
  mutation DeleteAttachment($id: Float!) {
    deleteAttachment(id: $id)
  }
`;

export const UPDATE_ATTACHMENT_DESCRIPTION = gql`
  mutation UpdateAttachmentDescription($id: Float!, $description: String!) {
    updateAttachmentDescription(id: $id, description: $description) {
      id
      name
      description
      contentURL
      type
      size
      lastModified
      createdAt
      updatedAt
      createdByUser
    }
  }
`;
