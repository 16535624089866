import React from 'react';
import PropTypes from 'prop-types';
import {
  propTypeFormContext,
  StandardFieldset,
  StandardLabeledTextbox,
  StandardLabeledCheckbox,
  StandardLabelledReadonly,
} from '../../components/Forms';
import { Types } from '../admin-console/users/constants/user-details-constants';

export const UserProfileBasicInfo = ({ formContext, userTypeId }) => {
  return (
    <StandardFieldset label="Personal Information" name="personalInformation">
      <StandardLabelledReadonly
        formContext={formContext}
        name="email"
        label="Email"
      />
      <StandardLabeledTextbox
        formContext={formContext}
        name="firstName"
        label="First name"
        maxLength={50}
        required
      />
      <StandardLabeledTextbox
        formContext={formContext}
        name="lastName"
        label="Last name"
        maxLength={50}
        required
      />
      {userTypeId === Types.CUSTOMER && (
        <StandardLabeledCheckbox
          formContext={formContext}
          name="isContractor"
          label="I am a non-federal employee working under contract with a federal agency"
        />
      )}
    </StandardFieldset>
  );
};

UserProfileBasicInfo.propTypes = {
  formContext: propTypeFormContext.isRequired,
  userTypeId: PropTypes.string.isRequired,
};

export default UserProfileBasicInfo;
