import Regex from '../../utilities/RegexUtils';
import { isGSAEmail } from '../../utilities/StringUtils';

const validationOptions = {
  email: {
    required: 'Please provide your email',
    pattern: {
      value: Regex.email,
      message: 'Email is not valid',
    },
    validate: {
      isGSAEmail: (em) => !isGSAEmail(em) || 'Please log in with GSA email',
    },
  },
  confirmEmail: {
    required: 'Please re-type your email',
    pattern: {
      value: Regex.email,
      message: 'Email is not valid',
    },
  },
  firstName: {
    required: 'Please provide your first name',
    maxLength: {
      value: 51,
      message: 'First name should be 50 characters or fewer',
    },
    pattern: {
      value: Regex.name,
      message: 'First name is not valid',
    },
  },
  lastName: {
    required: 'Please provide your last name',
    maxLength: {
      value: 51,
      message: 'Last name should be 50 characters or fewer',
    },
    pattern: {
      value: Regex.name,
      message: 'Last name is not valid',
    },
  },
  userType: { required: 'Please select an account type' },
  terms: { required: 'Please agree to terms and conditions' },
};

export default validationOptions;
