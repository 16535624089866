import React from 'react';
import { PageHeader } from '../../../components';

const RolesHeader = () => {
  return (
    <>
      <PageHeader title="Roles" id="roles-page-header">
        <p>
          A role is a collection of permissions and scopes to grant users the
          appropiate level of access to the site. The published roles will
          appear below, where you can view the details, deactivate/reactivate or
          edit the role.
        </p>
      </PageHeader>
    </>
  );
};

export default RolesHeader;
