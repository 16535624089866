import React from 'react';
import { useUserDetails } from '../../user-details/providers/user-details-provider';
import '../../user-details/components/user-header.scss';
import UserName from '../../user-details/components/user-name';
import UserStatus from '../../user-details/components/user-status';

export const UserHeader = () => {
  const { user } = useUserDetails();
  const fullName = user ? user.fullName : '';
  return (
    <>
      <div className="desktop:display-flex desktop:flex-justify">
        <div className="desktop:display-flex">
          <UserName>{fullName}</UserName>
          <UserStatus className="flex-align-self-center padding-bottom-2 desktop:padding-left-5 desktop:padding-bottom-0" />
        </div>
      </div>     
    </>
  );
};

export default UserHeader;
