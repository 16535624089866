import React from 'react';
import { Alert } from '@gsa/afp-component-library';
import { InternalError } from '@gsa/afp-shared-ui-utils';
import { useUserInvitation } from '../providers';

const Types = {
  success: 'success',
  error: 'error',
};

const alertType = (feedback, onClose) => {
  const { type, title, message } = feedback || {};
  if (!type) return null;

  if (type === Types.error && !message) {
    return <InternalError id="feedback-user-error-message" />;
  }
  return (
    <Alert
      heading={title}
      type={type}
      className="display-flex flex-justify"
      data-testid="feedback-user-success-message"
      focused
      slim
      onClose={onClose}
    >
      {message}
    </Alert>
  );
};

const UserInvitationFeedbackMessage = () => {
  const { feedback, setFeedbackMessage } = useUserInvitation();
  const handleClose = () => {
    setFeedbackMessage(false);
  };
  return alertType(feedback, handleClose);
};

export default UserInvitationFeedbackMessage;
