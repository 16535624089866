import React, { useState, useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import {
  RadioButton,
  FormGroup,
  SelectDropdown,
  ErrorMessage,
} from '@gsa/afp-component-library';
import { useLazyQuery } from '@apollo/client';
import { useCreateInvitation } from '../providers/create-invitation-provider';
import { Types } from '../../users/constants/user-constants';
import { StandardFieldset } from '../../../components/Forms';
import {
  GET_VENDOR_MANAGERS,
  GET_AGENCIES,
} from '../../../services/data-layer';
import VendorNameTypeAhead from './vendor-name-typeahead';
import { isFeatureEnabled } from '../../../utilities/feature-toggle';

const InvitationOrganizationForm = () => {
  const { watch, register, errors, control, setValue } = useFormContext();
  const [userType, setUserType] = useState(Types.VENDOR);
  const [vendorManagers, setVendorManagers] = useState([]);
  const [agencies, setAgencies] = useState([]);
  const { showLoader, hideLoader, setMessage } = useCreateInvitation();
  const isVendorFeatureEnabled = isFeatureEnabled('vendors');
  const formfields = {
    vendorFleetNo: 'vendorFleetNo',
    vendorManager: 'vendorManager',
    agencyCode: 'agencyCode',
    userType: 'userType',
  };

  const defaultOption = {
    value: '',
    label: '- Select -',
  };
  const defaultNoManagerOption = {
    value: '',
    label: 'There are no managers available for this organization',
  };
  const defaultNoSelectionOption = {
    value: '',
    label: 'Please select a Vendor',
  };

  const validationOptions = {
    vendorFleetNo: {
      required: 'Please select a Vendor name',
    },
    vendorManager: {},
    userType: {
      required: 'Please select a User Type',
    },
    agencyCode: {
      required: 'Please select an agency',
    },
  };

  const selectedVendor = watch('vendorFleetNo');

  // Form queries
  const [getVendorManagers, { loading: vendorsManagersLoading }] = useLazyQuery(
    GET_VENDOR_MANAGERS,
    {
      fetchPolicy: 'network-only',
      onCompleted: ({ getVendorManagers: data }) => {
        setVendorManagers(
          data.length
            ? [
                defaultOption,
                ...data.map(({ id, fullName, email }) => {
                  return { label: `${fullName} <${email}>`, value: id };
                }),
              ]
            : [defaultNoManagerOption],
        );
      },
      onError: () => {
        // do nothing
      },
    },
  );

  // Customer Info
  const [getAgencies, { loading: getAgenciesLoading }] = useLazyQuery(
    GET_AGENCIES,
    {
      onError: () => {
        setMessage({
          type: 'ISE',
          message: 'Internal Server Error - Unmanaged',
        });
      },
      onCompleted: (data) => {
        if (data?.getAgencies) {
          const options = [
            defaultOption,
            ...data.getAgencies.map((c) => ({
              value: c.id,
              label: c.name,
            })),
          ];
          setAgencies(options);
        }
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  useEffect(() => {
    // reset vendor manager dropdown
    setValue(formfields.vendorManager, defaultNoManagerOption.value);

    if (selectedVendor) {
      getVendorManagers({
        variables: { fleetVendorNumber: selectedVendor },
      });
    } else {
      setVendorManagers([defaultNoSelectionOption]);
    }
  }, [selectedVendor]);

  useEffect(
    () => (vendorsManagersLoading ? showLoader() : hideLoader()),
    [vendorsManagersLoading],
  );

  // Get roles on mounted event
  useEffect(() => {
    if (userType === Types.CUSTOMER) getAgencies();
  }, [userType]);

  return (
    <>
      <StandardFieldset label="User Type" name={formfields.userType}>
        <FormGroup className="margin-left-0" error={errors?.userType}>
          {errors?.userType && (
            <ErrorMessage id="user-type-errors">
              {errors.userType.message}
            </ErrorMessage>
          )}
          {isVendorFeatureEnabled ? (
            <RadioButton
              name={formfields.userType}
              label="Vendor"
              onChange={() => setUserType(Types.VENDOR)}
              checked={userType === Types.VENDOR}
              value={Types.VENDOR}
              aria-invalid={errors?.userType ? 'true' : 'false'}
              aria-describedby={
                errors?.userType ? 'user-type-errors' : undefined
              }
              inputRef={register(validationOptions.userType)}
              required
            />
          ) : null}
          <RadioButton
            name={formfields.userType}
            label="Customer"
            onChange={() => setUserType(Types.CUSTOMER)}
            checked={userType === Types.CUSTOMER}
            value={Types.CUSTOMER}
            aria-invalid={errors?.userType ? 'true' : 'false'}
            aria-describedby={errors?.userType ? 'user-type-errors' : undefined}
            inputRef={register(validationOptions.userType)}
            required
          />
        </FormGroup>
      </StandardFieldset>
      {isVendorFeatureEnabled && userType === Types.VENDOR && (
        <StandardFieldset label="Vendor Information" name="vendorInfo">
          <Controller
            name={formfields.vendorFleetNo}
            control={control}
            rules={validationOptions.vendorFleetNo}
            defaultValue=""
            render={({ ref, ...fieldProps }) => {
              return (
                <VendorNameTypeAhead
                  onVendorSelection={(v) => {
                    fieldProps.onChange(v?.fleetVendorNumber);
                  }}
                  errorMessage={
                    errors?.vendorFleetNo ? errors.vendorFleetNo.message : null
                  }
                />
              );
            }}
          />
          <Controller
            name={formfields.vendorManager}
            control={control}
            defaultValue=""
            render={({ ref, ...fieldProps }) => {
              return (
                <SelectDropdown
                  {...fieldProps}
                  label={<span className="text-bold">Manager</span>}
                  name={formfields.vendorManager}
                  id={formfields.vendorManager}
                  options={vendorManagers}
                  className="maxw-full"
                />
              );
            }}
          />
        </StandardFieldset>
      )}
      {userType === Types.CUSTOMER && !getAgenciesLoading && (
        <StandardFieldset label="Customer Information" name="customerInfo">
          <Controller
            name={formfields.agencyCode}
            control={control}
            defaultValue=""
            render={({ ref, ...fieldProps }) => {
              return (
                <SelectDropdown
                  {...fieldProps}
                  label={<span className="text-bold">Agency</span>}
                  name={formfields.agencyCode}
                  id={formfields.agencyCode}
                  options={agencies}
                  className="maxw-full"
                />
              );
            }}
          />
        </StandardFieldset>
      )}
    </>
  );
};

export default InvitationOrganizationForm;
