import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Spinner } from '@gsa/afp-component-library';
import { useMutation } from '@apollo/client';
import {
  InternalError,
  useAppAbility,
  useCurrentUser
} from '@gsa/afp-shared-ui-utils';
import { RequiredFieldsHintAlt } from '../../../components/Required/RequiredFieldsHint';
import { useCreateInvitation, useUserInvitation } from '../providers';
import InvitationPersonalInfoForm from './invitation-personal-info-form';
import InvitationOrganizationForm from './invitation-organization-form';
import { CREATE_USER_INVITATION } from '../../../services/data-layer';
import InvitationOrganizationVendorForm from './invitation-organization-vendor-form';
import { Types } from '../../users/constants/user-constants';
import InvitationCustomerForm from './invitation-customer-form';
import { canManageAll } from '../../users/authorization';

const CreateInvitationModal = () => {
  const methods = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    shouldFocusError: true,
  });
  const ability = useAppAbility();
  const { currentUser } = useCurrentUser();
  const { setFeedbackMessage } = useUserInvitation();
  const {
    loadingCount,
    message,
    showLoader,
    hideLoader,
    setMessage,
    closeModal,
  } = useCreateInvitation();

  const showISE = message?.type === 'ISE';
  const userTypeIs = (type) => {
    return currentUser?.userType?.id === type;
  };

  const [createInvitation, { loading: creatingUserProcessing }] = useMutation(
    CREATE_USER_INVITATION,
    {
      onError: (err) => {
        switch (err.message) {
          // TODO: implement error codes
          case 'User already exists':
          case 'Invitation already exists':
            setMessage({
              type: 'warning',
              message: 'User or Invitation already exists',
            });
            break;
          default:
            setMessage({
              type: 'ISE',
              message: 'Internal Server Error - Unmanaged',
            });
            break;
        }
      },
      onCompleted: (data) => {
        closeModal();
        setFeedbackMessage({
          type: 'success',
          message: (
            <>
              <strong> {data.addUserInvitation.fullName}</strong> is
              successfully invited.
            </>
          ),
        });
      },
      refetchQueries: ['GetUserInvitations'],
    },
  );

  useEffect(
    () => (creatingUserProcessing ? showLoader() : hideLoader()),
    [creatingUserProcessing],
  );

  const onSubmit = (data) => {
    const {
      firstName,
      lastName,
      email,
      userType,
      vendorFleetNo,
      vendorManager,
      agencyCode,
    } = data;
    const type = userType || currentUser?.userType?.id;

    const userInvitationInput = {
      firstName,
      lastName,
      email,
      userTypeId: parseInt(type, 10), // Assuming it's for vendor only for now
      managerId: vendorManager?.length ? vendorManager : null,
      vendorAttrsInput: null,
      customerAttrsInput: null,
    };

    if (type === Types.VENDOR) {
      userInvitationInput.vendorAttrsInput = {
        fleetVendorNo: vendorFleetNo,
      };
    }
    if (type === Types.CUSTOMER) {
      userInvitationInput.customerAttrsInput = {
        agencyCode,
      };
    }

    setMessage(null); // Reset the message

    createInvitation({
      variables: {
        userInvitationInput,
      },
    });
  };

  const userTypeForm = () => {
    if (canManageAll(ability)) {
      return <InvitationOrganizationForm />;
    }
    if (userTypeIs(Types.CUSTOMER)) {
      return <InvitationCustomerForm />;
    }
    if (userTypeIs(Types.VENDOR)) {
      return <InvitationOrganizationVendorForm />;
    }
    return null;
  };

  return (
    <section className="mobile-lg:grid-col-9">
      {showISE && <InternalError />}
      {loadingCount > 0 && (
        <Spinner aria-busy="true" className="loading_backdrop" size="large" />
      )}
      <p>
        Please fill out the following information to invite new user for your
        organization. This invitation will expire 72 hours after being sent.
      </p>
      <RequiredFieldsHintAlt />
      <FormProvider {...methods}>
        <form
          noValidate
          onSubmit={methods.handleSubmit(onSubmit)}
          id="create-user-invitation-form"
        >
          <InvitationPersonalInfoForm />
          {userTypeForm()}
        </form>
      </FormProvider>
    </section>
  );
};

export default CreateInvitationModal;
