import React from 'react';
import { FilterPanel } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import { getTaskTypeOptions } from './constants';

const TaskManagerFilters = ({ setFilters, defaultFilters }) => {

  const { FilterPanel: TaskManagerFilterPanel } = FilterPanel;


  // eslint-disable-next-line
  let initialFilterStructure = [
    {
      key: 'taskTypeId', 
      title: 'Task Type', 
      type: 'select', 
      placeholder: '',
      value: '2', 
      permanent: true, 
      operator: '$exact', 
      options: getTaskTypeOptions('2'), // SPR tasks
      expanded: true,
      hideClear: true,
    },
    {
      key: 'status',
      title: 'Task Status',
      type: 'multiselect',
      value: ['To-Do', 'In progress'],
      options: [
        { value: 'To-Do', label: 'To-Do' },
        { value: 'In progress', label: 'In progress' },
        { value: 'Closed', label: 'Done' },
      ],
      permanent: false,
      operator: '$in',
      expanded: true,
      hideClear: true,
    },
    {
      key: 'dueDate',
      title: 'Due Date',
      type: 'select',
      placeholder: '',
      value: '',
      permanent: false,
      operator: '$between',
      options: [
        { value: '', label: 'All dates' },
        { value: '3 days', label: '3 days', defaultValue: true },
        { value: '7 days', label: '7 days', defaultValue: true },
        { value: '30 days', label: '30 days', defaultValue: true },
      ],
      expanded: true,
      hideClear: true,
    },
  ];


  const handleFiltersChange = (updatedFilters) => {
    const filterByKeys = updatedFilters?.reduce(
      (filterObj, currentFilterObj) => {
        return { ...filterObj, [currentFilterObj?.key]: currentFilterObj };
      },
      {},
    );

    const appliedFilters = {
      operator: '$and',
      conditions: [],
    };

    if (filterByKeys?.taskTypeId?.value) {
      appliedFilters.conditions.push({
        key: `taskTypeId`,
        operator: '$exact',
        value: filterByKeys?.taskTypeId?.value,
      });
    }

    if (filterByKeys?.status?.value) {
      appliedFilters.conditions.push({
        key: 'status',
        operator: '$in',
        value: filterByKeys?.status?.value,
      });
    }

    if (filterByKeys?.dueDate?.value) {
      const value = filterByKeys?.dueDate?.value;
      appliedFilters.conditions.push({
        key: 'dueDate',
        operator: '$between',
        value,
      });
    }
    setFilters({ ...appliedFilters });
  };



  return (
    <TaskManagerFilterPanel
      clearButtonLabel="Reset All"
      filterStructure={initialFilterStructure}
      model="Task"
      setQueryFiltersState={handleFiltersChange}
      showClearIcon
      handleClearAll={() => {
        setFilters(defaultFilters);
      }}
    />
  );
};

TaskManagerFilters.propTypes = {
  defaultFilters: PropTypes.shape({
    operator: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.array,
    ]),
    key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  setFilters: PropTypes.func.isRequired,
};

export default TaskManagerFilters;
