import {
  isSupportedCountry,
  formatPhoneNumberIntl,
} from 'react-phone-number-input';

const formatPhoneNumber = (phone, country) => {
  let formattedPhone = phone.match(/\d/g)?.join('');

  if (country === 'US') {
    if (formattedPhone?.length === 10 || formattedPhone?.length === 11) {
      if (formattedPhone.length === 11 && formattedPhone[0] !== '1')
        return phone;
      if (formattedPhone.length === 10 && formattedPhone[0] === '1')
        return phone;

      formattedPhone = `+${
        formattedPhone[0] === '1' ? formattedPhone.slice(1) : formattedPhone
      }`;
      const matches = formattedPhone.match(/(\d{3})(\d{3})(\d{4})/);

      return `${matches[1]}-${matches[2]}-${matches[3]}`;
    }

    return phone;
  }

  try {
    if (isSupportedCountry(country)) {
      return formatPhoneNumberIntl(`+${formattedPhone}`);
    }
    return phone;
  } catch {
    return phone;
  }
};

export default formatPhoneNumber;
