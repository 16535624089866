import { useLazyQuery } from '@apollo/client';
import { Breadcrumbs, Spinner } from '@gsa/afp-component-library';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { InternalError } from '@gsa/afp-shared-ui-utils';
import { CURRENT_USER } from '../../services/data-layer';
import { UserProfileForm } from './user-profile-form';
import UserInformation from './readonly/user-profile-locked';
import { Status } from '../admin-console/users/constants/user-details-constants';
import './userprofile.scss';

/**
 * from result of GraphQL Current user call, display the current user's info
 * @returns renders the users profile
 */
const UserProfile = () => {
  const [invalidState, _setInvalidState] = useState(false);
  const setInvalidState = (isInvalid, message) => {
    // Extra debugging for when unit tests fail    
    _setInvalidState(isInvalid, message);
  };
  const [currentUser, setCurrentUser] = useState(null);
  const [getCurrentUser, { loading: isCurrentUserLoading }] = useLazyQuery(
    CURRENT_USER,
    {
      onError: (err) => setInvalidState(true, `getCurrentUser|${err}`),
      onCompleted: (data) => {
        return data && setCurrentUser(data.me)
      },
    },
  );

  useEffect(() => {
    getCurrentUser();
  }, []);

  const busy = isCurrentUserLoading;

  return (
    <div>
      <Breadcrumbs
        trail={[
          <Link style={{ textDecoration: 'underline' }} to="/home">
            Home
          </Link>,
        ]}
        current="Profile"
      />
      {busy && (
        <Spinner aria-busy="true" className="loading_backdrop" size="large" />
      )}
      {invalidState &&
        <InternalError />}
      {!isCurrentUserLoading && currentUser && (
        currentUser.status?.id === Status.PENDING_PROFILE ?
          <UserProfileForm
            currentUser={currentUser}
            setInvalidState={setInvalidState}
          /> :
          <UserInformation
            currentUser={currentUser}
            setInvalidState={setInvalidState}
          />

      )}
    </div>
  );
};

export default UserProfile;
