import { Breadcrumbs } from '@gsa/afp-component-library';
import React from 'react';
import { Link } from 'react-router-dom';
import { UserFeedbackMessage, UserHeader, UserTable } from './components';
import { UserFilterProvider, UserProvider } from './providers';

const Users = () => {
  return (
    <UserProvider>
      <UserFilterProvider>
        <div className="grid-col">
          <Breadcrumbs
            trail={[
              <Link style={{ textDecoration: 'underline' }} to="/home">
                Home
              </Link>,
              <Link style={{ textDecoration: 'underline' }} to="/admin">
                Admin Console
              </Link>,
            ]}
            current="Users"
          />
          <UserFeedbackMessage />
          <UserHeader />
          <UserTable />
        </div>
      </UserFilterProvider>
    </UserProvider>
  );
};

export default Users;
