import React from 'react';
import { Spinner } from '@gsa/afp-component-library';
import './login-redirect-feedback.scss';

// TODO allow Spinner to take a feedback text
const LoginRedirectFeedback = () => {
    return (
      <div
        data-testid="login-redirect-feedback"
        className="login-redirect_overlay"
        aria-busy="true"
      >
        <div className="login-redirect_spinner">
          <p className="title-m-bold">Please wait. You&apos;re being redirected.</p>
          <Spinner size="large" />
        </div>
      </div>
    );
}

export default LoginRedirectFeedback;