import React, { useState } from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';
import { FilterPanel } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import { useVendorListing } from '../../vendor-listing-provider';
import {
  GET_VENDOR_LIST_FILTERS,
  GET_VENDOR_TYPE_AHEAD_OPTIONS,
} from '../../vendor-listing.gql';
import {
  FLEET_STATUS_OPTIONS,
} from '../../../constants';
import { GET_COUNTRIES } from '../../../../services/data-layer';

const VendorListingFilter = ({ currentFilters, setFilters }) => {
  // load country and state options from db
  const { data: countriesData } = useQuery(GET_COUNTRIES);
  const countriesList = countriesData?.getCountries || [];
  const defaultFilter = {
    conditions: [
      {
        key: '',
        operator: '',
        value: '',
      },
    ],
    operator: '$and',
  };

  const {
    setVendorFilters,
    setMessage,
    vendorFilters,
  } = useVendorListing();
  const { stateFilter } = vendorFilters;
  const [typeaheadData, setTypeaheadData] = useState([]);

  useQuery(GET_VENDOR_LIST_FILTERS, {
    fetchPolicy: 'network-only',
    onError: () =>
      setMessage('error', 'Failed to return one or more filter options'),
    onCompleted: (data) => {
      setVendorFilters(data);
    },
  });

  const vendorTypeOptions = [
    { label: '', value: '' },
    ...vendorFilters?.vendorTypeFilter,
  ].sort((a, b) => (a.label < b.label ? -1 : 1));

  const initialFilterStructure = [
    {
      key: 'vendorName',
      title: 'Name',
      type: 'typeahead',
      value: '',
      operator: '$exact',
      expanded: false,
      hideClear: true,
      customFieldProps: { inputCharNum: 2 },
    },
    {
      key: 'fleetVendorNumber',
      title: 'Fleet Vendor Number',
      type: 'typeahead',
      value: '',
      operator: '$exact',
      expanded: false,
      hideClear: true,
    },
    {
      key: 'vendorTypeCode',
      title: 'Vendor type',
      type: 'select',
      options: vendorTypeOptions,
      value: '',
      operator: '$in',
      expanded: false,
      hideClear: true,
    },
    {
      key: 'fleetStatus',
      title: 'Fleet Status',
      type: 'select',
      options: FLEET_STATUS_OPTIONS,
      value: '',
      operator: '$exact',
      expanded: false,
      hideClear: true,
    },
    {
      key: 'countryId',
      title: 'Country',
      type: 'select',
      options: [
        { value: '', label: '' },
        ...countriesList
          ?.map((c) => ({
            value: c.id,
            label: c.countryName,
          }))
          .sort((a, b) => (a.label < b.label ? -1 : 1)),
      ],
      value: '',
      operator: '$exact',
      expanded: false,
      hideClear: true,
    },
    {
      key: 'city',
      title: 'City',
      type: 'typeahead',
      value: '',
      operator: '$exact',
      expanded: false,
      hideClear: true,
    },
    {
      key: 'stateId',
      title: 'State',
      type: 'select',
      options: [{ value: '', label: '' }, ...stateFilter],
      value: '',
      operator: '$exact',
      expanded: false,
      hideClear: true,
    },
  ];

  const { FilterPanel: VendorLisitngFilterPanel } = FilterPanel;

  const handleClearAll = () => {
    setFilters(defaultFilter);
  };

  const handleFiltersChange = (filters) => {
    const vendorNameFilter = filters.find(
      (filter) => filter.key === 'vendorName',
    );
    const fleetVendorNumberFilter = filters.find(
      (filter) => filter.key === 'fleetVendorNumber',
    );
    const fleetStatusFilter = filters.find(
      (filter) => filter.key === 'fleetStatus',
    );
    const vendorTypeFilter = filters.find(
      (filter) => filter.key === 'vendorTypeCode',
    );

    const countryFilter = filters.find((filter) => filter.key === 'countryId');
    const vendorStateFilter = filters.find(
      (filter) => filter.key === 'stateId',
    );
    const vendorCityFilter = filters.find((filter) => filter.key === 'city');

    const updatedFilters = {
      ...currentFilters,
      conditions: [],
    };
    if (vendorNameFilter) {
      updatedFilters.conditions.push(vendorNameFilter);
    }
    if (fleetVendorNumberFilter) {
      updatedFilters.conditions.push(fleetVendorNumberFilter);
    }
    if (fleetStatusFilter) {
      updatedFilters.conditions.push(fleetStatusFilter);
    }
    if (vendorTypeFilter) {
      updatedFilters.conditions.push(vendorTypeFilter);
    }
    if (countryFilter) {
      updatedFilters.conditions.push(countryFilter);
      updatedFilters.conditions.push({
        key: 'contactType',
        operator: '$exact',
        value: 'VendorPhysicalContact',
      });
    }
    if (vendorStateFilter) {
      updatedFilters.conditions.push(vendorStateFilter);
      updatedFilters.conditions.push({
        key: 'contactType',
        operator: '$exact',
        value: 'VendorPhysicalContact',
      });
    }
    if (vendorCityFilter) {
      updatedFilters.conditions.push(vendorCityFilter);
      updatedFilters.conditions.push({
        key: 'contactType',
        operator: '$exact',
        value: 'VendorPhysicalContact',
      });
    }
    setFilters(updatedFilters);
  };

  const [getVendorTypeAheadOptions] = useLazyQuery(
    GET_VENDOR_TYPE_AHEAD_OPTIONS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        if (data.getVendorTypeAheadOptions) {
          const { key, options } = data.getVendorTypeAheadOptions;
          // return unique options only.
          const uniqueOptions = Array.from(new Set(options));
          setTypeaheadData({
            field: key,
            values: uniqueOptions,
          });
        }
      },
    },
  );

  const handleFetchVendorTypeaheads = ({ variables: queryVariables }) => {
    const selectedKey = queryVariables?.field;
    const filterObj = queryVariables?.filters[0]?.conditions?.find(
      (filter) => filter.key === selectedKey,
    );

    getVendorTypeAheadOptions({
      variables: {
        key: selectedKey,
        search: filterObj.value,
      },
    });
  };

  return (
    <>
      <VendorLisitngFilterPanel
        clearButtonLabel="Reset All"
        filterStructure={initialFilterStructure}
        setQueryFiltersState={handleFiltersChange}
        handleClearAll={handleClearAll}
        fetchTypeaheads={handleFetchVendorTypeaheads}
        typeaheadData={typeaheadData}
        showClearIcon
      />
    </>
  );
};

VendorListingFilter.propTypes = {
  currentFilters: PropTypes.arrayOf(
    PropTypes.shape({
      operator: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      conditions: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
      ]),
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }),
  ).isRequired,
  setFilters: PropTypes.func.isRequired,
};

export default VendorListingFilter;
