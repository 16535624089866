import { connectModal, Modal, useModal } from '@gsa/afp-component-library';
import classnames from 'classnames';
import FocusTrap from 'focus-trap-react';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

const AttachmentModal = ({
  id,
  title,
  className,
  show,
  actions,
  onClose,
  children,
}) => {
  const [activeTrap, setActiveTrap] = useState(false);

  const { isOpen, openModal, closeModal } = useModal();

  const classes = classnames('attachment-modal', className);

  const unmountTrap = () => {
    setActiveTrap(false);
  };

  useEffect(() => {
    if (show) {
      setActiveTrap(show);
      openModal();
    } else {
      closeModal();
    }

    return () => {
      setActiveTrap(false);
    };
  }, [show]);

  const AddModal = useMemo(
    () => () => (
      <Modal
        id={id}
        className={classes}
        title={title}
        onClose={() => {
          closeModal();
          onClose();
        }}
        actions={actions}
      >
        {children}
      </Modal>
    ),
    [show, actions],
  );

  const ConnectedModal = connectModal(AddModal);

  return activeTrap ? (
    <FocusTrap
      focusTrapOptions={{
        onDeactivate: unmountTrap,
        escapeDeactivates: false,
      }}
    >
      <div className="trap">
        <ConnectedModal isOpen={isOpen} onClose={closeModal} />
      </div>
    </FocusTrap>
  ) : (
    <ConnectedModal isOpen={isOpen} onClose={closeModal} />
  );
};

AttachmentModal.defaultProps = {
  id: 'attachment-modal',
  className: 'modal',
  title: null,
  children: null,
  actions: null,
  show: false,
  onClose: () => {
    // default empty function
  },
};
AttachmentModal.propTypes = {
  id: PropTypes.string,
  title: PropTypes.node,
  show: PropTypes.bool,
  actions: PropTypes.node,
  className: PropTypes.string,
  children: PropTypes.node,
  onClose: PropTypes.func,
};

export default AttachmentModal;
