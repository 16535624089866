import { Breadcrumbs } from '@gsa/afp-component-library';
import React from 'react';
import { Link } from 'react-router-dom';
import {
  UserInvitationFeedbackMessage,
  UserInvitationHeader,
  UserInvitationTable,
} from './components';
import {
  UserInvitationFilterProvider,
  UserInvitationProvider,
} from './providers';

const UserInvitations = () => {
  return (
    <UserInvitationProvider>
      <UserInvitationFilterProvider>
        <div className="grid-col">
          <Breadcrumbs
            trail={[
              <Link style={{ textDecoration: 'underline' }} to="/home">
                Home
              </Link>,
              <Link style={{ textDecoration: 'underline' }} to="/admin">
                Admin Console
              </Link>,
            ]}
            current="User Invitations"
          />
          <UserInvitationFeedbackMessage />
          <UserInvitationHeader />
          <UserInvitationTable />
        </div>
      </UserInvitationFilterProvider>
    </UserInvitationProvider>
  );
};

export default UserInvitations;
