import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import {
  GET_USER_TYPES,
  GET_USER_STATUSES,
  GET_ACTIVE_ROLES,
  GET_AGENCIES,
  GET_BUREAUS,
  GET_OFFICES,
} from '../../../services/data-layer';

const UserFilterContext = createContext({});
const useUserFilter = () => useContext(UserFilterContext);

const initialState = {
  error: '',
  userTypes: [],
  userStatuses: [],
  activeRoles: [],
  agencies: [],
  bureaus: [],
  offices: [],
  recertStatuses: [],
  filters: [],
};

const actions = {
  setUserTypes: 'SET_USER_TYPES',
  setUserStatuses: 'SET_USER_STATUSES',
  setActiveRoles: 'SET_USER_ROLES',
  setAgencies: 'SET_AGENCIES',
  setBureaus: 'SET_BUREAUS',
  setOffices: 'SET_OFFICES',
  setRecertificationStatuses: 'SET_RECERTIFICATION_STATUSES',
  setFilters: 'SET_FILTERS',
  setError: 'SET_ERROR',
};

const extractErrorMessage = (err) => err.message || 'Unknown Error';

const userFilterReducer = (state, { action, payload }) => {
  const {
    setUserTypes,
    setUserStatuses,
    setActiveRoles,
    setAgencies,
    setBureaus,
    setOffices,
    setRecertificationStatuses,
    setFilters,
    setError,
  } = actions;
  const {
    userTypes,
    userStatuses,
    activeRoles,
    agencies,
    bureaus,
    offices,
    recertStatuses,
    error,
  } = initialState;

  switch (action) {
    case setUserTypes: {
      return { ...state, error, userTypes: payload || userTypes };
    }
    case setUserStatuses: {
      return { ...state, error, userStatuses: payload || userStatuses };
    }
    case setActiveRoles: {
      return { ...state, error, activeRoles: payload || activeRoles };
    }
    case setAgencies: {
      return { ...state, error, agencies: payload || agencies };
    }
    case setBureaus: {
      return { ...state, error, bureaus: payload || bureaus };
    }
    case setOffices: {
      return { ...state, error, offices: payload || offices };
    }
    case setRecertificationStatuses: {
      return { ...state, error, recertStatuses: payload || recertStatuses };
    }
    case setFilters: {
      return {
        ...state,
        error,
        filters: {
          operator: '$and',
          conditions: payload || {},
        },
      };
    }
    case setError: {
      return { ...state, error: extractErrorMessage(payload) };
    }
    default:
      throw new Error('Invalid user filter action');
  }
};

const UserFilterProvider = ({ children, ...props }) => {
  const [state, setDispatch] = useReducer(
    userFilterReducer,
    initialState,
    () => initialState,
  );

  const dispatch = (action, payload) => setDispatch({ action, payload });

  // Get User Types Lazy.
  const [getUserTypes] = useLazyQuery(GET_USER_TYPES, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (err) => dispatch(actions.setError, err),
    onCompleted: (data) => dispatch(actions.setUserTypes, data?.getUserTypes),
  });

  // Get User Statuses Lazy.
  const [getUserStatuses] = useLazyQuery(GET_USER_STATUSES, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (err) => dispatch(actions.setError, err),
    onCompleted: (data) =>
      dispatch(actions.setUserStatuses, data?.getUserStatuses),
  });

  // Get User Roles Lazy.
  const [getActiveRoles] = useLazyQuery(GET_ACTIVE_ROLES, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (err) => dispatch(actions.setError, err),
    onCompleted: (data) =>
      dispatch(actions.setActiveRoles, data?.getActiveRoles),
  });

  // Get Agencies Lazy.
  const [getAgencies] = useLazyQuery(GET_AGENCIES, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (err) => dispatch(actions.setError, err),
    onCompleted: (data) => dispatch(actions.setAgencies, data?.getAgencies),
  });

  // Get Bureaus Lazy.
  const [getBureaus] = useLazyQuery(GET_BUREAUS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (err) => dispatch(actions.setError, err),
    onCompleted: (data) => dispatch(actions.setBureaus, data?.getBureaus),
  });

  // Get Offices Lazy.
  const [getOffices] = useLazyQuery(GET_OFFICES, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (err) => dispatch(actions.setError, err),
    onCompleted: (data) => dispatch(actions.setOffices, data?.getOfficesNext),
  });

  const getRecertStatuses = () => {
    dispatch(actions.setRecertificationStatuses, [
      { label: 'Past due', value: 'Past due' },
      { label: 'Recertified', value: 'Recertified' },
      { label: 'Pending', value: 'Pending' },
    ]);
  };

  const setFilters = (value) => dispatch(actions.setFilters, value);

  return (
    <UserFilterContext.Provider
      value={{
        ...state,
        ...props,
        getUserTypes,
        getUserStatuses,
        getActiveRoles,
        getAgencies,
        getBureaus,
        getOffices,
        getRecertStatuses,
        setFilters,
      }}
    >
      {children}
    </UserFilterContext.Provider>
  );
};

UserFilterProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { UserFilterProvider as default, useUserFilter };
