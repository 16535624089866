/* eslint-disable react/prop-types */
import React from 'react';
import {
  AFPTable,
  Pagination,
  Spinner,
  EmptyState,
} from '@gsa/afp-component-library';

const TableWrapper = ({ tableProps, paginationProps }) => {
  const { isPending, data } = tableProps || {};
  const isNoData = !data?.length;

  return (
    <>
      <AFPTable {...tableProps} />
      {!isNoData && <Pagination {...paginationProps} />}
      {isPending && <Spinner aria-busy="true" size="small" />}
      {isNoData && !isPending && (
        <div className="text-center margin-top-neg-2 height-full">
          <EmptyState
            hasBackground
            containerStyles="padding-y-6 height-full"
            topText={<p>No results found for your selected filters</p>}
          />
        </div>
      )}
    </>
  );
};

export default TableWrapper;
