import { DefaultLayout } from '@gsa/afp-component-library';
import { useCurrentUser, useNavigationMenu } from '@gsa/afp-shared-ui-utils';
import PropTypes from 'prop-types';
import React from 'react';

const Layout = ({ children }) => {
  const { currentUser, isLoggedIn } = useCurrentUser();

  const menu = useNavigationMenu();

  return (
    <DefaultLayout
      currentUser={isLoggedIn && currentUser}
      menu={[{ title: 'Home', linkUrl: '/home' }, ...menu]}
    >
      {children}
    </DefaultLayout>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
