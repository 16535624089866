import { Alert, Button } from '@gsa/afp-component-library';
import {
  useCurrentUser,
  FleetTechnicalSupportContact,
} from '@gsa/afp-shared-ui-utils';
import React, { forwardRef } from 'react';
import './signup.scss';

const ExistingAccountError = forwardRef((props, ref) => {
  const auth = useCurrentUser();

  const handleLoginExternal = async (e) => {
    e.preventDefault();
    await auth.loginUserExternal();
  };
  return (
    <div className="usa-prose">
      <Alert
        type="error"
        heading="Account already exists"
        id="existing-account-error"
        innerRef={ref}
        focused
        actions={
          <Button
            label="Log in using FAS ID"
            variant="primary"
            className="margin-top-2 margin-bottom-1 desktop:margin-left-6"
            id="external-okta-login"
            onClick={handleLoginExternal}
            data-testid="handle-external-login-existing-user"
          />
        }
      >
        <div>
          We noticed that you already have a GSA Fleet account. Please login
          using your FAS ID and password. For technical&nbsp; support,{' '}
          <FleetTechnicalSupportContact />.
        </div>
      </Alert>
    </div>
  );
});

export default ExistingAccountError;
