export const enDashUnicode = '\u2013';
export const emDashUnicode = '\u2014';

export const getFeatures = () => window?.AFP_CONFIG?.features || {};

export const SUBJECTS = {
  StandardItem: 'StandardItem',
  EquipmentCodes: 'EquipmentCodes',
  CatalogCodes: 'CatalogCodes',
  FVS: 'FederalVehicleStandards',
  Requirements: 'Requirements',
  PSOQuestionnaire: 'PSOQuestionnaire',
  FVSComments: 'FVSComments',
  Solicitation: 'Solicitation',
  Vendor: 'Vendor',
  AdminConsole: 'AdminConsole',
};

export const OPERATIONS = {
  View: 'view',
  Create: 'create',
  Update: 'update',
  Delete: 'delete',
  Approve: 'approve',
  Manage: 'manage',
};

export const USER_TYPE_ID = {
  Customer: '1',
  Vendor: '2',
  GSA: '3',
  Public: '4',
  System: '5',
};
