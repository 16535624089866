import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { startCase } from 'lodash';
import { Button, Modal, TextInput, Alert } from '@gsa/afp-component-library';
import { useOfficeManagementListing } from './office-management-listing-provider';
import { EDIT_OFFICE } from './office-management-listing.gql';

const ModalContent = () => {
  const {
    setMessage,
    refetchOfficeManagements,
    setEditOfficeModalState,
    selectedOffice,
  } = useOfficeManagementListing();

  const { handleSubmit } = useForm();

  const initalValues = {
    agencyCode: selectedOffice.agencyCode,
    agencyName: selectedOffice.agency.name,
    bureauCode: selectedOffice.bureauCode,
    bureauName: selectedOffice.bureau.name,
    officeCode: selectedOffice.officeCode,
    officeName: selectedOffice.officeName    
  };

  const initialMessages = {
    message: '',
    officeCodeError: '',
    officeNameError: '',
  };

  const [office, setOffice] = useState(initalValues);
  const [messages, setMessages] = useState(initialMessages);

  const [isShowAlert, setIsShowAlert] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const validateOfficeName = (e) => {
    if (e.target.value.trim().length === 0) {
      setMessages({ ...messages, officeNameError: 'Office name is required.' });
      return;
    }
    if (e.target.value.trim().length > 100) {
      setMessages({
        ...messages,
        officeNameError: 'Only 100 characters allowed.',
      });
    } else {
      setMessages({ ...messages, officeNameError: '' });
    }
  };

  const [editOffice] = useMutation(EDIT_OFFICE, {
    onError: (errors) => {
      const errorMessage = errors?.graphQLErrors[0]?.message;
      if (errorMessage?.trim() === 'office.PRIMARY must be unique') {
        setMessages({
          ...messages,
          message: 'Office code already exists for this agency/bureau.',
        });
      } else if (
        errorMessage.indexOf('fieldsvalidationError: officeName - ') > -1
      ) {
        setMessages({
          ...messages,
          message: errorMessage.replace(
            'fieldsvalidationError: officeName -',
            '',
          ),
        });
      } else {
        setMessages({ ...messages, message: errorMessage });
      }
      setIsSuccess(false);
      setIsShowAlert(true);
    },
    onCompleted: () => {
      setMessage(
        'success',
        `You have successfully edited ${office.officeCode}-${office.officeName}.`,
      );
      setOffice(office);
      setEditOfficeModalState(false);
      refetchOfficeManagements();
    },
  });

  const onSubmit = () => {
    if (messages.officeNameError.trim().length === 0) {
      editOffice({
        variables: {
          officeName: office.officeName,
          agencyCode: office.agencyCode,
          bureauCode: office.bureauCode,
          officeCode: office.officeCode,
        },
      });
    }
  };

  return (
    <>
      {isShowAlert && (
        <Alert
          type={isSuccess ? 'success' : 'error'}
          showClose
          onClose={() => {
            setOffice(office);
            setIsShowAlert(false);
          }}
        >
          {messages.message}
        </Alert>
      )}

      <form
        data-testid="edit-office-form"
        id="edit-office-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <p>
          Use this tool to edit and name offices within your agency/bureau to be
          displayed throughout GSAFleet.gov&apos;s Organization filters.
        </p>
        <p className="text-bold">Agency</p>
        <p>
          {office.agencyCode} -{' '}
          {startCase(office.agencyName?.toLocaleLowerCase())}
        </p>
        <p className="text-bold">Bureau</p>
        <p>
          {office.bureauCode} -{' '}
          {startCase(office.bureauName.toLocaleLowerCase())}
        </p>
        <p className="text-bold">Office code</p>
        <p>{office.officeCode}</p>
        <TextInput
          id="edit-office-name"
          label="Office name"
          name="officeName"
          value={office.officeName}
          required
          onBlur={validateOfficeName}
          onChange={(e) => {
            setOffice({ ...office, officeName: e.target.value });
          }}
          data-testid="office-name"
          characterLimit={100}
          errorMessage={messages.officeNameError}
        />
        <div className="margin-top-4 margin-bottom-8">
          <Button
            id="cancel-office-edit"
            type="button"
            variant="unstyled"
            className="margin-right-3"
            onClick={() => {
              setEditOfficeModalState(false);
            }}
            label="Cancel"
          />

          <Button
            id="edit-office"
            type="submit"
            form="edit-office-form"
            label="Submit"
          />
        </div>
      </form>
    </>
  );
};

const EditOfficeModal = () => {
  const { showEditOfficeModal, setEditOfficeModalState } =
    useOfficeManagementListing();

  return (
    showEditOfficeModal && (
      <div className="afp-modal-wrapper">
        <div className="afp-modal-overlay">
          <Modal
            title={<h2 className="margin-bottom-1 text-bold">Edit Office</h2>}
            variant="large"
            onClose={() => {
              setEditOfficeModalState(false);
            }}
          >
            <>
              <ModalContent />
            </>
          </Modal>
        </div>
      </div>
    )
  );
};

export default EditOfficeModal;
