import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import {
  GET_AGENCIES,
  GET_BUREAUS,
  GET_BUREAUS_BY_PERMISSION,
  GET_STATES,
  GET_CATALOGS_FOR_CATEGORY,
  GET_CUSTOMER_SUPERVISORS,
  GET_INTERNAL_SUPERVISORS,
  GET_OFFICES,
  GET_OFFICES_BY_PERMISSION,
  GET_PHONE_COUNTRY_CODES,
  GET_VENDOR_MANAGERS,
} from '../../services/data-layer';
import { Types } from '../admin-console/users/constants/user-details-constants';
import './userprofile.scss';

const defaultDropdownValue = '-';

export const useUserProfileData = (setInvalidState) => {
  const defaultDropdownOptions = [
    { value: defaultDropdownValue, label: 'Please select an option' },
  ];

  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [agenciesOptions, setAgenciesOptions] = useState(
    defaultDropdownOptions,
  );
  const [bureausOptions, setBureausOptions] = useState(defaultDropdownOptions);
  const [officesOptions, setOfficesOptions] = useState(defaultDropdownOptions);
  const [statesOptions, setStatesOptions] = useState(defaultDropdownOptions);
  const [supervisorsOptions, setSupervisorsOptions] = useState(
    defaultDropdownOptions,
  );
  const [vendorManagerOptions, setVendorManagerOptions] = useState(
    defaultDropdownOptions,
  );
  const [countriesOptions, setCountriesOptions] = useState(
    defaultDropdownOptions,
  );

  const [phoneCountryCodesOptions, setPhoneCountryCodesOptions] = useState(
    defaultDropdownOptions,
  );

  const mapOptions = (data, map, sort) => {
    if (!data) {
      return defaultDropdownOptions;
    }
    const options = data.map(map);
    if (sort) {
      options.sort(sort);
    }
    options.unshift(...defaultDropdownOptions);
    return options;
  };

  const [getCountries, { loading: getCountriesLoading }] = useLazyQuery(
    GET_CATALOGS_FOR_CATEGORY,
    {
      onError: () => {
        setInvalidState(true, 'getCountries');
      },
      onCompleted: (data) => {
        const options = mapOptions(
          data?.getCatalogsForCategory,
          (c) => ({
            value: c.code,
            label: c.displayText,
          }),
          (a, b) => a.label.localeCompare(b.label),
        );
        setCountriesOptions(options);
      },
    },
  );

  const [getStates, { loading: getStatesLoading }] = useLazyQuery(GET_STATES, {
    onError: () => {
      setInvalidState(true, 'getStates');
    },
    onCompleted: (data) => {
      const options = mapOptions(
        data?.getStates.filter((s) => s.isoCountryCode2 === 'US'),
        (c) => ({
          value: c.stateCode,
          label: `${c.stateCode} - ${c.stateName}`,
        }),
        (a, b) => a.label.localeCompare(b.label),
      );
      setStatesOptions(options);
    },
  });

  const [
    getPhoneCountryCodes,
    { loading: getPhoneCountryCodesLoading },
  ] = useLazyQuery(GET_PHONE_COUNTRY_CODES, {
    onError: () => {
      setInvalidState(true, 'getPhoneCountryCodes');
    },
    onCompleted: (data) => {
      const options = mapOptions(data?.getPhoneCountryCodes, (c) => ({
        value: c.description,
        label: c.description,
      }));
      setPhoneCountryCodesOptions(options);
    },
    fetchPolicy: 'cache-and-network',
  });

  const [getAgencies, { loading: getAgenciesLoading }] = useLazyQuery(
    GET_AGENCIES,
    {
      onError: () => {
        setInvalidState(true, 'getAgencies');
      },
      onCompleted: (data) => {
        const options = mapOptions(data?.getAgencies, (c) => ({
          value: c.id,
          label: c.name,
        }));
        setAgenciesOptions(options);
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  const [getBureausQuery, { loading: getBureausLoading }] = useLazyQuery(
    GET_BUREAUS,
    {
      onError: () => {
        setInvalidState(true, 'getBureausQuery');
      },
      onCompleted: (data) => {
        const options = mapOptions(data?.getBureaus, (c) => ({
          value: c.id,
          label: c.name,
        }));
        setBureausOptions(options);
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  const [
    getBureausByPermissionQuery,
    { loading: getBureausByPermLoading },
  ] = useLazyQuery(GET_BUREAUS_BY_PERMISSION, {
    onError: () => {
      setInvalidState(true, 'getBureausByPermissionQuery');
    },
    onCompleted: (data) => {
      const options = mapOptions(data?.getBureausByPermission, (c) => ({
        value: c.id,
        label: c.name,
      }));
      setBureausOptions(options);
    },
    fetchPolicy: 'cache-and-network',
  });

  const [getOfficesQuery, { loading: getOfficesLoading }] = useLazyQuery(
    GET_OFFICES,
    {
      onError: () => {
        setInvalidState(true, 'getOfficesQuery');
      },
      onCompleted: (data) => {
        const options = mapOptions(data?.getOfficesNext, (c) => ({
          value: c.officeCode,
          label: c.officeName,
        }));
        setOfficesOptions(options);
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  const [
    getOfficesByPermissionQuery,
    { loading: getOfficesByPermLoading },
  ] = useLazyQuery(GET_OFFICES_BY_PERMISSION, {
    onError: () => {
      setInvalidState(true, 'getOfficesByPermissionQuery');
    },
    onCompleted: (data) => {
      const options = mapOptions(data?.getOfficesByPermission, (c) => ({
        value: c.officeCode,
        label: c.officeName,
      }));
      setOfficesOptions(options);
    },
    fetchPolicy: 'cache-and-network',
  });

  const [
    getCustomerSupervisorsQuery,
    { loading: getCustomerSupervisorsLoading },
  ] = useLazyQuery(GET_CUSTOMER_SUPERVISORS, {
    onError: () => {
      setInvalidState(true, 'getCustomerSupervisorsQuery');
    },
    onCompleted: (data) => {
      const options = mapOptions(data?.getCustomerSupervisors, (c) => ({
        value: c.id,
        label: c.fullName,
      }));
      setSupervisorsOptions(options);
    },
    fetchPolicy: 'cache-and-network',
  });

  const [
    getVendorManagersQuery,
    { loading: getVendorManagersLoading },
  ] = useLazyQuery(GET_VENDOR_MANAGERS, {
    onError: () => {
      setInvalidState(true, 'getVendorManagersQuery');
    },
    onCompleted: (data) => {
      const options = mapOptions(data?.getVendorManagers, (vm) => ({
        value: vm.id,
        label: vm.fullName,
      }));
      setVendorManagerOptions(options);
    },
    fetchPolicy: 'cache-and-network',
  });

  // TODO implement server onCompleted response when displaying internal supervisor dropdown
  const [
    getInternalSupervisorsQuery,
    { loading: getInternalSupervisorsLoading },
  ] = useLazyQuery(GET_INTERNAL_SUPERVISORS, {
    onError: () => {
      setInvalidState(true, 'getInternalCustomerSupervisorsQuery');
    },
    onCompleted: () => {
      setSupervisorsOptions([]);
    },
    fetchPolicy: 'cache-only',
  });

  useEffect(() => {
    getAgencies();
    getCountries({
      variables: {
        category: 'ISOCountryCode2',
      },
    });
    getStates({
      variables: {
        category: 'ISOStateCode-US',
      },
    });
    getPhoneCountryCodes();
    setIsInitialLoad(false);
  }, []);

  const getBureaus = (agencyCode) => {
    if (agencyCode && agencyCode !== defaultDropdownValue) {
      getBureausQuery({ variables: { agencyCode } });
    } else {
      setBureausOptions(defaultDropdownOptions);
    }
  };

  const getBureausByPermission = (operation, subject, agencyCode) => {
    if (agencyCode && agencyCode !== defaultDropdownValue) {
      getBureausByPermissionQuery({
        variables: {
          agencyCode,
          operation,
          subject,
        },
      });
    } else {
      setBureausOptions(defaultDropdownOptions);
    }
  };

  const getOffices = (agencyCode, bureauCode) => {
    if (agencyCode && bureauCode && bureauCode !== defaultDropdownValue) {
      getOfficesQuery({ variables: { bureauCode, agencyCode, order: [['officeCode', 'asc']] } });
    } else {
      setOfficesOptions(defaultDropdownOptions);
    }
  };

  const getOfficesByPermission = (
    operation,
    subject,
    agencyCode,
    bureauCode,
  ) => {
    if (agencyCode && bureauCode && bureauCode !== defaultDropdownValue) {
      getOfficesByPermissionQuery({
        variables: {
          operation,
          subject,
          bureauCode,
          agencyCode,
        },
      });
    } else {
      setOfficesOptions(defaultDropdownOptions);
    }
  };

  const getSupervisors = (userTypeId, agencyCode, bureauCode, officeCode) => {
    if (userTypeId === Types.CUSTOMER) {
      getCustomerSupervisorsQuery({
        variables: {
          agencyCode: agencyCode === '-' ? '' : agencyCode,
          bureauCode: bureauCode === '-' ? '' : bureauCode,
          officeCode: officeCode === '-' ? '' : officeCode,
        },
      });
    }
    if (userTypeId === Types.GSA_EMPLOYEE) {
      getInternalSupervisorsQuery();
    }
  };

  const getVendorManagers = (fleetVendorNumber) => {
    getVendorManagersQuery({
      variables: {
        fleetVendorNumber,
      },
    });
  };

  const isLoading =
    isInitialLoad ||
    getAgenciesLoading ||
    getBureausLoading ||
    getOfficesLoading ||
    getCountriesLoading ||
    getStatesLoading ||
    getPhoneCountryCodesLoading ||
    getCustomerSupervisorsLoading ||
    getVendorManagersLoading ||
    getInternalSupervisorsLoading ||
    getBureausByPermLoading ||
    getOfficesByPermLoading;

  return [
    isLoading,
    agenciesOptions,
    bureausOptions,
    officesOptions,
    countriesOptions,
    statesOptions,
    supervisorsOptions,
    vendorManagerOptions,
    phoneCountryCodesOptions,
    getBureaus,
    getOffices,
    getSupervisors,
    getVendorManagers,
    getBureausByPermission,
    getOfficesByPermission,
  ];
};

export default useUserProfileData;
