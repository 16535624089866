import React from 'react';
import PropTypes from 'prop-types';
import Recall from './Recall';
import { TaskPropType } from '../../../utilities/types';

// Placeholder for unimplemented task types
const Generic = ({ task }) => <Recall task={task} />;

Generic.propTypes = {
  task: PropTypes.shape(TaskPropType).isRequired,
};

export default Generic;
