import { gql } from '@apollo/client';

export const commentResponse = gql`
  fragment CommentFields on Comment {
    id
    comment
    createdByUser
    author {
      fullName
    }
    createdAt
    deletedAt
    updatedByUser
    updatedAt
  }
`;

export const GET_TASK_COMMENTS = gql`
  query GetTaskComments(
    $id: Float!
    $limit: Float
    $offset: Float
    $order: OrderBy
  ) {
    getTaskComments(id: $id, limit: $limit, offset: $offset, order: $order) {
      rows {
        ...CommentFields
      }
      count
      hasMore
    }
  }
  ${commentResponse}
`;

export const CREATE_TASK_COMMENT = gql`
  mutation AddTaskComment($id: Float!, $comment: String!) {
    addTaskComment(id: $id, comment: $comment) {
      ...CommentFields
    }
  }
  ${commentResponse}
`;

export const DELETE_COMMENT = gql`
  mutation DeleteComment($id: Float!) {
    deleteComment(id: $id)
  }
`;

export default {
  CREATE_TASK_COMMENT,
  GET_TASK_COMMENTS,
  DELETE_COMMENT,
};
