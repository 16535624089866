import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FilterPanel } from '@gsa/afp-component-library';
import { useUserFilter } from '../providers';
import { getSelectedFilterValue } from '../../../utilities/filterUtils';

const {
  FilterPanelField,
  FilterPanelFilterShape,
  useFilterPanel,
} = FilterPanel;

const placeholderOption = {
  value: '',
  label: 'Select department',
  defaultValue: true,
};

const OfficeFilterItem = ({ filter }) => {
  const {
    getFilterFromState,
    mergedFilters,
    clearOneFilter,
  } = useFilterPanel();

  const { getOffices, offices, filters } = useUserFilter();
  const selectedValue = getSelectedFilterValue(filters, filter) || '';

  const [options, setOptions] = useState([placeholderOption]);
  const [agencyCode, setAgencyCode] = useState('');
  const [bureauCode, setBureauCode] = useState('');

  useEffect(() => {
    const agencyFilter = getFilterFromState(
      '$customerAttrs.registered_agency_code$',
    );
    setAgencyCode(agencyFilter.value || '');
    const bureauFilter = getFilterFromState(
      '$customerAttrs.registered_bureau_code$',
    );
    setBureauCode(bureauFilter.value || '');
  }, [mergedFilters]);

  useEffect(() => {
    // clear office when agency, and bureau are changed
    clearOneFilter(filter);

    // eslint-disable-next-line no-unused-expressions
    bureauCode
      ? getOffices({ variables: { bureauCode, agencyCode, order: [['officeCode', 'asc']] } })
      : setOptions([placeholderOption]);
  }, [bureauCode]);

  useEffect(() => {
    setOptions([
      placeholderOption,
      ...offices.map(({ officeCode, officeName }) => ({
        value: officeCode,
        label: officeName,
      })),
    ]);
  }, [offices]);

  return (
    <>
      <p className="text-bold">Department</p>
      <FilterPanelField
        key={[filter.key]}
        filter={{
          ...filter,
          type: 'select',
          options,
          value: selectedValue,
        }}
      />
    </>
  );
};

OfficeFilterItem.propTypes = {
  filter: PropTypes.shape(FilterPanelFilterShape).isRequired,
};

export default OfficeFilterItem;
