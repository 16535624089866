import React from 'react';
import { Alert } from '@gsa/afp-component-library';
import { InternalError } from '@gsa/afp-shared-ui-utils';
import { useRole } from '../providers';

const Types = {
  success: 'success',
  error: 'error',
};

const alertType = (feedback, onClose) => {
  const { type, message } = feedback || {};
  if (!type) return null;

  if (type === Types.success) {
    return (
      <Alert
        type={type}
        data-testid="feedback-role-success-message"
        focused
        slim
        onClose={onClose}
      >
        {message}
      </Alert>
    );
  }

  if (type === Types.error) {
    return (
      <InternalError
        id="feedback-role-failure-message" 
      />
    );
  }
  return null;
};

const FeedbackMessage = () => {
  const { feedback, setFeedbackMessage } = useRole();
  const handleClose = () => {
    setFeedbackMessage(false);
  };
  return alertType(feedback, handleClose);
};

export default FeedbackMessage;
