/* eslint-disable react/prop-types */
import React, { useCallback, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useFormContext, Controller } from 'react-hook-form';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import { SelectDropdown, Label } from '@gsa/afp-component-library';
import { StandardFieldset } from '../../../components/Forms';
import { GET_VENDORS_BY_PERMISSION } from '../../../services/data-layer';
import { Types } from '../../users/constants/user-constants';
import {
  OPERATIONS,
  SUBJECTS,
  emDashUnicode,
} from '../../../utilities/constants';
import { vendorTypeAheadValue } from './vendor-name-typeahead';

const formfields = {
  vendorFleetNo: 'vendorFleetNo',
  vendorManager: 'vendorManager',
  userType: 'userType',
};

const validationOptions = {
  vendorFleetNo: {
    required: 'Please select a Vendor name',
  },
  vendorManager: {},
  userType: {},
};
const defaultVendorSelectionOption = {
  value: '',
  label: 'Please select a Vendor',
};
export const StaticField = ({ value, label }) => {
  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
    <section tabIndex={0} aria-label={`${label} ${value}`}>
      <Label className="text-bold">{label}</Label>
      <p className="margin-top-1">{value}</p>
    </section>
  );
};

const InvitationOrganizationVendorForm = () => {
  const { errors, control, reset } = useFormContext();
  const { currentUser } = useCurrentUser();
  const { firstName = '', lastName = '', id } = currentUser || {};
  const { data: { getVendorsByPermission: vendorsList } = {} } = useQuery(
    GET_VENDORS_BY_PERMISSION,
    {
      variables: { operation: OPERATIONS.View, subject: SUBJECTS.Vendor },
    },
  );

  const getVendorsDropdownOptions = useCallback(
    () => [
      defaultVendorSelectionOption,
      ...(vendorsList?.map(
        ({ fleetVendorNumber, vendorName, vendorContacts }) => ({
          label: vendorTypeAheadValue(
            vendorName,
            vendorContacts,
            fleetVendorNumber,
          ),
          value: fleetVendorNumber,
        }),
      ) || []),
    ],
    [vendorsList],
  );

  const showReadOnlyVendor = vendorsList?.length < 2;
  const { vendorName: firstVendorName, fleetVendorNumber: firstVendorNumber } =
    vendorsList?.[0] || {};
  useEffect(() => {
    reset({
      [formfields.vendorFleetNo]: showReadOnlyVendor
        ? firstVendorNumber
        : defaultVendorSelectionOption.value,
    });
  }, [vendorsList]);

  return (
    <>
      <StandardFieldset label="Vendor Information" name="vendorInfo">
        <Controller
          name={formfields.vendorFleetNo}
          control={control}
          rules={validationOptions.vendorFleetNo}
          defaultValue={defaultVendorSelectionOption.value}
          render={({ _, ...fieldProps }) => {
            return showReadOnlyVendor ? (
              <StaticField
                label="Name"
                value={firstVendorName || emDashUnicode}
              />
            ) : (
              <SelectDropdown
                {...fieldProps}
                required
                label={<span className="text-bold">Name</span>}
                id={formfields.vendorFleetNo}
                options={getVendorsDropdownOptions()}
                className="maxw-full"
                errorMessage={errors?.vendorFleetNo?.message || null}
                aria-invalid={errors?.vendorFleetNo ? 'true' : 'false'}
                data-testid="vendor-invitation-vendor-list"
              />
            );
          }}
        />

        <Controller
          name={formfields.vendorManager}
          control={control}
          defaultValue={id}
          render={() => (
            <StaticField label="Manager" value={`${firstName} ${lastName}`} />
          )}
        />
        <Controller
          name={formfields.userType}
          control={control}
          defaultValue={Types.VENDOR}
          render={() => <></>}
        />
      </StandardFieldset>
    </>
  );
};

export default InvitationOrganizationVendorForm;
