import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@gsa/afp-component-library';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import {
  propTypeFormContext,
  propTypeSelectOptions,
  StandardFieldset,
  StandardLabeledSelectDropdown,
  StandardLabelledReadonly,
} from '../../components/Forms';
import { canManageAll } from '../users/authorization';
import { validateRequiredDropdown } from './user-profile-validation';

export const UserProfileAgencyBureauOffice = ({
  formContext,
  agenciesOptions,
  bureausOptions,
  officesOptions,
  supervisorsOptions,
  isAdminEditingUser,
  onAgencyChange,
  onBureauChange,
  onOfficeChange,
  onSupervisorChange,
}) => {
  const { defaultValues = {}, validationOptions, control } = formContext || {};
  const { registeredAgencyCode } = defaultValues;
  const [agencyCode, setAgencyCode] = useState(registeredAgencyCode);
  const option = agenciesOptions.find((o) => o.value === agencyCode);
  const ability = useAppAbility();

  const agencyHandler = (evt) => {
    setAgencyCode(evt.target.value);
    onAgencyChange(evt);
  }

  if(agencyCode !== registeredAgencyCode){
    control.setValue('supervisorId', '', { shouldValidate: false });
  }

  if(supervisorsOptions?.length <= 1){
    validationOptions.supervisorId = null;
  }else{
    validationOptions.supervisorId = {
      required: 'Required',
      validate: validateRequiredDropdown('Please select your manager'),
    };
  }

  return (
    <StandardFieldset label="Work Information" name="workInformation">
      {isAdminEditingUser && !canManageAll(ability) ? (
        <StandardLabelledReadonly
          formContext={formContext}
          name="registeredAgencyCode"
          defaultValue={option?.label}
          label="Agency"
        />
      ) : (
        <>
          <StandardLabeledSelectDropdown
            label="Agency"
            formContext={formContext}
            name="registeredAgencyCode"
            options={agenciesOptions}
            onChangeCallback={agencyHandler}
            required
          />
          {(agencyCode !== registeredAgencyCode && canManageAll(ability)) && (
          <Alert
            type="warning"
            id="user-change-agency-warning"
            data-testid="user-change-agency-warning-testid"
            slim
          >
            Updating the agency will remove all roles. Please assign roles after updating profile.
          </Alert>
        )}
        </>
      )}
      <StandardLabeledSelectDropdown
        formContext={formContext}
        name="registeredBureauCode"
        label="Bureau"
        options={bureausOptions}
        onChangeCallback={onBureauChange}
      />
      <StandardLabeledSelectDropdown
        formContext={formContext}
        name="registeredOfficeCode"
        label="Office"
        options={officesOptions}
        onChangeCallback={onOfficeChange}
      />
      {supervisorsOptions?.length > 1 &&
      <StandardLabeledSelectDropdown 
        formContext={formContext}
        name="supervisorId"
        label="Manager"
        options={supervisorsOptions}
        onChangeCallback={onSupervisorChange}
        required
      />}
      {supervisorsOptions?.length <= 1 &&
      <StandardLabeledSelectDropdown 
        formContext={formContext}
        name="supervisorId"
        label="Manager"
        options={supervisorsOptions}
        onChangeCallback={onSupervisorChange}
      />}
    </StandardFieldset>
  );
};

UserProfileAgencyBureauOffice.propTypes = {
  formContext: propTypeFormContext.isRequired,
  agenciesOptions: propTypeSelectOptions.isRequired,
  bureausOptions: propTypeSelectOptions.isRequired,
  officesOptions: propTypeSelectOptions.isRequired,
  onAgencyChange: PropTypes.func.isRequired,
  onBureauChange: PropTypes.func.isRequired,
  onOfficeChange: PropTypes.func.isRequired,
  isAdminEditingUser: PropTypes.bool,
  supervisorsOptions: PropTypes.arrayOf(PropTypes.shape).isRequired,
  onSupervisorChange: PropTypes.func,
};

UserProfileAgencyBureauOffice.defaultProps = {
  isAdminEditingUser: false,
  onSupervisorChange: () => {
    // default empty function
  }
};

export default UserProfileAgencyBureauOffice;
