import React from 'react';
import { Breadcrumbs } from '@gsa/afp-component-library';
import { Link } from 'react-router-dom';
import { capitalize } from '../../../../../utilities/StringUtils';
import { useUserDetails } from '../providers';

const UserBreadcrumbs = () => {
  const { user } = useUserDetails();
  const fullName = user ? user.fullName : '';
  return (
    <Breadcrumbs
      trail={[
        <Link style={{ textDecoration: 'underline' }} to="/home">
          Home
        </Link>,
        <Link style={{ textDecoration: 'underline' }} to="/admin">
          Admin Console
        </Link>,
        <Link style={{ textDecoration: 'underline' }} to="/admin/users">
          Users
        </Link>,
      ]}
      current={capitalize(fullName)}
    />
  );
};

export default UserBreadcrumbs;
