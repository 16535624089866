import PropTypes from 'prop-types';
import React from 'react';

export const StandardPageDescription = ({ children }) => {
  return <div className="standard-page-description">{children}</div>;
};

StandardPageDescription.propTypes = {
  children: PropTypes.node.isRequired,
};

export default StandardPageDescription;
