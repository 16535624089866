import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@gsa/afp-component-library';

const AlertNotifications = ({ messages, onClose }) => {
  const handleClose = useCallback((id) => () => onClose?.(id), []);

  return messages?.length
    ? messages?.map?.((aMessage) => {
        const { message, id, ...restProps } = aMessage || {};
        return id ? (
          <Alert
            {...restProps}
            key={id}
            onClose={handleClose(id)}
            data-testid="alert-test-id"
            showClose
          >
            {message}
          </Alert>
        ) : null;
      })
    : null;
};

const messageShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  message: PropTypes.node,
});
AlertNotifications.propTypes = {
  messages: PropTypes.arrayOf(messageShape),
  onClose: PropTypes.func,
};
AlertNotifications.defaultProps = {
  messages: [],
  onClose: null,
};
export default AlertNotifications;
