import React from 'react';
import { Alert, Button } from '@gsa/afp-component-library';
import { useCurrentUser, FleetTechnicalSupportContact } from '@gsa/afp-shared-ui-utils';
import './signup.scss';

const GSAExternalPathError = () => {
  const auth = useCurrentUser();
  const handleLoginGSA = async (e) => {
    e.preventDefault();
    await auth.loginUserGSA();
  };

  const action = (
    <Button
      label="GSA employee login"
      variant="primary"
      className="margin-top-1 margin-bottom-1 desktop:margin-left-4"
      id="gsa-secureAuth-login"
      onClick={handleLoginGSA}
      data-testid="handle-gsa-login-requirement"
    />
  );

  return (
    <Alert
      type="warning"
      actions={action}
      id="gsa-login-requirement-error"
      slim
    >
      We noticed that you have a GSA.gov email. 
      If you are a GSA employee, 
      you must use your GSA.gov email to log in and create an account 
      even if you have not logged into GSAFleet.gov before.  
      If you are a GSA Fleet customer, <FleetTechnicalSupportContact />.
    </Alert>
  );
};

export default GSAExternalPathError;
