import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  StatusBadge,
  AFPTableRowAction,
  FilterTableFrame,
} from '@gsa/afp-component-library';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import RoleFilters from './role-filters';
import { useRole, useRoleFilter } from '../providers';
import ConfirmReactivationModal from './confirm-reactivation-modal';
import ConfirmDeactivationModal from './confirm-deactivation-modal';
import RoleExtra from './role-extra';
import { canActivateRole } from '../authorization';
import TableWrapper from '../../users/components/afp-table-wrapper';

const RolesTable = () => {
  const {
    getRoles,
    roles,
    confirmReactivation,
    confirmDeactivation,
  } = useRole();
  const { filters } = useRoleFilter();
  const [sort, setSort] = useState('name ASC');
  const [paginationState, setPaginationState] = useState({
    limit: 25,
    offset: 0,
    currentPage: 1,
    isReset: false,
  });
  const ability = useAppAbility();

  const tableRef = React.createRef();

  const getData = () => {
    const { limit, offset } = paginationState;
    getRoles({
      variables: {
        limit,
        offset,
        order: sort,
        filters,
      },
    });
  };

  useEffect(() => {
    getData();
  }, [sort, paginationState, filters]);

  useEffect(() => {
    setPaginationState({
      ...paginationState,
      offset: 0,
      currentPage: 1,
      isReset: true,
    });
  }, [filters]);

  const cellStatus = (value) => ({
    label: value ? 'Deactivated' : 'Active',
    status: value ? 'Urgent-Gray' : 'Ready-Gray',
  });

  const actionsLabel = {
    view: 'View',
    reactivate: 'Reactivate',
    deactivate: 'Deactivate',
    edit: 'Edit',
  };

  // TODO: 01-06-22 removed the view and edit role actions as they are not yet implemented
  // Please look in the history and revert the changes and remove this comment
  const actionsColumn = {
    getActions(role) {
      const { reactivate, deactivate } = actionsLabel;
      const actions = [];
      if (canActivateRole(ability)) {
        actions.push({
          icon: 'close',
          label: role.isInactive ? reactivate : deactivate,
        });
      }

      return actions;
    },
    execute(operation, role) {
      const { reactivate, deactivate } = actionsLabel;
      if (operation === reactivate) {
        confirmReactivation(role);
      }
      if (operation === deactivate) {
        confirmDeactivation(role);
      }
    },
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Role name',
        accessor: 'name',
      },
      {
        Header: 'User type',
        accessor: 'userType.name',
      },
      {
        Header: 'Status',
        accessor: 'isInactive',
        sortable: false,
        Cell: (cell) => {
          const { value } = cell;
          const { status, label } = cellStatus(value);
          return <StatusBadge variant={status}>{label}</StatusBadge>;
        },
      },
      {
        Header: 'Actions',
        sortable: false,
        headerClassName: 'cell-center',
        cellClassName: 'cell-center',
        Cell: (props) => {
          /* eslint-disable react/prop-types */
          const { cell } = props;
          const { row } = cell;
          if (!row) {
            return '-';
          }
          const { original: role } = row;
          const actions = actionsColumn.getActions(role);
          return (
            <AFPTableRowAction
              actions={actions}
              onSelectAction={(operation) => {
                actionsColumn.execute(operation, role);
              }}
              {...props}
            />
          );
        },
      },
    ],
    [],
  );

  const renderRowSubComponent = useCallback(({ row: { original: data } }) => {
    const { id } = data;

    return <RoleExtra roleId={id} />;
  }, []);

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    const offset = (currentPage - 1) * itemsPerPage;
    setPaginationState({
      limit: itemsPerPage,
      offset,
      currentPage,
    });
  };

  const FTF = useMemo(
    () => FilterTableFrame(null, null, RoleFilters, TableWrapper),
    [],
  );
  const roleTableProps = {
    tableProps: {
      expandable: true,
      fullWidth: true,
      testId: 'roles-table',
      ref: tableRef,
      columns,
      data: roles?.rows,
      renderRowSubComponent,
      onSort: setSort,
      defaultSort: sort,
    },
    paginationProps: {
      variant: 'advanced',
      itemsPerPageOptions: [10, 25, 50],
      itemsCount: roles?.count,
      itemsPerPage: paginationState.limit,
      currentPage: paginationState.currentPage,
      isReset: paginationState.isReset,
      onPageChange: handlePaginationChange,
    },
  };
  return (
    <div className="roles-table">
      <FTF lowRightProps={roleTableProps} filterToggle />
      <ConfirmReactivationModal />
      <ConfirmDeactivationModal />
    </div>
  );
};

export default RolesTable;
