import React from 'react';
import { Link } from 'react-router-dom';
import { PageTitle, Breadcrumbs } from '@gsa/afp-component-library';
import WelcomeMessage from '../../widgets/welcome-message';
import OfficeManagementListingProvider from './office-management-listing-provider';
import OfficeManagementListingTable from './office-management-listing-table';
import OfficeManagementListingAlert from './office-management-alert';

const OfficeManagementListing = () => {
  return (
    <div className="grid-col sales-listing-page">
      <OfficeManagementListingProvider>
        <>
          <Breadcrumbs
            trail={[
              <Link
                style={{ textDecoration: 'underline' }}
                to="/home"
              >
                Home
              </Link>,
              <Link
                style={{ textDecoration: 'underline' }}
                to="/admin/organization-profile"
              >
                Organization Profile
              </Link>,
            ]}
            current="Office Management"
          />
          <OfficeManagementListingAlert />
          <PageTitle title='Office Management' />
          <WelcomeMessage message="" />
          <OfficeManagementListingTable  />          
        </>
      </OfficeManagementListingProvider>
    </div>
  );
};

export default OfficeManagementListing;
