import React from 'react';
import { PageHeader } from '../../../components';

const UsersHeader = () => {
  return (
    <>
      <PageHeader title="Users" id="users-page-header">
        <p>
          The Users page stores a list of GSAFleet.gov user profile information
          including their name, user status, user types, roles, registration
          date and last logged in date.
        </p>
      </PageHeader>
    </>
  );
};

export default UsersHeader;
