export const RecertifiableStatus = {
  RECERTIFIABLE: 1,
  NOT_RECERTIFIABLE: 2,
  UNKNOWN: 0,
};

export const Types = {
  CUSTOMER: '1',
  VENDOR: '2',
  GSA_EMPLOYEE: '3',
  PUBLIC_OR_GENERAL: '4',
};
