const Regex = {
  // This probably does not match some new TLD
  email:
    /^[a-z0-9!#$%&'+/=?^{|}~_-]+(?:\.[a-z0-9!#$%&'*+/=?^_{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+(?:[a-z]{2}|aero|asia|biz|cat|com|coop|edu|gov|info|int|jobs|mil|mobi|museum|name|net|org|pro|tel|travel)$/i,
  // Recommended: https://regex101.com/r/mF3pK7/1
  // email: /.+\@.+\..+/,

  phoneNumberNumeric: /^\d*$/,
  phoneNumberUS: /^(?:(?:\(\d{3}\))|\d{3})[-. ]?\d{3}[-. ]?\d{4}$/,

  // TODO: Unicode support?
  name: /^[a-zA-Z]+(?:[ -]*[a-zA-Z]+)*$/,

  // https://regex101.com/r/WKXLX5/1
  phoneNumberDomestic: /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/,
  // https://regex101.com/r/msxufY/1
  phoneNumberInternational: /^([0-9\-.])+$/,
  // https://regex101.com/r/Kmoqw0/1
  phoneNumberExtDomestic: /^([0-9]{0,6})$/,
  phoneNumberExtInternational: /^([0-9]{0,10})$/,

  // https://regex101.com/r/0hvd8R/1
  zipCodeDomestic: /^([0-9]{5})(-[0-9]{4})?$/,
  // postalCodeInternational: // No regex, freeform field

  url: /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/,
};

export default Regex;
