import React from 'react';
import { Breadcrumbs as DSBreadcrumbs } from '@gsa/afp-component-library';

import { Link } from 'react-router-dom';

const Breadcrumbs = () => (
  <DSBreadcrumbs
    trail={[<Link to="/home">Home</Link>]}
    current="Task Manager"
  />
);
export default Breadcrumbs;
