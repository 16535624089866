import React from 'react';
import PropTypes from 'prop-types';
import './user-name.scss';

const UserName = ({ className, children }) => {
  return (
    <h1
      data-testid="user-name-test-id"
      className={`text-capitalize ${className}`}
    >
      {children}
    </h1>
  );
};

UserName.defaultProps = {
  className: '',
};

UserName.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default UserName;
