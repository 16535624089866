import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  GET_ROLES,
  REACTIVATE_ROLE,
  DEACTIVATE_ROLE,
} from '../../../services/data-layer';

const RoleContext = createContext({});
const useRole = () => useContext(RoleContext);

const initialState = {
  error: '',
  feedback: null,
  roles: {
    rows: [],
    count: 0,
    hasMore: false,
  },
  reactivatingRole: null,
  deactivatingRole: null,
};

const extractErrorMessage = (err) => err.message || 'Unknown Error';

const actions = {
  setRoles: 'SET_ROLE_LIST',
  setConfirmReactivation: 'SET_CONFIRM_REACTIVATION',
  setConfirmDeactivation: 'SET_CONFIRM_DEACTIVATION',
  setFeedback: 'SET_FEEDBACK',
  setError: 'SET_ERROR',
};

const roleReducer = (state, { action, payload }) => {
  const {
    setRoles,
    setConfirmReactivation,
    setConfirmDeactivation,
    setFeedback,
    setError,
  } = actions;
  switch (action) {
    case setRoles: {
      return {
        ...state,
        error: '',
        roles: { ...payload } || initialState.roles,
      };
    }
    case setConfirmReactivation: {
      return {
        ...state,
        error: '',
        reactivatingRole: payload,
      };
    }
    case setConfirmDeactivation: {
      return {
        ...state,
        error: '',
        deactivatingRole: payload,
      };
    }
    case setFeedback: {
      return {
        ...state,
        error: '',
        feedback: payload,
      };
    }
    case setError: {
      return { ...state, error: extractErrorMessage(payload) };
    }
    default:
      throw new Error('Invalid role action');
  }
};

const RoleProvider = ({ children, ...props }) => {
  const [state, setDispatch] = useReducer(
    roleReducer,
    initialState,
    () => initialState,
  );

  const dispatch = (action, payload) => setDispatch({ action, payload });

  // Get Roles lazy.
  const [getRoles, { refetch: refetchRoles }] = useLazyQuery(GET_ROLES, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (err) => dispatch(actions.setError, err),
    onCompleted: (data) => dispatch(actions.setRoles, data?.getRoles),
  });

  // Confirmation
  const confirmReactivation = (role) =>
    dispatch(actions.setConfirmReactivation, role);
  const confirmDeactivation = (role) =>
    dispatch(actions.setConfirmDeactivation, role);

  const setFeedbackMessage = (value) => {
    dispatch(actions.setFeedback, value);
  };

  // Role status
  const [reactivateRole] = useMutation(REACTIVATE_ROLE, {
    onError: (err) => {
      confirmReactivation(false);
      setFeedbackMessage({ type: 'error' });
      dispatch(actions.setError, err);
    },
    onCompleted: (data) => {
      const { reactivateRole: role } = data;
      if (role) {
        confirmReactivation(false);
        const { name = 'The role' } = role;
        setFeedbackMessage({
          type: 'success',
          message: (
            <span>
              Role <b>{name}</b> has been successfully reactivated.
            </span>
          ),
        });
        refetchRoles();
      }
    },
  });
  const [deactivateRole] = useMutation(DEACTIVATE_ROLE, {
    onError: (err) => {
      confirmDeactivation(false);
      setFeedbackMessage({ type: 'error' });
      dispatch(actions.setError, err);
    },
    onCompleted: (data) => {
      const { deactivateRole: role } = data;
      if (role) {
        const { name = 'The role' } = role;
        confirmDeactivation(false);
        setFeedbackMessage({
          type: 'success',
          message: (
            <span>
              Role <b>{name}</b> has been successfully deactivated.
            </span>
          ),
        });
        refetchRoles();
      }
    },
  });

  // Status update wrapper
  const deactivate = (id) => {
    deactivateRole({
      variables: { id },
    });
  };
  const reactivate = (id) => {
    reactivateRole({
      variables: { id },
    });
  };

  return (
    <RoleContext.Provider
      value={{
        ...state,
        ...props,
        getRoles,
        confirmReactivation,
        confirmDeactivation,
        reactivate,
        deactivate,
        setFeedbackMessage,
      }}
    >
      {children}
    </RoleContext.Provider>
  );
};

RoleProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { RoleProvider as default, useRole };
