import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useModal } from '@gsa/afp-component-library';

export const createInvitationContext = createContext();

const defaultState = {
  loadingCount: 0,
  message: null,
};

const actions = {
  INCREMENT_LOADER: 'INCREMENT_LOADER',
  DECREMENT_LOADER: 'DECREMENT_LOADER',
  SET_MESSAGE: 'SET_MESSAGE',
  RESET: 'RESET',
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.INCREMENT_LOADER:
      return { ...state, loadingCount: state.loadingCount + 1 };
    case actions.DECREMENT_LOADER:
      return {
        ...state,
        loadingCount: state.loadingCount
          ? state.loadingCount - 1
          : state.loadingCount,
      };
    case actions.SET_MESSAGE:
      return { ...state, message: action.payload };
    case actions.RESET:
      return defaultState;
    default:
      return state;
  }
};

export const CreateInvitationProvider = ({ children }) => {
  const { isOpen, openModal, closeModal } = useModal();
  const [state, dispatch] = useReducer(reducer, defaultState);

  const showLoader = () => {
    dispatch({ type: actions.INCREMENT_LOADER });
  };

  const hideLoader = () => {
    dispatch({ type: actions.DECREMENT_LOADER });
  };

  const reset = () => {
    dispatch({ type: actions.RESET });
    closeModal();
  };

  const setMessage = (message) => {
    dispatch({ type: actions.SET_MESSAGE, payload: message });
  };

  

  return (
    <createInvitationContext.Provider
      value={{
        ...state,
        showLoader,
        hideLoader,
        reset,
        setMessage,
        isOpen,
        openModal,
        closeModal,
      }}
    >
      {children}
    </createInvitationContext.Provider>
  );
};

CreateInvitationProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useCreateInvitation = () => useContext(createInvitationContext);
