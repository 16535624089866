import React from 'react';
import PropTypes from 'prop-types';
import {
  connectModal,
  Modal,  
  Button,
} from '@gsa/afp-component-library';
import { useMutation } from '@apollo/client';
import {  
  UNASSIGN_ROLE,
} from '../../../../../services/data-layer';
import { getScopesStr } from '../utils/scope-utils';

const UnassignRoleModal = (props) => {
  const {
    user,
    role,
    feedback,
    isOpen
  } = props;
  const UNAUTHORIZED_OPERATION_FAILURE = 'UNAUTHORIZED_OPERATION_FAILURE';
  const getScopeValue = (scope) => getScopesStr(scope, ' / ');

  const [unassignRole] = useMutation(UNASSIGN_ROLE, {    
    onError: ({ graphQLErrors = [] }) => {
      const err = graphQLErrors[0];
      const { exception } = err?.extensions || {};
      if(exception 
          && exception?.jse_cause 
          && exception?.jse_cause?.name === UNAUTHORIZED_OPERATION_FAILURE
      ){
        feedback({
          type: 'customError',
          message: <>You don&apos;t have permission to unassign this role.</>
        });
      }
      else{
        feedback({ type: 'customError', message: "You don't have permission to unassign this role." });
      }
    },
    onCompleted: () => {
      feedback({ 
        type: 'success', 
        message: ( 
          <>
            <strong>{user.fullName}</strong> is successfully unassigned as a <strong>{role.role.name}</strong>
            {' '}at <strong>{getScopeValue(role.scope)}.</strong>      
          </>
        )    
     });
    },
  });
  
  const closeModal = () => feedback();

  const unassign = () => {
   unassignRole({variables: { userRoleId: Number(role.id) }} );
  };

  const Actions = ({ onCancel, onSave }) => {
    return (
      <div data-testid="unassign-role-modal-div">
        <Button
          label="Cancel"
          type="button"
          variant="unstyled"
          className="padding-right-1"
          onClick={onCancel}
          data-testid="unassign-role-cancel-btn"
        />
        <Button
          label="Unassign Role"
          type="button"
          onClick={onSave}
          data-testid="unassign-role-save-btn"
        />
      </div>
    );
  };

  Actions.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
  };

  const Content = () => {
    return (
      <p>
        Are you sure you want to unassign <strong>{user.fullName}</strong>&apos;s role as a <strong>{role.role.name}</strong> 
        {' '}at <strong>{getScopeValue(role.scope)}</strong>?          
      </p>
    );
  };

  const UnassignModal = () => (
    <Modal
      title={<h2>Unassign role</h2>}
      onClose={closeModal}
      actions={<Actions onCancel={closeModal} onSave={unassign} />}
    >
      <Content />
    </Modal>
  );

  const ConnectedUnassignModal = connectModal(UnassignModal);

  return (   
    <ConnectedUnassignModal isOpen={isOpen} onClose={closeModal} />    
  );
};

UnassignRoleModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string,
    fullName: PropTypes.string
  }).isRequired,
  role: PropTypes.shape({
    id: PropTypes.string,
    role: PropTypes.shape({
      name: PropTypes.string,
    }),
    scope: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  }).isRequired,
  feedback: PropTypes.func.isRequired
};

export default UnassignRoleModal;
