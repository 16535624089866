import React from 'react';
import PropTypes from 'prop-types';
import {
  Breadcrumbs,
  Title,
  Description,
  TaskDetails,
  Documents,
  Comments,
  VehicleDetails,
  CustomerDetails,
} from '../widgets';
import { TaskPropType } from '../../../utilities/types';

const Recall = ({ task }) => {
  return (
    <div className="grid-container">
      <Breadcrumbs />
      <Title task={task} />
      <div className="grid-row grid-gap">
        <div className="grid-col">
          <Description
            task={task}
            taskType="recall"
            recallType={(task.metaData || {}).recallTypeCode || ''}
          />
          <TaskDetails task={task} />
          <Documents task={task} />
          <Comments />
        </div>
        <div className="grid-col-4 afp-task-details__side_col">
          <VehicleDetails task={task} />
          <CustomerDetails task={task} />
        </div>
      </div>
    </div>
  );
};

Recall.propTypes = {
  task: PropTypes.shape(TaskPropType).isRequired,
};

export default Recall;
