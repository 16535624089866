import React, { Suspense, lazy } from 'react';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { Spinner } from '@gsa/afp-component-library';
import {
  PrivateRoute,
  PublicRoute,
  AppProvider,
  NotFound,
  PageFocus,
  Unauthorized,
  FeatureProvider,
} from '@gsa/afp-shared-ui-utils';
import { useFeatureToggle } from './utilities/feature-toggle';
import '@gsa/afp-component-library/dist/css/index.css';
import Layout from './utilities/Layout';
import UserProfilePage from './pages/user-profile';
import LogoutPage from './pages/auth/logout';
import AdminConsole from './pages/admin-console/AdminConsole';
import PublicPage from './pages/public/public-home';
import SignUpPage from './pages/signup';
import HomePage from './pages/home/home';
import TaskManagerPage from './pages/tasks/TaskManager';
import TaskDetailPage from './pages/task-detail';
import VendorListing from './pages/vendor-listing';
import {
  vehicleRedirect,
  catalogRedirect,
  storeRedirect,
} from './utilities/redirects';
import dataStore, { GET_SHARED_ENABLED_FEATURES } from './services/data-layer';
import UserDetailsPage from './pages/admin-console/users/user-details';
import ProfileEditablePage from './pages/admin-console/users/profile-editable';
import Roles from './pages/roles';
import Users from './pages/users';
import UserInvitations from './pages/user-invitations';
import PrivacyRegulationsPage from './pages/privacy-regulations';
import InactiveStatusPage from './pages/user-inactive/index';
import ReactivationSubmittedPage from './pages/user-inactive/reactivation-submitted';
import OrganizationProfile from './pages/organization-profile';
import OfficeManagement from './pages/office-management';
import DocumentationManagement from './pages/documentation-management/documents-management';
import BehaviorRulesModal from './components/ModalBehaviorRules/behavior-rules-modal';
import './App.scss';
import AcceptInvitationPage from './pages/user-invitations/accept-invitation';
import Help from './pages/help';
import { HELP_SIDENAV_MAPPING } from './pages/help/help-sidenav-mapping';
import { VMSSubjects } from './vms-authz';

const VendorSwitch = lazy(() => import('./pages/vendor-profile'));

function App() {
  const { feature } = useFeatureToggle();
  return (
    <ApolloProvider client={dataStore}>
      <AppProvider>
        <FeatureProvider featureQuery={GET_SHARED_ENABLED_FEATURES}>
          <Layout>
            <Router>
              <PageFocus />
              <Suspense
                fallback={
                  <Spinner data-testid="lazy-load-spinner" size="small" />
                }
              >
                <Switch>
                  <PublicRoute
                    exact
                    path="/"
                    component={PublicPage}
                    title="Home"
                  />
                  <PublicRoute
                    exact
                    path="/privacy-regulations"
                    component={PrivacyRegulationsPage}
                    title="Privacy and Regulations"
                  />
                  <PublicRoute exact path="/signup" component={SignUpPage} />
                  <PublicRoute
                    exact
                    path="/invitation"
                    component={AcceptInvitationPage}
                  />
                  <PublicRoute
                    exact
                    path="/logout"
                    component={LogoutPage}
                    title="Logout"
                  />
                  <PrivateRoute
                    exact
                    path="/inactive"
                    title="Don't Lose Your Account"
                    component={InactiveStatusPage}
                  />
                  <PrivateRoute
                    exact
                    path="/request-submitted"
                    title="Thank You For Reactivating Your Account"
                    component={ReactivationSubmittedPage}
                  />
                  <PrivateRoute
                    exact
                    path="/home"
                    component={HomePage}
                    title="Home"
                  />
                  {Object.keys(HELP_SIDENAV_MAPPING)
                    .filter((key) => HELP_SIDENAV_MAPPING[key])
                    .map((key) => {
                      return (
                        <PublicRoute
                          key={key}
                          exact
                          path={`/${key}`}
                          component={Help}
                          title="Help"
                        />
                      );
                    })}
                  <PrivateRoute path="/profile" component={UserProfilePage} />
                  <PrivateRoute
                    exact
                    path="/tasks"
                    component={TaskManagerPage}
                  />
                  <PrivateRoute
                    exact
                    path="/task/:id"
                    component={TaskDetailPage}
                  />
                  <PrivateRoute path="/vehicle" component={vehicleRedirect} />
                  <PrivateRoute path="/catalog" component={catalogRedirect} />
                  <PrivateRoute path="/store" component={storeRedirect} />
                  <PublicRoute
                    exact
                    path="/unauthorized"
                    component={Unauthorized}
                    title="Access Denied"
                  />
                  <PrivateRoute
                    exact
                    path="/vendor/*"
                    component={feature(VendorSwitch, 'vendors')}
                  />
                  <PrivateRoute
                    exact
                    path="/vendor-listing"
                    subject="Vendor"
                    operation="view"
                    component={feature(VendorListing, 'vendors')}
                    title="Vendor Listing"
                  />
                  <PrivateRoute
                    operation="view"
                    subject="User"
                    exact
                    path="/admin/users/:id"
                    component={UserDetailsPage}
                  />
                  <PrivateRoute
                    operation="view"
                    subject="User"
                    exact
                    path="/admin/users/:id/edit"
                    component={ProfileEditablePage}
                  />
                  <PrivateRoute
                    operation="view"
                    subject="AdminConsole"
                    exact
                    path="/admin"
                    title="Admin Console"
                    component={AdminConsole}
                  />
                  <PrivateRoute
                    operation="view"
                    subject="Role"
                    exact
                    path="/admin/roles"
                    title="Roles"
                    component={Roles}
                  />
                  <PrivateRoute
                    operation="view"
                    subject="User"
                    exact
                    path="/admin/users"
                    title="Users"
                    component={Users}
                  />
                  <PrivateRoute
                    operation="view"
                    subject="Invitation"
                    exact
                    path="/admin/user-invitations"
                    title="User Invitations"
                    component={UserInvitations}
                  />
                  <PrivateRoute
                    operation="view"
                    subject={VMSSubjects.ORGANIZATION_PROFILE}
                    exact
                    path="/admin/organization-profile"
                    title="Organization Profile"
                    component={OrganizationProfile}
                  />
                  <PrivateRoute
                    operation="view"
                    subject={VMSSubjects.ORGANIZATION_PROFILE}
                    exact
                    path="/office-management"
                    title="Office Management"
                    component={OfficeManagement}
                  />                  
                  <PrivateRoute
                    operation="view"
                    subject={VMSSubjects.ORGANIZATION_PROFILE}
                    exact
                    path="/document-management"
                    title="Documentation Management"
                    component={feature(DocumentationManagement, 'document-management')}
                  />
                  <PublicRoute
                    path="*"
                    component={NotFound}
                    title="Sorry for the Detour"
                  />
                </Switch>
              </Suspense>
              <BehaviorRulesModal />
            </Router>
          </Layout>
        </FeatureProvider>
      </AppProvider>
    </ApolloProvider>
  );
}

export default App;
