import React, { useState } from 'react';
import {
  Spinner,
  Breadcrumbs,
  Button,
  PageTitle,
} from '@gsa/afp-component-library';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import {
  InternalError,
  useCurrentUser,
  FleetTechnicalSupportContact,
} from '@gsa/afp-shared-ui-utils';
import './styles/inactive.css';
import { REQUEST_REACTIVATION } from '../../services/data-layer';

const InactiveStatusPage = () => {

  const { isLoading } = useCurrentUser();
  const [invalidState, setInvalidState] = useState(false);
  const [requestReactivationSuccess, setRequestReactivationSuccess] = useState(
    false,
  );

  if (isLoading) return <Spinner className="margin-y-9" />;

  const [
    requestReactivation,
    { loading: requestReactivationLoading },
  ] = useMutation(REQUEST_REACTIVATION, {
    onError: () => {
      setInvalidState(true);
    },
    onCompleted: () => {
      setRequestReactivationSuccess(true);
    },
  });

  return (
    <div>
      <Breadcrumbs
        trail={[
          <Link style={{ textDecoration: 'underline' }} to="/">
            Home
          </Link>,
        ]}
        current="Don't Lose Your Account"
      />
      {invalidState && <InternalError />}
      {requestReactivationSuccess ? (
        <div className="padding-bottom-5">
          <PageTitle
            data-testid="thank-you-for-reactivation"
            title="Thank you for Reactivation Request"
          />
          <article className="padding-bottom-5">
            We received your request to reactivate your account. You will
            receive an email with updates from an authorized approver. For
            technical support, <FleetTechnicalSupportContact />.
          </article>
        </div>
      ) : (
        <>
          {requestReactivationLoading && (
            <Spinner
              aria-busy="true"
              className="loading_backdrop"
              size="large"
            />
          )}
          <div className="padding-bottom-5">
            <PageTitle
              data-testid="dont-lose-your-account"
              title="Don't Lose Your Account"
            />
            <article className="padding-bottom-5">
              Since your account hasn&apos;t been used in more than 90 days it
              is now inactive <span className="line-block">&nbsp;</span> but we
              would love to welcome you back. Click &apos;Reactivate your
              account &apos; below to reactivate your account for approval. If
              you do not reactivate within a year, your account will be deleted.
              For technical support, <FleetTechnicalSupportContact />.
            </article>
            <Button
              label="Reactivate your account"
              variant="primary"
              id="reactivate-btn"
              className="usa-button margin-top-1"
              data-testid="reactivate-btn"
              onClick={() => {
                requestReactivation();
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default InactiveStatusPage;
