import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import {
  GET_USER_TYPES,
  GET_VENDOR_TYPE_AHEAD_WITH_FVN,
} from '../../../services/data-layer';

const UserInvitationFilterContext = createContext({});
const useUserInvitationFilter = () => useContext(UserInvitationFilterContext);

const initialState = {
  error: '',
  userTypes: [],
  statuses: [],
  vendors: [],
  vendorTypeaheadData: [],
  filters: [],
};

const actions = {
  setUserTypes: 'SET_USER_TYPES',
  setStatuses: 'SET_STATUSES',
  setVendorTypeaheadData: 'SET_VENDOR_TYPE_AHEAD_DATA',
  setFilters: 'SET_FILTERS',
  setError: 'SET_ERROR',
};

const extractErrorMessage = (err) => err.message || 'Unknown Error';

const userInvitationFilterReducer = (state, { action, payload }) => {
  const {
    setUserTypes,
    setStatuses,
    setVendorTypeaheadData,
    setFilters,
    setError,
  } = actions;
  const { userTypes, statuses, vendorTypeaheadData, vendors, error } =
    initialState;

  switch (action) {
    case setUserTypes: {
      return { ...state, error, userTypes: payload || userTypes };
    }
    case setStatuses: {
      return { ...state, error, statuses: payload || statuses };
    }
    case setVendorTypeaheadData: {
      return {
        ...state,
        error,
        vendorTypeaheadData: payload.typeaheadoptions || vendorTypeaheadData,
        vendors: payload.options || vendors,
      };
    }
    case setFilters: {
      // If the filter is based on the key $vendorInvitationAttrs.fleet_vendor_no$
      // replace the value of the filter from vendor name to fleet vendor number

      const vendorNameFilter = payload.filter(
        (val) => val.key === '$vendorInvitationAttrs.fleet_vendor_no$',
      );
      let selectedVendor;
      let newPayload = payload;

      if (vendorNameFilter.length > 0) {
        selectedVendor = state.vendors.filter(
          (vendor) => vendor.vendorName === vendorNameFilter[0].value,
        );
      }
      if (selectedVendor && selectedVendor.length > 0) {
        newPayload = payload.map((a) => {
          return a.key === '$vendorInvitationAttrs.fleet_vendor_no$'
            ? {
                key: a.key,
                operator: a.operator,
                value: selectedVendor[0].fleetVendorNumber,
              }
            : a;
        });
      }

      return {
        ...state,
        error,
        filters: {
          operator: '$and',
          conditions: newPayload || [],
        },
      };
    }
    case setError: {
      return { ...state, error: extractErrorMessage(payload) };
    }
    default:
      throw new Error('Invalid user filter action');
  }
};

const UserInvitationFilterProvider = ({ children, ...props }) => {
  const [state, setDispatch] = useReducer(
    userInvitationFilterReducer,
    initialState,
    () => initialState,
  );

  const dispatch = (action, payload) => setDispatch({ action, payload });

  // Get User Types Lazy.
  const [getUserTypes] = useLazyQuery(GET_USER_TYPES, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (err) => dispatch(actions.setError, err),
    onCompleted: (data) => dispatch(actions.setUserTypes, data?.getUserTypes),
  });

  const getStatuses = () => {
    dispatch(actions.setStatuses, [
      { label: 'Invited', value: 'Invited' },
      { label: 'Completed', value: 'Completed' },
      { label: 'Cancelled', value: 'Cancelled' },
      { label: 'Expired', value: 'Expired' },
    ]);
  };

  const setFilters = (value) => {
    dispatch(actions.setFilters, value);
  };

  const [getVendorTypeAheadOptions] = useLazyQuery(
    GET_VENDOR_TYPE_AHEAD_WITH_FVN,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        if (data.getVendorTypeAheadWithFvn) {
          const { options } = data.getVendorTypeAheadWithFvn;
          // return unique options only.
          // const uniqueOptions = Array.from(new Set(options));
          const typeaheadoptions =
            options && options.length > 0
              ? options.map((option) => option.vendorName)
              : [];
          dispatch(actions.setVendorTypeaheadData, {
            typeaheadoptions: {
              field: '$vendorInvitationAttrs.fleet_vendor_no$',
              values: typeaheadoptions,
            },
            options,
          });
        }
      },
    },
  );

  const handleFetchVendorTypeaheads = ({ variables: queryVariables }) => {
    const selectedKey = queryVariables?.field;
    const filterObj = queryVariables?.filters[0]?.conditions?.find(
      (filter) => filter.key === selectedKey,
    );

    getVendorTypeAheadOptions({
      variables: {
        search: filterObj.value,
        limit: 10,
      },
    });
  };

  return (
    <UserInvitationFilterContext.Provider
      value={{
        ...state,
        ...props,
        getUserTypes,
        getStatuses,
        setFilters,
        handleFetchVendorTypeaheads,
      }}
    >
      {children}
    </UserInvitationFilterContext.Provider>
  );
};

UserInvitationFilterProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { UserInvitationFilterProvider as default, useUserInvitationFilter };
