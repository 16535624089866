import createReducerActions from "../../../../utilities/createReducerActions";

export const defaultState = {};
export const actionTypes = {
  SET_NOTIFICATION: 'SET_NOTIFICATION',
  CLEAR_NOTIFICATION: 'CLEAR_NOTIFICATION',
  RESET_NOTIFICATIONS: 'RESET_NOTIFICATIONS',
};
export const createActions = createReducerActions(actionTypes);
const reducerFn = (state, action) => {
  switch (action?.type) {
    case actionTypes.RESET_NOTIFICATIONS:
      return { ...defaultState };
    case actionTypes.SET_NOTIFICATION:
      return {
        ...state,
        ...(action?.payload?.id
          ? { [action?.payload?.id]: action?.payload }
          : {}),
      };
    case actionTypes.CLEAR_NOTIFICATION: {
      const { [action?.payload]: _, ...restState } = state || {};
      return restState;
    }
    default:
      return { ...state };
  }
};

export default reducerFn;
