import { gql } from '@apollo/client';
import { commentResponse } from './comments';
import { VehicleFieldsFull } from './fragments';

export const VEHICLE_DETAIL = gql`
  query GetVehicle($id: String!) {
    getVehicle(id: $id) {
      ...VehicleFieldsFull
    }
  }
  ${VehicleFieldsFull}
`;

export const GET_VEHICLE_COMMENTS = gql`
  query getVehicleComments($id: String!, $limit: Float, $offset: Float) {
    getVehicleComments(id: $id, limit: $limit, offset: $offset) {
      rows {
        ...CommentFields
      }
      count
      hasMore
    }
  }
  ${commentResponse}
`;

export const ADD_VEHICLE_COMMENT = gql`
  mutation AddVehicleComment($id: String!, $comment: String!) {
    addVehicleComment(id: $id, comment: $comment) {
      ...CommentFields
    }
  }
  ${commentResponse}
`;

export const GET_VEHICLE_RECALLS = gql`
  query GetVehicleRecalls(
    $vin: String!
    $status: String!
    $limit: Float
    $order: OrderBy!
  ) {
    getVehicleRecalls(id: $vin, status: $status, limit: $limit, order: $order) {
      rows {
        recallNhtsaID
        recallCode
        recallTypeCode
        recallStatusCode
        recallGsaNotifiedDate
        recallUpdatedDate
        recallClosedDate
      }
      count
    }
  }
`;

export const GET_VEHICLE_TASKS = gql`
  query GetVehicleTasks(
    $vin: String!
    $limit: Float
    $offset: Float
    $order: OrderBy!
  ) {
    getVehicleTasks(id: $vin, limit: $limit, offset: $offset, order: $order) {
      rows {
        id
        taskID
        status
        title
        metaData
        updatedAt
      }
      count
      hasMore
    }
  }
`;

export default {
  VEHICLE_DETAIL,
  GET_VEHICLE_COMMENTS,
  ADD_VEHICLE_COMMENT,
  GET_VEHICLE_RECALLS,
};
