import React from 'react';
import { Alert } from '@gsa/afp-component-library';
import { useUserDetails } from '../providers/user-details-provider';

const Types = {
  success: 'success',
};

const alertMessage = (assignSuccess, onClose) => {
  const { value, label } = assignSuccess;
  if (!value) return null;
  return (
    <Alert
      type={Types.success}
      data-testid="feedback-role-success-message"
      focused
      slim
      onClose={onClose}
    >
      <strong>{label}</strong> has been successfully assigned.
    </Alert>
  );
};

const FeedbackAssignRole = () => {
  const { assignSuccess, assignRoleSuccess } = useUserDetails();
  const handleClose = () => {
    assignRoleSuccess(false, {});
  };
  return alertMessage(assignSuccess, handleClose);
};

export default FeedbackAssignRole;
