import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  FormGroup,
  Button,
  Textbox,
  Spinner,
  ErrorMessage,
  Breadcrumbs,
  InteractiveCheck,
  PageTitle,
} from '@gsa/afp-component-library';
import { useForm } from 'react-hook-form';
import { useLazyQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { InternalError, useCurrentUser, useTitle } from '@gsa/afp-shared-ui-utils';
import './signup.scss';
import {
  GET_PROSPECT,
  ACTIVATE_PROSPECT,
  SET_CREDENTIALS,
} from '../../services/data-layer';
import {
  containsStr,
  containsWord,
  hasMinLen,
  hasUpperCase,
  hasLowerCase,
  hasNumber,
  hasSpace,
  hasSpecial,
  passwordHasEmailParts
} from '../../utilities/StringUtils';
import ResendActivationLink from './resend-activation-link';
import ActivationTokenError from '../error/activation-token-error';
import { RequiredFieldsHint } from '../../components/Required/RequiredFieldsHint';

const AccountSetup = ({ token }) => {
  useTitle("Account Setup");
  const auth = useCurrentUser();
  const [invalidState, setInvalidState] = useState(false);
  const [invalidActivationToken, setInvalidActivationToken] = useState(false);
  const [activationSuccessful, setActivationSuccessful] = useState(false);
  const [resendActivationLink, setResendActivationLink] = useState(false);
  const {
    register,
    formState: { errors, dirtyFields },
    watch,
    handleSubmit,
    getValues,
    trigger,
  } = useForm({
    mode: 'onBlur',
  });

  useEffect(() => {
    const accountUpdatedHeading = document.getElementById(
      'account-updated-heading',
    );
    if (accountUpdatedHeading) {
      accountUpdatedHeading.focus();
    }
  }, [resendActivationLink]);

  const [
    getProspect,
    { loading: getProspectLoading, data: prospect },
  ] = useLazyQuery(GET_PROSPECT, {
    context: {
      clientName: 'public',
    },
    onError: () => {
      setInvalidState(true);
    },
  });

  const [setCredentials, { loading: settingCredentials }] = useMutation(
    SET_CREDENTIALS,
    {
      context: {
        clientName: 'public',
      },
      onError: () => {
        setInvalidState(true);
      },
      onCompleted: () => {
        setActivationSuccessful(true);
      },
    },
  );

  const [activateProspect, { loading: activatingProspect }] = useMutation(
    ACTIVATE_PROSPECT,
    {
      context: {
        clientName: 'public',
      },
      onError: ({ graphQLErrors = [] }) => {
        if (
          graphQLErrors.find(
            ({ extensions }) =>
              extensions?.exception?.name === ActivationTokenError.TokenExpired,
          )
        ) {
          setInvalidActivationToken(true);
        } else {
          setInvalidState(true);
        }
      },
      onCompleted: () => {
        // Success
      },
    },
  );

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    token && getProspect({ variables: { code: token } });
  }, [token]);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    token &&
      activateProspect({
        variables: { code: token },
      });
  }, [token]);

  const pwd = watch('password', '');
  const confirmPwd = watch('confirmPassword', '');
  const securityAns = watch('securityAnswer', '');

  const onSubmit = (data) => {
    setCredentials({
      variables: {
        code: token,
        password: data.password,
        recoveryQuestion: data.securityQuestion,
        recoveryAnswer: data.securityAnswer,
      },
    });
  };

  /**
   * to login for External Users using Okta ( Vendors, Customer and Public Users)
   * @param {*}
   */
  const handleLoginExternal = async (e) => {
    e.preventDefault();
    await auth.loginUserExternal();
  };

  const handleResendActivationLink = async (e) => {
    e.preventDefault();
    setResendActivationLink(true);
  };

  const hasErrors = (condition) => {
    return condition ? 'error' : '';
  };

  const passwordRequirements = [
    {
      id: 1,
      condition: hasMinLen(pwd),
      message: 'Minimum 12 characters',
    },
    {
      id: 2,
      condition: hasUpperCase(pwd),
      message: 'At least one uppper case',
    },
    {
      id: 3,
      condition: hasLowerCase(pwd),
      message: 'At least one lower case',
    },
    {
      id: 4,
      condition: hasNumber(pwd),
      message: 'At least one number (0-9)',
    },
    {
      id: 5,
      condition: hasSpecial(pwd),
      message: 'At least one special character (e.g.,!@#$%&*)',
    },
    {
      id: 6,
      condition: hasSpace(pwd),
      message: 'No space character',
    },
    {
      id: 7,
      condition: !containsStr(pwd, prospect?.getProspectById?.firstName),
      message: 'Password may not contain first name',
    },
    {
      id: 8,
      condition: !containsStr(pwd, prospect?.getProspectById?.lastName),
      message: 'Password may not contain last name',
    },
    {
      id: 9,
      condition: !passwordHasEmailParts(pwd, prospect?.getProspectById?.email),
      message: 'Password may not contain any parts of email address',
    },
  ];

  const securityAnswerRequirements = [
    {
      id: 1,
      condition: securityAns !== pwd,
      message: 'Answer may not contain your password',
    },
    {
      id: 2,
      condition: securityAns !== prospect?.getProspectById?.email,
      message: 'Answer may not contain your email address',
    },
    {
      id: 3,
      condition: !containsWord(securityAns, getValues('securityQuestion')),
      message: 'Answer may not be or contain part of the security question',
    },
    {
      id: 4,
      condition: securityAns?.length >= 4,
      message: 'Minimum 4 characters',
    },
    {
      id: 5,
      condition: securityAns?.length <= 100,
      message: 'Maximum 100 characters',
    },
  ];

  return (
    <>
      {(getProspectLoading || activatingProspect || settingCredentials) && (
        <Spinner aria-busy="true" className="loading_backdrop" size="large" />
      )}
      {invalidActivationToken ? (
        <>
          {resendActivationLink ? (
            <ResendActivationLink token={token} />
          ) : (
            <div className="margin-bottom-6">
              <div className="font-sans-2xl text-bold">
                Activation Link Has Expired
              </div>
              <p className="measure-6" aria-hidden="true">
                To protect your account, the activation link has expired after 7
                days or <br />
                immediately in the event of suspicious activity.
              </p>
              <Button
                label="Resend Activation Link"
                type="button"
                variant="primary"
                id="external-login"
                className="usa-button"
                onClick={handleResendActivationLink}
              />
            </div>
          )}
        </>
      ) : (
        <>
          {activationSuccessful ? (
            <div className="margin-bottom-6">
              <PageTitle
                id="account-updated-heading"
                title="Your Account is Successfully Updated"
              />
              <p className="measure-6" aria-hidden="true">
                Please log in.
              </p>
              <Button
                label="Log in"
                type="button"
                variant="primary"
                id="external-login"
                data-testid="external-login"
                className="usa-button"
                onClick={handleLoginExternal}
              />
            </div>
          ) : (
            <div>
              <Breadcrumbs
                trail={[
                  <Link style={{ textDecoration: 'underline' }} to="/">
                    Home
                  </Link>,
                ]}
                current="Account setup"
              />
              <PageTitle title="Account setup" />
              {invalidState ? (
                <InternalError />
              ) : (
                <>
                  <p>
                    Please set up a new password and security question to
                    complete your registration.
                  </p>
                  <form
                    data-testid="update-account-form"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <fieldset className="usa-fieldset margin-top-6">
                      <legend className="usa-legend margin-bottom-neg-1">
                        <h2 className="text-uppercase text-primary font-body-md">
                          Set up new password
                        </h2>
                      </legend>
                      <RequiredFieldsHint />
                      <FormGroup
                        className="margin-left-0"
                        error={errors && errors.password}
                      >
                        <label
                          className="usa-label text-bold"
                          htmlFor="password"
                        >
                          Enter new password{' '}
                          <abbr
                            title="required"
                            className="usa-label--required"
                          >
                            *
                          </abbr>
                        </label>
                        {errors && errors.password && (
                          <ErrorMessage>
                            Please provide a secure password
                          </ErrorMessage>
                        )}
                        <Textbox
                          data-testid="new-password-testid"
                          name="password"
                          type="password"
                          size="large"
                          id="password"
                          inputRef={register({
                            required: true,
                            minLength: 12,
                            maxLength: 72,
                            pattern: {
                              value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?^\S*$)(?=.*?[!@#$%^&*]).{12,}$/,
                            },
                            validate: {
                              firstName: (value) =>
                                !containsStr(
                                  value,
                                  prospect.getProspectById.firstName,
                                ),
                              lastName: (value) =>
                                !containsStr(
                                  value,
                                  prospect.getProspectById.lastName,
                                ),
                              username: (value) =>
                                !passwordHasEmailParts(
                                  value,
                                  prospect.getProspectById.email,
                                ),
                            },
                          })}
                          className="usa-input"
                          variant={hasErrors(errors.password)}
                          onChange={async () => {
                            if (dirtyFields.confirmPassword) {
                              trigger('confirmPassword');
                            }
                          }}
                        />
                      </FormGroup>
                      <div aria-hidden="true">
                        <InteractiveCheck
                          checks={passwordRequirements}
                          isDirty={dirtyFields.password}
                        />
                      </div>
                      <FormGroup
                        className="margin-left-0"
                        error={errors && errors.confirmPassword}
                      >
                        <label
                          className="usa-label text-bold"
                          htmlFor="confirmPassword"
                        >
                          Re-type new password{' '}
                          <abbr
                            title="required"
                            className="usa-label--required"
                          >
                            *
                          </abbr>
                        </label>
                        {errors &&
                          errors.confirmPassword &&
                          errors.confirmPassword.message && (
                            <ErrorMessage>
                              {errors.confirmPassword &&
                                errors.confirmPassword.message}
                            </ErrorMessage>
                          )}
                        <Textbox
                          name="confirmPassword"
                          type="password"
                          size="large"
                          id="confirmPassword"
                          data-testid="confirm-password-testid"
                          inputRef={register({
                            required: 'Please re-type your password',
                            validate: () =>
                              confirmPwd === pwd ||
                              'Password does not match. Please try again.',
                          })}
                          className="usa-input"
                          variant={hasErrors(errors.confirmPassword)}
                        />
                      </FormGroup>
                    </fieldset>
                    <fieldset className="usa-fieldset margin-top-6 margin-bottom-6">
                      <legend className="usa-legend margin-bottom-neg-3">
                        <h2 className="text-uppercase text-primary font-body-md">
                          Set up recovery question
                        </h2>
                      </legend>
                      <p>
                        Please enter a security question for account recovery.
                      </p>
                      <FormGroup
                        className="margin-left-0"
                        error={errors && errors.securityQuestion}
                      >
                        <label
                          className="usa-label text-bold"
                          htmlFor="security-question"
                        >
                          Enter security question{' '}
                          <abbr
                            title="required"
                            className="usa-label--required"
                          >
                            *
                          </abbr>
                        </label>
                        {errors &&
                          errors.securityQuestion &&
                          errors.securityQuestion.message && (
                            <ErrorMessage>
                              {errors.securityQuestion &&
                                errors.securityQuestion.message}
                            </ErrorMessage>
                          )}
                        <Textbox
                          name="securityQuestion"
                          type="text"
                          size="large"
                          id="security-question"
                          inputRef={register({
                            required: true,
                            minLength: 4,
                            maxLength: 100,
                          })}
                          className="usa-input"
                          variant={hasErrors(errors.securityQuestion)}
                        />
                        <div className="margin-top-1 text-base">
                          A minimum of 4 characters and maximum 100 characters
                          allowed
                        </div>
                      </FormGroup>
                      <FormGroup
                        className="margin-left-0"
                        error={errors.securityAnswer}
                      >
                        <label
                          className="usa-label text-bold"
                          htmlFor="security-answer"
                        >
                          Enter security answer{' '}
                          <abbr
                            title="required"
                            className="usa-label--required"
                          >
                            *
                          </abbr>
                        </label>
                        {errors && errors.securityAnswer && (
                          <ErrorMessage>
                            Please provide a valid security answer
                          </ErrorMessage>
                        )}
                        <Textbox
                          name="securityAnswer"
                          type="text"
                          size="large"
                          id="security-answer"
                          maxLength="100"
                          inputRef={register({
                            required: true,
                            minLength: 4,
                            maxLength: 100,
                            validate: {
                              username: (value) =>
                                prospect?.getProspectById?.email !== value,
                              password: (value) =>
                                getValues('password') !== value,
                              securityQuestion: (value) =>
                                !containsWord(
                                  value,
                                  getValues('securityQuestion'),
                                ),
                            },
                          })}
                          className="usa-input"
                          variant={hasErrors(errors.securityAnswer)}
                        />
                      </FormGroup>
                      <div aria-hidden="true">
                        <InteractiveCheck
                          checks={securityAnswerRequirements}
                          isDirty={dirtyFields.securityAnswer}
                        />
                      </div>
                    </fieldset>
                    <Button
                      label="Update your account"
                      variant="primary"
                      id="update-account"
                      data-testid="update-account-testid"
                      className="usa-button margin-top-4 margin-bottom-6"
                      type="submit"
                    />
                  </form>
                </>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

AccountSetup.propTypes = {
  token: PropTypes.string.isRequired,
};

export default AccountSetup;
