import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Modal,
  connectModal,
  Button,
  Spinner,
} from '@gsa/afp-component-library';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import { useUser } from '../../providers';
import {
  GET_USERS_BY_IDS,
  RECERTIFY_USERS,
} from '../../../../services/data-layer';
import Content from './content/recertification-content';
import { RecertifiableStatus } from '../../constants/user-constants';
import './styles/modal.css';

const Title = (props) => {
  const { users } = props;
  return (
    <h2 className="padding-bottom-2">{`${users.length} user(s) will be recertified`}</h2>
  );
};

Title.defaultProps = {
  users: [],
};
Title.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape),
};

const Actions = ({ onCancel, onConfirm, hideButton }) => {
  return (
    <>
      <Button
        label="Cancel"
        variant={`${hideButton ? 'primary' : 'unstyled'}`}
        className="padding-right-1"
        onClick={onCancel}
        data-testid="user-recertification-cancel-btn"
      />
      {!hideButton ? (
        <Button
          label="Recertify user(s)"
          variant="primary"
          type="submit"
          data-testid="user-recertification-confirm-btn"
          onClick={onConfirm}
        />
      ) : null}
    </>
  );
};

Actions.defaultProps = {
  hideButton: false,
};

Actions.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  hideButton: PropTypes.bool,
};

const ConfirmRecertificationModal = () => {
  const { currentUser } = useCurrentUser();
  const {
    confirmRecertification,
    selectedUsers,
    setFeedbackMessage,
    refetchUsers,
  } = useUser();
  const [recertifiableUsers, setRecertifiableUsers] = useState([]);
  const [notRecertifiableUsers, setNotRecertifiableUsers] = useState([]);
  const [selfCertifyUser, setSelfCertifyUser] = useState();
  const [getUsersByIds, { data: users, loading: loadingUsers }] = useLazyQuery(
    GET_USERS_BY_IDS,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onError: () => {
        setFeedbackMessage({ type: 'error' });
      },
      onCompleted: (data) => {
        let selfCertifying;
        const certifiables = [];
        const notCertifiables = [];
        data.getUsersByIds.forEach((user) => {
          if (user.id === currentUser.id) {
            selfCertifying = user;
          } else if (user.recertifiable === RecertifiableStatus.RECERTIFIABLE) {
            certifiables.push(user);
          } else {
            notCertifiables.push(user);
          }
        });

        setSelfCertifyUser(selfCertifying);
        setRecertifiableUsers(certifiables);
        setNotRecertifiableUsers(notCertifiables);
      },
    },
  );
  useEffect(() => {
    if (Array.isArray(selectedUsers)) {
      getUsersByIds({ variables: { ids: selectedUsers } });
    }
  }, [selectedUsers]);

  const [recertifyUsers] = useMutation(RECERTIFY_USERS, {
    onError: () => {
      confirmRecertification(false);
      setFeedbackMessage({ type: 'error' });
    },
    onCompleted: () => {
      confirmRecertification(false);
      setFeedbackMessage({
        type: 'success',
        message: (
          <span>
            <b>{`${recertifiableUsers.length}`}</b> user(s) are recertified
            successfully.
          </span>
        ),
      });
      refetchUsers();
    },
  });

  const onRecertify = () => {
    const usersIds = recertifiableUsers.map((user) => user.id);
    recertifyUsers({
      variables: {
        ids: usersIds,
      },
    });
  };

  const hideButton = recertifiableUsers.length === 0;
  const ConfirmModal = connectModal(() => (
    <Modal
      title={<Title users={recertifiableUsers} />}
      variant="large"
      actions={
        <Actions
          onCancel={() => confirmRecertification(false)}
          onConfirm={() => onRecertify()}
          hideButton={hideButton}
        />
      }
      onClose={() => confirmRecertification(false)}
    >
      {users && (
        <Content
          recertifiableUsers={recertifiableUsers}
          notRecertifiableUsers={notRecertifiableUsers}
          selfCertifyUser={selfCertifyUser}
        />
      )}
    </Modal>
  ));

  return (
    <>
      {loadingUsers ? (
        <Spinner aria-busy="true" className="loading_backdrop" />
      ) : (
        <ConfirmModal isOpen={selectedUsers} />
      )}
    </>
  );
};

export default ConfirmRecertificationModal;
