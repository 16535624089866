import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { GET_USER_TYPES } from '../../../services/data-layer';

const RoleFilterContext = createContext({});
const useRoleFilter = () => useContext(RoleFilterContext);

const initialState = {
  error: '',
  userTypes: [],
  rolesStatus: [],
  filters: [],
};

const actions = {
  setUserTypes: 'SET_USER_TYPES',
  setRolesStatus: 'SET_ROLES_STATUS',
  setFilters: 'SET_FILTERS',
  setError: 'SET_ERROR',
};

const extractErrorMessage = (err) => err.message || 'Unknown Error';

const roleFilterReducer = (state, { action, payload }) => {
  const { setUserTypes, setRolesStatus, setFilters, setError } = actions;
  const { userTypes, rolesStatus, error } = initialState;

  switch (action) {
    case setUserTypes: {
      return { ...state, error, userTypes: payload || userTypes };
    }
    case setRolesStatus: {
      return { ...state, error, rolesStatus: payload || rolesStatus };
    }
    case setFilters: {
      return {
        ...state,
        error,
        filters: {
          operator: '$and',
          conditions: payload || [],
        },
      };
    }
    case setError: {
      return { ...state, error: extractErrorMessage(payload) };
    }
    default:
      throw new Error('Invalid role filter action');
  }
};

const RoleFilterProvider = ({ children, ...props }) => {
  const [state, setDispatch] = useReducer(
    roleFilterReducer,
    initialState,
    () => initialState,
  );

  const dispatch = (action, payload) => setDispatch({ action, payload });

  // Get User Types Lazy.
  const [getUserTypes] = useLazyQuery(GET_USER_TYPES, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (err) => dispatch(actions.setError, err),
    onCompleted: (data) => dispatch(actions.setUserTypes, data?.getUserTypes),
  });

  const getRolesStatus = () => {
    dispatch(actions.setRolesStatus, [
      { label: 'Active', value: '0' },
      { label: 'Deactivated', value: '1' },
    ]);
  };

  const setFilters = (value) => dispatch(actions.setFilters, value);

  return (
    <RoleFilterContext.Provider
      value={{
        ...state,
        ...props,
        getUserTypes,
        getRolesStatus,
        setFilters,
      }}
    >
      {children}
    </RoleFilterContext.Provider>
  );
};

RoleFilterProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { RoleFilterProvider as default, useRoleFilter };
