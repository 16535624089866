import React, { useState, useEffect } from 'react';
import { Spinner } from '@gsa/afp-component-library';
import { useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { GET_ROLE_EXTRA_INFO } from '../../../services/data-layer';

const RoleExtra = ({ roleId }) => {
  const [roleDetails, setRoleDetails] = useState(undefined);
  const handleError = () => {
    /* TODO display invalid state message */
  };

  const [getRoleDetails, { data: roleData, loading }] = useLazyQuery(
    GET_ROLE_EXTRA_INFO,
    {
      onError: () => handleError,
      onCompleted: () => setRoleDetails(roleData.getRole),
      fetchPolicy: 'cache-and-network',
    },
  );

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    roleId && getRoleDetails({ variables: { roleId } });
  }, [roleId]);

  const showLoading = !roleDetails && loading;

  return (
    <>
      {showLoading && <Spinner size="small" />}
      {roleDetails && (
        <div className="grid-container padding-top-2 padding-bottom-2">
          <div className="tablet:display-flex">
            <div className="grid-col padding-top-1 padding-bottom-1">
              <div className="text-bold padding-bottom-1">Description</div>
              <div>{roleDetails.desc || '-'}</div>
            </div>
            <div className="grid-col tablet:padding-left-4">
              <div className="tablet:display-flex tablet:flex-justify tablet:border-base-lighter tablet:border-bottom padding-top-1 padding-bottom-1">
                <div
                  className="text-bold margin-right-1"
                  style={{ flex: '0 0 auto' }}
                >
                  Role inheritance
                </div>
                <span>
                  {roleDetails.roleParentNames &&
                  roleDetails.roleParentNames.length
                    ? roleDetails.roleParentNames.join(', ')
                    : '-'}
                </span>
              </div>
              <div className="tablet:display-flex tablet:flex-justify tablet:border-base-lighter tablet:border-bottom padding-top-1 padding-bottom-1">
                <div className="text-bold">Assigned users</div>
                <span>{roleDetails.assignedUsers || 0}</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

RoleExtra.propTypes = {
  roleId: PropTypes.string.isRequired,
};

export default RoleExtra;
