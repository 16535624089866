import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery, useLazyQuery } from '@apollo/client';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import { PageTitle, Breadcrumbs, SelectDropdown, AFPTable, EmptyState, Button, Label } from '@gsa/afp-component-library';
import { GET_AGENCIES_BY_PERMISSION } from '../../services/data-layer';
import { GET_ATTACHMENTS } from "../../components/Attachment/helpers";

const DocumentationManagement = () => {
  const [agencyData, setAgencyData] = useState([]);
  const [agency, setAgency] = useState([]);
  const [attachmentData, setAttachmentData] = useState({
    rows: [],
    count: 0,
    hasMore: false,
  });
  const { currentUser } = useCurrentUser();
  const isSiteAdmin = currentUser?.roles?.some(
    (role) => role.name === 'Site Administrator',
  )
  const isCustomerAdmin = currentUser?.roles?.some(
    (role) => role.name === 'Customer Administrator',
  );

  const { data: agencies } = useQuery(GET_AGENCIES_BY_PERMISSION, {
    variables: {
      operation: 'view',
      subject: 'OrganizationProfile',
    },
  });

  const [getAttachments] = useLazyQuery(
    GET_ATTACHMENTS,
    {
      onCompleted: (data) => {
        if (data?.getAttachments) {
          const attachmentResponse = data?.getAttachments;
          setAttachmentData({
            ...attachmentResponse,
          });
        }
      }
    },
  );

  const getFormattedDate = (cretaedDate) => {
    const date = new Date(cretaedDate);
    const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    return formattedDate;
  }

  useEffect(() => {
    if (agencies) {
      const agenciesData = agencies && agencies?.getAgenciesByPermission?.map((o) => {
        return { label: o.name, value: o.id }
      });
      setAgencyData(agenciesData);
      if (agenciesData?.length === 1) {
        setAgency(agenciesData[0]?.value);
        getAttachments({
          variables: {
            filter: { agencyCode: agenciesData[0]?.value },
            limit: 5,
            offset: 0,
            order: [['createdAt', 'DESC']],
          }
        })
      }
    }
  }, [agencies?.getAgenciesByPermission]);


  const displayBtnValidation = isCustomerAdmin || isSiteAdmin;


  const columns = useMemo(() => {
    const documentsColumns = [
      {
        Header: 'Category',
        accessor: 'category',
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original } }) => {
          return original?.type;
        },
      },
      {
        Header: 'File name',
        accessor: 'fileName',
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original } }) => {
          return (
            <Button
              data-testid="download-button"
              variant="unstyled"
              onClick={() => { }}
              // eslint-disable-next-line react/prop-types
              label={original?.name}
            />
          );
        },
      },
      {
        Header: 'Description',
        accessor: 'description',
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original } }) => {
          return original?.description;
        },
      },
      {
        Header: 'Date uploaded',
        accessor: 'createdAt',
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original } }) => {
          return getFormattedDate(original.createdAt);
        },
      },
      {
        Header: 'Actions',
        id: 'table-row-action',
        sortable: false,
        // eslint-disable-next-line react/prop-types
        Cell: () => {
          return (
            <Button
              disabled={!displayBtnValidation}
              label="Delete"
              leftIcon={{ name: "close" }}
              variant="unstyled"
              type="button"
              onClick={() => { }}
            />
          );
        },
      },
    ];

    return documentsColumns;

  }, []);

  return (
    <div className="grid-col">
      <>
        <Breadcrumbs
          trail={[
            <Link style={{ textDecoration: 'underline' }} to="/home">Home</Link>,
            <Link style={{ textDecoration: 'underline' }} to="/admin/organization-profile">Organization Profile</Link>
          ]}
          current="Document Management"
        />
        <PageTitle title='Documentation Management' />

        {agencyData && (
          <SelectDropdown
            label={
              <Label className="agency-dropdown-label">
                Show documents for organization:
              </Label>
            }
            name="agency"
            options={[{ value: '', label: 'Select agency' }, ...agencyData]}
            onChange={(e) => {
              setAgency(e.target.value);
              getAttachments({
                variables: {
                  filter: { agencyCode: e.target.value },
                  limit: 5,
                  offset: 0,
                  order: [['createdAt', 'DESC']],
                }
              })

            }}
            value={agency}
            data-testid="documentation-agency"
          />
        )}

        <>
          <div className="agency-documentation">
            <div className="attachments-title">Agency documentation</div>
            <Button
              disabled={!displayBtnValidation}
              onClick={() => { /* Upload logic will be implemented in continuation story AFP-130463  */ }}
              type="button"
              variant="outline"
              leftIcon={{ name: 'file_upload' }}
              label="Upload file"
            />
          </div>
          <AFPTable
            testId="documentation-table"
            columns={columns}
            data={attachmentData?.rows}
          />

          <EmptyState
            hasBackground
            containerStyles="margin-top-neg-2 padding-y-10"
            bottomText="No files uploaded"
          />
        </>
      </>
    </div>
  );
};

export default DocumentationManagement;
