import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { Spinner } from '@gsa/afp-component-library';
import {
  propTypeFormContext,
  StandardLabeledSelectDropdown,
} from '../../../components/Forms';
import { GET_DIVISIONS_BRANCHES } from '../../../services/data-layer';

const defaultDropdownOptions = [
  { value: '-', label: 'Please select an option' },
];

const getBranchOptions = (divisions, divisionId) => {
  if(!divisions){
    return [];
  }
  const div = divisions.find(z => z.id === divisionId);
  if(!div || !div.branches){
    return [];
  }
  const opts = [...div.branches]
    .sort((b1,b2)=> b1.name.localeCompare(b2.name))
    .map(b => ({value: b.id, label: b.name}));
  opts.unshift(...defaultDropdownOptions);
  return opts;
}

export default function UserProfileDivisionBranches({ formContext, setInvalidState }){
  const [ divisions, setDivisions ] = useState(defaultDropdownOptions);
  const [ branches, setBranches ] = useState(defaultDropdownOptions);
  const [ allDivisionsAndBranches, setAllDivisionsAndBranches] = useState([]);

  const [ getDivisionAndBranches, { loading: loadingDivisionBranch } ] = useLazyQuery(GET_DIVISIONS_BRANCHES, {
    onError: () => {
      setInvalidState(true, 'getDivisionAndBranches');
    },
    onCompleted: (data) => {
      const options = data.getDivisions.map(d =>
        ({value: d.id, label: d.name}));
      options.unshift(...defaultDropdownOptions);
      setDivisions(options);
      // set chosen division's branches
      if (formContext.defaultValues.divisionId) {
        const opts = getBranchOptions(data.getDivisions, formContext.defaultValues.divisionId)
        setBranches(opts);
      }
      setAllDivisionsAndBranches(data.getDivisions);
    },
    fetchPolicy: 'cache-and-network',
  })

  useEffect(getDivisionAndBranches, []);

  return loadingDivisionBranch ? <Spinner /> : (
    <>
      <StandardLabeledSelectDropdown
        label="Which Headquarter division are you assigned to?"
        formContext={formContext}
        name="divisionId"
        options={divisions}
        required
        onChangeCallback={ev => {
          const chosenId = ev.target.value;
          const options = getBranchOptions(allDivisionsAndBranches, chosenId)
          setBranches(options)
        }}
      />
      {branches.length > 1 && <StandardLabeledSelectDropdown
        formContext={formContext}
        name="branchId"
        label="Which Branch are you assigned to?"
        options={branches}
        required
      />}
    </>
  );
}

UserProfileDivisionBranches.propTypes = {
  formContext: propTypeFormContext.isRequired,
  setInvalidState: PropTypes.func.isRequired,
};

