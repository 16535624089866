import PropTypes from 'prop-types';
import React from 'react';
import { propTypeFormContext } from './createFormContext';
import { StandardFormGroup } from './standard-form-group';

export const StandardCheckbox = ({
  formContext,
  name,
  label,
  ...restProps
}) => {
  const { control } = formContext;
  return (
    <div data-testid={`${name}_wrapper`} className="usa-checkbox">
      <input
        className="usa-checkbox__input"
        id={name}
        type="checkbox"
        name={name}
        ref={control.register}
        aria-label={label}
        {...restProps}
      />
      <label className="usa-checkbox__label" htmlFor={name}>
        {label}
      </label>
    </div>
  );
};

StandardCheckbox.propTypes = {
  formContext: propTypeFormContext.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

StandardCheckbox.defaultProps = {};

export default StandardCheckbox;

export const StandardLabeledCheckbox = ({ formContext, name, label }) => {
  return (
    <StandardFormGroup formContext={formContext} name={name}>
      <StandardCheckbox formContext={formContext} label={label} name={name} />
    </StandardFormGroup>
  );
};

StandardLabeledCheckbox.propTypes = {
  formContext: propTypeFormContext.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

StandardLabeledCheckbox.defaultProps = {};
