import { gql } from '@apollo/client';

export const GET_OFFICE_MANAGEMENTS = gql`
query GetOfficesList(
  $order: OrderBy!
  $limit: Int!
  $offset: Int!
  $filters: [Filter!]
  $agencyCode: String!
  $bureauCode: String
) {
  getOfficesList(
    order: $order
    limit: $limit
    offset: $offset
    filters: $filters
    agencyCode: $agencyCode
    bureauCode: $bureauCode
  ) {
    rows {
      bureauCode
      agencyCode
      officeCode
      officeName
      bureau {
        shortName
        name
      }
      agency {
        name
      }
    }
    count
    hasMore
  }
}
`;

export const GET_OFFICE_MANAGEMENT_LIST = gql`
  query GetOfficesList(
    $order: OrderBy!
    $limit: Int!
    $offset: Int!
    $filters: [Filter!]
    $agencyCode: String!
    $bureauCode: String
  ) {
    getOfficesList(
      order: $order
      limit: $limit
      offset: $offset
      filters: $filters
      agencyCode: $agencyCode
      bureauCode: $bureauCode
    ) {
      rows {
        bureauCode
        agencyCode
        officeCode
        officeName
        agency {
          name
        }
        bureau {
          shortName
          name
        }
      }
      count
      hasMore
    }
  }
`;

export const GET_OFFICE_MANAGEMENT_LIST_FILTERS = gql`
  query getOfficeMangementFilters {
    bureauFilter: getBureaus {
      label: name
      value: id
    }
    agencyFilter: getAgencies {
      label: name
      value: id
    }
  }
`;

export const DELETE_OFFICE_MANAGEMENT = gql`
  mutation vendorDelete($fleet_vendor_no: String!) {
    vendorDelete(fleetVendorNumber: $fleet_vendor_no) {
      fleetVendorNumber
    }
  }
`;

export const SEARCH_OFFICE_MANAGEMENT = gql`
  query vendorSearch($col: String!, $queryStr: String!, $limit: Float) {
    vendorSearch(col: $col, queryStr: $queryStr, limit: $limit) {
      fleetVendorNumber
      vendorName
      contactPhone
      city
      emailAddress
    }
  }
`;

export const GET_OFFICE_MANAGEMENT_TYPE_AHEAD_OPTIONS = gql`
  query getVendorTypeAheadOptions($key: String!, $search: String!) {
    getVendorTypeAheadOptions(key: $key, search: $search)
  }
`;

export const CHANGE_OFFICE_MANAGEMENT_FLEET_STATUS = gql`
  mutation toggleVendorFleetStatus($input: ToggleVendorFleetStatusInput!) {
    toggleVendorFleetStatus(fleetStatusToggleInput: $input) {
      id
      vendorName
      fleetStatus
      fleetStatusUpdatedOn
    }
  }
`;

export const CREATE_OFFICE = gql`
  mutation CreateOffice($office: CreateOfficeInput!) {
    createOffice(office: $office) {
      agencyCode
      bureauCode
      officeCode
      officeName
    }
  }
`;

export const EDIT_OFFICE = gql`
  mutation EditOffice(
    $officeName: String!
    $agencyCode: String!
    $bureauCode: String!
    $officeCode: String!
  ) {
    editOffice(
      officeName: $officeName
      agencyCode: $agencyCode
      bureauCode: $bureauCode
      officeCode: $officeCode
    ) {
      agencyCode
      bureauCode
      officeCode
      officeName
    }
  }
`;

export default {
  GET_OFFICE_MANAGEMENT_TYPE_AHEAD_OPTIONS,
  GET_OFFICE_MANAGEMENT_LIST,
  GET_OFFICE_MANAGEMENT_LIST_FILTERS,
  SEARCH_OFFICE_MANAGEMENT,
  GET_OFFICE_MANAGEMENTS,
  CHANGE_OFFICE_MANAGEMENT_FLEET_STATUS,
  CREATE_OFFICE,
  EDIT_OFFICE
};
