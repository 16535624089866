import React from 'react';
import PropTypes from 'prop-types';
import { DetailsTable, Spinner, Alert } from '@gsa/afp-component-library';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import { VENDOR_TYPE_MAP } from '../../constants';
import { isUserVendor } from '../../../utilities/user';

const VendorUserProfileCard = ({ vendors, isLoading }) => {
  const { currentUser } = useCurrentUser();

  if (isLoading) return <Spinner size="small" />;

  if (!vendors || vendors.length === 0) return null;

  return vendors.map((vendor) => {
    const address = vendor.vendorContacts.find(
      (contact) => contact.contactType === 'VendorPhysicalContact',
    );
    return (
      <div
        key={vendor.id}
        className="customer-details mobile-lg:grid-col-12 tablet:grid-col-8 desktop:grid-col-6"
      >
        <h2 className="text-heavy text-primary font-sans-xs text-uppercase">
          {vendor.vendorName}
        </h2>
        <div className="grid-container-widescreen details-locked bg-gray-3 padding-3 radius-md">
          {isUserVendor(currentUser) && !vendor.isConfirmed && (
            <Alert className="margin-bottom-2" type="warning" slim>
              Please review company profile details
            </Alert>
          )}
          <div className="text-bold margin-bottom-1">
            <a href={`/vendor/details/${vendor.id}`}>
              View Vendor Company Profile
            </a>
          </div>
          <DetailsTable
            data={[
              ['City/Town', address?.city],
              ['State/Province/Region', address?.state?.stateCode],
              [
                'Vendor type/s',
                vendor.vendorTypes
                  ?.map((type) => VENDOR_TYPE_MAP[type.vendorTypeCode])
                  .join(', '),
              ],
            ]}
            bordered
          />
        </div>
      </div>
    );
  });
};

VendorUserProfileCard.propTypes = {
  vendors: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool,
};
VendorUserProfileCard.defaultProps = {
  isLoading: false,
};

export default VendorUserProfileCard;
