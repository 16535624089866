/* eslint-disable import/prefer-default-export */
const CatalogSubjects = {
  StandardItem: 'StandardItem',
  EquipmentCodes: 'EquipmentCodes',
  CatalogCodes: 'CatalogCodes',
};

const CatalogOperations = {
  VIEW: 'view',
};

export const canViewStandardItem = (ability) =>
  ability.can(CatalogOperations.VIEW, CatalogSubjects.StandardItem);

export const canViewEquipmentCode = (ability) =>
  ability.can(CatalogOperations.VIEW, CatalogSubjects.EquipmentCodes);

export const canViewCatalogCode = (ability) =>
  ability.can(CatalogOperations.VIEW, CatalogSubjects.CatalogCodes);
