import React,{useState} from 'react';
import {
  propTypeFormContext,
  propTypeSelectOptions,
  StandardLabeledSelectDropdown,
  StandardLabeledTextbox,
} from '../../components/Forms';
import {
  formatPostalCodeInternational,
  formatZipCodeDomestic,
} from './zipCodeFormatter';

export const USA_DOMESTIC_COUNTRY_CODE = 'US';
export const UserProfileWorkAddress = ({
  formContext,
  countriesOptions,
  statesOptions,
}) => {
  const { defaultValues } = formContext;
  const [isDomestic, setIsDomestic] = useState(defaultValues.countryCode=== USA_DOMESTIC_COUNTRY_CODE);

  return (
    <>
      <StandardLabeledSelectDropdown
        formContext={formContext}
        name="countryCode"
        label="Country"
        options={countriesOptions}
        required
        onChangeCallback={(ev) => {
          setIsDomestic(ev.target.value === USA_DOMESTIC_COUNTRY_CODE);
        }}
      />
      {isDomestic ? (
        <StandardLabeledTextbox
          formContext={formContext}
          key="addrline1Domestic"
          name="addrline1Domestic"
          label="Address line 1"
          required
        />
      ) : (
        <StandardLabeledTextbox
          formContext={formContext}
          key="addrline1International"
          name="addrline1International"
          label="Address line 1"
        />
      )}

      <StandardLabeledTextbox
        formContext={formContext}
        name="addrline2"
        label="Address line 2"
      />

      {isDomestic ? (
        <StandardLabeledTextbox
          formContext={formContext}
          key="cityDomestic"
          name="cityDomestic"
          label="City"
          required
          maxLength={50}
        />
      ) : (
        <StandardLabeledTextbox
          formContext={formContext}
          key="cityInternational"
          name="cityInternational"
          label="City / Town"
          maxLength={50}
        />
      )}

      {isDomestic ? (
        <StandardLabeledSelectDropdown
          formContext={formContext}
          key="stateDomestic"
          name="stateDomestic"
          label="State"
          required={isDomestic}
          options={statesOptions}
        />
      ) : (
        <StandardLabeledTextbox
          formContext={formContext}
          key="stateInternational"
          name="stateInternational"
          label="State / Province / Region"
          required={isDomestic}
          maxLength={50}
        />
      )}

      {isDomestic ? (
        <StandardLabeledTextbox
          formContext={formContext}
          key="zipCodeDomestic"
          name="zipCodeDomestic"
          label="ZIP Code"
          required
          maxLength={'55555-4444'.length}
          onBlur={(ev) => {
            const formattedZipCode = formatZipCodeDomestic(ev.target.value);
            formContext.control.setValue('zipCodeDomestic', formattedZipCode);
          }}
        />
      ) : (
        <StandardLabeledTextbox
          formContext={formContext}
          key="postalCodeInternational"
          name="postalCodeInternational"
          label="Postal code"
          maxLength={15}
          onBlur={(ev) => {
            const formattedPostalCode = formatPostalCodeInternational(
              ev.target.value,
            );
            formContext.control.setValue(
              'postalCodeInternational',
              formattedPostalCode,
            );
          }}
        />
      )}
    </>
  );
};

UserProfileWorkAddress.propTypes = {
  formContext: propTypeFormContext.isRequired,
  countriesOptions: propTypeSelectOptions.isRequired,
  statesOptions: propTypeSelectOptions.isRequired,
};

export default UserProfileWorkAddress;
