import { SelectDropdown } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller } from 'react-hook-form';
import { StandardFormGroup } from './standard-form-group';
import {
  propTypeFormContext,
  propTypeSelectOptions,
} from './createFormContext';

export const StandardControlledSelectDropdown = ({
  formContext,
  name,
  label,
  options,
  onChangeCallback,
  defaultValue,
  validationKey,
  required,
}) => {
  const { control, defaultValues, register, validationOptions } = formContext;
  const { errors } = formContext;
  const error =
    (errors[name] && (errors[name].message || errors[name].type)) || '';

  const defaultVal = defaultValue ?? defaultValues[name];
  const vk = validationKey || name;
  const validation = validationOptions[vk];

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultVal}
      render={({ onChange, value }) => {
        // Sanity check to make sure component is controlled (React recommendation)
        const val = value || ''; 
        return (
          <SelectDropdown
            id={name}
            name={name}
            onChange={(ev) => {
              onChange(ev);
              if (onChangeCallback) {
                onChangeCallback(ev);
              }
            }}
            options={options}
            value={val}
            defaultValue={defaultVal}
            data-testid={`${name}-select-dropdown`}
            aria-invalid={error ? 'true' : undefined}
            aria-label={label}
            required={required}
            inputRef={validation && register(validation)}
          />
        );
      }}
    />
  );
};

StandardControlledSelectDropdown.propTypes = {
  formContext: propTypeFormContext.isRequired,
  name: PropTypes.string.isRequired,
  options: propTypeSelectOptions.isRequired,
  defaultValue: PropTypes.string,
  onChangeCallback: PropTypes.func,
  validationKey: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
};

StandardControlledSelectDropdown.defaultProps = {
  defaultValue: undefined,
  onChangeCallback: undefined,
  validationKey: undefined,
  required: undefined,
  label: undefined,
};

export default StandardControlledSelectDropdown;

export const StandardLabeledSelectDropdown = ({
  formContext,
  name,
  label,
  options,
  onChangeCallback,
  defaultValue,
  validationKey,
  required,
}) => {
  return (
    <StandardFormGroup
      formContext={formContext}
      name={name}
      label={label}
      required={required}
    >
      <StandardControlledSelectDropdown
        formContext={formContext}
        label={label}
        name={name}
        options={options}
        required={required}
        defaultValue={defaultValue}
        validationKey={validationKey}
        onChangeCallback={onChangeCallback}
      />
    </StandardFormGroup>
  );
};

StandardLabeledSelectDropdown.propTypes = {
  formContext: propTypeFormContext.isRequired,
  name: PropTypes.string.isRequired,
  options: propTypeSelectOptions.isRequired,
  defaultValue: PropTypes.string,
  onChangeCallback: PropTypes.func,
  validationKey: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string.isRequired,
};

StandardLabeledSelectDropdown.defaultProps = {
  defaultValue: undefined,
  onChangeCallback: undefined,
  validationKey: undefined,
  required: undefined,
};
