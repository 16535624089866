import { useFeatureContext } from "@gsa/afp-shared-ui-utils"
import { useEffect, useState } from "react";

// eslint-disable-next-line
export const useFeature = () => {
    const [features, setFeatures] = useState({});
    const {
        featureError,
        featureData,
        isFeatureLoading
    } = useFeatureContext();

    useEffect(() => {
        if (isFeatureLoading || featureError) {
            setFeatures({});
        } else {
            setFeatures(featureData.getSharedEnabledFeatures
                .reduce((result, feat) => ({ ...result, [feat]: true }), {}));
        }

    }, [isFeatureLoading, featureError]);

    return features
}