import React from 'react';
import useQueryParam from './use-query-params';
import SignupPage from './signup';
import AccountSetup from './acctSetup';

const SignUpPage = () => {
  const [token] = useQueryParam('code', undefined);

  return <>{token ? <AccountSetup token={token} /> : <SignupPage />}</>;
};

export default SignUpPage;
