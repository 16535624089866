import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Button } from '@gsa/afp-component-library';
import React from 'react';

export const EditUserProfileButton = (props) => { 
  const history = useHistory();
  const {
    userId,
  } = props;
  return (
    <>
      <Button
        label="Edit user profile"
        leftIcon={{
          name: "edit"
        }}
        type="button"
        variant="primary"
        data-testid="edit-user-profile-button"
        onClick={() => {
          history.push(`/admin/users/${userId}/edit`);
        }}
      />    
    </>
  );
};

EditUserProfileButton.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default EditUserProfileButton;
