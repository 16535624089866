import {
  FormGroup,
  Button,
  Textbox,
  ErrorMessage,
  Spinner,
  Breadcrumbs,
  PageTitle,
  RequiredFieldIndicator,
} from '@gsa/afp-component-library';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import {
  InternalError,
  useCurrentUser,
  FleetTechnicalSupportContact,
  useTitle
} from '@gsa/afp-shared-ui-utils';
import validationOptions from './signup-validation';
import ExistingAccountError from './existing-account-error';
import DeactivatedAccountError from './deactivated-account-error';
import GSAExternalPathError from './gsa-external-path-error';
import './signup.scss';
import UserCreationError from '../error/user-creation-error';
import { Types } from '../users/constants/user-constants';
import SignupActivationEmail from './signup-activation-email';
import { REGISTER_PROSPECT } from '../../services/data-layer';

const SignupPage = () => {
  useTitle("Create Account");
  const auth = useCurrentUser();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    validateCriteriaMode: 'all',
    mode: 'all',
    shouldFocusError: true,
  });

  const [registrationResponse, setRegistrationResponse] = useState({});
  const [registrationError, setRegistrationError] = useState(undefined);
  const [userData, setUserData] = useState({});
  const email = watch('email', '');
  const confirmEmail = watch('confirmEmail', '');
  const knownErrors = [
    UserCreationError.ExistingUser,
    UserCreationError.DeactivatedUser,
  ];

  const isInvalidError = (err) => {
    return err && !knownErrors.some((knownError) => knownError === err);
  };
  const isUserExists = (err) => {
    return err === UserCreationError.ExistingUser;
  };
  const isUserDeactivated = (err) => {
    return err === UserCreationError.DeactivatedUser;
  };

  const isActivating = (state) => {
    return state === 'ACTIVATE';
  };
  const isAccountCreated = (state) => {
    return state === 'CREATED';
  };

  const [registerProspect, { loading: creatingProspect }] = useMutation(
    REGISTER_PROSPECT,
    {
      context: {
        clientName: 'public',
      },
      onError: ({ graphQLErrors = [] }) => {
        const err = graphQLErrors[0];
        const { exception } = err?.extensions || {};
        setRegistrationError(exception?.name || 'unknown');
      },
      onCompleted: (data) => {
        const { state } = data?.registerProspect;
        setRegistrationResponse({ state });
      },
    },
  );

  const onSubmit = async (data) => {
    if (data) {
      const variables = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        userType: Number(Types.CUSTOMER),
      };

      setRegistrationError(undefined);
      setUserData(variables);
      await registerProspect({ variables });
    }
  };

  const handleLoginExternal = async (e) => {
    e.preventDefault();
    await auth.loginUserExternal();
  };

  const hasErrors = (condition) => {
    return condition ? 'error' : '';
  };

  if (isAccountCreated(registrationResponse.state)) {
    return (
      <div className="desktop:grid-col-7">
        <Breadcrumbs
          trail={[
            <Link style={{ textDecoration: 'underline' }} to="/">
              Home
            </Link>,
          ]}
          current="Create Account"
        />
        <PageTitle title="Account successfully created" />
        <p>
          We noticed you have a GSA account already using your FAS ID and we
          have added your existing account to GSAFleet.gov to log in. For
          technical support, <FleetTechnicalSupportContact />.
        </p>
        <Button
          label="Log in using FAS ID"
          variant="primary"
          className="margin-top-2 margin-bottom-5"
          id="external-okta-login"
          onClick={handleLoginExternal}
          data-testid="handle-external-login-existing-user"
        />
      </div>
    );
  }

  if (isActivating(registrationResponse.state)) {
    return (
      <SignupActivationEmail
        token={registrationResponse.token}
        user={userData}
      />
    );
  }

  return (
    <div className="desktop:grid-col-7">
      {creatingProspect && (
        <Spinner aria-busy="true" className="loading_backdrop" size="large" />
      )}
      <Breadcrumbs
        trail={[
          <Link style={{ textDecoration: 'underline' }} to="/">
            Home
          </Link>,
        ]}
        current="Create Account"
      />
      {isInvalidError(registrationError) && <InternalError />}
      {isUserExists(registrationError) && <ExistingAccountError />}
      {isUserDeactivated(registrationError) && <DeactivatedAccountError />}
      <PageTitle title="Create Account" />
      <p>
        Let&apos;s get you set up with your GSAFleet.gov account.
        If you are a GSA employee, please use the{' '}
        <a
          href={`${process.env.PUBLIC_URL}/GSAFleet_gov-Account-Creation-User-Guide-v2.pdf`}
          className="mobile:display-block"
          target="_blank"
          rel="noopener noreferrer"
          data-testid="acct-creation-guide-testid"
        >
          account creation guide
        </a>{' '}
        to create an account unless you are responsible for managing GSA&apos;s
        owned vehicles.
      </p>

      <div className="divider margin-top-4 margin-bottom-2" />

      <h2>For GSA Fleet Vendors</h2>
      <div>
        Do not fill out this form. Please reach out to your GSA Fleet Point of
        Contact or email{' '}
        <a href="mailto:fleetsystemsmodernization@gsa.gov">
          fleetsystemsmodernization@gsa.gov
        </a>
        .
      </div>

      <div className="divider margin-top-4 margin-bottom-2" />

      <h2>For GSA Fleet Customers</h2>
      <div className="padding-bottom-2">
        Required fields are marked with an asterisk (
        <strong>
          <RequiredFieldIndicator />
        </strong>
        ).
      </div>

      <form
        noValidate
        className="width-full margin-bottom-6"
        data-testid="signup-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <fieldset className="usa-fieldset">
          <FormGroup className="margin-left-0" error={errors && errors.email}>
            <h3 className="text-uppercase text-primary">CONTACT INFORMATION</h3>
            <label className="usa-label text-bold" htmlFor="email">
              Email{' '}
              <abbr title="required" className="usa-label--required">
                *
              </abbr>
            </label>
            {errors && errors.email && errors.email.message && (
              <ErrorMessage id="error-email">
                {errors.email && errors.email.message}
              </ErrorMessage>
            )}

            <Textbox
              name="email"
              type="email"
              id="email"
              data-testid="email-testid"
              className="usa-input max-w-none"
              variant={hasErrors(errors.email)}
              aria-describedby="error-email"
              inputRef={register(validationOptions.email)}
              required
            />
          </FormGroup>
          {errors.email && errors.email.type === 'isGSAEmail' && (
            <GSAExternalPathError />
          )}

          <FormGroup
            className="margin-left-0"
            error={errors && errors.confirmEmail}
          >
            <label className="usa-label text-bold" htmlFor="confirm-email">
              Re-type email{' '}
              <abbr title="required" className="usa-label--required">
                *
              </abbr>
            </label>
            {errors && errors.confirmEmail && errors.confirmEmail.message && (
              <ErrorMessage id="error-confirm-email">
                {errors?.confirmEmail.message}
              </ErrorMessage>
            )}

            <Textbox
              name="confirmEmail"
              type="email"
              id="confirm-email"
              data-testid="confirm-email-testid"
              className="usa-input max-w-none"
              variant={hasErrors(errors.confirmEmail)}
              aria-describedby="error-confirm-email"
              inputRef={register({
                ...validationOptions.confirmEmail,
                validate: () =>
                  confirmEmail === email ||
                  'Your email address does not match. Please try again.',
              })}
              required
            />
          </FormGroup>
          <FormGroup
            className="margin-left-0"
            error={errors && errors.firstName && errors.firstName.message}
          >
            <label className="usa-label text-bold" htmlFor="first-name">
              First name{' '}
              <abbr title="required" className="usa-label--required">
                *
              </abbr>
            </label>
            {errors && errors.firstName && errors.firstName.message && (
              <ErrorMessage id="error-firstname">
                {errors.firstName && errors.firstName.message}
              </ErrorMessage>
            )}
            <Textbox
              name="firstName"
              type="text"
              id="first-name"
              data-testid="firstName-testid"
              className="usa-input max-w-none"
              variant={hasErrors(errors.firstName)}
              maxLength="51"
              aria-describedby="error-firstname"
              inputRef={register(validationOptions.firstName)}
              aria-invalid={errors.firstName ? 'true' : 'false'}
              required
            />
          </FormGroup>
          <FormGroup
            className="margin-left-0"
            error={errors && errors.lastName && errors.lastName.message}
          >
            <label className="usa-label text-bold" htmlFor="last-name">
              Last name{' '}
              <abbr title="required" className="usa-label--required">
                *
              </abbr>
            </label>
            {errors.lastName && errors.lastName.message && (
              <ErrorMessage id="error-lastname">
                {errors.lastName && errors.lastName.message}
              </ErrorMessage>
            )}
            <Textbox
              name="lastName"
              type="text"
              id="last-name"
              data-testid="lastName-testid"
              className="usa-input max-w-none"
              variant={hasErrors(errors.lastName)}
              maxLength="51"
              aria-describedby="error-lastname"
              inputRef={register(validationOptions.lastName)}
              required
            />
          </FormGroup>
        </fieldset>
        <Button
          label="Create account"
          variant="primary"
          id="create-account"
          className="usa-button margin-top-5"
          data-testid="create-account-testid"
          type="submit"
        />
      </form>
    </div>
  );
};

export default SignupPage;
