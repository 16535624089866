import React, { useContext, useEffect } from 'react';
import { Button } from '@gsa/afp-component-library';
import { useMutation } from '@apollo/client';
import { TOGGLE_TASK_STATUS } from '../../../services/data-layer';
import { taskContext } from '../TaskContextProvider';
import { taskStatusMapping } from '../helpers/taskStatus';

const CloseTaskButton = () => {
  const { task, setTask } = useContext(taskContext);
  const [toggleTaskStatus, { data }] = useMutation(TOGGLE_TASK_STATUS, {
    variables: {
      id: parseInt(task.id, 10),
      status: taskStatusMapping(task.status),
    },
  });

  useEffect(() => {
    if (data && data.toggleTaskStatus) {
      setTask({
        ...task,
        ...data.toggleTaskStatus,
      });
    }
  }, [data && data.toggleTaskStatus]);

  const buttonLabel = (status) => {
    switch (status) {
      case 'Open':
        return 'Mark this task as in progress';
      case 'Inprogress':
        return 'Mark this task as done';
      case 'Closed':
        return 'Reopen this task';
      default:
        return 'Mark this task as done';
    }
  };

  return <Button label={buttonLabel(task.status)} onClick={toggleTaskStatus} />;
};

export default CloseTaskButton;
