import { Button } from '@gsa/afp-component-library';
import React from 'react';
import { useUserDetails } from '../providers/user-details-provider';

const ApproveUserAccessButton = () => {
  const { userId, approveUserAccess } = useUserDetails();

  return (
    <>
      <Button
        label="Approve access"
        leftIcon={{
          name: "check"
        }}
        type="button"
        variant="primary"
        data-testid="approve-user-access-button"
        onClick={() => {
          approveUserAccess({ variables: { id: userId } });
        }}
      />
    </>
  );
};

export default ApproveUserAccessButton;
